import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { EBiddingStrategyClass, ECampaignObjective, type TTDCampaign } from '@feathr/blackbox';
import { Select } from '@feathr/components';
import { flattenError } from '@feathr/hooks';

import { validateStepOptimize } from '../AdWizardOptimizationStep.utils';
import type { IObjectiveOption } from './ObjectiveOption';
import ObjectiveOption from './ObjectiveOption';
import { createOptions } from './ObjectiveSelect.utils';
import ObjectiveValue from './ObjectiveValue';

interface IObjectiveSelectProps {
  campaign: TTDCampaign;
}

function ObjectiveSelect({ campaign }: IObjectiveSelectProps): JSX.Element {
  const { t } = useTranslation();
  const options = createOptions({ campaign, t });
  const selectedOption = options.find((option) => option.id === campaign.get('objective'));

  const validationErrors = validateStepOptimize(campaign);
  const error = flattenError(validationErrors.objective);
  const isDisabled = campaign.isPastEndDate && !campaign.isDraft;

  function checkDisabled(option: IObjectiveOption): boolean {
    return Boolean(option.disabled);
  }

  function handleSelectSingle(option: IObjectiveOption): void {
    campaign.set({
      objective: option.id as ECampaignObjective,
    });

    // Force the optimization strategy to auto when an auto-only objective is selected
    if (option.id !== ECampaignObjective.Custom) {
      const currentBiddingStrategy = toJS(campaign.get('bidding_strategy'));

      campaign.set({
        bidding_strategy: {
          ...currentBiddingStrategy,
          _cls: EBiddingStrategyClass.Auto,
        },
      });
    }
  }

  return (
    <Select
      attribute={'objective'}
      components={{
        Option: ObjectiveOption,
        SingleValue: ObjectiveValue,
      }}
      disabled={isDisabled}
      isOptionDisabled={checkDisabled}
      label={t('Campaign objective')}
      name={'campaign_objective'}
      onSelectSingle={handleSelectSingle}
      options={options}
      placeholder={t('Select an objective...')}
      validationError={error}
      value={selectedOption}
    />
  );
}

export default observer(ObjectiveSelect);
