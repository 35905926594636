import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import type { Font } from '@feathr/blackbox';
import {
  Button,
  CardV2 as Card,
  EmptyState,
  FileUpload,
  Input,
  Label,
  SaveButtonValid,
  Value,
} from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { useAccount, useLocalUrl, useStore } from '@feathr/extender/state';
import { useId } from '@feathr/hooks';

import * as styles from './FontEditPage.css';

interface ISaveButtonProps {
  font: Font;
  onSave: () => void;
}

const SaveButton = observer(({ font, onSave }: ISaveButtonProps) => {
  const validationErrors = font.validate([], false);
  return (
    <SaveButtonValid<Font>
      errors={toJS(validationErrors.errors)}
      method={font.isEphemeral ? 'add' : 'patch'}
      model={font}
      onSave={onSave}
    >
      Save
    </SaveButtonValid>
  );
});

function FontEditPage(): JSX.Element {
  const account = useAccount();
  const history = useHistory();
  const localUrl = useLocalUrl();
  const { fontId } = useParams<{ fontId: string }>();

  const { Fonts } = useStore();
  const [font] = useState<Font>(fontId === 'add' ? Fonts.create() : Fonts.get(fontId));
  const [fontFaceLoaded, setFontFaceLoaded] = useState(false);
  const elementId = useId();

  function onSave(): void {
    history.push(localUrl('/settings/account/fonts'));
  }

  function handleClearFilename(): void {
    font.set({ filename: '', key: '' });
    setFontFaceLoaded(false);
  }

  useEffect(() => {
    if (font.get('key')) {
      const fontFace = new FontFace(
        'sample',
        `url(https://feathr-api-fonts.s3.amazonaws.com/${encodeURIComponent(font.get('key'))})`,
      );
      fontFace.load().then(() => {
        document.fonts.add(fontFace);
        setFontFaceLoaded(true);
      });
    } else {
      setFontFaceLoaded(false);
    }
  }, [font.get('key')]);

  return (
    <Page loading={font.isPending} title={font.name}>
      <Card>
        <Card.Header title={font.isEphemeral ? 'Add Font' : 'Edit Font'} />
        <Card.Content addVerticalGap={true}>
          {!font.get('filename') && (
            <EmptyState
              description={'Accepts .eot, .otf, .svg, .ttf, .woff, and .woff2 files'}
              label={'No font uploaded'}
              theme={'slate'}
            >
              <FileUpload
                attribute={'filename'}
                disabled={!!font.get('filename')}
                helpPlacement={'bottom'}
                id={elementId}
                model={font}
                onUpload={(key, __, ___, originalFilename) => {
                  font.set({
                    name: originalFilename?.split('.')[0],
                    filename: originalFilename,
                    key,
                  });
                }}
                pickerOptions={{
                  accept: ['.eot', '.otf', '.svg', '.ttf', '.woff', '.woff2'],
                  maxFiles: 1,
                  exposeOriginalFile: true,
                  storeTo: {
                    location: 's3',
                    container: `feathr-api-fonts/${account.id}`,
                    access: 'public',
                    region: 'us-east-1',
                  },
                }}
              />
            </EmptyState>
          )}

          {/* {font.get('filename') && ( */}
          <>
            {font.get('filename') && (
              <div>
                <Label>Uploaded file</Label>
                <Value
                  className={styles.filename}
                  isClearable={true}
                  isLoading={font.isPending}
                  onClear={handleClearFilename}
                  type={'pre'}
                  value={font.get('filename')}
                />
              </div>
            )}
            {font.get('name') && (
              <Input
                attribute={'name'}
                helpText={
                  'This is what the font will be called in the template editor. Must be unique among your fonts.'
                }
                label={'Font name'}
                model={font}
                type={'text'}
              />
            )}
            {fontFaceLoaded && (
              <div>
                <Label>Sample</Label>
                <p style={{ fontFamily: 'sample', fontSize: '3rem', lineHeight: 1 }}>
                  Sphinx of black quartz, judge my vow.
                </p>
              </div>
            )}
          </>
          {/* )} */}
        </Card.Content>
        <Card.Actions>
          <Button key={'cancel'} onClick={onSave}>
            Cancel
          </Button>
          <SaveButton font={font} key={'save'} onSave={onSave} />
        </Card.Actions>
      </Card>
    </Page>
  );
}

export default observer(FontEditPage);
