import type { IObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { DailyStat, IDailyStats, TAttributionModel } from '@feathr/blackbox';
import { Table } from '@feathr/components';

import type { IComputedDailyStats, IRowOutput } from './DailyStatsColumns';
import { DailyStatsColumns, getConversionsColumn } from './DailyStatsColumns';

import * as styles from './DailyStatsTable.css';

interface IProps {
  stats: IObservableArray<DailyStat>;
  isPending: boolean;
  showSpend?: boolean;
  showConversions?: boolean;
  attributionModel?: TAttributionModel;
}

interface ITableItem extends IDailyStats {
  views: number;
  clicks: number;
}

function DailyStatsTable({
  stats,
  isPending,
  showSpend = true,
  showConversions = true,
  attributionModel = 'full',
}: Readonly<IProps>): JSX.Element {
  const excludedFilters: string[] = [];
  if (!showSpend) {
    excludedFilters.push('spend', 'cpm', 'cpc', 'cpa');
  }
  const columns = DailyStatsColumns.filter((c) => !excludedFilters.includes(c.id!));
  if (showConversions) {
    columns.push(getConversionsColumn(attributionModel));
  }
  return (
    <Table<IComputedDailyStats, IRowOutput>
      className={styles.tableWrapper}
      columns={columns}
      idKey={'id'}
      isLoading={isPending}
      items={stats.map((stat) => {
        const flv = stat.get('flavors', {});
        return {
          ...stat.toJS(),
          views: (flv.ad_view ?? 0) + (flv.email_view ?? 0) + (flv.page_view ?? 0),
          clicks: (flv.ad_click ?? 0) + (flv.email_link_click ?? 0) + (flv.page_link_click ?? 0),
        } as ITableItem;
      })}
      noDataText={'No Data'}
    />
  );
}

export default observer(DailyStatsTable);
