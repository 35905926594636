import { faBullhorn, faEye } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign, IFacebookCampaignInsightsParams } from '@feathr/blackbox';
import { CampaignClass, CampaignState } from '@feathr/blackbox';
import { Alert, AlertType, Button, Card, Tooltip } from '@feathr/components';
import { cssVar } from '@feathr/hooks';
import {
  ClicksStat,
  ReachStat,
  UniqueClicksStat,
  ViewsStat,
} from '@feathr/report_components/components/Stats';
import { useStore } from '@feathr/report_components/state';
import { campaignColorMap, campaignIconMap } from '@feathr/report_components/styles/campaign';
import type { IReportProps } from '@feathr/report_components/types';

import * as styles from './CampaignStats.css';

interface IProps {
  campaign: Campaign;
  end: IReportProps['end'];
  localUrl?: IReportProps['localUrl'];
  start: IReportProps['start'];
}

function CampaignStats({ campaign, end, localUrl, start }: Readonly<IProps>): JSX.Element {
  const { AggregatedStats } = useStore();
  const { t } = useTranslation();

  const type = campaign.get('_cls');
  const isFacebook = [CampaignClass.Facebook, CampaignClass.EmailListFacebook].includes(type);
  const { FacebookCampaignInsights } = useStore();

  const aggregatedStats = AggregatedStats.aggregate({
    filters: {
      metadata__date__gte: start,
      metadata__date__lte: end,
      metadata__obj_id: campaign.id,
    },
    model: isFacebook ? 'facebook_campaign' : 'campaign',
  });

  let views = 0;
  let clicks = 0;
  let uniqueClicks = 0;
  let users = 0;

  let hasUpsyncErrors = false;
  if (!campaign.isPending) {
    hasUpsyncErrors = isFacebook && campaign.get('state') === CampaignState.Erroring;
  }

  const facebookCampaignStats = campaign.isFacebook
    ? FacebookCampaignInsights.getStats({
        campaign_id: campaign.id,
        date_start: start,
        date_end: end,
      } as IFacebookCampaignInsightsParams)
    : undefined;

  if (!aggregatedStats.isPending) {
    const flavors = aggregatedStats.get('flavors');
    views =
      (flavors.ad_view || 0) +
      (flavors.page_view || 0) +
      (flavors.email_view || 0) +
      (flavors.pinpoint_tracked_email_open || 0);
    clicks =
      (flavors.ad_click || 0) +
      (flavors.page_link_click || 0) +
      (flavors.email_link_click || 0) +
      (flavors.pinpoint_tracked_email_click || 0);

    if (isFacebook && facebookCampaignStats) {
      ({ reach: users, unique_clicks: uniqueClicks } = facebookCampaignStats.get([
        'reach',
        'unique_clicks',
      ]));
    } else {
      ({ num_users_new: users = 0, num_clicks_new: uniqueClicks = 0 } = aggregatedStats.get([
        'num_users_new',
        'num_clicks_new',
      ]));
    }
  }

  const theme = campaignColorMap.get(type);
  const color = cssVar(`--color-${theme}-500`);

  return (
    <Card
      color={color}
      contentClassName={styles.content}
      title={
        <>
          <FontAwesomeIcon icon={campaignIconMap.get(type) || faBullhorn} />{' '}
          <span className={styles.title}>{campaign.name}</span>
          {!!localUrl && (
            <Tooltip title={t('View details')}>
              <Button className={styles.view} link={localUrl(campaign.getItemUrl())}>
                <FontAwesomeIcon icon={faEye} />
              </Button>
            </Tooltip>
          )}
        </>
      }
    >
      {hasUpsyncErrors && (
        <Alert className={styles.alert} type={AlertType.danger}>
          {t('Failed to sync with Meta.')}
          {!!localUrl && (
            <>
              {' '}
              <Button
                href={localUrl(`${campaign.getItemUrl('edit')}`)}
                target={'_blank'}
                type={'link'}
              >
                {t('Learn more...')}
              </Button>
            </>
          )}
        </Alert>
      )}
      {/* div used here instead of StatsCardV1 so as to preserve styling for StatsCardV1 being used elsewhere */}
      <div className={styles.wrapper}>
        <ViewsStat size={'mini'} value={views} />
        <ReachStat size={'mini'} value={users} />
        <ClicksStat size={'mini'} value={clicks} />
        <UniqueClicksStat size={'mini'} value={uniqueClicks} />
      </div>
    </Card>
  );
}

export default observer(CampaignStats);
