import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { OptionProps } from 'react-select';
import { components } from 'react-select';

import { Tooltip } from '@feathr/components';

import * as styles from './ObjectiveOption.css';

export interface IObjectiveOption {
  id: string;
  name: string;
  description: string;
  icon: JSX.Element;
  disabled?: boolean;
}

export type TOptionProps = OptionProps<IObjectiveOption>;

function ObjectiveOption(props: TOptionProps): JSX.Element {
  const { data } = props;
  const { t } = useTranslation();
  return (
    <components.Option {...props} isDisabled={data.disabled}>
      <Tooltip
        position={'bottom-start'}
        title={
          data.disabled
            ? t('This objective is not available for the selected campaign type')
            : undefined
        }
      >
        <div className={styles.root}>
          <div>{data.icon}</div>
          <div>
            <span className={styles.label}>{data.name}</span>
            <p className={styles.description}>{data.description}</p>
          </div>
        </div>
      </Tooltip>
    </components.Option>
  );
}

export default ObjectiveOption;
