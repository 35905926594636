import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { Flavors } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import { Select } from '@feathr/components';
import FlavorSelect from '@feathr/extender/components/FlavorSelect';
import { useStore } from '@feathr/extender/state';
import type { IWretchResponseError, IWretchResponseValid } from '@feathr/rachis';
import { isWretchError, wretch } from '@feathr/rachis';

import * as styles from './Filters.css';

interface IFlvrOptionsResponse extends Record<string, unknown> {
  person_flvrs: Array<keyof Flavors>;
}

export type TMode = 'all' | 'group';

interface IModeOption extends ISelectOption {
  id: TMode;
}

export interface IFiltersProps {
  flavors: Array<keyof Flavors>;
  mode?: TMode;
  onFlavorsChange: (flavors?: Array<keyof Flavors>) => void;
  onFlavorsClear: () => void;
  onModeChange: (mode?: TMode) => void;
  personId: string;
  showMode?: boolean;
}

function Filters({
  flavors,
  mode,
  onFlavorsChange,
  onFlavorsClear,
  onModeChange,
  personId,
  showMode = false,
}: Readonly<IFiltersProps>): JSX.Element {
  const { Breadcrumbs } = useStore();
  const [flvrsOptions, setFlvrsOptions] = useState<Array<keyof Flavors> | undefined>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const getFilteredFlvrOptions = async (): Promise<
      IWretchResponseValid<IFlvrOptionsResponse> | IWretchResponseError
    > => {
      return wretch<IFlvrOptionsResponse>(Breadcrumbs.url('person.flavors', personId), {
        headers: Breadcrumbs.getHeaders(),
        method: 'GET',
      });
    };
    (async (): Promise<void> => {
      const response = await getFilteredFlvrOptions();
      if (isWretchError(response)) {
        setFlvrsOptions(undefined);
      } else {
        setFlvrsOptions(response.data.person_flvrs);
      }
    })();
  }, [Breadcrumbs, personId]);

  function handleActivityModeChange(modeOption?: IModeOption): void {
    onModeChange(modeOption?.id);
  }

  const filterActivityOptions: IModeOption[] = [
    { name: t('All activity'), id: 'all' },
    { name: t('Group activity'), id: 'group' },
  ];

  return (
    <div className={styles.root}>
      {showMode && (
        <Select<IModeOption>
          aria-label={t('Activity')}
          name={'activity-select'}
          onChange={handleActivityModeChange}
          options={filterActivityOptions}
          value={filterActivityOptions.find(({ id }) => id === mode)}
          wrapperClassName={styles.mode}
        />
      )}
      <FlavorSelect
        aria-label={t('Filters')}
        className={styles.flavors}
        isMulti={true}
        limitOptionsTo={flvrsOptions}
        onChange={onFlavorsChange}
        onClear={onFlavorsClear}
        placeholder={t('Select activity types...')}
        value={flavors}
      />
    </div>
  );
}

export default Filters;
