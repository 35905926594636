import type { JSX, ReactNode } from 'react';
import { createContext, useMemo, useState } from 'react';
import React from 'react';

type TPrioritizeVerifiedActivity = boolean;
type TReportProviderProps = { readonly children: ReactNode };

interface IReportContext {
  prioritizeVerifiedActivity: TPrioritizeVerifiedActivity;
  setPrioritizeVerifiedActivity: (value?: boolean) => void;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
}

export const ReportContext = createContext<IReportContext | undefined>(undefined);

/*
 * See Kent C. Dodds' article on effective context usage:
 * https://kentcdodds.com/blog/how-to-use-react-context-effectively
 */
export function ReportProvider({ children }: TReportProviderProps): JSX.Element {
  // Using internal state setter with default `false` to control bot traffic inclusion.
  // The public setter (setIncludeBotTraffic) wraps this to match the Toggle component's `onChange` type.
  const [prioritizeVerifiedActivity, setVerifiedActivityState] =
    useState<TPrioritizeVerifiedActivity>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const value = useMemo(
    (): IReportContext => ({
      prioritizeVerifiedActivity,
      setPrioritizeVerifiedActivity: (value?: boolean) => setVerifiedActivityState(value ?? true),
      isLoading,
      setIsLoading,
    }),
    [prioritizeVerifiedActivity, isLoading],
  );

  return <ReportContext.Provider value={value}>{children}</ReportContext.Provider>;
}
