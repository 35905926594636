import debounce from 'debounce-promise';
import { when } from 'mobx';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AsyncSelect } from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import type { IObject } from '@feathr/rachis';

import type { IRedirectDomainOption } from '.';

interface IProps {
  label?: string;
  onSelectSingle: (option: IRedirectDomainOption) => void;
  selectedDomainId?: string;
}

function AuthorizedDomainSelect({
  label,
  onSelectSingle,
  selectedDomainId,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { RedirectDomains } = useStore();
  const [domainOptions, setDomainOptions] = useState<IRedirectDomainOption[] | undefined>(
    undefined,
  );

  async function fetchOptions(inputValue: string): Promise<IRedirectDomainOption[]> {
    const filters: IObject = {
      _is_verified: true,
      id__ne: '000000000000000000000000',
    };
    // Prevent get url from changing if there is no input to filter by
    if (inputValue.length) {
      filters.domain__icontains = inputValue;
    }
    const domains = RedirectDomains.list({ filters });
    await when(() => !domains.isPending);

    const readyDomainOptions: IRedirectDomainOption[] = domains.models.map((domain) => ({
      name: domain.get('domain'),
      id: domain.id,
    }));

    // Set domain options on the first render to retrieve current value.
    if (domainOptions === undefined) {
      setDomainOptions(readyDomainOptions);
    }

    return readyDomainOptions;
  }

  // Only load options when input has stopped for 300ms
  const loadOptions = debounce(fetchOptions, 300, { leading: false });

  function getOptionLabel({ name: label }: IRedirectDomainOption): string {
    return label;
  }

  function getOptionValue({ id: value }: IRedirectDomainOption): string {
    return value;
  }

  return (
    <AsyncSelect
      defaultOptions={true}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      label={label ?? t('Content serving domain')}
      loadOptions={loadOptions}
      name={'authorized-domain-select'}
      onSelectSingle={onSelectSingle}
      value={domainOptions?.find((option) => option.id === selectedDomainId)}
    />
  );
}

export default AuthorizedDomainSelect;
