import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Flight, IConfig } from '@feathr/blackbox';
import { AlertV2 as Alert, EAlertV2Type as EAlertType, Layout, Tab } from '@feathr/components';
import type { IPageProps } from '@feathr/extender/App/Page';
import Page from '@feathr/extender/App/Page';
import { useLocalUrl } from '@feathr/extender/state';

import styles from './FlightPage.css';

export interface IProps extends IPageProps {
  children: ReactNode;
  flight: Flight;
  config?: IConfig;
  actons?: ReactNode;
}

function FlightPage({
  description,
  children,
  config,
  flight,
  title,
  actions,
  ...props
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const localUrl = useLocalUrl();
  const flightUrl = localUrl(flight.getItemUrl());

  return (
    <Page
      {...props}
      actions={actions}
      tabs={[
        <Tab exact={true} key={'overview'} link={flightUrl} title={'Overview'} />,
        <Tab key={'report'} link={`${flightUrl}/report`} title={'Report'} />,
      ]}
      title={flight.name}
      width={'wide'}
    >
      {flight.get('rerun') && (
        <Layout width={'max'}>
          <Alert
            className={styles.alert}
            title={t(
              "This Flight's Goals or Legs recently changed, so its conversions and conversion value are currently being recalculated. This process should take around 30 minutes.",
            )}
            type={EAlertType.info}
          />
        </Layout>
      )}
      <h3>{title}</h3>
      {!!description && <div className={styles.description}>{description}</div>}
      {children}
    </Page>
  );
}

export default observer(FlightPage);
