import type { WithT } from 'i18next';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { AutoPinpointEmailCampaign } from '@feathr/blackbox';
import { Button, ButtonValid, Form } from '@feathr/components';
import { flattenErrors } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import type { IActionErrors } from '../../../CampaignSummary';
import AutomationSchedule from '../../AutomationSchedule/AutomationSchedule';
import PinpointTriggersConfig from '../../PinpointActions/PinpointTriggersConfig';
import TriggerTypeRadios from '../../TriggerTypeRadios/TriggerTypeRadios';
import useTriggerTypeRadios from '../../TriggerTypeRadios/useTriggerTypeRadios';

interface IProps {
  campaign: AutoPinpointEmailCampaign;
  onNext: () => void;
  onPrev: () => void;
  disabled: boolean;
}

interface IButtonProps extends WithT {
  campaign: AutoPinpointEmailCampaign;
  onNext: () => void;
}

interface IErrors extends TValidateGrouped {
  actions?: IActionErrors[];
  cooldown_value?: string[];
  delay_unit?: string[];
  delay_value?: string[];
  repeat?: string[];
  send_schedule?: string[];
}

export function validateStepAutomation(campaign: AutoPinpointEmailCampaign): IErrors {
  // TODO: Fix pre-existing bug on validation on cooldown_value and delay_value fields - see #2767
  return campaign.validate<IErrors>(
    [
      'actions',
      'cooldown_value',
      'cooldown_unit',
      'delay_unit',
      'delay_value',
      'repeat',
      'time_to_send',
    ],
    false,
    'grouped',
  ).errors;
}

const NextStepButton = observer(({ campaign, onNext, t }: IButtonProps): JSX.Element => {
  const validationErrors = validateStepAutomation(campaign);

  return (
    <ButtonValid errors={flattenErrors(validationErrors)} name={'next_step'} onClick={onNext}>
      {t('Next')}
    </ButtonValid>
  );
});

function AutoPinpointEmailCampaignStepAutomation({
  campaign,
  disabled,
  onNext,
  onPrev,
}: IProps): JSX.Element {
  const { t } = useTranslation();

  const { handleSubtypeChange, repeat, setRepeat, subtype, triggerTypeOptions } =
    useTriggerTypeRadios(campaign);

  const validationErrors = validateStepAutomation(campaign);

  return (
    <Form
      actions={[
        <Button key={'prev'} name={'previous_step'} onClick={onPrev}>
          {t('Previous')}
        </Button>,
        <NextStepButton campaign={campaign} key={'next'} onNext={onNext} t={t} />,
      ]}
      description={t(
        'When should the email in this campaign be sent to people? Configure as many triggers as you like. Activities triggers (like URL visits) can only be combined with other activities. Attribute triggers (like job title) can only be combined with other attributes.',
      )}
      label={t('Edit Campaign: Targeting')}
      title={t('Automation')}
      width={'wide'}
    >
      <TriggerTypeRadios
        disabled={disabled}
        handleSubtypeChange={handleSubtypeChange}
        subtype={subtype}
        triggerTypeOptions={triggerTypeOptions}
      />

      <PinpointTriggersConfig campaign={campaign} disabled={disabled} />

      <AutomationSchedule
        campaign={campaign}
        context={'pinpoint'}
        disabled={disabled}
        repeat={repeat}
        setRepeat={setRepeat}
        validationErrors={validationErrors}
      />
    </Form>
  );
}

export default observer(AutoPinpointEmailCampaignStepAutomation);
