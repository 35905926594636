import type { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import {
  faBrowser,
  faEnvelope,
  faEnvelopeOpenText,
  faImage,
  faLink,
} from '@fortawesome/pro-light-svg-icons';

import { TemplateClass } from '@feathr/blackbox';

export function templateTypeColor(type: TemplateClass): string {
  const typeMap: Record<TemplateClass, string> = {
    [TemplateClass.Email]: 'yellow',
    [TemplateClass.ReferralEmail]: 'yellow',
    [TemplateClass.Page]: 'green',
    [TemplateClass.LandingPage]: 'teal',
    [TemplateClass.ReferralPage]: 'green',
    [TemplateClass.Banner]: 'blue',
    [TemplateClass.ReferralBanner]: 'blue',
    [TemplateClass.Link]: 'sky',
    [TemplateClass.PinpointEmail]: 'yellow',
  };
  return typeMap[type];
}

export function templateTypeIcon(type: TemplateClass): IconDefinition {
  const typeMap: Record<TemplateClass, IconDefinition> = {
    [TemplateClass.Email]: faEnvelope,
    [TemplateClass.ReferralEmail]: faEnvelope,
    [TemplateClass.Page]: faBrowser,
    [TemplateClass.LandingPage]: faBrowser,
    [TemplateClass.ReferralPage]: faBrowser,
    [TemplateClass.Banner]: faImage,
    [TemplateClass.ReferralBanner]: faImage,
    [TemplateClass.Link]: faLink,
    [TemplateClass.PinpointEmail]: faEnvelopeOpenText,
  };
  return typeMap[type];
}
