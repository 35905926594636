import { observer } from 'mobx-react-lite';
import moment from 'moment';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { TTDCampaign } from '@feathr/blackbox';
import type { ICampaignValidationErrors } from '@feathr/extender/App/EventsPage/CampaignSummary/CampaignSummary.types';
import { TimeFormat } from '@feathr/hooks';

import ExtendEndDate from './ExtendEndDate';
import IncreaseBudget from './IncreaseBudget';
import RecommendationModal from './RecommendationModal';

interface IProps {
  campaign: TTDCampaign;
  errors?: ICampaignValidationErrors;
}

function IncreaseBudgetExtendEndDate({ campaign, errors }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const recommendedDate = '2025-04-05T04:00:00';

  const endTimeRecommendedFormatted = moment
    .utc(recommendedDate)
    .local()
    .format(TimeFormat.longDateTime);

  // Initial End Date
  const endTimeInitialFormatted = moment
    .utc(campaign.initialAttributes.date_end)
    .local()
    .format(TimeFormat.longDateTime);

  const recommendedBudget = 25;
  const recommendedBudgetFormatted = numeral(recommendedBudget).format('$0');

  const initialBudget = campaign.initialAttributes.exposure_settings?.target_value ?? 0;
  const initialBudgetFormatted = numeral(initialBudget).format('$0');

  // TODO: make this more reusable when th recommendation structure is more clear
  const alerts = [
    t('Extend end date from {{before}} to {{after}}', {
      before: endTimeInitialFormatted,
      after: endTimeRecommendedFormatted,
    }),
    t('Increase budget from {{before}} to {{after}}', {
      before: initialBudgetFormatted,
      after: recommendedBudgetFormatted,
    }),
  ];

  const budgetErrors = errors?.budget ?? [];
  const endDateErrors = errors?.duration ?? [];

  return (
    <>
      <RecommendationModal.Alert description={alerts} />
      <ExtendEndDate campaign={campaign} errors={endDateErrors} suppressAlert={true} />
      <IncreaseBudget campaign={campaign} errors={budgetErrors} suppressAlert={true} />
    </>
  );
}

export default observer(IncreaseBudgetExtendEndDate);
