import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { FieldDataType, ICustomField } from '@feathr/blackbox';
import { FieldCollection } from '@feathr/blackbox';
import type { IModalProps } from '@feathr/components';
import {
  Chip,
  ConfirmModal,
  Form,
  Icon,
  Input,
  showError,
  Textarea,
  toast,
  Well,
} from '@feathr/components';
import DataTypeSelect from '@feathr/extender/components/DataTypeSelect';
import { useStore } from '@feathr/extender/state';

import { useFormEditor } from '../FormEditorContext';

import * as styles from './CreateCustomFieldModal.css';
interface IProps extends IModalProps {}

interface INewCustomField extends Partial<ICustomField> {
  collection: FieldCollection.Person;
}

interface IErrors {
  u_key: string[];
  data_type: string[];
}

function CreateCustomFieldModal({ opened, onClose }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { CustomFields } = useStore();
  const { onCreateCustomField } = useFormEditor();
  const [newField, setNewField] = useState<INewCustomField>({
    collection: FieldCollection.Person,
    u_key: '',
    data_type: undefined,
    description: '',
  });
  const [errors, setErrors] = useState<IErrors>({
    u_key: [],
    data_type: [],
  });

  function handleChangeDataType(newValue?: FieldDataType): void {
    setNewField({ ...newField, data_type: newValue });
  }

  function handleChangeName(newValue?: string): void {
    setNewField({ ...newField, u_key: newValue ?? '' });
  }

  function handleChangeDescription(newValue?: string): void {
    setNewField({ ...newField, description: newValue ?? '' });
  }

  async function addCustomField(): Promise<void> {
    try {
      const newCustomField = CustomFields.create(newField);
      const response = await CustomFields.add(newCustomField);

      await when(() => response.isFetched);
      toast(t('Custom field created.'), { type: 'success' });
      onCreateCustomField(newCustomField);
      setNewField({
        collection: FieldCollection.Person,
        u_key: '',
        data_type: undefined,
        description: '',
      });
      setErrors({
        u_key: [],
        data_type: [],
      });
      onClose();
    } catch (error) {
      showError(error, t);
    }
  }

  const validate = useCallback(async () => {
    const errors: IErrors = { u_key: [], data_type: [] };
    const uniquenessError = await CustomFields.validateUniqueness({
      newField,
      collection: FieldCollection.Person,
      t,
    });
    if (uniquenessError) {
      errors.u_key.push(uniquenessError);
    }

    if (!newField.u_key) {
      errors.u_key.push(t('Field name is required'));
    }

    if (!newField.data_type) {
      errors.data_type.push(t('Data type is required'));
    }

    setErrors(errors);
  }, [newField]);

  useEffect(() => {
    validate();
  }, [newField]);

  return (
    <ConfirmModal
      allowOverflow={true}
      confirmButtonText={t('Create')}
      confirmDisabled={[...errors.u_key, ...errors.data_type].length > 0}
      description={t('These can be used throughout the entire app.')}
      onClose={onClose}
      onConfirm={addCustomField}
      opened={opened}
      t={t}
      title={t('Create custom field')}
      validationError={[...errors.u_key, ...errors.data_type]}
    >
      <div className={styles.root}>
        <Well
          description={t('This custom field will be available as a Person field.')}
          label={t('Collection')}
        >
          <Chip className={styles.chip} prefix={<Icon className={styles.icon} icon={faUser} />}>
            {t('Person')}
          </Chip>
        </Well>
        <Form label={t('Custom field details')}>
          <Input
            label={t('Field name')}
            onChange={handleChangeName}
            type={'text'}
            validationError={errors.u_key}
            value={newField.u_key}
          />
          <DataTypeSelect
            label={t('Data type')}
            onChange={handleChangeDataType}
            value={newField.data_type}
          />
          <Textarea
            label={t('Description')}
            onChange={handleChangeDescription}
            optional={true}
            value={newField.description}
          />
        </Form>
      </div>
    </ConfirmModal>
  );
}

export default observer(CreateCustomFieldModal);
