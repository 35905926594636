import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { Form } from '@feathr/blackbox';

import AfterFormSubmissionCard from './AfterFormSubmissionSettings';
import SubmissionEmailsCard from './SubmissionEmailSettings';

interface IProps {
  form: Form;
}

function Settings({ form }: Readonly<IProps>): JSX.Element {
  return (
    <>
      <SubmissionEmailsCard form={form} />
      <AfterFormSubmissionCard form={form} />
    </>
  );
}

export default observer(Settings);
