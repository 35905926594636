import capitalize from 'lodash.capitalize';
import { Observer, observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Breadcrumb, Creative, FlavorKeys, ICustomData, IUTMParams } from '@feathr/blackbox';
import { FieldCollection, isPinpointCampaign } from '@feathr/blackbox';
import { Button, CardV2 as Card, Chip, Fieldset, Time, TLDR, Well } from '@feathr/components';
import MarkerMap from '@feathr/extender/components/MarkerMap';
import { useLocalUrl, useStore } from '@feathr/extender/state';
import {
  breadcrumbFlavorDescriptionMap,
  payloadLabelMap,
} from '@feathr/extender/styles/breadcrumbs';
import { formatLabel } from '@feathr/extender/utils';
import { TimeFormat, useToggle } from '@feathr/hooks';

import ContextWell from '../ContextWell';
import FlavorChip from '../FlavorChip';
import PersonTLDR from '../PersonTLDR';
import BreadcrumbRawModal from './BreadcrumbRawModal';
import CampaignTLDR from './CampaignTLDR';
import ContactListTLDR from './ContactListTLDR';
import CreativeTLDR from './CreativeTLDR';
import EventTLDR from './EventTLDR';
import PartnerTLDR from './PartnerTLDR';
import TemplateTLDR from './TemplateTLDR';

import * as styles from './BreadcrumbDetails.css';

export interface IBreadcrumbDetailsProps {
  readonly breadcrumb: Breadcrumb;
  readonly hasViewRawData?: boolean;
  readonly context: FieldCollection.Person | FieldCollection.Partner;
}

interface IProjectData {
  readonly action: '+' | '-';
  readonly id: string;
  readonly name: string;
}

interface IMsg {
  readonly id: string;
  readonly d_c: string;
  readonly per_id: string;
  readonly flvr: string;
  readonly cpn_id: string | null;
  readonly global: boolean;
  readonly projects: IProjectData[];
}

const payloadFlvrExclusions: FlavorKeys[] = [
  'email_preferences_update',
  'shared_email_preferences_update',
];

function BreadcrumbDetails({
  breadcrumb,
  context,
  hasViewRawData = true,
}: IBreadcrumbDetailsProps): JSX.Element {
  const { t } = useTranslation();
  const localUrl = useLocalUrl();
  const {
    Campaigns,
    ContactLists,
    Creatives,
    Events,
    Forms,
    Importers,
    Partners,
    Persons,
    SendRequests,
    Tags,
  } = useStore();
  const [toggleShowRawData, setToggleShowRawData] = useToggle(false);

  const partnerId = breadcrumb.get('p_id');
  const partner = partnerId ? Partners.get(partnerId) : undefined;

  const personId = breadcrumb.get('per_id');
  const person = personId ? Persons.get(personId) : undefined;

  const individual = context === FieldCollection.Partner ? partner : person;

  const eventId = breadcrumb.get('e_id');
  const event = eventId ? Events.get(eventId) : undefined;

  const contactListId = breadcrumb.get('cl_id');
  const contactList = contactListId ? ContactLists.get(contactListId) : undefined;

  const sendRequestId = breadcrumb.get('sreq_id');
  const campaignId = breadcrumb.get('cpn_id');
  const sendRequest = sendRequestId && campaignId ? SendRequests.get(sendRequestId) : undefined;
  const campaign = campaignId ? Campaigns.get(campaignId) : undefined;

  const creativeId = breadcrumb.get('crv_id');
  const creative = creativeId ? Creatives.get(creativeId) : undefined;

  const tagId = breadcrumb.get('tag_id');
  const tag = tagId ? Tags.get(tagId) : undefined;

  const importId = breadcrumb.get('import_id');
  const currentImport = importId ? Importers.get(importId) : undefined;
  const importName = currentImport?.get('name');

  const name = individual?.name ?? t('Unknown person');
  const custom: ICustomData = breadcrumb.get('custom_data', {});
  const location = breadcrumb.get('loc');
  const payload: ICustomData = breadcrumb.get('payload', {});
  const msg = breadcrumb.get('msg');
  const othersList = breadcrumb.get('others_list');
  const othersListDisplay = othersList ? JSON.parse(othersList[0]) : [];
  const referrer = breadcrumb.get('rfr');
  const source = breadcrumb.get('loc_url');
  const target = breadcrumb.get('trgt');
  const utmParams = breadcrumb.get('utm_params', {} as IUTMParams);
  const breadcrumbFlvr = breadcrumb.get('flvr');
  const msgDisplay: IMsg | undefined =
    msg && ['email_preferences_update', 'shared_email_preferences_update'].includes(breadcrumbFlvr)
      ? JSON.parse(msg)
      : undefined;
  const formId = breadcrumb.get('form_id');
  const form =
    ['form_submission', 'form_view'].includes(breadcrumbFlvr) && formId
      ? Forms.get(formId)
      : undefined;

  const action =
    breadcrumbFlavorDescriptionMap(t, breadcrumbFlvr, form?.name) ||
    `dropped a ${breadcrumbFlvr} breadcrumb`;
  // To the end user the word 'Data' is more understandable than 'Payload'. However, we use 'Payload' in the code.

  const dataLabel = payloadLabelMap(t, breadcrumbFlvr) || t('Data');

  function formatPayloadValue(payload: ICustomData, key: string): string {
    const val = payload[key];

    if (typeof val === 'object') {
      return Object.values(val).join(', ');
    }

    return String(val);
  }

  // + means opt-out
  // - means opt-in
  // global means opt-out of all emails; no need to show opt-out
  const optIns =
    msgDisplay?.projects
      .filter((project: IProjectData) => {
        return project.action === '-';
      })
      .map((project: IProjectData) => {
        return project.name;
      }) ?? [];

  const optOuts =
    msgDisplay?.projects
      .filter((project: IProjectData) => {
        return project.action === '+';
      })
      .map((project: IProjectData) => {
        return project.name;
      }) ?? [];

  const hasImport =
    importName &&
    (breadcrumbFlvr === 'created_by_import' || breadcrumbFlvr === 'updated_by_import');
  const hasContext = !!(breadcrumb.get('pform') || breadcrumb.get('brow') || breadcrumb.get('s_w'));
  const hasConnections = !!(source || referrer || target);

  const isEmailPreferencesUpdate = breadcrumbFlvr === 'email_preferences_update';
  const isSharedEmailPreferencesUpdate = breadcrumbFlvr === 'shared_email_preferences_update';

  const hasEmailOptInOrOut =
    msgDisplay && (isEmailPreferencesUpdate || isSharedEmailPreferencesUpdate);

  /*
   * Payload display, which is ICustomData object
   * Do not display payload if breadcrumbFlvr is in exclusions
   */
  const hasPayload =
    !payloadFlvrExclusions.includes(breadcrumbFlvr) && Object.keys(payload).length > 0;
  const hasUTMParams = Object.keys(utmParams).length > 0;
  const hasCustomData = Object.keys(custom).length > 0;
  const hasOthersListDisplay = Object.keys(othersListDisplay).length > 0;
  const hasTag = tag && !tag.isPending;

  // Prevent display of an empty Card.Content
  const showBreadcrumbData =
    hasImport ||
    hasContext ||
    hasConnections ||
    hasEmailOptInOrOut ||
    hasPayload ||
    hasUTMParams ||
    hasCustomData ||
    hasOthersListDisplay ||
    hasTag ||
    location ||
    event ||
    campaign ||
    creative ||
    partner ||
    contactList ||
    sendRequest ||
    toggleShowRawData;

  return (
    <Card contentClassName={styles.root} name={'Activity details'}>
      <Card.Content>
        <div className={styles.flavor}>
          <FlavorChip flavor={breadcrumbFlvr} />
          <Time
            className={styles.time}
            format={TimeFormat.shortDateTime}
            timestamp={breadcrumb.get('d_c')}
          />
          {/* Must use a span and not a p to avoid styles from vertical timeline */}
          <span aria-label={'Flavor description'} className={styles.flavorDescription}>
            {name} {action}
          </span>
        </div>
      </Card.Content>

      {showBreadcrumbData && (
        <Card.Content>
          {hasImport && (
            <Fieldset direction={'column'} label={t('Import')}>
              <TLDR
                action={<Button href={localUrl(`/data/imports/${importId}`)}>{t('View')}</Button>}
                title={importName}
              />
            </Fieldset>
          )}

          {/* platform, browser and screen width code block */}
          {hasContext && (
            <Fieldset direction={'column'} label={t('Context')}>
              <ContextWell
                browser={breadcrumb.get('brow')}
                platform={breadcrumb.get('pform')}
                screenWidth={breadcrumb.get('s_w')}
              />
            </Fieldset>
          )}

          {/* source, referrer and target */}
          {hasConnections && (
            <Fieldset label={t('Connections')}>
              {!!source && <Well description={source} label={t('Source URL')} />}
              {!!referrer && <Well description={referrer} label={t('Referrer URL')} />}
              {!!target && <Well description={target} label={t('Target URL')} />}
            </Fieldset>
          )}

          {/* display email opt outs/opt ins  */}
          {hasEmailOptInOrOut && (
            <>
              {(msgDisplay.global || !!optOuts.length) && (
                <Fieldset label={t('Opted out of emails from')}>
                  {msgDisplay.global && <Well description={'true'} label={'All Emails'} />}
                  {!msgDisplay.global && !!optOuts.length && (
                    <Well
                      description={optOuts.join(', ')}
                      label={t('Project name', { count: optOuts.length })}
                    />
                  )}
                </Fieldset>
              )}
              {!msgDisplay.global && !!optIns.length && (
                <Fieldset label={t('Opted in to emails from')}>
                  <Well
                    description={optIns.join(', ')}
                    label={t('Project name', { count: optIns.length })}
                  />
                </Fieldset>
              )}
              {isSharedEmailPreferencesUpdate && (
                <Fieldset
                  label={
                    msgDisplay.global || !!optOuts.length
                      ? t('Opted out because of a change of preference by')
                      : t('Opted in because of a change of preference by')
                  }
                >
                  <Observer>
                    {(): JSX.Element => {
                      const personId = msgDisplay.per_id;
                      const person = personId ? Persons.get(personId) : undefined;
                      return <PersonTLDR hasLink={true} person={person} />;
                    }}
                  </Observer>
                </Fieldset>
              )}
            </>
          )}
          {hasPayload && (
            // Label is "Data" unless Zapier or iMIS import or update
            <Fieldset label={dataLabel}>
              {Object.keys(payload)
                // We still want to display false and 0 values
                .filter((key) => payload[key] || payload[key] === false || payload[key] === 0)
                .map((key) => (
                  <Well
                    description={formatPayloadValue(payload, key)}
                    key={key}
                    label={formatLabel(key)}
                  />
                ))}
            </Fieldset>
          )}
          {/* UTM data display */}
          {hasUTMParams && (
            <Fieldset label={t('UTM data')}>
              {Object.keys(utmParams)
                .filter((key) => utmParams[key])
                .map((key) => (
                  <Well description={utmParams[key].toString()} key={key} label={capitalize(key)} />
                ))}
            </Fieldset>
          )}
          {/* Custom Data display */}
          {hasCustomData && (
            <Fieldset label={t('Custom data')}>
              {Object.keys(custom)
                .filter((key) => custom[key])
                .map((key) => (
                  <Well description={custom[key]!.toString()} key={key} label={capitalize(key)} />
                ))}
            </Fieldset>
          )}
          {/* Others list display */}
          {hasOthersListDisplay && (
            <Fieldset label={t('Details')}>
              {Object.keys(othersListDisplay)
                .filter((key) => othersListDisplay![key])
                .map((key) => (
                  <Well
                    description={othersListDisplay[key]!.toString()}
                    key={key}
                    label={capitalize(key).replace('_', ' ')}
                  />
                ))}
            </Fieldset>
          )}

          {/* tag display */}
          {hasTag && (
            <Fieldset>
              <Well
                description={
                  <Chip className={styles.chipFix} theme={'tag'}>
                    {tag.name}
                  </Chip>
                }
                label={t('Tag')}
              />
            </Fieldset>
          )}
          {/* Map Display */}
          {location && (
            <Fieldset>
              <MarkerMap
                className={styles.map}
                lat={location.latitude!}
                lng={location.longitude!}
              />
            </Fieldset>
          )}
          {/* associated Project display */}
          {event && (
            <Fieldset label={t('Project')}>
              <EventTLDR event={event} />
            </Fieldset>
          )}
          {form && (
            <Fieldset label={t('Form')}>
              <TLDR
                action={
                  <Button href={localUrl(form.getItemUrl('report'))} target={'_blank'}>
                    {t('View form')}
                  </Button>
                }
                isLoading={form.isPending}
                title={form.name}
              />
            </Fieldset>
          )}
          {/* associated campaign display */}
          {campaign && (
            <Fieldset label={t('Campaign')}>
              <CampaignTLDR campaign={campaign} />
            </Fieldset>
          )}
          {/* associated campaign email template display */}
          {!!campaign && isPinpointCampaign(campaign) && (
            <Fieldset label={t('Template')}>
              <TemplateTLDR campaign={campaign} />
            </Fieldset>
          )}
          {/* associated creatives display */}
          {creative && (
            <Fieldset direction={'column'} label={'Creative'}>
              <CreativeTLDR creative={creative as Creative} />
            </Fieldset>
          )}
          {/* associated partner display */}
          {partner && (
            <Fieldset label={t('Partner')}>
              <PartnerTLDR partner={partner} />
            </Fieldset>
          )}
          {/* associated contact list download */}
          {contactListId && (
            <Fieldset label={t('Contact list')}>
              <ContactListTLDR contactList={contactList} />
            </Fieldset>
          )}
          {sendRequest && !sendRequest.isPending && campaign && !campaign.isPending && (
            <Fieldset label={t('Email invite send')}>
              <Well description={campaign.name} label={t('Campaign')} />
              <Well
                description={
                  <Time
                    format={TimeFormat.shortDateTime}
                    timestamp={sendRequest.get('date_to_send')}
                  />
                }
                label={t('Date')}
              />
              <Well description={capitalize(sendRequest.get('state'))} label={t('Status')} />
            </Fieldset>
          )}
        </Card.Content>
      )}
      {hasViewRawData && (
        <Card.Actions>
          <Button onClick={setToggleShowRawData}>Show raw data</Button>
        </Card.Actions>
      )}
      {toggleShowRawData && (
        <BreadcrumbRawModal breadcrumb={breadcrumb} toggle={setToggleShowRawData} />
      )}
    </Card>
  );
}

export default observer(BreadcrumbDetails);
