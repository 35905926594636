import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import { useStore } from '@feathr/extender/state';

import { skeleton } from '@feathr/components/dist/style/skeleton.css';

interface IProps {
  className?: string;
  id: string;
}

function MessageLabel({ className, id }: Readonly<IProps>): JSX.Element {
  const { Campaigns } = useStore();
  const message = Campaigns.get(id);

  return (
    <div className={classNames({ [skeleton]: message.isPending }, className)}>{message.name}</div>
  );
}

export default observer(MessageLabel);
