import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { AggregatedStat, Form } from '@feathr/blackbox';
import { CardV2 as Card, StatBox, toast } from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import { errorMessage } from '@feathr/hooks';

import * as styles from './ActivitySummary.css';

interface IProps {
  form: Form;
}

function ActivitySummary({ form }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { AggregatedStats } = useStore();
  const [stats, setStats] = useState<AggregatedStat | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchStats(): Promise<void> {
      try {
        setIsLoading(true);
        const stats = AggregatedStats.aggregate({
          filters: {
            metadata__obj_id: form.id,
            metadata__date__gte: form.get('date_created'),
            metadata__date__lte: undefined,
          },
          model: 'form',
        });

        await when(() => !stats.isPending);

        setStats(stats);
      } catch (error) {
        toast(t('Error fetching form stats:\n{{- error}}', { error: errorMessage(error, t) }));
      } finally {
        setIsLoading(false);
      }
    }

    fetchStats();
  }, []);

  return (
    <Card width={'full'}>
      <Card.Header title={t('Activity Summary (Last 30 days)')} />
      <Card.Content contentClassName={styles.root}>
        <StatBox
          isLoading={isLoading}
          label={t('Views')}
          primary={String(stats?.get('num_views') ?? 0)}
          tooltip={t(
            'The total number of times this form has been viewed across all content domains.',
          )}
        />
        <StatBox
          isLoading={isLoading}
          label={t('Submissions')}
          primary={String(stats?.get('num_submissions') ?? 0)}
          tooltip={t(
            'The total number of submissions this form has received across all content domains.',
          )}
        />
      </Card.Content>
    </Card>
  );
}

export default observer(ActivitySummary);
