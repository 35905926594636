import { toJS } from 'mobx';
import { useMemo } from 'react';

import type { DripStepCampaign, IDripCampaignStepSpec, Template } from '@feathr/blackbox';
import { CampaignClass, dripExcludedAttributes } from '@feathr/blackbox';
import { useStore } from '@feathr/extender/state';

interface IEphemeralCampaignResponse {
  campaign: DripStepCampaign;
  handleApply: () => Promise<void>;
  handleCancel: () => Promise<void>;
  handleSync: () => Promise<void>;
  template: Template | null;
}

interface IUseEphemeralCampaignProps {
  dripCampaignId: string;
  onApply?: (updatedStep: IDripCampaignStepSpec) => Promise<void>;
  onCancel?: () => void;
  onSync?: (updatedStep: IDripCampaignStepSpec) => Promise<void>;
  step: IDripCampaignStepSpec;
}

function useEphemeralCampaign({
  dripCampaignId,
  onApply,
  onSync,
  onCancel,
  step,
}: Readonly<IUseEphemeralCampaignProps>): IEphemeralCampaignResponse {
  const { Campaigns, Templates } = useStore();

  const { step: stepAttributes = step } = step;

  const campaign = useMemo(() => {
    const dripCampaignStep = {
      ...stepAttributes,
      parent_id: dripCampaignId,
      _cls: CampaignClass.DripStep,
    };
    return Campaigns.create(dripCampaignStep) as DripStepCampaign;
    // Do not add deps here - this should only run once.
  }, []);

  const templateId = campaign.get('template_id');
  const template = templateId ? Templates.get(templateId) : null;

  async function handleApply(): Promise<void> {
    // TODO: Convert campaign .toJS()?
    const updatedCampaignAttributes = { ...campaign.attributes };
    for (const key of dripExcludedAttributes) {
      delete updatedCampaignAttributes[key];
    }
    const updatedStep: IDripCampaignStepSpec = updatedCampaignAttributes;
    await onApply?.(updatedStep);
  }

  async function handleSync(): Promise<void> {
    // TODO: Convert campaign .toJS()?
    const updatedCampaignAttributes = { ...campaign.attributes };
    for (const key of dripExcludedAttributes) {
      delete updatedCampaignAttributes[key];
    }
    const updatedStep: IDripCampaignStepSpec = updatedCampaignAttributes;
    await onSync?.(updatedStep);
  }

  async function handleCancel(): Promise<void> {
    const parentCampaign = Campaigns.get(dripCampaignId);
    const stepSpecs = toJS(parentCampaign.shadowAttributes.step_specs);
    const currentStep = stepSpecs.find((step: IDripCampaignStepSpec) => {
      return step.key === campaign.attributes.key;
    });
    const initialAttrs = { ...currentStep };

    await onSync?.(initialAttrs);
    onCancel?.();
  }

  return {
    campaign,
    handleApply,
    handleCancel,
    handleSync,
    template,
  };
}

export default useEphemeralCampaign;
