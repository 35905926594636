export const defaultPartnerAttributes = [
  {
    id: 'name',
    collection: 'Partner',
    data_type: 'str',
    u_key: 'Name',
    is_default: true,
  },
  {
    id: 'email',
    collection: 'Partner',
    data_type: 'str',
    u_key: 'Primary email',
    is_default: true,
  },
  {
    id: 'emails',
    collection: 'Partner',
    data_type: 'str',
    u_key: 'Additional emails',
    is_default: true,
  },
  {
    id: 'website',
    collection: 'Partner',
    u_key: 'Website',
    data_type: 'str',
    is_default: true,
  },
  {
    id: 'description',
    collection: 'Partner',
    u_key: 'Description',
    data_type: 'str',
    is_default: true,
  },
  {
    id: 'external_id',
    collection: 'Partner',
    u_key: 'External ID',
    data_type: 'str',
    is_default: true,
  },
  {
    id: 'logo',
    collection: 'Partner',
    u_key: 'Logo',
    data_type: 'str',
    is_default: true,
  },
];
