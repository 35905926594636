import { set, when } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect } from 'react';

import type { Segment } from '@feathr/blackbox';
import { Spinner, Table } from '@feathr/components';
import { useLocalUrl, useStore } from '@feathr/extender/state';

import type { ISegmentUsage } from './SegmentInformaticsColumns';
import SegmentInformaticsColumns from './SegmentInformaticsColumns';

interface IProps {
  segment: Segment;
}

function SegmentsInformaticsTable({ segment }: IProps): JSX.Element {
  const { Campaigns, Flights, Goals, Targetings } = useStore();
  const localUrl = useLocalUrl();
  const state = useLocalObservable(() => ({
    items: [] as ISegmentUsage[],
    setItems: (items: ISegmentUsage[]): void => {
      state.items = items;
    },
    page: 0,
    pageSize: 10,
  }));

  const getItems = async (): Promise<void> => {
    const goals = Goals.list({
      filters: {
        segment: segment.id,
      },
      pagination: {
        page_size: 999,
      },
    });
    const targetings = Targetings.list({
      filters: {
        _target_data: segment.id,
      },
      pagination: {
        page_size: 999,
      },
    });

    await when(() => !goals.isPending && !targetings.isPending);
    const itemsMap: Record<string, ISegmentUsage> = {};
    goals.models.forEach((goal) => {
      const item = itemsMap[goal.get('parent')];
      if (item && !item.usage.includes('goal')) {
        item.usage.push('goal');
      } else if (!item) {
        itemsMap[goal.get('parent')] = {
          id: goal.get('parent'),
          kind: goal.get('kind') || 'campaign',
          usage: ['goal'],
          inclusion: goal.get('included'),
        };
      }
    });
    targetings.models.forEach((target) => {
      const item = itemsMap[target.get('parent')];
      if (item && !item.usage.includes('target')) {
        item.usage.push('target');
        item.inclusion = target.get('included');
      } else if (!item) {
        itemsMap[target.get('parent')] = {
          id: target.get('parent'),
          kind: 'campaign',
          usage: ['target'],
          inclusion: target.get('included'),
        };
      }
    });
    state.setItems(Object.values(itemsMap));
  };

  useEffect(() => {
    getItems();
  }, []);

  function handlePageChange(newPage: number): void {
    set(state, { page: newPage });
  }

  function handlePageSizeChange(newPageSize: number): void {
    set(state, { pageSize: newPageSize });
  }

  if (!state.items) {
    return <Spinner />;
  }

  return (
    <Table<ISegmentUsage>
      columns={SegmentInformaticsColumns({ localUrl, Campaigns, Flights })}
      idKey={'id'}
      isLoading={!state.items}
      items={state.items.slice(state.page * state.pageSize, (state.page + 1) * state.pageSize)}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
      page={state.page}
      pages={Math.ceil(state.items.length / state.pageSize)}
      pageSize={state.pageSize}
    />
  );
}

export default observer(SegmentsInformaticsTable);
