import type { CSSProperties } from 'react';

import { cssVar } from '@feathr/hooks';

export enum ETheme {
  blue = 'blue',
  cyan = 'cyan',
  emerald = 'emerald',
  fuchsia = 'fuchsia',
  green = 'green',
  lime = 'lime',
  orange = 'orange',
  pink = 'pink',
  purple = 'purple',
  red = 'red',
  rose = 'rose',
  sky = 'sky',
  teal = 'teal',
  violet = 'violet',
  yellow = 'yellow',
  white = 'white',
}

export type TTheme = keyof typeof ETheme;

const {
  blue,
  cyan,
  emerald,
  fuchsia,
  green,
  lime,
  orange,
  pink,
  purple,
  red,
  rose,
  sky,
  teal,
  violet,
  yellow,
} = ETheme;

// Maps old Feathr colors to our new primitive colors.
export const themeToPrimitiveMap = (theme: string): ETheme => {
  const map: Record<string, ETheme> = {
    amaranth: rose,
    amber: yellow,
    amethyst: purple,
    apricot: orange,
    aquamarine: teal,
    azure: blue,
    'baby blue': sky,
    beige: yellow,
    black: violet,
    blue,
    'blue-green': sky,
    'blue-violet': purple,
    blush: pink,
    bronze: orange,
    brown: orange,
    burgundy: red,
    byzantium: fuchsia,
    carmine: red,
    cerise: rose,
    cerulean: sky,
    champagne: orange,
    chartreuse: lime,
    chocolate: orange,
    'cobalt blue': blue,
    coffee: orange,
    copper: orange,
    coral: rose,
    crimson: rose,
    cyan,
    'desert sand': orange,
    'electric blue': cyan,
    emerald,
    erin: lime,
    fuchsia,
    gold: yellow,
    gray: fuchsia,
    green,
    harlequin: lime,
    honeydew: lime,
    indigo: violet,
    ivory: yellow,
    jade: emerald,
    'jungle green': emerald,
    khaki: orange,
    lavender: purple,
    lemon: yellow,
    lilac: purple,
    lime,
    magenta: fuchsia,
    'magenta rose': pink,
    maroon: red,
    mauve: violet,
    'navy blue': violet,
    ocher: orange,
    olive: lime,
    orange,
    'orange-red': red,
    orchid: fuchsia,
    peach: orange,
    pear: lime,
    periwinkle: violet,
    'persian blue': violet,
    pink,
    plum: fuchsia,
    'prussian blue': blue,
    puce: rose,
    purple,
    raspberry: rose,
    red,
    'red-violet': pink,
    rose,
    'royal blue': blue,
    ruby: rose,
    salmon: red,
    sangria: red,
    sapphire: blue,
    scarlet: red,
    silver: violet,
    sky,
    'slate gray': violet,
    'spring bud': lime,
    'spring green': emerald,
    tan: orange,
    taupe: orange,
    teal,
    turquoise: teal,
    violet,
    viridian: emerald,
    white: violet,
    yellow,
  };

  return map[theme] || sky;
};

/** Utility function to check if a shade exists for a given theme */
export function shadeExists(theme: TTheme, shade: number): boolean {
  const primitive = themeToPrimitiveMap(theme);
  const dummyElement = document.createElement('div');
  dummyElement.style.display = 'none';
  dummyElement.style.setProperty('color', `var(--color-${primitive}-${shade})`);
  document.body.appendChild(dummyElement);
  const exists = !!getComputedStyle(dummyElement).getPropertyValue('color');
  document.body.removeChild(dummyElement);

  /*
   * Prevent console.warn from rendering during tests. It triggers a lot of warnings for a
   * component that uses it because the css variables don't exist while in the test env.
   */
  if (!exists && process.env.NODE_ENV !== 'test') {
    // eslint-disable-next-line no-console
    console.warn(`Shade ${shade} does not exist for theme ${theme}.`);
  }
  return exists;
}

/**
 * These are the default shades available for every theme. Certain themes have more options for shade.
 * Reference components/src/style/theme/index.css for other available shades.
 */
export type TThemeShade = 50 | 500 | 600 | 700 | 900;

interface IThemeToStyleParams {
  /** The CSS rule to apply the color to. */
  rule: string;
  theme?: TTheme;
  shade?: TThemeShade;
  /**
   * Fallback value to use if theme is undefined.
   * @default '--color-text-body'
   */
  fallback?: string;
}

/** Transforms a theme into a style object for a component. */
export function themeToStyle({
  rule,
  theme,
  shade = 500,
  fallback = '--color-text-body',
}: IThemeToStyleParams): CSSProperties {
  if (theme) {
    shadeExists(theme, shade);
  }
  return { [rule]: cssVar(theme ? `--color-${themeToPrimitiveMap(theme)}-${shade}` : fallback) };
}

/*
 * Converts a Feathr theme and shade to a Hex color that can be directly used in a style.
 * For example, themeToHex('sky', 500) returns the hex color of the sky theme at shade 500, i.e. `#0369a1`.
 */
export const themeToHex = (theme: TTheme, shade: TThemeShade = 500): string => {
  return cssVar(`--color-${theme}-${shade}`);
};
