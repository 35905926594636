export function getFormEmbedCode(
  shortCode: string,
  contentDomain: string,
  formName: string,
): string {
  const formId = `form-${shortCode}`;
  // TODO: On localhost this doesn't work... will it work for preview on prod? #4945
  const url = `https://${contentDomain}/${shortCode}`;
  return `<div class="feathrBeeFormPreview" data-form-name="${formName}" id="${formId}"></div>
<script src="https://${contentDomain}/forms-js/embed.js"></script>
<script>
FeathrForms.generateForm({
  id: "${formId}",
  url: "${url}"
});
</script>`;
}
