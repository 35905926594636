import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Layout, SaveButtonValid, Tab } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { useActionBar, useLocalUrl, useStore } from '@feathr/extender/state';
import { flattenErrors } from '@feathr/hooks';
import type { IValidateGrouped } from '@feathr/rachis';

import RaisersEdgeIntegrationCard from './RaisersEdgeIntegrationCard';
import RaisersEdgeUnsubscribeCard from './RaisersEdgeUnsubscribeCard';

import * as styles from './RaisersEdgeIntegrationPage.css';

function RaisersEdgeIntegrationPage(): JSX.Element {
  const localUrl = useLocalUrl();
  const { t } = useTranslation();
  const { setLeftActions, setRightActions } = useActionBar();
  const { BlackbaudRaisersEdgeIntegrations } = useStore();
  const { tab } = useParams<{ tab: 'overview' | 'unsubscribes' }>();

  const isTabUnsubscribes = tab === 'unsubscribes';

  const blackbaudRaisersEdgeIntegrations = BlackbaudRaisersEdgeIntegrations.list();

  // There should only be one integration per account; so, if there is one, we have the basics of an integration
  const integration = blackbaudRaisersEdgeIntegrations.models.length
    ? blackbaudRaisersEdgeIntegrations.models[0]
    : undefined;

  const validationErrors: IValidateGrouped = integration
    ? integration.validate(['unsubscribe_sync_preference.solicit_code'], false, 'grouped').errors
    : ({} as IValidateGrouped);

  useEffect(() => {
    if (isTabUnsubscribes && integration) {
      setRightActions(
        <SaveButtonValid
          disabled={!integration.isDirty}
          errors={flattenErrors(validationErrors)}
          method={'patch'}
          model={integration}
        >
          {t('Save')}
        </SaveButtonValid>,
      );
    } else {
      setRightActions(undefined);
    }
  }, [
    integration?.isDirty,
    integration?.isValid(),
    isTabUnsubscribes,
    setLeftActions,
    setRightActions,
  ]);

  const learnMoreLink =
    'https://help.feathr.co/hc/en-us/articles/21160663599255-How-to-Integrate-your-Blackbaud-Raiser-s-Edge-NXT-Account-with-Feathr';

  const tabs = [
    <Tab
      exact={true}
      key={'overview'}
      link={localUrl('/settings/integrations/raisers-edge')}
      title={t('Overview')}
    />,
  ];

  if (integration) {
    tabs.push(
      <Tab
        exact={true}
        key={'unsubscribes'}
        link={localUrl('/settings/integrations/raisers-edge/unsubscribes')}
        title={t('Manage Unsubscribes')}
      />,
    );
  }

  return (
    <Page
      collapseHeader={false}
      collapseHeaderOnScroll={true}
      description={
        <Layout width={'max'}>
          <Trans t={t}>
            <div className={styles.description}>
              Sync all your data, breadcrumbs, and activities from Blackbaud Raiser’s Edge NXT to
              Feathr.{' '}
              <a href={learnMoreLink} target={'_blank'}>
                Learn more
              </a>
            </div>
          </Trans>
        </Layout>
      }
      loading={blackbaudRaisersEdgeIntegrations.isPending}
      tabs={tabs}
      title={t("Blackbaud: Raiser's Edge NXT")}
    >
      {isTabUnsubscribes ? (
        integration && <RaisersEdgeUnsubscribeCard integration={integration} />
      ) : (
        <RaisersEdgeIntegrationCard integration={integration} />
      )}
    </Page>
  );
}

export default observer(RaisersEdgeIntegrationPage);
