import { observer } from 'mobx-react-lite';
import moment from 'moment';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { TTDCampaign } from '@feathr/blackbox';
import { DatePicker } from '@feathr/components';
import { useRecommendedValue } from '@feathr/extender/App/EventsPage/CampaignEditPage/AdWizard';
import { momentToDate, TimeFormat, timezoneAbbr } from '@feathr/hooks';

import RecommendationModal from './RecommendationModal';

interface IProps {
  campaign: TTDCampaign;
  errors: string[];
  suppressAlert?: boolean;
}

function ExtendEndDate({ campaign, errors, suppressAlert = false }: Readonly<IProps>): JSX.Element {
  // TODO: pull the recommended value from the BE
  const recommendedValue = '2025-04-05T04:00:00';

  const { t } = useTranslation();
  const { shouldUseRecommendedValue, setHasUserSelected } =
    useRecommendedValue<string>(recommendedValue);

  // Current End Date
  const endTimeStamp = shouldUseRecommendedValue ? recommendedValue : campaign.get('date_end');
  const endMoment = moment.utc(endTimeStamp).local();

  // Recommended End Date
  const recommendedMoment = moment.utc(recommendedValue).local();
  const endTimeRecommendedFormatted = moment
    .utc(recommendedValue)
    .local()
    .format(TimeFormat.longDateTime);

  // Initial End Date
  const endTimeInitialFormatted = moment
    .utc(campaign.initialAttributes.date_end)
    .local()
    .format(TimeFormat.longDateTime);

  function handleOnChangeDateEnd(newValue?: string): void {
    if (!newValue) {
      return;
    }

    campaign.set({
      date_end: newValue,
    });

    setHasUserSelected(true);
  }

  const alert = t('Extend end date from {{before}} to {{after}}', {
    before: endTimeInitialFormatted,
    after: endTimeRecommendedFormatted,
  });

  return (
    <>
      {!suppressAlert && <RecommendationModal.Alert description={alert} />}
      {/* TODO: consider a reusable component for this in the wizard that is extended here */}
      <DatePicker
        autoComplete={'off'}
        dateFormat={'MMM d, yyyy h:mm aa'}
        helpPlacement={'bottom'}
        helpText={t('Original end date: {{-date}}', { date: endTimeInitialFormatted })}
        isFullWidth={true}
        label={t('New end date')}
        minDate={momentToDate(recommendedMoment)}
        name={'date_end'}
        onDateStrChange={handleOnChangeDateEnd}
        showTimeSelect={true}
        suffix={timezoneAbbr(endMoment.toDate())}
        timeIntervals={5}
        validationError={errors}
        value={endTimeStamp}
      />
    </>
  );
}

export default observer(ExtendEndDate);
