import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { AggregatedStat, EmailBaseCampaign, TAttributionModel } from '@feathr/blackbox';
import { CampaignClass, isDripCampaign } from '@feathr/blackbox';
import { StatBox, StatsCard } from '@feathr/components';

import { ReportContext } from '../Report.context';
import { getEmailStats } from './EmailStats.utils';

import * as styles from './EmailStats.css';

interface ICardProps {
  aggregatedStats: AggregatedStat;
  attributionModel: TAttributionModel;
  campaign?: EmailBaseCampaign;
}

function EmailStats({
  aggregatedStats,
  attributionModel,
  campaign,
}: ICardProps): JSX.Element | null {
  const { t } = useTranslation();

  const { prioritizeVerifiedActivity } = useContext(ReportContext) ?? {};

  /*
   * Only for:
   * - CampaignClass.PinpointEmail,
   * - CampaignClass.SmartPinpointEmail,
   * - CampaignClass.AutoPinpointEmail,
   * - CampaignClass.Drip,
   * - CampaignClass.DripStep
   */
  if (!campaign?.isEmail) {
    return null;
  }

  const isSmart = [CampaignClass.SmartPinpointEmail, CampaignClass.AutoPinpointEmail].includes(
    campaign.get('_cls'),
  );
  const isDrip = isDripCampaign(campaign);

  const {
    clicks,
    clicksPerPerson,
    conversions,
    conversionValue,
    bounces,
    deliveries,
    deliveryRate,
    hardBounces,
    notSent,
    opens,
    opensPerPerson,
    sends,
    targeted,
    uniqueClicks,
    uniqueClickThroughRate,
    uniqueOpens,
    uniqueOpenRate,
    unsubscribes,
  } = getEmailStats(aggregatedStats, attributionModel, prioritizeVerifiedActivity);

  function formatNumber(value: number): string {
    return numeral(value).format('0,0');
  }

  function formatPercent(value: number, suffix?: string): string {
    return numeral(value).format('0.00%') + (suffix ? ' ' + suffix : '');
  }

  const { targetedLabel, targetedTooltip } = (function (): {
    targetedLabel: string;
    targetedTooltip: string;
  } {
    return {
      targetedLabel: isDrip ? t('Total people enrolled in drip') : t('Targeted'),
      targetedTooltip: isDrip
        ? t(
            'The number of unique individuals included in this drip campaign. These individuals are automatically sent emails according to the drip schedule.',
          )
        : t(
            'The total number of people initially targeted based on the combination of included and excluded groups.',
          ),
    };
  })();

  return (
    <>
      <StatsCard.Content title={t('Delivery')}>
        <div className={styles.content}>
          {targeted > 0 && (
            <StatBox
              label={targetedLabel}
              primary={
                !isSmart || campaign.isAfterDateSendEnd ? formatNumber(targeted) : formatNumber(0)
              }
              tooltip={targetedTooltip}
            />
          )}
          <StatBox
            label={t('Sends')}
            primary={formatNumber(sends)}
            secondary={
              notSent < 0
                ? undefined
                : !isSmart || campaign.isAfterDateSendEnd
                  ? formatNumber(notSent)
                  : formatNumber(0)
            }
            secondaryLabel={notSent < 0 ? undefined : t('Not sent')}
            tooltip={t(
              'The number of people this email was sent to. Your email will not be sent to targeted persons who previously hard bounced, unsubscribed, or marked your emails as spam.',
            )}
          />
          <StatBox
            label={t('Bounces')}
            primary={formatNumber(bounces)}
            secondary={formatNumber(hardBounces)}
            secondaryLabel={t('Hard bounces')}
            tooltip={t(
              'The number of people who did not receive your email due to a hard or soft bounce.',
            )}
          />
          <StatBox
            label={t('Deliveries')}
            primary={formatNumber(deliveries)}
            secondary={formatPercent(deliveryRate)}
            secondaryLabel={t('Delivery rate')}
            tooltip={t('The number of people who successfully received your email.')}
          />
          {!isDrip && (
            <StatBox
              label={t('Unsubscribes')}
              primary={formatNumber(unsubscribes)}
              tooltip={t('The number of people who unsubscribed through this email.')}
            />
          )}
        </div>
      </StatsCard.Content>
      <StatsCard.Content title={t('Performance')}>
        <div className={styles.content}>
          <StatBox
            label={t('Unique opens')}
            primary={formatNumber(uniqueOpens)}
            secondary={formatPercent(uniqueOpenRate)}
            secondaryLabel={t('Open rate')}
            tooltip={t('The total number of people who have opened this email.')}
          />
          <StatBox
            label={t('Total opens')}
            primary={formatNumber(opens)}
            secondary={formatNumber(opensPerPerson)}
            secondaryLabel={t('Opens per person')}
            tooltip={t(
              'The total number of times this email has been opened. Includes multiple opens by the same person.',
            )}
          />
          <StatBox
            label={t('Unique clicks')}
            primary={formatNumber(uniqueClicks)}
            secondary={formatPercent(uniqueClickThroughRate)}
            secondaryLabel={t('Click through rate')}
            tooltip={t('The total number of people who clicked on a link in this email.')}
          />
          <StatBox
            label={t('Total clicks')}
            primary={formatNumber(clicks)}
            secondary={formatNumber(clicksPerPerson)}
            secondaryLabel={t('Clicks per person')}
            tooltip={t(
              'The total number of times that links in this email have been clicked. Includes multiple clicks by the same person.',
            )}
          />
        </div>
      </StatsCard.Content>
      {isDrip && (
        <StatsCard.Content title={t('Conversions')}>
          <StatBox
            label={t('Conversions')}
            primary={formatNumber(conversions)}
            tooltip={t('The total number of conversions driven by emails.')}
          />
          <StatBox
            label={t('Conversion value')}
            primary={`$${formatNumber(conversionValue)}`}
            tooltip={t('The total monetary value of all conversions generated by this campaign.')}
          />
        </StatsCard.Content>
      )}
    </>
  );
}

export default observer(EmailStats);
