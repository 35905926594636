import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { SaveButton } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { useAccount, useActionBar, useRole } from '@feathr/extender/state';
import { flattenErrors } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import AttributionOptions from './AttributionOptions';
import ConversionsOptions from './ConversionsOptions';

function ConversionsPage(): JSX.Element {
  const account = useAccount();
  const { isAdmin, isSudoer } = useRole();
  const { t } = useTranslation();

  const canSetConversionOptions = isSudoer || isAdmin;
  const { setRightActions } = useActionBar();

  const validationErrors: TValidateGrouped = account.validate(
    ['multi_convert_cooldown_value'],
    false,
    'grouped',
  ).errors;
  const flattenedErrors = flattenErrors(validationErrors);

  useEffect(() => {
    setRightActions(
      <SaveButton
        disabled={flattenedErrors.length > 0 || !account.isDirty || account.isPending}
        method={'patch'}
        model={account}
      />,
    );
  }, [account.isDirty, flattenedErrors.length]);

  return (
    <Page title={t('Conversions')}>
      {canSetConversionOptions && <ConversionsOptions account={account} />}
      <AttributionOptions account={account} />
    </Page>
  );
}

export default observer(ConversionsPage);
