import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { TemplateClass } from '@feathr/blackbox';
import { Button, Card, Fieldset, Form, Icon, PageHeader, showError } from '@feathr/components';
import type {
  BeePluginContentDialogHandler,
  IAddOnResponseHTML,
} from '@feathr/extender/components/BeeEditor';
import FormSelect from '@feathr/extender/components/FormSelect';
import { getFormEmbedCode, useLocalUrl, useStore } from '@feathr/extender/state';

import * as styles from '../TemplateEditor.css';

interface ICustomFields {
  formId?: string;
  eventId?: string;
  redirectDomainId?: string;
  templateType?: TemplateClass;
}

export interface IFeathrFormDialogProps {
  customFields?: ICustomFields;
  onClose: () => void;
  resolve?: Parameters<BeePluginContentDialogHandler<IAddOnResponseHTML>>[0];
  reject?: Parameters<BeePluginContentDialogHandler<IAddOnResponseHTML>>[1];
}

function FeathrFormDialog({
  customFields,
  onClose,
  resolve,
  reject,
}: Readonly<IFeathrFormDialogProps>): JSX.Element {
  const { Events, Forms, RedirectDomains } = useStore();
  const localUrl = useLocalUrl();
  const { eventId: eventIdParam } = useParams<{ eventId: string }>();
  const [formId, setFormId] = useState<string | undefined>(customFields?.formId);
  const [redirectDomainId, setRedirectDomainId] = useState<string | undefined>(
    customFields?.redirectDomainId,
  );
  const { t } = useTranslation();

  const eventId = customFields?.eventId ?? eventIdParam;
  // TODO: Get templateType from props instead
  const templateType = customFields?.templateType ?? TemplateClass.LandingPage;

  // Set redirect domain from event
  useEffect(() => {
    (async (): Promise<void> => {
      if (!eventId) {
        return;
      }
      try {
        const event = Events.get(eventId);
        await when(() => !event.isPending);
        if (event.isErrored) {
          throw event.error;
        }
        if (templateType === TemplateClass.LandingPage) {
          // Landing pages
          setRedirectDomainId(event.get('mktg_page_rdr_domain').id);
        } else {
          // Invite pages
          setRedirectDomainId(event.get('mktg_email_rdr_domain').id);
        }
      } catch (error) {
        showError(error, t);
      }
    })();
  }, [eventId]);

  function handleClickCancel(): void {
    if (reject) {
      reject();
    }
    onClose();
  }

  async function handleConfirmClick(): Promise<void> {
    if (resolve && formId && redirectDomainId) {
      try {
        const publishedForm = await Forms.ensurePublishedForm(formId, redirectDomainId);
        if (!publishedForm.short_code) {
          throw new Error('The form does not have a short code.');
        }

        // Fetch domain from id
        const redirectDomain = RedirectDomains.get(redirectDomainId);
        await when(() => !redirectDomain.isPending);
        if (redirectDomain.isErrored) {
          throw new Error('Unable to resolve domain url');
        }

        const code = getFormEmbedCode(
          publishedForm.short_code,
          redirectDomain.get('domain'),
          publishedForm.name,
        );
        resolve({
          type: 'html',
          value: {
            customFields: {
              formId,
              redirectDomainId,
            },
            html: code,
          },
        });
        onClose();
      } catch (error) {
        showError(error, t);
        handleClickCancel();
        return;
      }
    }
  }

  function handleFormChange(value?: string): void {
    setFormId(value);
  }

  return (
    <Card className={styles.dialogForm}>
      <Form
        actions={[
          <Button key={'cancel'} onClick={handleClickCancel}>
            {t('Cancel')}
          </Button>,
          <Button key={'confirm'} onClick={handleConfirmClick} type={'primary'}>
            {t('Apply')}
          </Button>,
        ]}
        label={'Select a Form'}
      >
        <PageHeader title={'Select a Form'} />
        <Fieldset>
          <FormSelect
            filters={{ event: eventId, state: 'published' }}
            label={'Form'}
            onChange={handleFormChange}
            value={formId}
          />
        </Fieldset>
        <p>
          <Button
            link={localUrl(`/projects/${eventId}/content/forms`)}
            suffix={<Icon icon={faArrowUpRightFromSquare} />}
            target={'_blank'}
            type={'link'}
          >
            {t('Start from scratch')}
          </Button>
        </p>
      </Form>
    </Card>
  );
}

export default observer(FeathrFormDialog);
