import { validate } from '@feathr/rachis';

export function validateName({
  oldName,
  newName,
}: {
  oldName: string;
  newName?: string;
}): string[] {
  const error = validate(
    { oldName, newName },
    {
      newName: {
        presence: {
          allowEmpty: false,
          message: '^Name cannot be empty',
        },
        equality: {
          attribute: 'oldName',
          message: '^New name cannot be the same as the current name',
          comparator: (value: string, other: string) => value !== other,
        },
      },
    },
    { format: 'flat' },
  );
  return error;
}
