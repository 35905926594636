import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEqual from 'lodash.isequal';
import { when } from 'mobx';
import type { JSX } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type {
  EmailVerification,
  IAddress,
  IPinpointEmailCampaign,
  PinpointEmailBaseCampaign,
  TEmailVerificationStatus,
} from '@feathr/blackbox';
import { CampaignClass, EPinpointRequestStatus } from '@feathr/blackbox';
import type { ISelectOption, TEmailInputStatus } from '@feathr/components';
import {
  AddressInput,
  AlertV2 as Alert,
  Button,
  Checkbox,
  EAlertV2Type as AlertType,
  EmailSelect,
  Fieldset,
  Input,
  isAddressEmpty,
  SenderInformationWell,
  showError,
  toast,
  Tooltip,
  Well,
} from '@feathr/components';
import { useLocalUrl, useStore } from '@feathr/extender/state';
import { flattenError, useDebounce } from '@feathr/hooks';
import type { IValidateGrouped } from '@feathr/rachis';
import { validate } from '@feathr/rachis';

import * as styles from './SenderInformationInputs.css';

interface ISenderInformationInputsProps {
  campaign: PinpointEmailBaseCampaign;
  disabled: boolean;
  isVerifiedDomainSender?: boolean;
  validationErrors: IValidateGrouped;
}

function isEmailVerificationEmpty(emailVerification?: EmailVerification): boolean {
  const fromName = emailVerification?.get('from_name');
  const address = emailVerification?.get('address') ?? ({} as IAddress);
  return !fromName || isAddressEmpty(address);
}

function SenderInformationInputs({
  campaign,
  disabled = false,
  isVerifiedDomainSender = false,
  validationErrors,
}: Readonly<ISenderInformationInputsProps>): JSX.Element {
  const { t } = useTranslation();
  const { EmailVerifications } = useStore();
  const [copyFromVerification, setCopyFromVerification] = useState(false);
  const [userAddressOverride, setUserAddressOverride] = useState(false);
  const [emailVerification, setEmailVerification] = useState<EmailVerification | undefined>();
  const [fromAddress, setFromAddress] = useDebounce(campaign.get('from_address'));
  const [hadFocusFromAddress, setHadFocusFromAddress] = useState(false);
  const [isPending, setIsPending] = useState(true);

  const localUrl = useLocalUrl();
  const hasDefaults = !isEmailVerificationEmpty(emailVerification);

  const isAutosend = campaign.get('_cls') === CampaignClass.AutoPinpointEmail;
  const shouldEnforceSendLimit = !isAutosend && !isVerifiedDomainSender;

  const getEmailStatus: (
    fromAddress: string | undefined,
  ) => Promise<EmailVerification | undefined> = useCallback(
    async (fromAddress) => {
      try {
        // If fromAddress is not set, don't bother fetching anything
        if (!fromAddress) {
          setEmailVerification(undefined);
          return;
        }

        await when(() => !campaign.isPending);

        setIsPending(true);
        const emailVerifications = EmailVerifications.list(
          { filters: { email: fromAddress } },
          { reset: true },
        );
        await when(() => !emailVerifications.isPending);

        if (!emailVerifications.models.length) {
          const error: string[] | undefined = validate.single(fromAddress, {
            presence: { allowEmpty: false, message: 'Email address has to be valid.' },
            email: true,
          });

          if (!error) {
            const model = EmailVerifications.create({ email: fromAddress });
            await when(() => !model.isPending);
            const newEmailVerification = await EmailVerifications.add(model);
            await when(() => !newEmailVerification.isPending);

            setEmailVerification(newEmailVerification);
            return newEmailVerification;
          }
        }

        const newEmailVerification =
          !emailVerifications.isErrored && emailVerifications.models.length === 1
            ? emailVerifications.models[0]
            : undefined;
        setEmailVerification(newEmailVerification);

        return newEmailVerification;
      } catch (error) {
        showError(error, t);
        return;
      } finally {
        // Ensure isPending is always reset to false regardless of success or failure
        setIsPending(false);
      }
    },
    [campaign, EmailVerifications],
  );

  const emailStatus = emailVerification?.get('status') ?? 'Unverified';

  useEffect(() => {
    // Create a reference to track if this effect instance is still the most recent one
    let isCurrentEffect = true;

    const updateCampaign = async (): Promise<void> => {
      try {
        const newEmailVerification = await getEmailStatus(fromAddress);

        // Don't update state if this effect has been superseded by a more recent one
        if (!isCurrentEffect) {
          return;
        }

        const patch: Partial<IPinpointEmailCampaign> = {
          from_address: fromAddress,
          from_name: campaign.get('from_name'),
          address: campaign.get('address'),
        };

        if (newEmailVerification && newEmailVerification.get('status') === 'Success') {
          // If the from address has changed, update the campaign with the new verified address
          if (fromAddress !== campaign.get('from_address')) {
            patch.from_name = newEmailVerification.get('from_name');
            patch.address = newEmailVerification.get('address');
            setUserAddressOverride(false);
            // If the user has checked the checkbox to use the verified address, update the campaign with the verified address
          } else if (userAddressOverride && copyFromVerification) {
            patch.from_name = newEmailVerification.get('from_name');
            patch.address = newEmailVerification.get('address');
          }

          // only set copyFromVerification if it hasn't already been set by user checking or unchecking the checkbox
          if (!userAddressOverride) {
            if (
              patch.from_name === newEmailVerification.get('from_name') &&
              isEqual(patch.address, newEmailVerification.get('address'))
            ) {
              setCopyFromVerification(true);
            } else {
              setCopyFromVerification(false);
            }
          }
        }

        campaign.set(patch);
      } catch (error) {
        showError(error, t);
      }
    };

    updateCampaign();

    // Cleanup function to mark this effect as no longer current if dependencies change
    return () => {
      isCurrentEffect = false;
    };
  }, [getEmailStatus, fromAddress, campaign, copyFromVerification, userAddressOverride]);

  function handleBlurFromAddress(): void {
    setHadFocusFromAddress(true);
  }

  function handleChangeFromAddress(email?: string): void {
    setFromAddress(email ?? '');
  }

  async function handleVerify(): Promise<void> {
    const updatedEmailVerification = await getEmailStatus(fromAddress);
    const updatedEmailStatus = updatedEmailVerification?.get('status') ?? 'Unverified';
    if (updatedEmailStatus === 'Success') {
      toast(t('This email address has been verified.'), { type: ToastType.SUCCESS });
      return;
    }

    if (updatedEmailStatus === 'Pending') {
      // Status was updated by getEmailStatus().
      toast(t('Check your email for a verification link.'), { type: ToastType.INFO });
      return;
    }

    const model = EmailVerifications.create({ email: campaign.get('from_address') });
    const response = await EmailVerifications.add(model);
    if (response.isErrored) {
      toast(t('There was a problem trying to send a verification email.'), {
        type: ToastType.ERROR,
      });
    } else {
      setEmailVerification(response);
      const isVerified = response.get('status') === EPinpointRequestStatus.Success;
      toast(
        isVerified
          ? t('Your email address {{fromAddress}} is verified', {
              fromAddress: campaign.get('from_address'),
            })
          : t(
              'A verification link has been sent to {{fromAddress}}. This link will expire in 24 hours.',
              { fromAddress: campaign.get('from_address') },
            ),
        { type: isVerified ? ToastType.SUCCESS : ToastType.INFO },
      );
    }
  }

  async function handleResend(): Promise<void> {
    if (emailVerification) {
      const response = await emailVerification.resend();
      if (emailVerification.isErrored) {
        toast(t('There was a problem trying to resend a verification email.'), {
          type: ToastType.ERROR,
        });
        // eslint-disable-next-line no-console
        console.error(response);
      } else {
        toast(
          t(
            'A verification link has been resent to {{fromAddress}}. This link will expire in 24 hours.',
            { fromAddress: campaign.get('from_address') },
          ),
          { type: ToastType.INFO },
        );
      }
    }
  }

  async function handleChangeCustom(newValue?: boolean): Promise<void> {
    setCopyFromVerification(newValue ?? false);
    setUserAddressOverride(true);
    if (newValue) {
      await when(() => !isPending);
      if (
        emailVerification &&
        emailVerification.get('status') === 'Success' &&
        !isEmailVerificationEmpty(emailVerification)
      ) {
        campaign.set({
          from_name: emailVerification.get('from_name'),
          address: emailVerification.get('address'),
        });
      }
    }
  }

  function createOption(inputValue: string): ISelectOption {
    return { name: inputValue, id: inputValue };
  }

  async function loadOptions(inputValue: string): Promise<ISelectOption[]> {
    const data = EmailVerifications.list({
      filters: inputValue ? { email: { $regex: inputValue, $options: 'i' } } : {},
      ordering: ['email'],
    });
    await when(() => !data.isPending);
    return data.models.map((model) => {
      const email = model.get('email');
      return { id: email, name: email };
    });
  }

  const emailStatusLabelMap: Record<TEmailVerificationStatus, TEmailInputStatus> = {
    Unverified: t('Unverified'),
    Pending: t('Pending'),
    Success: t('Verified'),
  };

  const fromEmailTooltip = t(
    "Please provide an email address you'd like to use for this campaign. All emails sent to your partners or recipient list will originate from this email address.",
  );

  return (
    <>
      {emailVerification &&
        emailVerification?.get('status') !== 'Success' &&
        shouldEnforceSendLimit && (
          <Alert
            className={styles.alert}
            description={t(
              'You can still publish this campaign but we highly recommend authorizing your domain first. Doing so will improve security and deliverability.',
            )}
            title={t(
              'The domain of the sender address for this campaign has not been validated for sending emails yet.',
            )}
            type={AlertType.info}
          >
            <a href={localUrl('settings/account/domains')}>{t('Authorize domain')}</a>
          </Alert>
        )}
      <EmailSelect
        aria-label={t('From email address')}
        createOption={createOption}
        disabled={disabled}
        isLoading={campaign.isPending || isPending}
        label={
          <>
            {t('From email address')}{' '}
            <Tooltip title={fromEmailTooltip}>
              <FontAwesomeIcon icon={faInfoCircle} />
            </Tooltip>
          </>
        }
        loadOptions={loadOptions}
        name={'from_email_address'}
        onBlur={handleBlurFromAddress}
        onChange={handleChangeFromAddress}
        onVerify={handleVerify}
        status={emailStatusLabelMap[emailStatus]}
        t={t}
        validationError={
          hadFocusFromAddress ? flattenError(validationErrors.from_address) : undefined
        }
        value={campaign.get('from_address')}
        wrapperClassName={styles.emailAddress}
      />
      {!!emailVerification && emailStatus !== 'Success' && (
        <Button name={'resend_email_verification'} onClick={handleResend} type={'link'}>
          {t('Resend verification email')}
        </Button>
      )}
      {emailStatus === 'Success' && (
        <>
          {hasDefaults && (
            <Checkbox
              disabled={disabled || campaign.isPending || isPending}
              label={t('Use contact info from verified email address')}
              name={'use_verified_contact_info'}
              onChange={handleChangeCustom}
              value={copyFromVerification}
            />
          )}
          {!hasDefaults || !copyFromVerification ? (
            <Well className={styles.addressWell} layout={'vertical'}>
              <Fieldset>
                <Input
                  attribute={'from_name'}
                  disabled={disabled}
                  label={t('From name')}
                  model={campaign}
                  name={'from_name'}
                  type={'text'}
                />
              </Fieldset>
              <AddressInput<PinpointEmailBaseCampaign>
                attribute={'address'}
                disabled={disabled}
                isLoading={campaign.isPending}
                model={campaign}
                t={t}
              />
            </Well>
          ) : (
            <SenderInformationWell
              address={campaign.get('address')}
              fromName={campaign.get('from_name')}
              isLoading={campaign.isPending}
              t={t}
            />
          )}
        </>
      )}
    </>
  );
}

export default SenderInformationInputs;
