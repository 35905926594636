import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Button, Card, SaveButton, Tooltip } from '@feathr/components';
import EditFilters from '@feathr/extender/components/EditFilters';
import ParentSegment from '@feathr/extender/components/ParentSegment';
import { useActionBar, useStore } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

import DataPage from '../../DataPage';
import BreadcrumbsTable from '../../DataPage/BreadcrumbsTable';
import PersonsTable from '../../DataPage/PersonsTable';
import SegmentExportModalWrapper from '../SegmentExportModalWrapper';
import SaveAsNewButton from './SaveAsNewButton';

function DataSegmentPage(): JSX.Element {
  const [isExportModalOpen, toggleIsExportModalOpen] = useToggle(false);
  const { Segments } = useStore();
  const { segmentId, view } = useParams<{
    segmentId: string;
    view: 'activity' | 'people';
  }>();
  const { t } = useTranslation();
  const { setRightActions } = useActionBar();

  const segment = Segments.get(segmentId);
  const { canClone, canEdit, isReadOnly } = segment.permissions;

  const parentSegmentId = segment.get('parent_segment');
  const parentSegment = parentSegmentId ? Segments.get(parentSegmentId) : undefined;

  const isDisabled = isReadOnly || !canEdit;

  useEffect(() => {
    if (canEdit) {
      setRightActions(
        <Tooltip key={'create'} position={'bottom-end'} title={tooltipTitle}>
          <SaveButton disabled={!segment.isDirty || isDisabled} method={'patch'} model={segment}>
            {t('Save')}
          </SaveButton>
        </Tooltip>,
      );
    }
  }, [segment.isDirty]);

  const filters = (
    <Card>
      <ParentSegment parentSegment={parentSegment} />
      <EditFilters
        disabled={isDisabled}
        mode={view === 'people' ? 'count' : 'crumbs'}
        segment={segment}
      />
    </Card>
  );

  const tooltipTitle = isDisabled
    ? t('Read-only groups cannot be edited.')
    : t('Turn your selected filters into a reusable group.');

  const actions = [
    <Button key={'export'} onClick={toggleIsExportModalOpen}>
      {t('Export to CSV')}
    </Button>,
  ];

  if (canClone) {
    actions.push(<SaveAsNewButton group={segment} key={'save-as-new'} />);
  }

  return (
    <DataPage
      actions={actions}
      filters={filters}
      isGroupsSection={true}
      permissions={segment.permissions}
      sectionTitle={segment.name}
      title={view === 'people' ? t('People') : t('Activity')}
    >
      {view === 'people' ? (
        <PersonsTable segment={segment} />
      ) : (
        <BreadcrumbsTable segment={segment} />
      )}
      {isExportModalOpen && (
        <SegmentExportModalWrapper segment={segment} toggleIsModalOpen={toggleIsExportModalOpen} />
      )}
    </DataPage>
  );
}

export default observer(DataSegmentPage);
