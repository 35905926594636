import { observer } from 'mobx-react-lite';
import type { JSX, ReactElement } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { type Campaign, CampaignClass } from '@feathr/blackbox';
import { Label, Time, TimeRange } from '@feathr/components';
import { TimeFormat } from '@feathr/hooks';

import { useUser } from '../../state';

import styles from './CampaignDates.css';

interface ICampaignDatesProps {
  campaign: Campaign;
}

function CampaignDates({ campaign }: Readonly<ICampaignDatesProps>): JSX.Element | null {
  const { t } = useTranslation();
  const user = useUser();
  const timezone = user.get('timezone');

  function delimiter(from: ReactElement, to: ReactElement): JSX.Element {
    return (
      <div className={styles.timeRange}>
        <span className={styles.time}>
          <Label className={styles.label}>{t('From')}</Label>
          {from}
        </span>
        <span className={styles.time}>
          <Label className={styles.label} weight={'normal'}>
            {t('to')}
          </Label>
          {to}
        </span>
      </div>
    );
  }

  function getCampaignDate(campaign: Campaign, timezone: string): JSX.Element | null {
    /*
     * Single Send campaigns are also email campaigns.
     * We check for them first because they have a start date. The other types have a date range.
     */
    if (campaign.isSingleSend) {
      return (
        <Time
          format={TimeFormat.pickerShortDateTimeZone}
          prefix={t('Start date')}
          timestamp={campaign.get('date_send_start')}
          timezone={timezone}
          wrapperClassName={styles.timeLabel}
        />
      );
    }

    if (campaign.isEmail) {
      return (
        <TimeRange
          delimiter={delimiter}
          end={campaign.get('date_send_end')}
          format={TimeFormat.pickerShortDateTimeZone}
          start={campaign.get('date_send_start')}
          timezone={timezone}
        />
      );
    }

    if (campaign.isAdCampaign) {
      return (
        <TimeRange
          delimiter={delimiter}
          end={campaign.get('date_end')}
          format={TimeFormat.pickerShortDateTimeZone}
          start={campaign.get('date_start')}
          timezone={timezone}
        />
      );
    }

    if (campaign.isLandingPageCampaign) {
      return (
        <Time
          format={TimeFormat.shortWeekdayDate}
          prefix={t('Start date')}
          timestamp={campaign.get('date_start')}
          timezone={timezone}
          wrapperClassName={styles.timeLabel}
        />
      );
    }

    if (campaign.get('_cls') === CampaignClass.Conversation) {
      return null;
    }

    // Handle non-email campaign types
    return (
      <TimeRange
        delimiter={delimiter}
        end={campaign.get('date_end')}
        format={TimeFormat.shortWeekdayDate}
        start={campaign.get('date_start')}
        timezone={timezone}
      />
    );
  }

  return getCampaignDate(campaign, timezone);
}

export default observer(CampaignDates);
