import { observer } from 'mobx-react-lite';
import moment from 'moment';
import type { JSX } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { GoogleAdsCustomer } from '@feathr/blackbox';
import { EAdGrantStatus, EConnectionStatus } from '@feathr/blackbox';
import {
  AlertV2 as Alert,
  Button,
  CardV2 as Card,
  Chip,
  EAlertV2Type,
  toast,
  Well,
} from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { useStore } from '@feathr/extender/state';
import { connectionStatusInfo } from '@feathr/extender/styles/googleAdsConnectionStatus';

import GoogleAdsCustomerConnect from './GoogleAdsCustomerConnect';

import * as styles from './GoogleAdsCustomersPage.css';

import googleAdsLogo from 'images/integrations/google_ads.png';

function GoogleAdsCustomersPage(): JSX.Element {
  const { GoogleAdsCustomers } = useStore();
  const [customer, setCustomer] = useState<GoogleAdsCustomer | undefined>(undefined);
  const { t } = useTranslation();

  const customers = GoogleAdsCustomers.list();

  const status = customer?.get('invite_status') ?? EConnectionStatus.disconnected;

  const dateIntegrated = moment
    .utc(customer?.get('date_integrated'), moment.ISO_8601)
    .format('MMMM Do YYYY');

  const { theme, text } = connectionStatusInfo(status, t, dateIntegrated);

  async function handleConnect(id: string): Promise<void> {
    try {
      const response = await GoogleAdsCustomers.linkCustomer({
        customerId: id,
        action: 'link',
      });

      const newCustomer = GoogleAdsCustomers.create(response);
      setCustomer(newCustomer);

      if (newCustomer.get('invite_status') === EConnectionStatus.pending) {
        toast(t('Your invitation is still pending and has not been accepted yet.'), {
          type: ToastType.WARNING,
        });
      }
    } catch (error) {
      toast(t('Something went wrong: {{- error}}', { error }), {
        type: ToastType.ERROR,
      });
    }
  }

  async function handleDisconnect(): Promise<void> {
    try {
      if (customer) {
        const toastMessage =
          customer.get('invite_status') === EConnectionStatus.pending
            ? t('Your pending Google Ad Grants connection has been reset.')
            : t('Your Google Ad Grants account has been disconnected.');

        const response = await GoogleAdsCustomers.linkCustomer({
          customerId: customer.get('customer_id'),
          action: 'unlink',
        });

        const { invite_status: connectionStatus } = response;

        customer.set({
          invite_status: connectionStatus,
        });

        toast(toastMessage, {
          type: ToastType.INFO,
        });
      }
    } catch (error) {
      toast(t('Something went wrong: {{- error}}', { error }), {
        type: ToastType.ERROR,
      });
    }
  }

  const handleRefresh = useCallback(
    async (showToast = true): Promise<void> => {
      try {
        if (customer) {
          const { invite_status: connectionStatus, date_integrated: dateIntegrated } =
            await customer.refresh({
              customerId: customer.get('customer_id'),
            });

          customer.set({ invite_status: connectionStatus, date_integrated: dateIntegrated });

          if (showToast) {
            if (connectionStatus === EConnectionStatus.connected) {
              toast(t('Your Google Ad Grants account has been connected successfully.'), {
                type: ToastType.SUCCESS,
              });
            } else {
              toast(t('Your invitation is still pending and has not been accepted yet.'), {
                type: ToastType.WARNING,
              });
            }
          }
        }
      } catch (error) {
        toast(t('Something went wrong: {{- error}}', { error }), {
          type: ToastType.ERROR,
        });
      }
    },
    [customer, t],
  );

  useEffect(() => {
    const filteredCustomer = customers.models.find((customer) =>
      Object.values(EConnectionStatus).includes(customer.get('invite_status')),
    );

    if (filteredCustomer) {
      setCustomer(filteredCustomer);
      handleRefresh(false);
    }
  }, [customers.isPending, customers.models, handleRefresh]);

  const logo = (
    <div className={styles.logo}>
      <img alt={''} src={googleAdsLogo} />
    </div>
  );

  const showAdGrantStatusAlert =
    customer?.get('invite_status') === EConnectionStatus.disconnected &&
    customer?.get('ad_grant_status') === EAdGrantStatus.paid;

  return (
    <Page
      collapseHeader={false}
      collapseHeaderOnScroll={true}
      description={t(
        'Google Ads is an online advertising platform developed by Google, where advertisers bid to display brief advertisements, service offerings, product listings, or videos to web users. It can place ads both in the results of search engines like Google Search and on non-search websites, mobile apps, and videos.',
      )}
      loading={customers.isPending}
      title={t('Google Ads')}
    >
      <div className={styles.page}>
        <Card>
          <Card.Header title={t('Google Ads Accounts')} titlePrefix={logo}>
            <GoogleAdsCustomerConnect
              key={'customer-connect'}
              onConnect={handleConnect}
              onDisconnect={handleDisconnect}
              onRefresh={handleRefresh}
              status={status}
            />
          </Card.Header>
          <Card.Content
            addVerticalGap={true}
            description={t(
              'Google Ad Grants shows your message to people searching for nonprofits like yours. Each qualifying nonprofit has access to up to $10,000 per month in search ads shown on Google.com. Additional Google Ads may be purchased in a separate account.',
            )}
          >
            {status === EConnectionStatus.connected && (
              <Well
                description={`ID: ${customer?.get('customer_id')}`}
                // descriptive_name is string or null on the model and label expects string or undefined
                label={customer?.get('descriptive_name') ?? undefined}
              />
            )}
            <Chip className={styles.chip} theme={theme}>
              {text}
            </Chip>
            {status === EConnectionStatus.pending && (
              <Alert
                className={styles.alert}
                description={t('The status will appear as pending until processed by Google.')}
                title={t(
                  'After accepting the email, it can take up to an hour for the connected status to be reflected here.',
                )}
                type={EAlertV2Type.info}
              />
            )}
            {showAdGrantStatusAlert && (
              <Alert
                className={styles.alert}
                description={t(
                  "We were able to access your account, but we couldn't complete the connection because it's not a Google Ad Grants account. Please try again using a Google Ad Grants account. If this issue keeps happening, contact Support.",
                )}
                title={t('Unable to connect your account')}
                type={EAlertV2Type.warning}
              />
            )}
          </Card.Content>
          {status === EConnectionStatus.pending && (
            <Card.Content contentClassName={styles.footer} title={t('Having trouble connecting?')}>
              <Button onClick={handleDisconnect} type={'link'}>
                {t('Reset')}
              </Button>
            </Card.Content>
          )}
        </Card>
      </div>
    </Page>
  );
}

export default observer(GoogleAdsCustomersPage);
