import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { type TTDCampaign } from '@feathr/blackbox';
import { AddGoals } from '@feathr/extender/App/EventsPage/CampaignEditPage/StepGoals';
import { useStore } from '@feathr/extender/state';

import RecommendationModal from './RecommendationModal';

interface IProps {
  campaign: TTDCampaign;
  errors?: string[];
}

function AddGoal({ campaign }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { Goals } = useStore();

  const goals = Goals.list({
    filters: {
      _parent: campaign.id,
      is_archived__ne: true,
    },
  });

  const alert = t('Add a goal to track conversions and ROI');

  return (
    <>
      <RecommendationModal.Alert description={alert} />
      {/* TODO: verify that upon saving, the UI is refreshed on the report page to show the conversions stats */}
      {/* TODO: ask if we need to manually run recalc after adding a goal */}
      {/* TODO: validation isn't working 100% - figure out why we can have an empty goal target (probably need to add to child models in the save method) */}
      <AddGoals campaign={campaign} goals={goals.models} />
    </>
  );
}

export default observer(AddGoal);
