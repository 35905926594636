import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import { type Campaign, CampaignState } from '@feathr/blackbox';
import type { IPageProps } from '@feathr/extender/App/Page';
import Page from '@feathr/extender/App/Page';
import CampaignChips from '@feathr/extender/components/CampaignChips';
import CampaignDates from '@feathr/extender/components/CampaignDates';

export interface IProps extends IPageProps {
  campaign: Campaign;
  children: React.ReactNode;
}

function CampaignPage({ campaign, children, ...props }: IProps): JSX.Element {
  const state = campaign.get('state');

  return (
    <Page
      {...props}
      ChipsComponent={<CampaignChips campaign={campaign} />}
      secondaryText={state !== CampaignState.Draft && <CampaignDates campaign={campaign} />}
      width={'wide'}
    >
      {children}
    </Page>
  );
}

export default observer(CampaignPage);
