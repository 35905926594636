import { useHistory, useLocation } from 'react-router';

import { joinUri, parseUri } from './utils';

export function useRedirect(): [
  (path?: string) => void,
  (path: string, hash?: string, reuse?: boolean) => string,
  boolean,
] {
  const history = useHistory();
  const location = useLocation();

  function hasRedirect(): boolean {
    const params = new URLSearchParams(location.search);
    return !!params.get('redirect');
  }

  function redirect(defaultRedirect?: string): void {
    const params = new URLSearchParams(location.search);
    const redirectParam = params.get('redirect');
    if (redirectParam !== null) {
      history.push(redirectParam);
    } else if (defaultRedirect) {
      history.push(defaultRedirect);
    }
  }

  /**
   * @param path Path to append redirect query to.
   * @param hash Replace current path's hash with this.
   */
  function setRedirect(path: string, hash?: string, reuse?: boolean): string {
    const currentPath = joinUri({ ...location, hash });
    const params = new URLSearchParams(location.search);
    const redirectParamFromURL = params.get('redirect');

    const redirectParam =
      reuse && redirectParamFromURL !== null
        ? `redirect=${redirectParamFromURL}`
        : `redirect=${encodeURIComponent(currentPath)}`;

    const parsed = parseUri(path);
    return joinUri({
      ...parsed,
      search: parsed.search ? `${parsed.search}&${redirectParam}` : redirectParam,
    });
  }

  return [redirect, setRedirect, hasRedirect()];
}
