import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { useAccount, useLocalUrl, useRole } from '@feathr/extender/state';

import AccountsPage from '../AccountsPage';
import DashboardPage from '../DashboardPage';
import NotificationsPage from '../NotificationsPage';
import VersionPage from '../VersionPage';
import DataRoutes from './DataRoutes';
import EventsRoutes from './EventsRoutes';
import MarketingRoutes from './MarketingRoutes';
import NotFound from './NotFound';
import PermissionRequired from './PermissionRequired';
import RedirectTo404 from './RedirectTo404';
import RouteWithPermissions from './RouteWithPermissions';
import SettingsRoutes from './SettingsRoutes';

function Routes(): JSX.Element {
  const localUrl = useLocalUrl();
  const account = useAccount();

  const { activePackage } = account;
  const { isAdmin, isSudoer, hasSegmentsSome, hasProjectsSome } = useRole();
  // Users with access to "some projects" or "some segments" should not have access to the dashboard.
  const hasDashboardAccess = !hasProjectsSome && !hasSegmentsSome;
  const defaultRootPath = hasDashboardAccess ? '/:accountId/dashboard' : '/:accountId/marketing';

  if (!activePackage && !isSudoer) {
    return (
      <Switch>
        <Route path={'/:accountId/settings'}>
          <SettingsRoutes />
        </Route>
        <Redirect to={localUrl('/settings/billing/license')} />
        <RedirectTo404 />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route path={'/404-not-found'}>
        <NotFound />
      </Route>
      <Route path={'/403-forbidden'}>
        <PermissionRequired />
      </Route>
      <Route path={'/version'}>
        <VersionPage />
      </Route>

      <Redirect exact={true} from={'/'} to={defaultRootPath} />
      <Redirect exact={true} from={'/:accountId/'} to={defaultRootPath} />

      <Route path={'/:accountId/404-not-found'}>
        <NotFound />
      </Route>
      <Route path={'/:accountId/403-forbidden'}>
        <PermissionRequired />
      </Route>
      {(isAdmin || isSudoer) && (
        <Route path={'/:accountId/accounts'}>
          <AccountsPage />
        </Route>
      )}
      <RouteWithPermissions condition={hasDashboardAccess} path={'/:accountId/dashboard'}>
        <DashboardPage />
      </RouteWithPermissions>
      <Route path={'/:accountId/marketing'}>
        <MarketingRoutes />
      </Route>
      <Route path={'/:accountId/data'}>
        <DataRoutes />
      </Route>
      <Route path={'/:accountId/projects'}>
        <EventsRoutes />
      </Route>
      <Route path={'/:accountId/notifications'}>
        <NotificationsPage />
      </Route>
      <Route path={'/:accountId/settings'}>
        <SettingsRoutes />
      </Route>
      <Route path={'/:accountId/version'}>
        <VersionPage />
      </Route>

      <RedirectTo404 />
    </Switch>
  );
}

export default observer(Routes);
