import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Person } from '@feathr/blackbox';
import { AvatarV2, Button, TLDR } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';

interface IProps {
  className?: string;
  /** Whether or not to display a link to the person's profile */
  hasLink?: boolean;
  /** Person to display; when undefined, a loading state is displayed */
  person?: Person;
}

function PersonTLDR({ className, hasLink = false, person }: IProps): JSX.Element {
  const localUrl = useLocalUrl();
  const { t } = useTranslation();

  if (person === undefined || person.isPending) {
    return <TLDR className={className} isLoading={true} />;
  }

  return (
    <TLDR
      action={
        hasLink ? (
          <Button link={localUrl(person.getItemUrl())}>{t('View person')}</Button>
        ) : undefined
      }
      className={className}
      image={<AvatarV2 name={person.get('name')} placeholder={person.get('placeholder')} />}
      isLoading={false}
      title={person.name}
    />
  );
}

export default observer(PersonTLDR);
