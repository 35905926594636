import { Flex } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { TTDCampaign } from '@feathr/blackbox';
import { EBiddingStrategyClass, ECampaignObjective } from '@feathr/blackbox';
import { Button, Modal, Radios } from '@feathr/components';
import { cssVar, getIconForAction } from '@feathr/hooks';

import * as styles from './OptimizationRadios.css';

export interface IOptimizationRadiosProps {
  campaign: TTDCampaign;
}

function OptimizationRadios({ campaign }: Readonly<IOptimizationRadiosProps>): JSX.Element {
  const { t } = useTranslation();
  const [openedConfirmModal, { open: openConfirmModal, close: closeConfirmModal }] =
    useDisclosure(false);
  const objective = campaign.get('objective');
  const biddingStrategyType = campaign.get('bidding_strategy')._cls;
  const { isDraft, isPastEndDate } = campaign;

  const disableRadios = isPastEndDate && !isDraft;
  const disableCustomizeOption =
    disableRadios || (objective !== ECampaignObjective.Custom && objective !== undefined);

  function handleChangeOptimization(value?: string): void {
    if (!value) {
      return;
    }

    // If the user is switching from Auto to Manual, open a confirmation modal
    if (value === EBiddingStrategyClass.Manual) {
      openConfirmModal();
    } else {
      selectBiddingStrategyType(value as EBiddingStrategyClass);
    }
  }

  function selectBiddingStrategyType(value: EBiddingStrategyClass): void {
    const currentBiddingStrategy = toJS(campaign.get('bidding_strategy'));

    campaign.set({
      bidding_strategy: {
        ...currentBiddingStrategy,
        _cls: value,
      },
    });
  }

  function onConfirm(): void {
    selectBiddingStrategyType(EBiddingStrategyClass.Manual);
    closeConfirmModal();
  }

  const recommendedSuffix = (
    <Flex align={'center'} gap={cssVar('--spacing-2')}>
      {getIconForAction('magic')}
      <span className={styles.suffix}>{t('Recommended for you')}</span>
    </Flex>
  );

  return (
    <>
      <Radios
        dataName={'bidding_strategy'}
        disabled={disableRadios}
        label={t('Optimization')}
        layout={'block'}
        onChange={handleChangeOptimization}
        options={[
          {
            id: EBiddingStrategyClass.Auto,
            name: t('Auto-optimize'),
            description: t('Maximum performance with minimal effort'),
            suffix: recommendedSuffix,
            theme: 'violet',
            disabled: disableRadios,
          },
          {
            id: EBiddingStrategyClass.Manual,
            name: t('Customize'),
            description: t('Manual control for specific campaign needs'),
            disabled: disableCustomizeOption,
            tooltip: t(
              'Customize is only available when the custom campaign objective is selected',
            ),
          },
        ]}
        orientation={'vertical'}
        value={biddingStrategyType}
      />

      <Modal
        description={t(
          'This is not recommended and typically will result in a less-performant campaign.',
        )}
        onClose={closeConfirmModal}
        opened={openedConfirmModal}
        rightActions={
          <>
            <Button onClick={closeConfirmModal}>{t('Cancel')}</Button>
            <Button onClick={onConfirm} type={'primary'}>
              {t('Confirm')}
            </Button>
          </>
        }
        title={t('Customize optimization?')}
      />
    </>
  );
}

export default observer(OptimizationRadios);
