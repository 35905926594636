import capitalize from 'lodash.capitalize';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import { type Campaign } from '@feathr/blackbox';
import { Chip } from '@feathr/components';

import { campaignStateColorMap } from '../../styles/campaign';
import CampaignTypeChip from '../CampaignTypeChip';

import styles from './CampaignChips.css';

interface ICampaignChipsProps {
  campaign: Campaign;
}

function CampaignChips({ campaign }: Readonly<ICampaignChipsProps>): JSX.Element {
  const cls = campaign.get('_cls');
  const state = campaign.get('state');

  return (
    <div className={styles.chips}>
      <CampaignTypeChip cls={cls} isMonetization={campaign.isMonetization} />
      <Chip name={'campaign-status'} theme={campaignStateColorMap.get(state)}>
        {capitalize(state)}
      </Chip>
    </div>
  );
}

export default observer(CampaignChips);
