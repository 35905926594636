import type { UniqueIdentifier } from '@dnd-kit/core';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import type { Dispatch, JSX, SetStateAction } from 'react';
import React from 'react';

import type { IListRowItem } from '@feathr/blackbox';
import { FieldDataType, type IRowItem } from '@feathr/blackbox';
import { Button, Input, Toggle } from '@feathr/components';
import { getIconForAction } from '@feathr/hooks';

import type { IFieldError } from '../../FormEditor';
import SelectListOptions from './SelectListOptions';

import * as styles from './FieldProperties.css';

interface IProps {
  field: IRowItem;
  isRemovable: boolean;
  onFocusField: Dispatch<SetStateAction<IRowItem | undefined>>;
  updateFieldProperties: (key: UniqueIdentifier, value: unknown) => void;
  onDeleteField: () => void;
  onFieldBlur: (fieldId: string) => void;
  validationErrors: IFieldError[];
}

function FieldProperties({
  field,
  isRemovable = true,
  onFocusField,
  updateFieldProperties,
  onDeleteField,
  onFieldBlur,
  validationErrors,
}: Readonly<IProps>): JSX.Element {
  function handleToggleRequired(): void {
    updateFieldProperties('required', !field.required);
  }

  function handleChangeLabel(newValue?: string): void {
    updateFieldProperties('label', newValue ?? '');
  }

  function handleChangeHelpText(newValue?: string): void {
    updateFieldProperties('helpText', newValue ?? '');
  }

  function handleChangePlaceholder(newValue?: string): void {
    updateFieldProperties('placeholder', newValue ?? '');
  }

  function handleChangeOptions(newValue?: string[]): void {
    updateFieldProperties('options', newValue ?? []);
  }

  function handleToggleMulti(): void {
    updateFieldProperties('multi', !(field as IListRowItem).multi);
  }

  function handleClickBack(): void {
    onFocusField(undefined);
  }

  const { date, float, int, str, list } = FieldDataType;

  function isListField(field: IRowItem): field is IListRowItem {
    return field.type === list;
  }

  function handleFieldBlur(): void {
    onFieldBlur(field.id);
  }

  const labelError = validationErrors.find(
    (error) => error.fieldId === field.id && error.type === 'label',
  );
  const optionsError = validationErrors.find(
    (error) => error.fieldId === field.id && error.type === 'options',
  );

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <Button
          onClick={handleClickBack}
          prefix={getIconForAction('back')}
          type={'icon-outlined'}
        />
        <span className={styles.title}>{t('Edit {{- field}}', { field: field.label })}</span>
      </div>
      <div className={styles.inputs}>
        <Toggle
          disabled={field.id === 'email'}
          label={t('Make required')}
          layout={'well'}
          onChange={handleToggleRequired}
          tooltip={
            field.id === 'email' ? t('Email address is required for form submissions.') : undefined
          }
          value={field.required}
        />
        <Input disabled={true} label={t('Field')} value={field.name} />
        <Input
          label={t('Label')}
          onBlur={handleFieldBlur}
          onChange={handleChangeLabel}
          validationError={labelError?.message ? [labelError.message] : undefined}
          value={field.label}
        />
        <Input
          label={t('Help text')}
          onBlur={handleFieldBlur}
          onChange={handleChangeHelpText}
          optional={true}
          value={field.helpText}
        />
        {[str, int, float, date, list].includes(field.type) && (
          <Input
            label={t('Placeholder text')}
            onBlur={handleFieldBlur}
            onChange={handleChangePlaceholder}
            optional={true}
            value={field.placeholder}
          />
        )}
        {isListField(field) && (
          <>
            <SelectListOptions
              field={field}
              onBlur={handleFieldBlur}
              onChange={handleChangeOptions}
              validationError={optionsError?.message}
            />
            <Toggle
              disabled={field.id === 'email'}
              label={t('Allow multiple selections')}
              layout={'well'}
              onChange={handleToggleMulti}
              value={(field as IListRowItem).multi}
            />
          </>
        )}
        {isRemovable && (
          <Button
            onClick={onDeleteField}
            prefix={getIconForAction('delete')}
            type={'dangerSecondary'}
          >
            {t('Delete field')}
          </Button>
        )}
      </div>
    </div>
  );
}

export default observer(FieldProperties);
