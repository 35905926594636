import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import { useStore } from '@feathr/extender/state';

import { skeleton } from '@feathr/components/dist/style/skeleton.css';

interface IProps {
  className?: string;
  id: string;
}

function EventLabel({ className, id }: Readonly<IProps>): JSX.Element {
  const { Events } = useStore();
  const event = Events.get(id);
  return <div className={classNames({ [skeleton]: event.isPending }, className)}>{event.name}</div>;
}

export default observer(EventLabel);
