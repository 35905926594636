import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { WithT } from 'i18next';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import type { RowRenderProps } from 'react-table';

import type { Account } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { Button, Chip, TableColumnHeader } from '@feathr/components';
import { useUser } from '@feathr/extender/state';
import { packageLicenseTierToLabelMap } from '@feathr/extender/styles/license';

import UserCell from './UserCell';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';
import * as styles from './AccountsPage.css';

import noImg from 'images/no-img.png';

interface IRow extends RowRenderProps {
  original: Account;
}

function accountsColumns({ t }: WithT): Array<IColumn<Account>> {
  return [
    {
      id: 'favorite',
      Header: TableColumnHeader({ title: t('Favorite') }),
      sortable: false,
      width: 80,
      className: tableStyles.cellCenter,
      Cell({ original }: IRow): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              const user = useUser();

              const favorites: string[] = user.getSetting('favorite_account_ids') || [];

              async function handleClick(): Promise<void> {
                if (favorites.includes(original.id)) {
                  user.setSetting(
                    'favorite_account_ids',
                    favorites.filter((value) => value !== original.id),
                  );
                } else {
                  user.setSetting('favorite_account_ids', [...favorites, original.id]);
                }
                await user.patchDirty();
              }

              return (
                <Button className={styles.favorite} onClick={handleClick} type={'naked'}>
                  <FontAwesomeIcon
                    className={classNames({
                      [styles.favoriteSelected]: favorites.includes(original.id),
                    })}
                    icon={faStar}
                  />
                </Button>
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'logo',
      Header: TableColumnHeader({ title: t('Logo') }),
      sortable: false,
      width: 80,
      className: tableStyles.cellCenter,
      Cell({ original }: IRow): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => {
              return <img alt={''} className={styles.logo} src={original.get('logo') || noImg} />;
            }}
          </Observer>
        );
      },
    },
    {
      id: 'name',
      Header: TableColumnHeader({ sortType: 'alpha', title: t('Name') }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }: IRow): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => {
              return (
                <Button href={`/${original.id}/accounts`} type={'link'}>
                  {original.name}
                </Button>
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'license.package',
      accessor: 'license.package',
      Header: TableColumnHeader({ sortType: 'alpha', title: t('Type') }),
      headerClassName: tableStyles.sort,
      width: 200,
      Cell({ original }: IRow): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => (
              <Chip>
                {original.activePackage?.name
                  ? packageLicenseTierToLabelMap(t, original.activePackage.name)
                  : t('No active license')}
              </Chip>
            )}
          </Observer>
        );
      },
    },
    {
      id: 'csm',
      accessor: 'license.tier',
      Header: TableColumnHeader({ title: t('Account Manager') }),
      sortable: false,
      width: 200,
      className: tableStyles.cell,
      Cell(row: IRow): JSX.Element {
        return <UserCell {...row} className={styles.accountManager} />;
      },
    },
  ];
}

export default accountsColumns;
