import {
  faBullseye,
  faCalendarCirclePlus,
  faChartLineUp,
  faClock,
  faDollarSign,
  faImage,
  faLaptopMobile,
} from '@fortawesome/pro-regular-svg-icons';

// Move to blackbox when backend is complete
export enum ERecommendationType {
  IncreaseBudget = 'IncreaseBudget',
  IncreaseBudgetExtendEndDate = 'IncreaseBudgetExtendEndDate',
  ExtendEndDate = 'ExtendEndDate',
  UpdateCreatives = 'UpdateCreatives',
  IncreaseBaseBid = 'IncreaseBaseBid',
  IncreaseFrequencyCap = 'IncreaseFrequencyCap',
  EnableOmnitargeting = 'EnableOmnitargeting',
  AddGoal = 'AddGoal',
}

// TODO: Translate and combine with backend recommendations when they're available
export const CAMPAIGN_RECOMMENDATIONS = [
  {
    title: 'Increase budget to get more conversions',
    description:
      'This is a high ROI campaign. Each conversion is worth {4.3x} the cost to earn it. Increase budget to earn even more conversions.',
    action: 'Increase budget',
    icon: faDollarSign,
    type: ERecommendationType.IncreaseBudget,
  },
  {
    title: 'Increase budget and extend campaign to get more conversions',
    description:
      'This is a high ROI campaign. Each conversion is worth {4.3x} the cost to earn it. Increase its budget and length to get even more conversions.',
    action: 'Update campaign',
    icon: faCalendarCirclePlus,
    type: ERecommendationType.IncreaseBudgetExtendEndDate,
  },
  {
    title: 'Extend campaign to get more impressions and clicks',
    description:
      'This campaign is not projected to spend its full budget by the end date. Extend the end date to fully utilize its budget.',
    action: 'Extend campaign',
    icon: faCalendarCirclePlus,
    type: ERecommendationType.ExtendEndDate,
  },
  {
    title: 'Add new creatives to increase CTR',
    description:
      "This campaign's click-through-rate is suffering due to stale creatives. Replace some creatives to keep click-through-rates healthy.",
    action: 'Update creatives',
    icon: faImage,
    type: ERecommendationType.UpdateCreatives,
  },
  {
    title: 'Increase base bid to win more impressions',
    description:
      'This campaign is losing impressions for its target audience because of a low base bid. Increase your base bid to win more impressions.',
    action: 'Increase base bid',
    icon: faChartLineUp,
    type: ERecommendationType.IncreaseBaseBid,
  },
  {
    title: 'Increase frequency cap to win more impressions',
    description:
      'This campaign is losing impressions for its target audience because of a low frequency cap. Increase your base bid to win more impressions.',
    action: 'Increase frequency cap',
    icon: faClock,
    type: ERecommendationType.IncreaseFrequencyCap,
  },
  {
    title: 'Enable Omnitargeting to increase performance',
    description:
      'This campaign is missing out on impressions, clicks, and conversions because Omnitargeting is disabled.',
    action: 'Enable Omnitargeting',
    icon: faLaptopMobile,
    type: ERecommendationType.EnableOmnitargeting,
  },
  {
    title: 'Add a goal to track conversions and ROI',
    description:
      'This campaign is not tracking conversions, conversion value, or ROI because it does not have a goal. Add a goal to improve reporting and optimizations.',
    action: 'Add a goal',
    icon: faBullseye,
    type: ERecommendationType.AddGoal,
  },
];
