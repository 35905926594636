import { ObjectId } from 'bson';
import { runInAction, set } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { EUsageMetrics, type Segment } from '@feathr/blackbox';
import {
  Button,
  Fieldset,
  Input,
  Layout,
  ModalV1,
  showError,
  Table,
  Toolbar,
} from '@feathr/components';
import { useStore, useUsageAndQuota } from '@feathr/extender/state';
import { getIconForAction } from '@feathr/hooks';

import Page from '../Page';
import ConversionPixelsColumns from './ConversionPixelsColumns';
import ConversionPixelUsage from './ConversionPixelUsageTable';

interface IState {
  newSegment?: Segment;
  showModal: boolean;
}

function DataConversionPixelsPage(): JSX.Element {
  const state = useLocalObservable<IState>(() => ({
    newSegment: undefined,
    showModal: false,
  }));
  const { Goals, Segments } = useStore();
  const { t } = useTranslation();
  const { accountId } = useParams<{ accountId: string }>();
  const { getUsageText } = useUsageAndQuota(accountId, [EUsageMetrics.ConversionPixels]);

  async function addConversionPixel(): Promise<void> {
    const segmentId = new ObjectId().toHexString();
    const newSegment = Segments.create({
      id: segmentId,
      read_only: true,
      lookback_mode: 'unbounded',
      predicates: [
        {
          attr_type: 'string',
          attr_against: 'seg_id',
          comparison: 'eq',
          kind: 'activity',
          value: segmentId,
        },
      ],
      is_conversion_segment: true,
    });
    runInAction(() => {
      set(state, { newSegment, showModal: true });
    });
  }

  function close(): void {
    runInAction(() => {
      set(state, { showModal: false });
    });
  }

  async function confirm(): Promise<void> {
    if (state.newSegment) {
      const newSegment = await Segments.add(state.newSegment);
      if (newSegment.error) {
        showError(newSegment.error, t);
        return;
      }

      Segments.refreshApiCache();
      runInAction(() => {
        set(state, { newSegment: undefined, showModal: false });
      });
    }
  }

  const name = state.newSegment?.get('name');
  const validationErrors = useMemo(() => {
    return state.newSegment?.validate(['name'], false).errors;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.newSegment, name]);

  return (
    <Page
      actions={
        <Toolbar>
          <Button onClick={addConversionPixel} prefix={getIconForAction('add')} type={'primary'}>
            {t('Add Conversion Pixel')}
          </Button>
        </Toolbar>
      }
      collapseHeader={false}
      collapseHeaderOnScroll={true}
      description={
        <Layout width={'max'}>
          <Trans t={t}>
            <p>
              Create a conversion pixel for precise conversion tracking, including dynamic amount
              tracking. Share the code and{' '}
              <a href={'https://help.feathr.co/hc/en-us/articles/1500002693961'} target={'_blank'}>
                written guide
              </a>{' '}
              with your developer so that it is placed correctly. Then activate it in by using
              "Advanced" conversion tracking in campaigns. Reuse the same pixel for similar
              categories of conversions like donations, events, registrations, and more.
            </p>
          </Trans>
        </Layout>
      }
      secondaryText={getUsageText()}
      title={t('Conversion Pixels')}
    >
      <Table<Segment>
        collection={Segments}
        columns={ConversionPixelsColumns({ Goals, t })}
        filters={{ is_conversion_segment: true, is_archived__ne: true }}
        noDataText={t('No conversion pixels yet.')}
        SubComponent={ConversionPixelUsage}
      />
      {state.showModal && (
        <ModalV1
          confirmDisabled={!!validationErrors?.length}
          controlled={true}
          onClose={close}
          onConfirm={confirm}
          t={t}
          title={t('Add Conversion Pixel')}
        >
          <Fieldset>
            <Input
              attribute={'name'}
              label={t('Conversion Pixel Name')}
              model={state.newSegment!}
              type={'text'}
            />
          </Fieldset>
        </ModalV1>
      )}
    </Page>
  );
}

export default observer(DataConversionPixelsPage);
