import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import { useStore } from '@feathr/extender/state';

import { skeleton } from '@feathr/components/dist/style/skeleton.css';

interface IProps {
  className?: string;
  id: string;
}

function EmailLabel({ className, id }: Readonly<IProps>): JSX.Element {
  const { EmailVerifications } = useStore();
  const email = EmailVerifications.get(id);

  return <div className={classNames({ [skeleton]: email.isPending }, className)}>{email.name}</div>;
}

export default observer(EmailLabel);
