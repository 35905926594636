import type { AggregatedStat, TAttributionModel } from '@feathr/blackbox';

export enum EEmailStats {
  Clicks = 'clicks',
  ClicksPerPerson = 'clicksPerPerson',
  Complaints = 'complaints',
  Conversions = 'conversions',
  ConversionValue = 'conversionValue',
  Deliveries = 'deliveries',
  DeliveryRate = 'deliveryRate',
  Bounces = 'bounces',
  HardBounces = 'hardBounces',
  NotSent = 'notSent',
  Opens = 'opens',
  OpensPerPerson = 'opensPerPerson',
  Sends = 'sends',
  SoftBounces = 'softBounces',
  Targeted = 'targeted',
  UniqueClicks = 'uniqueClicks',
  UniqueClickThroughRate = 'uniqueClickThroughRate',
  UniqueOpenRate = 'uniqueOpenRate',
  UniqueOpens = 'uniqueOpens',
  Unsubscribes = 'unsubscribes',
  UnsubscribeRate = 'unsubscribeRate',
}

export function getEmailStats(
  stats: AggregatedStat,
  attributionModel?: TAttributionModel,
  prioritizeVerifiedActivity?: boolean,
): Record<EEmailStats, number> {
  const {
    pinpoint_tracked_email_click: humanClicks = 0,
    bot_pinpoint_tracked_email_click: botClicks = 0,
    pinpoint_tracked_email_delivered: deliveries = 0,
    pinpoint_tracked_email_open: humanOpens = 0,
    bot_pinpoint_tracked_email_open: botOpens = 0,
    pinpoint_tracked_email_send: sends = 0,
    pinpoint_tracked_email_suppression: suppressions = 0,
    pinpoint_tracked_email_hardbounce: hardBounces = 0,
    pinpoint_tracked_email_softbounce: softBounces = 0,
    pinpoint_tracked_email_complaint: complaints = 0,
  } = stats.get('flavors');

  // If `prioritizeVerifiedActivity` is false, include bot traffic in the stats
  const clicks = prioritizeVerifiedActivity ? humanClicks : humanClicks + botClicks;
  const opens = prioritizeVerifiedActivity ? humanOpens : humanOpens + botOpens;

  const emailStats = {
    clicks,
    clicksPerPerson: stats.get('clicks_per_person', 0),
    complaints,
    deliveries,
    deliveryRate: stats.get('successful_delivery_rate', 0),
    bounces: hardBounces + softBounces,
    hardBounces,
    opens,
    opensPerPerson: stats.get('opens_per_person', 0),
    sends: sends - suppressions,
    softBounces,
    targeted: stats.get('num_targeted', 0),
    notSent: stats.get('num_not_sent', 0),
    uniqueClicks: stats.get('num_clicks_new', 0),
    uniqueClickThroughRate: stats.get('email_click_through_rate', 0),
    uniqueOpenRate: stats.get('email_open_rate', 0),
    uniqueOpens: stats.get('num_users_new', 0),
    unsubscribes: stats.get('num_persons_unsubscribed', 0),
    unsubscribeRate: stats.get('unsubscribe_rate', 0),
  } as Record<EEmailStats, number>;

  if (attributionModel) {
    emailStats.conversions = stats.get('conversions')[attributionModel].num;
    emailStats.conversionValue = stats.get('conversions')[attributionModel].roi;
  }

  return emailStats;
}
