import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AlertV2 as Alert, EAlertV2Type as AlertType, Fieldset } from '@feathr/components';
import PartnerSelect from '@feathr/extender/components/PartnerSelect/PartnerSelect';
import { useLocalUrl, useStore } from '@feathr/extender/state';

export interface IAddCampaignModalPartnerSelectProps {
  onChange: (newValue?: string) => void;
  eventId?: string;
}

const filters = {
  // Rough query to try to only get partners that have valid logo and website
  logo__icontains: 'http',
  website__icontains: 'http',
  // And have a country code
  ttd__country_code__exists: true,
  ttd__country_code__ne: '',
};

function AddCampaignModalPartnerSelect({
  eventId,
  onChange,
}: IAddCampaignModalPartnerSelectProps): JSX.Element {
  const { Partners } = useStore();
  const localUrl = useLocalUrl();
  const { t } = useTranslation();

  const [value, setValue] = useState<string | undefined>(undefined);

  const partners = Partners.list({
    filters: {
      _parent: eventId,
      ...filters,
    },
    only: ['id'],
    pagination: { page_size: 1 },
  });
  const partnersCount = !partners.isPending ? partners.pagination.count : 0;

  function handleChangeValue(id?: string): void {
    setValue(id);
    onChange(id);
  }

  function handleClearValue(): void {
    setValue(undefined);
    onChange(undefined);
  }

  return (
    <>
      {!partners.isPending && partnersCount === 0 && eventId && (
        <Alert
          description={
            <Trans t={t}>
              To create a monetization campaign, you'll need at least one eligible partner. Partners
              must have a company logo, website and country applied.{' '}
            </Trans>
          }
          title={t('No eligible partners in this project')}
          type={AlertType.danger}
        >
          <Link target={'_blank'} to={localUrl(`/projects/${eventId}/partners`)}>
            Manage partners
          </Link>
        </Alert>
      )}

      <Fieldset>
        <PartnerSelect
          disabled={!eventId || (!partners.isPending && partnersCount === 0)}
          filters={filters}
          helpPlacement={'top'}
          helpText={t(
            'Only partners with a valid logo, website URL and country can have a monetization campaign.',
          )}
          isLoading={partners.isPending}
          isMulti={false}
          label={t('Partner')}
          onChange={handleChangeValue}
          onClear={handleClearValue}
          placeholder={t('Select partner...')}
          projectIds={eventId ? [eventId] : []}
          value={value}
        />
      </Fieldset>
    </>
  );
}

export default observer(AddCampaignModalPartnerSelect);
