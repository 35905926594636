import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Form } from '@feathr/blackbox';
import { Input } from '@feathr/components';
import { flattenError } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

interface IProps {
  form: Form;
}

function validateFormPostSubmitRedirectURL(form: Form): TValidateGrouped {
  return form.validate(['settings.post_submit_redirect_url'], false, 'grouped').errors ?? [];
}

function RedirectURLEditor({ form }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const validationErrors = validateFormPostSubmitRedirectURL(form);
  return (
    <Input
      attribute={['settings', 'post_submit_redirect_url']}
      isClearable={true}
      label={t('Link')}
      model={form}
      name={'post_submit_redirect_url'}
      required={true}
      type={'url'}
      validationError={flattenError(validationErrors['settings.post_submit_redirect_url'])}
    />
  );
}

export default observer(RedirectURLEditor);
