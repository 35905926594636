import { useDisclosure } from '@mantine/hooks';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import type { Form } from '@feathr/blackbox';
import { ArchiveModal, ConfirmModal, ContextMenu, Input, toast } from '@feathr/components';
import { useLocalUrl, useStore } from '@feathr/extender/state';
import { errorMessage, getIconForAction } from '@feathr/hooks';

import { validateName } from './FormContextMenu.utils';

type TFormContextMenuIcons = 'ellipsis' | 'ellipsis-v';
interface IProps {
  form: Form;
  icon?: TFormContextMenuIcons;
}

function FormContextMenu({ form, icon = 'ellipsis' }: IProps): JSX.Element {
  const { t } = useTranslation();
  const { Forms } = useStore();
  const history = useHistory();

  const { eventId, formId } = useParams<{
    eventId: string;
    formId: string;
  }>();
  const localUrl = useLocalUrl();

  const [showArchiveModal, { open: openArchiveModal, close: closeArchiveModal }] =
    useDisclosure(false);
  const [showDuplicateModal, { open: openDuplicateModal, close: closeDuplicateModal }] =
    useDisclosure(false);
  const [showRenameModal, { open: openRenameModal, close: closeRenameModal }] =
    useDisclosure(false);
  const [newName, setNewName] = useState<string | undefined>(undefined);
  const [isNamePatched, setIsNamePatched] = useState<boolean>(false);

  async function handleFormDelete(): Promise<void> {
    try {
      await form.delete();
      closeArchiveModal();
      toast(t('Form "{{- formName}}" has been deleted', { formName: form.name }), {
        type: 'success',
      });
      // Redirect to forms page if formId is present in URL params
      if (formId) {
        const targetPath = `/projects/${eventId}/content/forms`;
        history.push(localUrl(targetPath));
      }
    } catch (error) {
      toast(
        t('Error deleting form: "{{- formName}}"\n{{- error}}', {
          formName: form.name,
          error: errorMessage(error, t),
        }),
        {
          type: 'error',
        },
      );
    }
  }

  async function handleFormDuplicate(): Promise<void> {
    try {
      const duplicatedForm = await form.clone({});
      Forms.refreshApiCache();
      closeDuplicateModal();
      // Redirect to duplicated form page
      history.push(localUrl(duplicatedForm.getItemUrl('/edit')));
      toast(t('Form "{{- formName}}" has been duplicated', { formName: form.name }), {
        type: 'success',
      });
    } catch (error) {
      toast(
        t('Error duplicating form: "{{- formName}}"\n{{- error}}', {
          formName: form.name,
          error: errorMessage(error, t),
        }),
        {
          type: 'error',
        },
      );
    }
  }

  async function handleFormRename(): Promise<void> {
    try {
      const oldName = form.name;
      await form.patch({ name: newName });
      setIsNamePatched(true);
      closeRenameModal();
      toast(
        t('Form "{{- oldName}}" has been renamed to "{{- newName}}"', {
          oldName,
          newName,
        }),
        {
          type: 'success',
        },
      );
    } catch (error) {
      toast(
        t('Error renaming form: "{{- formName}}"\n{{- error}}', {
          formName: form.name,
          error: errorMessage(error, t),
        }),
        {
          type: 'error',
        },
      );
    }
  }

  return (
    <>
      <ContextMenu iconName={icon} key={'context-menu'}>
        <ContextMenu.Item
          name={'duplicate-form'}
          onClick={openDuplicateModal}
          prefix={getIconForAction('duplicate')}
        >
          {t('Duplicate')}
        </ContextMenu.Item>
        <ContextMenu.Item
          name={'rename-form'}
          onClick={openRenameModal}
          prefix={getIconForAction('rename')}
        >
          {t('Rename')}
        </ContextMenu.Item>
        <ContextMenu.Item
          name={'delete-form'}
          onClick={openArchiveModal}
          prefix={getIconForAction('delete')}
          theme={'danger'}
        >
          {t('Delete')}
        </ContextMenu.Item>
      </ContextMenu>
      <ArchiveModal
        cancelButtonText={t('Cancel')}
        confirmationPhrase={t('DELETE')}
        confirmButtonType={'danger'}
        description={t(
          'A deleted form cannot be restored. All data previously captured by this form will persist but it will no longer load in any published location.',
        )}
        method={'delete'}
        onClose={closeArchiveModal}
        onConfirm={handleFormDelete}
        opened={showArchiveModal}
        t={t}
        title={t('Delete form: "{{- formName}}"', { formName: form.name })}
      />
      <ConfirmModal
        confirmButtonText={t('Duplicate')}
        description={t(
          'Are you sure you want to duplicate this form? A copy will be created with "(Clone)" added to its name.',
        )}
        onClick={openDuplicateModal}
        onClose={closeDuplicateModal}
        onConfirm={handleFormDuplicate}
        opened={showDuplicateModal}
        t={t}
        title={t('Duplicate form: "{{- formName}}"', { formName: form.name })}
      />
      <ConfirmModal
        confirmButtonText={t('Rename')}
        confirmDisabled={validateName({ oldName: form.name, newName })?.length > 0}
        onClose={closeRenameModal}
        onConfirm={handleFormRename}
        opened={showRenameModal}
        t={t}
        title={t('Rename form: "{{- formName}}"', { formName: form.name })}
      >
        <Input
          label={t('Form name')}
          onChange={setNewName}
          validationError={
            !isNamePatched ? validateName({ oldName: form.name, newName }) : undefined
          }
          value={newName}
        />
      </ConfirmModal>
    </>
  );
}

export default observer(FormContextMenu);
