import {
  faAnalytics,
  faArrowLeft,
  faArrowRight,
  faArrowRotateLeft,
  faArrowsRotate,
  faArrowsUpDownLeftRight,
  faArrowTrendUp,
  faBoxArchive,
  faBrowser,
  faBullseye,
  faCalendar,
  faCheck,
  faChevronDown,
  faCodeMerge,
  faCopy,
  faEnvelope,
  faExternalLink,
  faEye,
  faFileDownload,
  faFloppyDisk,
  faInboxIn,
  faInfoCircle,
  faInputText,
  faListCheck,
  faLocation,
  faMagnifyingGlass,
  faPencil,
  faPlus,
  faSliders,
  faSortAlphaDown,
  faSortAlphaUpAlt,
  faSortNumericDown,
  faSortNumericUpAlt,
  faSparkles,
  faTrashAlt,
  faUsdCircle,
  faWavePulse,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { faCircleStop } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';
import React from 'react';

import textInputIcon from './textInputIcon';

// Create a mapping so we can utilize consistent actions across the app
const actionIconMap = {
  add: faPlus,
  analytics: faAnalytics,
  archive: faBoxArchive,
  arrowDown: faChevronDown,
  back: faArrowLeft,
  cancel: faXmark,
  customize: faSliders,
  configure: faSliders,
  create: faPlus,
  date: faCalendar,
  deepen: faWavePulse,
  delete: faTrashAlt,
  download: faFileDownload,
  drive: faArrowTrendUp,
  duplicate: faCopy,
  edit: faPencil,
  email: faEnvelope,
  export: faFileDownload,
  info: faInfoCircle,
  location: faLocation,
  magic: faSparkles,
  manage: faListCheck,
  merge: faCodeMerge,
  monetization: faUsdCircle,
  move: faArrowsUpDownLeftRight,
  next: faArrowRight,
  preview: faExternalLink,
  previous: faArrowLeft,
  publish: faCheck,
  reach: faBullseye,
  recalculate: faArrowsRotate,
  remove: faXmark,
  rename: faInputText,
  resend: faInboxIn,
  return: faArrowRotateLeft,
  revoke: faXmark,
  save: faFloppyDisk,
  search: faMagnifyingGlass,
  send: faInboxIn,
  shortLinks: faListCheck,
  stop: faCircleStop,
  template: faBrowser,
  view: faEye,
};

const sortIconMap = {
  stringAsc: faSortAlphaDown,
  stringDesc: faSortAlphaUpAlt,
  numberAsc: faSortNumericDown,
  numberDesc: faSortNumericUpAlt,
};

// TODO: Figure out how to install FontAwesomeKitIcons as a package to use custom icons more easily. https://github.com/Feathr/shrike/issues/5047
const customIconMap = {
  rename: textInputIcon,
};

const defaultIcons = { ...actionIconMap, ...sortIconMap };

type TAction = keyof typeof actionIconMap;
type TSort = keyof typeof sortIconMap;
type TCustom = keyof typeof customIconMap;

/*
 * Return the icon component for the given action. This will make a find and replace
 * when we convert to the Icon component much easier and reduce the number if imports
 * in the file the icon is added.
 */
export function getIconForAction(icon: TAction | TSort | TCustom): JSX.Element {
  if (icon in customIconMap) {
    return customIconMap[icon as TCustom]();
  }
  // Use Object.keys to type check icon as TAction | TSort
  if (Object.keys(defaultIcons).includes(icon)) {
    return <FontAwesomeIcon icon={defaultIcons[icon]} />;
  }
  throw new Error(`Icon ${icon} not found`);
}
