import type { DisplayCreative, TTDCampaign, User } from '@feathr/blackbox';
import { useUser } from '@feathr/extender/state';
import { useFlags } from '@feathr/extender/state';
import { useAccount } from '@feathr/extender/state';
import { useStore } from '@feathr/extender/state';
import { flattenErrors } from '@feathr/hooks';
import type { ListResponse, Model } from '@feathr/rachis';

import type { ICampaignValidationErrors } from '../../CampaignSummary';
import { getGoalSegments } from '../StepGoals';
import { validateStepGoals } from '../StepGoals';
import { validateStepGeneral } from '../WizardGeneralStep';
import type { IUniqueEmailsResult } from './AddWizard.useReconcileUniqueEmails';
import { useReconcileUniqueEmails } from './AddWizard.useReconcileUniqueEmails';
import type { ICampaignDetails } from './AdWizard';
import { validateStepBudget } from './AdWizardBudgetStep';
import { validateStepCreatives } from './AdWizardCreativesStep';
import { getGeoFilters, validateStepFilters } from './AdWizardFiltersStep';
import { validateStepOptimize } from './AdWizardOptimizationStep';
import { validatePartner } from './AdWizardReviewStep/AdWizardReviewStep';
import { getTargetables, validateStepTargets } from './AdWizardTargetsStep';
import { getTargetSegments } from './AdWizardTargetsStep';

interface IUseCampaignValidationProps {
  campaign: TTDCampaign;
}

interface IUseCampaignValidation {
  getValidationErrors: () => ICampaignValidationErrors;
  grandchildModels: Model[];
  childModels: Model[];
  emailValidation: IUniqueEmailsResult;
}

/** Validate all of the fields in the AdWizard. */
function useCampaignValidation({ campaign }: IUseCampaignValidationProps): IUseCampaignValidation {
  const { Creatives, Billables, Events, Goals, Segments, Targetings, Targetables, Partners } =
    useStore();
  const account = useAccount();
  const user = useUser();
  const flags = useFlags();

  const partnerId = campaign.isMonetization ? campaign.get('parent') : undefined;
  const partner = partnerId ? Partners.get(partnerId) : undefined;
  const event = Events.get(campaign.get('event'));
  const billing = event.get('billing');
  const billable =
    !event.isPending && billing && billing.billable_id
      ? Billables.get(billing.billable_id)
      : undefined;

  const targetings = Targetings.list({
    filters: {
      _parent: campaign.id,
      is_archived__ne: true,
    },
    pagination: { page_size: 1000 },
  });

  const emailValidation = useReconcileUniqueEmails({
    targetings: targetings.models,
    campaign,
  });

  const goals = Goals.list({
    filters: {
      _parent: campaign.id,
      is_archived__ne: true,
    },
  });
  // Campaigns only have DisplayCreatives.
  const creatives = Creatives.list({
    filters: {
      _parent: campaign.id,
      is_archived__ne: true,
    },
  }) as ListResponse<DisplayCreative>;

  const grandchildModels: Model[] = [
    ...getTargetSegments(targetings.models, Segments),
    ...getGoalSegments(goals.models, Segments),
    ...getGeoFilters(targetings.models, Targetables),
  ];
  // Converting observables back to vanilla JavaScript.
  const childModels: Model[] = [
    ...getTargetables(targetings.models, Targetables),
    ...targetings.models.slice(),
    ...creatives.models.slice(),
    ...goals.models.slice(),
  ];

  function validate(
    details: ICampaignDetails,
    user?: User,
    noMaxBudget?: boolean,
  ): ICampaignValidationErrors {
    const {
      campaign,
      event,
      account,
      billable,
      creatives,
      goals,
      goalSegments,
      targetables,
      targetings,
      targetingSegments,
      partner,
      emailValidation,
    } = details;

    const generalStepValidation = validateStepGeneral(campaign);
    const targetsStepValidation = flattenErrors(
      validateStepTargets({
        campaign,
        targetings,
        segments: targetingSegments ?? [],
        targetables: targetables ?? [],
        emailValidation,
      }),
    );
    const filtersStepValidation = validateStepFilters(targetings);
    const creativesStepValidation = validateStepCreatives(campaign, creatives);
    const budgetStepValidation = validateStepBudget(
      campaign,
      event,
      billable,
      account,
      user,
      noMaxBudget,
    );
    const optimizationStepValidation = flattenErrors(validateStepOptimize(campaign));
    const goalsStepValidation = validateStepGoals(goals, goalSegments);

    const monetizationStepValidation = [
      ...(budgetStepValidation.monetization_value ?? []),
      ...(partner ? validatePartner(partner) : []),
    ];

    const validationErrors: ICampaignValidationErrors = {
      name: generalStepValidation,
      targets: targetsStepValidation,
      filters: filtersStepValidation,
      creatives: creativesStepValidation,
      duration: [
        ...(budgetStepValidation.date_start ?? []),
        ...(budgetStepValidation.date_end ?? []),
      ],
      optimization: optimizationStepValidation,
      budget: budgetStepValidation.budget,
      monetization: monetizationStepValidation,
      goals: goalsStepValidation.goals,
    };

    return validationErrors;
  }

  function getValidationErrors(): ICampaignValidationErrors {
    const targetingSegments = getTargetSegments(targetings.models, Segments);
    const targetables = getTargetables(targetings.models, Targetables);
    const goalSegments = getGoalSegments(goals.models, Segments);
    return validate(
      {
        account,
        billable,
        campaign,
        creatives: creatives.models,
        event,
        goals: goals.models,
        goalSegments,
        targetables,
        targetings: targetings.models,
        targetingSegments,
        partner,
        emailValidation,
      },
      user,
      flags.noMaxBudget,
    );
  }

  return { getValidationErrors, grandchildModels, childModels, emailValidation };
}

export default useCampaignValidation;
