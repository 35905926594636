/**
 * WCAG compliant handler for the Enter or Space key.
 * @param event - The keyboard event.
 * @param callback - The function to call when the Enter or Space key is pressed.
 */

interface IProps {
  /** The function invoke when the key is pressed. */
  callback: (() => void) | undefined;
  /** The key(s) listen for. Defaults to Enter and Spacebar. */
  keys?: string[];
  /** By default prevents certain keys from causing page scrolling.
   * Set to true to allow scrolling if supported by the given key(s). */
  allowScrolling?: boolean;
}

export function useKeyDownHandler({
  callback,
  keys = ['Enter', ' ', 'Return'],
  allowScrolling = false,
}: IProps): (event: React.KeyboardEvent<HTMLSpanElement>) => void {
  return (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (!callback) {
      return;
    }

    if (keys.includes(event.key)) {
      // Prevent page scrolling.
      if (!allowScrolling) {
        event.preventDefault();
      }
      callback();
    }
  };
}
