import type { Moment } from 'moment-timezone';
import moment from 'moment-timezone';

export enum TimeFormat {
  // Example: 01/01/2020
  shortDate = 'L',
  // Example: Jan 1, 2020
  longDate = 'll',
  // Example: 01/01/2020, 5:00 PM
  shortDateTime = 'L, LT',
  // Example: 01/01/2020, 5:00 PM PST
  shortDateTimeZone = 'L, LT z',
  // Example: Jan 1, 2020 5:00 PM
  longDateTime = 'lll',
  // Example: January 2020
  monthYear = 'MMMM YYYY',
  // Example: 2020-01-01
  isoDate = 'YYYY-MM-DD',
  // Example: 2020-01-01T17:00:00.000
  isoDateTime = 'YYYY-MM-DDTHH:mm:ss.SSS',
  // Example: 2020-01-01 17:00:00
  intlDateTime = 'YYYY-MM-DD HH:mm:ss',
  // Example: January 1, 2020
  pickerDate = 'MMMM D, yyyy',
  // Example: January 1, 2020 5:00 PM
  pickerDateTime = 'MMMM D, yyyy h:mm a',
  // Example: January 1, 2020 5:00 PM PST
  pickerDateTimeZone = 'MMMM D, yyyy h:mm a z',
  // Example: January 1, 2020 5:00 PM PST
  pickerShortDateTimeZone = 'MMM D, yyyy h:mm a z',
  // Dynamic, shows relative time (e.g., "5 minutes ago", "in 2 hours")
  timeFromNow = 'TIME_FROM_NOW',
  // Example: Wednesday, January 1, 2020
  longWeekdayDate = 'dddd, MMMM D, YYYY',
  // Example: Wednesday, January 1, 2020 5:00 PM
  longWeekdayDateTime = 'dddd, MMMM D, YYYY LT',
  // Example: Wed, Jan 1, 2020
  shortWeekdayDate = 'ddd MMM D, YYYY z',
  // Example: Wed, Jan 1, 2020 5:00 PM PST
  shortWeekdayDateTime = 'ddd MMM D, YYYY LT z',
  // 5:00 PM PST
  timeAndTimeZone = 'LT z',
}

export function localTime(
  timestamp: string = moment.utc().format(TimeFormat.isoDateTime),
  format: TimeFormat = TimeFormat.isoDateTime,
  inputFormat?: moment.MomentFormatSpecification,
): string {
  return moment.utc(timestamp, inputFormat).local().format(format);
}

/**
 * Get timezone abbreviation for date
 *
 * @param date
 * @param timezone
 * @returns
 */
export function timezoneAbbr(date?: Date, timezone: string = moment.tz.guess()): string {
  return moment(date).tz(timezone).zoneAbbr();
}

export function momentToDate(toConvert: Moment): Date {
  const asDate = toConvert.toDate();
  return new Date(
    Date.UTC(
      asDate.getUTCFullYear(),
      asDate.getUTCMonth(),
      asDate.getUTCDate(),
      asDate.getUTCHours(),
      asDate.getUTCMinutes(),
    ),
  );
}

export type TTimeUnit = 'days' | 'weeks' | 'months' | 'years';

/**
 * Returns a date string representing a past date based on the current date and the specified unit and amount.
 *
 * @param {Object} params - The parameters for calculating the past date.
 * @param {TUnit} params.unit - The unit of time to subtract (days, weeks, months, or years).
 * @param {number} params.amount - The amount of the specified unit to subtract.
 * @returns {string} - The calculated past date in ISO format (YYYY-MM-DD).
 */
export function getPastDate({ unit, amount }: { unit: TTimeUnit; amount: number }): string {
  const date = new Date();
  if (unit === 'days') {
    date.setDate(date.getDate() - amount);
  } else if (unit === 'weeks') {
    date.setDate(date.getDate() - amount * 7);
  } else if (unit === 'months') {
    date.setMonth(date.getMonth() - amount);
  } else if (unit === 'years') {
    date.setFullYear(date.getFullYear() - amount);
  }
  return date.toISOString().split('T')[0];
}

export default moment;

/*
 *const timestamp = '2021-02-22T16:05:24';
 *
 * // Now in Local time.
 *moment().format();
 *
 * // Now in UTC.
 *moment.utc().format();
 *
 * // Local time to UTC.
 *moment(timestamp).utc().format();
 *
 * // Local time.
 *moment(timestamp).format();
 *moment(timestamp).local().format();
 *
 * // UTC.
 *moment.utc(timestamp).format();
 *
 * // UTC to Local time.
 *moment.utc(timestamp).local().format();
 */
