import classNames from 'classnames';
import type { WithT } from 'i18next';
import { when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import type { RowRenderProps } from 'react-table';

import type { Goals as GoalsCollection, IPersonsListParams, Segment } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { Button, ModalV1, Spinner, TableColumnHeader, Time, Tooltip } from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import { TimeFormat, useToggle } from '@feathr/hooks';

import ConversionPixelCode from './ConversionPixelCode';
import ConversionPixelOptionsCell from './ConversionPixelOptionsCell';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

interface IRow extends RowRenderProps {
  original: Segment;
}

interface IProps extends WithT {
  Goals: GoalsCollection;
}

function ConversionPixelsColumns({ Goals, t }: IProps): Array<IColumn<Segment>> {
  return [
    {
      id: 'name',
      Header: TableColumnHeader({ sortType: 'alpha', title: t('Name') }),
      headerClassName: tableStyles.sort,
      className: classNames(tableStyles.cellCenter),
      Cell({ original }: IRow): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => (
              <div style={{ display: 'flex', alignItems: 'center' }}>{original.name}</div>
            )}
          </Observer>
        );
      },
    },
    {
      id: 'flights',
      Header: TableColumnHeader({
        title: t('Flights'),
        tooltip: t('The number of flights using the pixel for advanced conversion tracking.'),
      }),
      headerClassName: tableStyles.tooltip,
      sortable: false,
      expander: true,
      className: classNames(tableStyles.cellRight),
      width: 120,
      Expander({ isExpanded, original }: IRow): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => (
              <Tooltip title={isExpanded ? t('Click to hide usage') : t('Click to show usage')}>
                <Button type={'link'}>
                  {numeral(
                    Goals.count({
                      filters: {
                        segment: original.id,
                        kind: 'flight',
                        is_archived__ne: true,
                      },
                    }),
                  ).format('0,0')}
                </Button>
              </Tooltip>
            )}
          </Observer>
        );
      },
    },
    {
      id: 'campaigns',
      Header: TableColumnHeader({
        title: t('Campaigns'),
        tooltip: t('The number of campaigns using the pixel for advanced conversion tracking.'),
      }),
      headerClassName: tableStyles.tooltip,
      sortable: false,
      expander: true,
      className: classNames(tableStyles.cellRight),
      width: 145,
      Expander({ isExpanded, original }: IRow): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => (
              <Tooltip title={isExpanded ? t('Click to hide usage') : t('Click to show usage')}>
                <Button type={'link'}>
                  {numeral(
                    Goals.count({
                      filters: {
                        segment: original.id,
                        kind: 'campaign',
                        is_archived__ne: true,
                      },
                    }),
                  ).format('0,0')}
                </Button>
              </Tooltip>
            )}
          </Observer>
        );
      },
    },
    {
      id: 'code',
      Header: TableColumnHeader({
        title: t('Code'),
      }),
      sortable: false,
      className: classNames(tableStyles.cellCenter),
      width: 85,
      Cell({ original }: IRow): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              const [showCodeModal, toggleCodeModal] = useToggle(false);
              return (
                <>
                  <Button onClick={toggleCodeModal}>{t('Show')}</Button>
                  {showCodeModal && (
                    <ModalV1
                      confirmButtonText={t('Ok')}
                      controlled={true}
                      onClose={toggleCodeModal}
                      t={t}
                      title={t('Conversion Pixel')}
                    >
                      <ConversionPixelCode segment={original} />
                    </ModalV1>
                  )}
                </>
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'breadcrumbs',
      Header: TableColumnHeader({
        title: t('Times Seen'),
        tooltip: t('The number of times the conversion pixel has successfully tracked activity.'),
      }),
      headerClassName: tableStyles.tooltip,
      sortable: false,
      className: classNames(tableStyles.cellRight),
      width: 150,
      Cell({ original }: IRow): JSX.Element {
        return (
          <Observer>{(): JSX.Element => <>{numeral(original.crumbs).format('0,0')}</>}</Observer>
        );
      },
    },
    {
      id: 'breadcrumbs',
      Header: TableColumnHeader({
        title: t('Last Seen'),
        tooltip: t(
          'The date and time of the most recent activity tracked by the conversion pixel.',
        ),
      }),
      headerClassName: tableStyles.tooltip,
      sortable: false,
      className: classNames(tableStyles.cellRight),
      width: 140,
      Cell({ original: group }: IRow): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              const [lastSeen, setLastSeen] = useState<string | undefined>();
              const [isPending, setIsPending] = useState(false);
              const { Persons } = useStore();

              const mode = group.get('mode');
              const lookback_mode = group.get('lookback_mode');
              const lookback_value = group.get('lookback_value');
              const predicates = useMemo(() => group.get('predicates'), [group]);

              useEffect(() => {
                async function getLastSeen(): Promise<void> {
                  setIsPending(true);
                  const params = {
                    mode,
                    lookback_mode,
                    lookback_value,
                    predicates,
                    include_date_last_matched_activity: true,
                    pagination: {
                      page_size: 1,
                    },
                  } as Partial<IPersonsListParams>;

                  const persons = Persons.list(params, { url: Persons.url('page') });
                  await when(() => !persons.isPending);

                  const lastSeen = persons.models[0]?.get('date_last_matched_activity');

                  // Validation: Ensure lastSeen is not in the future
                  const now = new Date();
                  const lastSeenDate = lastSeen ? new Date(lastSeen) : null;
                  if (lastSeenDate && lastSeenDate > now) {
                    // If lastSeen is in the future, set it to undefined
                    setLastSeen(undefined);
                  } else {
                    setLastSeen(lastSeen);
                  }
                  setIsPending(false);
                }

                getLastSeen();
              }, [mode, lookback_mode, lookback_value, predicates, Persons]);

              if (isPending) {
                return <Spinner size={16} />;
              }
              if (!lastSeen) {
                return <>{t('Never')}</>;
              }

              return (
                <Tooltip title={<Time format={TimeFormat.longDate} timestamp={lastSeen} />}>
                  <Time format={TimeFormat.timeFromNow} timestamp={lastSeen} />
                </Tooltip>
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'options',
      Header: TableColumnHeader({
        title: t('Options'),
      }),
      className: tableStyles.cellCenter,
      width: 80,
      sortable: false,
      Cell(row: IRow): JSX.Element {
        return <ConversionPixelOptionsCell {...row} />;
      },
    },
  ];
}

export default ConversionPixelsColumns;
