import type { JSX } from 'react';
import React from 'react';

import { Spinner } from '@feathr/components';

export default function LoadingMessage(): JSX.Element {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Spinner />
        <p>Preparing report, thank you for your patience!</p>
      </div>
    </div>
  );
}
