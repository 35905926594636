import classNames from 'classnames';
import type { WithT } from 'i18next';
import type { JSX } from 'react';
import React from 'react';

import type { TPredicateMode } from '@feathr/blackbox';

import * as styles from './Glue.css';

export interface IGlueProps extends WithT {
  /** If `true` returns `null`. If `false` returns the glue element, i.e. `— and —` or `— or —`.  */
  isLastItem?: boolean;
  /** The predicate mode. Defaults to `match_all`. */
  mode?: TPredicateMode;
  className?: string;
  /** Add negative top and bottom margin to reduce gap */
  suppressGap?: boolean;
}

function Glue({
  isLastItem,
  mode = 'match_all',
  className,
  t,
  suppressGap = false,
}: Readonly<IGlueProps>): JSX.Element | null {
  if (isLastItem) {
    return null;
  }
  return (
    <div className={classNames(styles.root, className, { [styles.suppressGap]: suppressGap })}>
      {mode === 'match_all' ? t('and') : t('or')}
    </div>
  );
}

export default Glue;
