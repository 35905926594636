import classNames from 'classnames';
import type { IObservableArray } from 'mobx';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign, Targeting, TPredicateMode } from '@feathr/blackbox';
import { CampaignClass } from '@feathr/blackbox';
import {
  AlertV2 as Alert,
  Button,
  ButtonValid,
  CardV2 as Card,
  ContextMenu,
  EAlertV2Type as EAlertType,
  EmptyState,
  Form,
  Radios,
} from '@feathr/components';
import Glue from '@feathr/extender/components/Glue';
import { useStore } from '@feathr/extender/state';
import { flattenErrors, getIconForAction } from '@feathr/hooks';

import {
  type IUniqueEmailsResult,
  useReconcileUniqueEmails,
} from '../AddWizard.useReconcileUniqueEmails';
import { useText } from './AdWizardTargetsStep.useText';
import {
  getTargetables,
  getTargetSegments,
  validateStepTargets,
} from './AdWizardTargetsStep.utils';
import AffinityTargeting from './AffinityTargeting';
import EmailListTargeting from './EmailListTargeting';
import EmailListUploadButton from './EmailListTargeting/EmailListUploadButton';
import GeoFenceTargeting from './GeoFenceTargeting';
import LookalikeTargeting from './LookalikeTargeting';
import SearchKeywordTargeting from './SearchKeywordTargeting';
import SeedSegmentTargeting from './SeedSegmentTargeting';
import SegmentTargeting from './SegmentTargeting';

import * as styles from '../AdWizard.css';

interface IProps {
  onNext: () => void;
  onPrev: () => void;
  campaign: Campaign;
  targetings: IObservableArray<Targeting>;
  emailValidation: IUniqueEmailsResult;
}

export function getTargetingKind(campaignClass: CampaignClass): string {
  const targetingKinds: Partial<Record<CampaignClass, string>> = {
    [CampaignClass.Segment]: 'segment',
    [CampaignClass.Lookalike]: 'lookalike',
    [CampaignClass.SeedSegment]: 'lookalike',
    [CampaignClass.Affinity]: 'lookalike',
    // Email list upload button specifies the email_list kind.
    // This allows the use of segments on the same page.
    [CampaignClass.EmailList]: 'segment',
    [CampaignClass.EmailListFacebook]: 'segment',
    [CampaignClass.Search]: 'search',
    [CampaignClass.MobileGeoFencing]: 'geofence',
    [CampaignClass.MobileGeoFenceRetargeting]: 'geo_audience',
    [CampaignClass.Facebook]: 'segment',
  };

  return targetingKinds[campaignClass] ?? 'segment';
}

const NextStepButton = observer(
  ({ campaign, targetings, onNext }: Omit<IProps, 'onPrev' | 'emailValidation'>) => {
    const { Segments, Targetables } = useStore();
    const { t } = useTranslation();

    const segments = getTargetSegments(targetings, Segments);
    const targetables = getTargetables(targetings, Targetables);
    const emailValidation = useReconcileUniqueEmails({
      targetings,
      campaign,
    });

    const validationErrors = validateStepTargets({
      campaign,
      targetings,
      segments,
      targetables,
      emailValidation,
    });

    return (
      <ButtonValid errors={flattenErrors(validationErrors)} name={'next_step'} onClick={onNext}>
        {t('Next')}
      </ButtonValid>
    );
  },
);

const NUM_RECOMMENDED_EMAILS_FACEBOOK = 10000;

function AdWizardTargetsStep({
  campaign,
  targetings,
  onNext,
  onPrev,
  emailValidation,
}: Readonly<IProps>): JSX.Element {
  const { Targetings } = useStore();
  const { t } = useTranslation();
  const cls = campaign.get('_cls');
  const { description, helpDeskUrl } = useText({ cls });
  const targetingKind = getTargetingKind(cls);

  const {
    isGeofencingCampaign,
    isSegmentCampaign,
    isEmailMappingCampaign,
    isDisabledCampaignState,
    isFacebook,
    isTTDCampaign,
  } = campaign;
  const isDisabledCampaignClass = isGeofencingCampaign || isEmailMappingCampaign;
  const mode = campaign.get('mode');
  const {
    numUniqueEmails,
    hasEnoughUniqueEmails,
    remainingEmails,
    isLoading: isLoadingNumUniqueEmails,
  } = emailValidation;

  const removeTargeting = useCallback(
    (targeting: Targeting) => {
      if (targeting.isEphemeral) {
        targeting.collection!.remove(targeting.id);
        targetings.remove(targeting);
      } else {
        targeting.set({ is_archived: true });
      }
    },
    [targetings],
  );

  const onClick = useCallback(() => {
    const model = Targetings.create({
      parent: campaign.get('id'),
      kind: targetingKind,
      included: true,
      group: mode === 'match_all' ? Date.now() : 0,
    });

    runInAction(() => {
      targetings.push(model);
    });
  }, [targetings, mode, campaign, cls, Targetings]);

  const searchTargetings = targetings.filter((t) => {
    return t.get('kind') === 'search' && !t.get('is_archived');
  });

  const filteredTargetings = targetings.filter(
    (t) => !t.get('is_archived') && t.get('kind') !== 'geo',
  );

  const isReconcilingEmails = isEmailMappingCampaign && isLoadingNumUniqueEmails;

  const addTargetButton = (
    <Button
      disabled={
        (isDisabledCampaignState && isDisabledCampaignClass) ||
        (campaign.get('_cls') === CampaignClass.Search && searchTargetings.length >= 1) ||
        isReconcilingEmails
      }
      key={'add'}
      name={'add_target'}
      onClick={onClick}
      prefix={getIconForAction('add')}
    >
      {t('Add target')}
    </Button>
  );

  const handleChangeAudienceGroup = useCallback(
    (value?: string) => {
      if (!value) {
        return;
      }
      campaign.set({ mode: value as TPredicateMode });
      // Update all existing targetings when mode changes
      runInAction(() => {
        filteredTargetings.forEach((targeting, index) => {
          if (value === 'match_all') {
            targeting.set({ group: Date.now() + index });
          } else {
            targeting.set({ group: 0 });
          }
        });
      });
    },
    [filteredTargetings],
  );

  const addTargetButtonEmailMapping = (
    <ContextMenu
      buttonText={t('Add target')}
      disabled={isReconcilingEmails}
      position={'bottom-start'}
      suffix={getIconForAction('arrowDown')}
    >
      <EmailListUploadButton
        campaign={campaign}
        onRemove={removeTargeting}
        targetings={targetings}
      />
      {!campaign.isFacebook && (
        <ContextMenu.Item onClick={onClick}>{t('Select a Group')}</ContextMenu.Item>
      )}
    </ContextMenu>
  );

  const targetButton = isEmailMappingCampaign ? addTargetButtonEmailMapping : addTargetButton;
  const isNotReconcilingEmails =
    isEmailMappingCampaign && !isLoadingNumUniqueEmails && numUniqueEmails;

  return (
    <Form
      actions={
        // TODO: Remove this when we horizontal wizard facebook campaigns #4451
        !isTTDCampaign && [
          <Button key={'prev'} name={'previous_step'} onClick={onPrev}>
            {t('Previous')}
          </Button>,
          <NextStepButton
            campaign={campaign}
            key={'next'}
            onNext={onNext}
            targetings={targetings}
          />,
        ]
      }
      className={classNames({ [styles.formRoot]: isTTDCampaign })}
      label={'Edit Campaign: Targets'}
      width={'wide'}
    >
      {/* Targets with groups need extra space to build predicates */}
      <Card width={isSegmentCampaign || isEmailMappingCampaign ? 'wide' : 'narrow'}>
        <Card.Header
          description={
            <>
              {description}
              {helpDeskUrl}
            </>
          }
          title={t('Targets')}
        />
        <Card.Content addVerticalGap={true}>
          {campaign.isAffinityCampaign && (
            <Radios
              layout={'block'}
              onChange={handleChangeAudienceGroup}
              options={[
                {
                  id: 'match_any',
                  name: t('Any of the following'),
                  description: t('Target people who match at least one of the selected audiences'),
                },
                {
                  id: 'match_all',
                  name: t('All of the following'),
                  description: t('Only target people who match all selected audiences'),
                },
              ]}
              value={mode}
            />
          )}

          {isGeofencingCampaign && (
            <Alert
              className={styles.alert}
              title={t('Geofence locations within the EU are no longer available due to GDPR.')}
              type={EAlertType.warning}
            >
              <a
                href={
                  'https://help.feathr.co/hc/en-us/articles/360039271953-Data-Protection-Compliance-with-Feathr'
                }
                target={'_blank'}
              >
                {t('Learn more about GDPR Compliance')}
              </a>
            </Alert>
          )}
          {filteredTargetings.map((targeting: Targeting, index: number): JSX.Element => {
            if (isSegmentCampaign) {
              return (
                <SegmentTargeting
                  campaign={campaign}
                  key={targeting.listId}
                  onRemove={removeTargeting}
                  targeting={targeting}
                  targetings={targetings}
                />
              );
            }
            if (cls === CampaignClass.Lookalike) {
              return (
                <LookalikeTargeting
                  campaign={campaign}
                  key={targeting.listId}
                  onRemove={removeTargeting}
                  targeting={targeting}
                />
              );
            }
            if (cls === CampaignClass.SeedSegment) {
              return (
                <SeedSegmentTargeting
                  campaign={campaign}
                  key={targeting.listId}
                  onRemove={removeTargeting}
                  targeting={targeting}
                />
              );
            }
            if (cls === CampaignClass.Affinity) {
              const isLast = index === filteredTargetings.length - 1;
              return (
                <React.Fragment key={targeting.listId}>
                  <AffinityTargeting
                    campaign={campaign}
                    onRemove={removeTargeting}
                    targeting={targeting}
                  />
                  <Glue isLastItem={isLast} mode={mode} suppressGap={true} t={t} />
                </React.Fragment>
              );
            }
            if (isEmailMappingCampaign) {
              return (
                <React.Fragment key={targeting.listId}>
                  {targeting.get('kind') === 'email_list' && (
                    <EmailListTargeting
                      campaign={campaign}
                      isReconcilingEmails={isLoadingNumUniqueEmails}
                      onRemove={removeTargeting}
                      targeting={targeting}
                    />
                  )}
                  {targeting.get('kind') === 'segment' && (
                    <SegmentTargeting
                      campaign={campaign}
                      isReconcilingEmails={isLoadingNumUniqueEmails}
                      onRemove={removeTargeting}
                      targeting={targeting}
                      targetings={targetings}
                    />
                  )}
                </React.Fragment>
              );
            }
            if (cls === CampaignClass.Search) {
              return (
                <SearchKeywordTargeting
                  campaign={campaign}
                  key={targeting.listId}
                  onRemove={removeTargeting}
                  targeting={targeting}
                />
              );
            }
            if (isGeofencingCampaign) {
              return (
                <GeoFenceTargeting
                  campaign={campaign}
                  key={targeting.listId}
                  onRemove={removeTargeting}
                  targeting={targeting}
                />
              );
            }
            throw new Error('Unexpected campaign class in AdWizardTargetsStep');
          })}

          {isNotReconcilingEmails && !hasEnoughUniqueEmails ? (
            <Alert
              className={styles.alert}
              description={t('Add at least {{num}} more unique email addresses to proceed.', {
                count: remainingEmails,
                num: numeral(remainingEmails).format('0,0'),
              })}
              title={t('{{num}} unique email addresses have been added.', {
                count: numUniqueEmails,
                num: numeral(numUniqueEmails).format('0,0'),
              })}
              type={EAlertType.danger}
            />
          ) : (
            isFacebook &&
            hasEnoughUniqueEmails &&
            numUniqueEmails &&
            numUniqueEmails < NUM_RECOMMENDED_EMAILS_FACEBOOK && (
              <Alert
                // We really need to remove the margin bottom on our alerts
                className={styles.alert}
                description={t(
                  'Feathr recommends {{emailCountRecommendation}} unique email address to ensure good results from the email mapping process.',
                  {
                    count: numUniqueEmails,
                    emailCountRecommendation: numeral(NUM_RECOMMENDED_EMAILS_FACEBOOK).format(
                      '0,0',
                    ),
                  },
                )}
                title={t(
                  'This campaign may not perform well with {{emailCountProvided}} unique email addresses.',
                  {
                    count: numUniqueEmails,
                    emailCountProvided: numeral(numUniqueEmails).format('0,0'),
                  },
                )}
                type={EAlertType.info}
              />
            )
          )}

          {filteredTargetings.length === 0 ? (
            <EmptyState
              description={t('Add a target to get started')}
              label={t('No targets added')}
              theme={'slate'}
            >
              {targetButton}
            </EmptyState>
          ) : (
            targetButton
          )}
        </Card.Content>
      </Card>
    </Form>
  );
}

export default observer(AdWizardTargetsStep);
