import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { IRedirectDomain, RedirectDomain } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import { Spinner } from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import type { ListResponse } from '@feathr/rachis';

import AuthorizedDomainSelect from './AuthorizedDomainSelect';
import NoDomainsAlert from './NoDomainsAlert';
import SingleDomain from './SingleDomain/SingleDomain';

export interface IRedirectDomainOption extends ISelectOption {
  id: IRedirectDomain['id'];
  name: IRedirectDomain['domain'];
}

interface IRedirectDomainSelectProps {
  domains?: ListResponse<RedirectDomain>;
  label?: string;
  onChange: (option: IRedirectDomainOption) => void;
  value: IRedirectDomain['id'] | undefined;
}

function RedirectDomainSelect({
  domains: domainsProp,
  label: labelProp,
  value,
  onChange,
}: Readonly<IRedirectDomainSelectProps>): JSX.Element {
  const { RedirectDomains } = useStore();
  const { t } = useTranslation();

  const domains =
    domainsProp ??
    RedirectDomains.list({ filters: { _is_verified: true, id__ne: '000000000000000000000000' } });

  if (domains.isPending) {
    return <Spinner size={16} />;
  }

  const label = labelProp ?? t('Content serving domain');
  if (domains.models.length === 1) {
    const domain = domains.models[0];
    return <SingleDomain label={label} onChange={onChange} value={domain} />;
  }
  if (domains.models.length > 1) {
    return (
      <AuthorizedDomainSelect label={label} onSelectSingle={onChange} selectedDomainId={value} />
    );
  }
  return <NoDomainsAlert />;
}

export default observer(RedirectDomainSelect);
