import type { ITTDCampaignAttributes } from './ttd_base';
import { TTDCampaign } from './ttd_base';
import type { CampaignClass } from './types';

// Affinity
export type TAffinityCampaign = ITTDCampaignAttributes & {
  readonly _cls: CampaignClass.Affinity;
};

export class AffinityCampaign extends TTDCampaign<TAffinityCampaign> {
  public override getDefaults(): Partial<TAffinityCampaign> {
    return {
      ...super.getDefaults(),
    };
  }
}
