import type { JSX } from 'react';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import type { Template } from '@feathr/blackbox';
import { TemplateClass } from '@feathr/blackbox';
import type { IEditorIframeProps } from '@feathr/components';
import { Editor } from '@feathr/components';
import { useReactionEffect } from '@feathr/hooks';

import { Bee } from '.';
import type { IBeeEditorConfig } from './defaults';
import { getDefaultTemplateEditorConfig } from './defaults';
import type { IBeeConfig } from './types';

export interface IBeeEditorProps extends Omit<IEditorIframeProps, 'element' | 'src'> {
  config: Omit<IBeeEditorConfig, 'mergefields'>;
  isReadOnly?: boolean;
  setBeePlugin?: (newPlugin: Bee) => void;
  template: Template;
}

function BeeEditor({
  children,
  config,
  id,
  isLoading,
  setBeePlugin,
  template,
  isReadOnly = false,
  ...editorProps
}: Readonly<IBeeEditorProps>): JSX.Element {
  const editorRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const { models } = config;
  useReactionEffect(
    // These must be done fetching before we can render the editor.
    () =>
      !models.fonts.isPending &&
      !models.formCustomFields.isPending &&
      !models.mergefieldCustomFields.isPending,
    () => {
      async function initializeBeePlugin(): Promise<void> {
        const mergefields = template.getMergefields(
          models.mergefieldCustomFields.models,
          config.shouldParseMergeTags,
        );
        const type = template.get('_cls');
        const templateParentKind = template.get('parent_kind');
        const templateParent = template.get('parent');

        const beeConfig = getDefaultTemplateEditorConfig({
          ...config,
          // For now, feathr-form is only enabled for LandingPage that are attached to a campaign
          addOns: [
            {
              id: 'feathr-form',
              enabled:
                [TemplateClass.LandingPage].includes(type) &&
                templateParentKind === 'campaign' &&
                !!templateParent,
            },
          ],
          mergefields,
          isReadOnly,
          t,
        });
        const newBeePlugin = new Bee();
        const getTokenAttributes: [string, string, undefined] = [
          TemplateClass.Page,
          TemplateClass.ReferralPage,
          TemplateClass.LandingPage,
        ].includes(type)
          ? [BEEPLUGIN_PAGE_EDITOR_CLIENT_ID, BEEPLUGIN_PAGE_EDITOR_CLIENT_SECRET, undefined]
          : [BEEPLUGIN_EMAIL_EDITOR_CLIENT_ID, BEEPLUGIN_EMAIL_EDITOR_CLIENT_SECRET, undefined];

        await newBeePlugin.getToken(...getTokenAttributes);

        const content = JSON.parse(template.get('content').json);

        const templateJSON = { page: content.page, comments: content.comments };
        // Cast from IFeathrBeeConfig to IBeeConfig to appease bad sdk types
        await newBeePlugin.start(beeConfig as IBeeConfig, templateJSON);
        setBeePlugin?.(newBeePlugin);
      }

      initializeBeePlugin();
    },
  );

  return (
    <Editor
      {...editorProps}
      element={'div'}
      id={'bee-plugin-container'}
      isLoading={template.isPending || isLoading}
      name={'bee-plugin-container'}
      ref={editorRef}
      wrapperId={'editorRoot'}
    >
      {children}
    </Editor>
  );
}

export default BeeEditor;
