import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { TTDCampaign } from '@feathr/blackbox';
import FreqCapSelect from '@feathr/extender/App/EventsPage/CampaignEditPage/AdWizard/AdWizardOptimizationStep/FreqCapSelect/FreqCapSelect';

import RecommendationModal from './RecommendationModal';

interface IProps {
  campaign: TTDCampaign;
  errors?: string[];
}

function IncreaseFrequencyCap({ campaign }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();

  // TODO: make this dynamic
  const recommendedValue = 16;

  const alert = t('Increase frequency cap from {{before}} to {{after}}', {
    before: t('{{count}} impression', {
      count: campaign.initialAttributes.bidding_strategy?.freq_cap,
    }),
    after: t('{{count}} impression', { count: recommendedValue }),
  });

  return (
    <>
      <RecommendationModal.Alert description={alert} />
      {/* TODO: figure out how to handle the dropdown disappearing inside the modal */}
      <FreqCapSelect campaign={campaign} recommendedValue={recommendedValue} />
    </>
  );
}

export default observer(IncreaseFrequencyCap);
