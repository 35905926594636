import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import { Skeleton } from '@feathr/components';
import { useStore } from '@feathr/extender/state';

interface IProps {
  className?: string;
  id: string;
}

function BillingConfigurationLabel({ className, id }: Readonly<IProps>): JSX.Element {
  const { Billables } = useStore();
  const billable = Billables.get(id);

  return (
    <div className={className}>{billable.isPending ? <Skeleton width={100} /> : billable.name}</div>
  );
}

export default observer(BillingConfigurationLabel);
