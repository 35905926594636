import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import type { TStageType } from '@feathr/blackbox';
import type { Partner } from '@feathr/blackbox';
import { CampaignClass } from '@feathr/blackbox';
import { useAccount, useLocalUrl, useStore, useUser } from '@feathr/extender/state';
import { logUserEvents } from '@feathr/hooks';
import type { IObject } from '@feathr/rachis';

import { defaultPartnersColumns } from '../../../EventPartnersPage/EventPartnersPage';
import PartnersActions from '../../../EventPartnersPage/PartnersActions';
import PartnersFilters from '../../../EventPartnersPage/PartnersFilters/PartnersFilters';
import PartnersTable from '../../../EventPartnersPage/PartnersTable';
import ReferralCampaignPage from '../ReferralCampaignPage';
import type { IProps as IReferralParticipationFunnelProps } from './ReferralParticipationFunnel/ReferralParticipationFunnel';
import ReferralParticipationFunnel from './ReferralParticipationFunnel/ReferralParticipationFunnel';

import * as styles from './StatusReferralCampaignPage.css';

// The filters stored to communicate the ui's state
export interface IFilters extends IObject {
  name: string;
}

const defaultInvitePartnersColumns = defaultPartnersColumns.filter(
  (columnId) => columnId !== 'activity',
);

function StatusReferralCampaignPage(): JSX.Element {
  const { t } = useTranslation();
  const account = useAccount();
  const { Campaigns, Partners } = useStore();
  const history = useHistory();
  const localUrl = useLocalUrl();
  const { campaignId, eventId } = useParams<{ eventId: string; campaignId: string }>();
  const [columnIds, setColumnIds] = useState<string[]>(defaultInvitePartnersColumns);
  const [filters, setFilters] = useState<IFilters>({
    name: '',
  });
  const [selected, setSelected] = useState<string[]>([]);
  const [step, setStep] = useState<TStageType | undefined>('new');
  const user = useUser();

  useEffect(() => {
    // Only fire once if page re renders
    logUserEvents({
      'Viewed invites report partners tab': {
        account_id: account.id,
        campaign_id: campaignId,
      },
    });
  }, []);

  useEffect(() => {
    if (!user.isPending) {
      const partnersColumnIds =
        user.getSetting<'invite_partners_column_ids'>('invite_partners_column_ids') ||
        defaultInvitePartnersColumns;
      setColumnIds(partnersColumnIds);
    }
  }, [user.isPending]);

  const updateColumnIds = useCallback(
    (newColumnIds: string[]) => {
      user.setSetting<'invite_partners_column_ids'>('invite_partners_column_ids', newColumnIds);
      setColumnIds(newColumnIds);
      user.patchDirty();
    },
    [user],
  );

  const partners = Partners.list({
    filters: { participation: campaignId },
    pagination: { page_size: 9999 },
    only: ['id', 'campaign_states'],
  });

  const stagesPartners: Record<TStageType, Partner[]> = {
    new: [],
    unread: [],
    received: [],
    visited: [],
    shared: [],
    leads: [],
    completed: [],
  };

  const stages: IReferralParticipationFunnelProps['stages'] = {
    new: 0,
    unread: 0,
    received: 0,
    visited: 0,
    shared: 0,
    leads: 0,
    completed: 0,
  };

  if (!partners.isPending) {
    partners.models.forEach((partner) => {
      const campaignStates = partner.get('campaign_states');

      // Funneled
      if (campaignStates.completed.includes(campaignId)) {
        stagesPartners.completed.push(partner);
        stages.completed++;
        if (campaignStates.leads.includes(campaignId)) {
          stagesPartners.leads.push(partner);
          stages.leads++;
        }
      } else if (campaignStates.leads.includes(campaignId)) {
        stagesPartners.leads.push(partner);
        stages.leads++;
      } else if (campaignStates.shared.includes(campaignId)) {
        stagesPartners.shared.push(partner);
        stages.shared++;
      } else if (campaignStates.visited.includes(campaignId)) {
        stagesPartners.visited.push(partner);
        stages.visited++;
      } else if (campaignStates.received.includes(campaignId)) {
        stagesPartners.received.push(partner);
        stages.received++;
      } else if (campaignStates.unread.includes(campaignId)) {
        stagesPartners.unread.push(partner);
        stages.unread++;
      } else {
        stagesPartners.new.push(partner);
        stages.new++;
      }
    });
  }

  async function createMessage(partnerIds: string[] = []): Promise<void> {
    const message = Campaigns.create({
      _cls: CampaignClass.PinpointPartnerMessage,
      event: eventId,
      invites_campaign: campaignId,
      participation: {
        mode: 'manual',
        partner_ids: partnerIds,
      },
    });
    const response = await Campaigns.add(message, { validate: false });
    history.push(localUrl(response.getItemUrl()));
  }

  async function handleOnSendMessage(stage: TStageType): Promise<void> {
    await createMessage(stagesPartners[stage].map(({ id }) => id));
  }

  const filterElements = [
    ,
    <>
      <PartnersFilters<IFilters>
        columnIds={columnIds}
        filters={filters}
        setFilters={setFilters}
        updateColumnIds={updateColumnIds}
      />
      <div>
        <PartnersActions eventId={eventId} selected={selected} setSelected={setSelected} />
      </div>
    </>,
  ];

  const tableFilters: Record<string, string | number> = {
    participation: campaignId,
    [`campaign_states__${step}`]: campaignId,
  };
  if (filters.name) {
    tableFilters.name = filters.name;
  }

  return (
    <ReferralCampaignPage
      description={
        <Trans t={t}>
          <p>
            This page shows the progress each partner participating in this campaign has made toward
            the goal of generating conversions.
            <br />
            Each partner you add to this campaign will start out "unmessaged" and then move through
            a sequence of stages as they receive messages sent through Feathr, visit their
            dashboard, share their marketing materials and ultimately start to generate conversions.
            Send messages tailored to the partners in each stage to keep them engaged and achieve
            your marketing goals!
          </p>
        </Trans>
      }
      title={t('Partners ({{total}})', { total: partners.models.length.toLocaleString() })}
    >
      <ReferralParticipationFunnel
        className={styles.tabledFunnel}
        isLoading={partners.isPending}
        numParticipants={partners.models.length}
        onSendMessage={handleOnSendMessage}
        setStep={setStep}
        stages={stages}
        step={step}
        type={'primary'}
      />
      {step && (
        <PartnersTable
          campaignId={campaignId}
          className={styles.table}
          columnIds={columnIds}
          filterElements={filterElements}
          filters={tableFilters}
          isLoading={partners.isPending}
          selected={selected}
          setSelected={setSelected}
        />
      )}
    </ReferralCampaignPage>
  );
}

export default observer(StatusReferralCampaignPage);
