import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Person } from '@feathr/blackbox';
import { CardV2 as Card, Time } from '@feathr/components';
import { browserToLabelMap, platformToLabelMap } from '@feathr/extender/styles/person';
import { TimeFormat } from '@feathr/hooks';
import { flavorLabel } from '@feathr/report_components';

interface IProps {
  readonly person: Person;
}

function Metadata({ person }: IProps): JSX.Element {
  const { t } = useTranslation();
  const unknown = t('Unknown');

  function formatDate(date?: string): JSX.Element | string {
    if (date) {
      return <Time format={TimeFormat.longDate} timestamp={date} />;
    }
    return unknown;
  }

  const {
    source,
    placeholder,
    date_created: created,
    date_last_seen: lastSeen,
    date_last_session_start: lastSession,
    date_last_heard_from: lastHeardFrom,
    last_brow: browser,
    last_pform: platform,
    seen_count: seen = 0,
    session_count: sessions = 0,
  } = person.get([
    'source',
    'placeholder',
    'date_created',
    'date_last_seen',
    'date_last_session_start',
    'date_last_heard_from',
    'last_brow',
    'last_pform',
    'seen_count',
    'session_count',
  ]);

  return (
    <Card>
      <Card.Header title={t('Metadata')} />
      <Card.Content action={flavorLabel(t, source) || unknown} title={t('Source')} />
      <Card.Content action={placeholder?.name || unknown} title={t('Placeholder')} />
      <Card.Content action={formatDate(created)} title={t('First seen')} />
      <Card.Content action={formatDate(lastSeen)} title={t('Last seen')} />
      <Card.Content action={formatDate(lastSession)} title={t('Last session')} />
      <Card.Content action={formatDate(lastHeardFrom)} title={t('Last heard from')} />
      <Card.Content action={browserToLabelMap(t, browser)} title={t('Browser')} />
      <Card.Content action={platformToLabelMap(t, platform)} title={t('Platform')} />
      <Card.Content action={seen} title={t('Times seen')} />
      <Card.Content action={sessions} title={t('Sessions')} />
    </Card>
  );
}

export default observer(Metadata);
