import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX, Ref } from 'react';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionMeta, ValueType } from 'react-select';
import type ReactSelect from 'react-select';

import { Flavors } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import { Select } from '@feathr/components';
import { hiddenFlavors } from '@feathr/extender/state';

import * as styles from './FlavorSelect.css';

interface IFlavorSelectProps {
  className?: string;
  isLoading?: boolean;
  onClear: () => void;
  limitOptionsTo?: Array<keyof Flavors>;
  placeholder?: string;
  label?: string;
}
export interface IFlavorSelectMultiProps extends IFlavorSelectProps {
  isMulti: true;
  onChange: (value?: Array<keyof Flavors>) => void;
  value?: Array<keyof Flavors>;
}
export interface IFlavorSelectSingleProps extends IFlavorSelectProps {
  isMulti?: false;
  onChange: (value?: keyof Flavors) => void;
  value?: keyof Flavors;
}

function FlavorSelect(
  {
    className,
    isMulti = false,
    label,
    onChange,
    onClear,
    limitOptionsTo,
    placeholder,
    value,
    ...additionalProps
  }: IFlavorSelectMultiProps | IFlavorSelectSingleProps,
  ref: Ref<ReactSelect<ISelectOption>>,
): JSX.Element {
  const { t } = useTranslation();
  const flavors = Object.keys(Flavors)
    .filter(
      (key) =>
        !hiddenFlavors.includes(key) &&
        (limitOptionsTo ? limitOptionsTo.includes(key as keyof Flavors) : true),
    )
    .map((key) => ({ id: key, name: Flavors[key] }) as ISelectOption);

  function handleChange(
    newValue: ValueType<ISelectOption>,
    action: ActionMeta<ISelectOption>,
  ): void {
    if (['select-option', 'remove-value', 'clear'].includes(action.action)) {
      if (Array.isArray(newValue)) {
        if (newValue.length) {
          (onChange as IFlavorSelectMultiProps['onChange'])(
            (newValue as ISelectOption[]).map((v) => v.id as keyof Flavors),
          );
        } else {
          onClear();
        }
      } else if (newValue) {
        (onChange as IFlavorSelectSingleProps['onChange'])(
          (newValue as ISelectOption).id as keyof Flavors,
        );
      } else {
        onClear();
      }
    }
  }

  const selectedOption = value
    ? Array.isArray(value)
      ? flavors.filter((option) => value.includes(option.id as keyof Flavors))
      : flavors.find((option) => option.id === value)
    : undefined;

  return (
    <Select<ISelectOption>
      className={styles.tag}
      {...additionalProps}
      innerRef={ref}
      isMulti={isMulti}
      label={label}
      name={'flavor-select'}
      onChange={handleChange}
      onClear={onClear}
      options={flavors}
      placeholder={placeholder ?? t('Select activities...')}
      value={selectedOption}
      wrapperClassName={classNames(styles.root, className)}
    />
  );
}

export default observer(forwardRef(FlavorSelect));
