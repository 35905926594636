import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { IObservableArray } from 'mobx';
import { action, observable, reaction, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { Dispatch, JSX, SetStateAction } from 'react';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { IPredicate, TComparison } from '@feathr/blackbox';
import { Segment } from '@feathr/blackbox';
import { Button, Tooltip } from '@feathr/components';
import { generateKeys, objectId, removeElementAtIndex } from '@feathr/hooks';
import type { IBaseAttributes, Model } from '@feathr/rachis';

import Glue from '../../Glue';
import AddRuleButton from './AddRuleButton';
import Predicate from './Predicate';
import type { IAttrOption, TOptionsGroup } from './utils';
import { fieldToKind, getComparisonOptions, toPredicateAttrType } from './utils';

import styles from './Predicates.css';

interface IUniquePredicate extends IPredicate {
  key: string;
}

interface IProps {
  disabled?: boolean;
  excludeIds?: string[];
  onClickRemove?: () => void;
  model: Model<IBaseAttributes>;
  optionsGroup?: TOptionsGroup;
  predicate: IUniquePredicate;
  predicates: IUniquePredicate[];
  setPredicates: Dispatch<SetStateAction<IObservableArray<IUniquePredicate>>>;
}

const MAX_PREDICATE_VALUE_LENGTH = 1000;

function Predicates({
  model,
  disabled = false,
  excludeIds,
  onClickRemove: handleClickRemove,
  optionsGroup = 'segment',
  predicate,
  predicates,
  setPredicates,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const isSegment = model instanceof Segment;

  const [localGroup, setLocalGroup] = useState(
    observable.array(
      generateKeys(Number(predicate.group?.length) > 0 ? predicate.group! : [predicate]),
    ),
  );

  useEffect(
    () =>
      reaction(
        () => JSON.stringify(Object.values(predicate)),
        () => {
          // If Segment, update segment.predicate. If Campaign, update campaign.actions.
          isSegment ? model.setAttributeDirty('predicates') : model.setAttributeDirty('actions');
        },
      ),
    [predicate, model, isSegment],
  );

  const handleAddFilter = action((): void => {
    const attrAgainst = predicate.kind === 'activity' ? 'loc_url' : 'date_last_seen';
    const attrType = predicate.kind === 'activity' ? 'string' : 'date';
    const newRule = {
      key: objectId(),
      kind: predicate.kind,
      attr_against: attrAgainst,
      attr_type: attrType,
      comparison: 'eq',
      group: null,
      value: '',
      unit: 'days',
    };
    let updatedGroup;

    if (Number(predicate.group?.length) > 0) {
      updatedGroup = observable.array([...localGroup, newRule]);
    } else {
      /*
       * Remove the group from the existing predicate to prevent double nesting.
       * Then, set the predicate as the first rule of the new group.
       */
      const { group, ...rule } = predicate;
      updatedGroup = observable.array([rule, newRule]);
    }

    setLocalGroup(updatedGroup);
    const updatedPredicates = [...predicates];
    updatedPredicates.find(({ key }) => key === predicate.key)!.group = updatedGroup;
    model.set({ predicates: updatedPredicates });
    setPredicates(observable.array(updatedPredicates));
  });

  const handleRemoveFilter = action((index: number): void => {
    const updatedGroup = removeElementAtIndex([...localGroup], index);
    setLocalGroup(observable.array(updatedGroup));
    const updatedPredicates = [...predicates];
    updatedPredicates.find(({ key }) => key === predicate.key)!.group = updatedGroup;
    model.set({ predicates: updatedPredicates });
    setPredicates(observable.array(updatedPredicates));

    if (updatedGroup.length === 1) {
      // If the penultimate rule is removed, convert the group predicate to a single predicate.
      const {
        attr_against: attrAgainst,
        attr_type: attrType,
        comparison,
        kind,
        value,
      } = predicate.group![0];
      predicate.attr_against = attrAgainst;
      predicate.attr_type = attrType;
      predicate.kind = kind;
      predicate.comparison = comparison;
      predicate.value = value;
      predicate.group = [];
    }
  });

  const filters = (
    <div className={styles.predicateGroup}>
      {localGroup.map((item, index) => {
        const lastItemIndex = localGroup.length - 1;

        function handleChange(attr: string, value: string | TComparison | IAttrOption): void {
          let updatedGroup: Array<IPredicate & { key: string }> = [];
          runInAction(() => {
            updatedGroup = [...localGroup];
            Object.assign(
              updatedGroup[index],
              // If we are updating the target attribute, we must update the data that depends on it.
              attr === 'attr_against'
                ? {
                    [attr]: value['id'],
                    attr_type: toPredicateAttrType(value['type']),
                    kind: fieldToKind(value['kind']),
                    comparison: getComparisonOptions({ id: value['id'], type: value['type'] }, t)[0]
                      .id,
                    value: toPredicateAttrType(value['type']) === 'boolean' ? false : '',
                  }
                : {
                    [attr]: value,
                  },
            );

            setLocalGroup(observable.array(updatedGroup));
          });

          const updatedPredicates = [...predicates];
          const predicateToUpdate = updatedPredicates.find(({ key }) => key === predicate.key);
          if (predicateToUpdate) {
            /*
             * Since we treat all predicates as a group for rendering purposes, we only want to
             * change it to group if new filters are added to a rule.
             */
            const updateData =
              updatedGroup.length === 1
                ? updatedGroup[0]
                : {
                    group: updatedGroup,
                    attr_against: undefined,
                    attr_type: undefined,
                    kind: updatedGroup[0].kind,
                    comparison: undefined,
                    value: undefined,
                  };
            runInAction(() => {
              Object.assign(predicateToUpdate, updateData);
              model.set({ predicates: updatedPredicates });
              setPredicates(observable.array(updatedPredicates));
            });
          }
        }

        function handleChangeRule(rule: IAttrOption): void {
          handleChange('attr_against', rule);
        }

        function handleChangeComparison(comparison: TComparison): void {
          handleChange('comparison', comparison);
        }

        function handleChangeValue(value: string | IAttrOption): void {
          const valueLength = typeof value === 'string' ? value.length : 0;

          if (valueLength <= MAX_PREDICATE_VALUE_LENGTH) {
            handleChange('value', value);
          }
        }

        function handleRemove(): void {
          handleRemoveFilter(index);
        }

        const props = {
          disabled,
          excludeIds,
          model,
          optionsGroup,
          handleChangeRule,
          handleChangeComparison,
          handleChangeValue,
        };

        return (
          <Fragment key={item.key}>
            <div className={localGroup.length === 1 ? undefined : styles.predicateWrapper}>
              <Predicate predicate={item} {...props} />
              {!disabled && localGroup.length !== 1 && (
                <Tooltip title={t('Remove filter rule')}>
                  <Button
                    className={styles.singleClose}
                    name={'remove_filter_rule'}
                    onClick={handleRemove}
                    type={'icon'}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </Tooltip>
              )}
            </div>
            <Glue isLastItem={index === lastItemIndex} t={t} />
          </Fragment>
        );
      })}
    </div>
  );

  const actions = !disabled && (
    <span className={styles.actions}>
      {isSegment && <AddRuleButton onClick={handleAddFilter} />}
      {handleClickRemove && (
        <Tooltip title={t('Remove filter')}>
          <Button name={'remove_filter'} onClick={handleClickRemove} type={'icon'}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </Tooltip>
      )}
    </span>
  );

  return (
    <div className={classNames(styles.root, { [styles.disabled]: disabled })}>
      {filters}
      {!disabled && actions}
    </div>
  );
}

export default observer(Predicates);
