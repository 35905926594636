import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { IConfig, TConversionStats } from '@feathr/blackbox';
import { CampaignState, reportModuleLabels } from '@feathr/blackbox';
import { moment } from '@feathr/hooks';
import { useStore } from '@feathr/report_components/state';
import type { IReportProps } from '@feathr/report_components/types';

import ActivityCard from '../ActivityCard';
import ActivitySummaryCard from '../ActivitySummaryCard';
import AdPerformanceCard from '../AdPerformanceCard';
import BudgetCard from '../BudgetCard';
import ConversionTableCard from '../ConversionTableCard';
import EmailPerformanceCard from '../EmailPerformanceCard';
import FlightCard from '../FlightCard';
import LoadingMessage from '../LoadingMessage';
import { ReportContext } from '../Report.context';
import { ROICard } from '../ROICard';

function FlightReport({
  config = {
    attributionModel: 'full',
    includeActivitySummary: true,
    includeAdActivity: true,
    includeAdPerformance: true,
    includeConversionsTable: false,
    includeEmailActivity: true,
    includeEmailPerformance: true,
    includeFlightStats: true,
    includePageActivity: true,
    includeROI: false,
    includeSpendPerformance: true,
  } as IConfig,
  end,
  localUrl,
  hasGoals,
  modelId,
  start,
}: Readonly<IReportProps>): JSX.Element {
  const { t } = useTranslation();
  return (
    <Observer>
      {function useAnonymousFunction(): JSX.Element {
        const { AggregatedStats, Campaigns, Targetings, Goals, Flights, Stats } = useStore();
        const { prioritizeVerifiedActivity, setIsLoading } = useContext(ReportContext) ?? {};
        const flight = Flights.get(modelId);
        let flightDailyStats = Stats.newListResponse();
        const campaignsResult = Campaigns.list({
          filters: {
            flight: modelId,
            is_archived__ne: true,
            state__ne: CampaignState.Draft,
          },
        });
        const campaigns = campaignsResult.models;
        const campaignsIsPending = campaignsResult.isPending;
        const flightsResult = Flights.list({
          filters: {
            flight_id: modelId,
            is_archived__ne: true,
          },
        });
        const flights = flightsResult.models;
        const flightsIsPending = flightsResult.isPending;
        if (!flight.isPending) {
          const startMoment = moment.utc(start, moment.ISO_8601);
          const endMoment = moment.utc(end, moment.ISO_8601);
          const pageSize = Math.ceil(moment.duration(endMoment.diff(startMoment)).asDays()) + 1;
          flightDailyStats = Stats.list({
            filters: {
              metadata__date__gte: start,
              metadata__date__lte: end,
              metadata__obj_id: modelId,
            },
            model: 'flight',
            ordering: ['metadata.date'],
            pagination: {
              page_size: pageSize,
            },
          });
        }
        let goals = Goals.newListResponse();
        let targetings = Targetings.newListResponse();
        if (!campaignsIsPending && !flightsIsPending) {
          goals = Goals.list({
            filters: {
              _parent: modelId,
              is_archived__ne: true,
            },
          });
          targetings = Targetings.list({
            filters: {
              _parent__in: campaigns.map((cpn) => cpn.id),
              is_archived__ne: true,
            },
          });
        }

        const aggregatedStats = AggregatedStats.aggregate({
          filters: {
            metadata__date__gte: start,
            metadata__date__lte: end,
            metadata__obj_id: modelId,
          },
          model: 'flight',
          include_bots: !prioritizeVerifiedActivity,
        });

        const pending =
          flight.isPending ||
          flightDailyStats.isPending ||
          campaignsIsPending ||
          goals.isPending ||
          targetings.isPending ||
          flightsIsPending;

        useEffect(() => {
          setIsLoading?.(pending);
        }, [pending, setIsLoading]);

        if (pending) {
          return <LoadingMessage />;
        }

        const budget = campaigns
          .filter((cpn) => {
            const reportStartMoment = moment.utc(start, moment.ISO_8601);
            const reportEndMoment = moment.utc(end, moment.ISO_8601);
            const dateStartMoment = moment.utc(cpn.get('date_start'), moment.ISO_8601);
            return (
              dateStartMoment.isSameOrAfter(reportStartMoment) &&
              dateStartMoment.isBefore(reportEndMoment)
            );
          })
          .reduce((acc, cpn) => {
            const exposureSettings = cpn.get('exposure_settings');
            if (exposureSettings.target_type === 'fixed_budget') {
              acc += exposureSettings.target_value || 0;
            }
            return acc;
          }, 0);

        const showVerifiedActivityToggle = campaigns.some((cpn) => cpn.get('show_bot_toggle'));

        const numConversions = flightDailyStats.models.reduce((acc, stat) => {
          const conversions = stat.get('conversions', {} as TConversionStats);
          return acc + (conversions[config.attributionModel]?.num || 0);
        }, 0);
        return (
          <>
            {config.includeROI && hasGoals && (
              <ROICard
                attributionModel={config.attributionModel}
                campaigns={campaigns.map((cpn) => cpn.toJS())}
                dailyStats={flightDailyStats.models}
                end={end}
                flights={flights.map((f) => f.toJS())}
                goals={goals.models.map((goal) => goal.toJS())}
                start={start}
              />
            )}
            {config.includeActivitySummary && (
              <ActivitySummaryCard
                aggregatedStats={aggregatedStats}
                isEventOrFlight={true}
                reachTooltipText={t(
                  'People who viewed marketing material from campaigns in this flight.',
                )}
                uniqueClicksTooltipText={t(
                  'Unique people who clicked on marketing material from campaigns in this flight.',
                )}
              />
            )}
            {config.includeAdPerformance && (
              <AdPerformanceCard
                aggregatedStats={aggregatedStats}
                clicksTooltip={t('Total clicks across all ad campaigns in this flight.')}
                viewsTooltip={t('Total views across all ad campaigns in this flight.')}
              />
            )}
            {config.includeEmailPerformance && (
              <EmailPerformanceCard
                aggregatedStats={aggregatedStats}
                reportType={'Flight'}
                showVerifiedActivityToggle={showVerifiedActivityToggle}
              />
            )}
            {config.includeSpendPerformance && (
              <BudgetCard
                aggregatedStats={aggregatedStats}
                budget={budget}
                conversions={numConversions}
                spendTooltip={t(
                  'Total spend for all published campaigns that are in this Flight and within the report date window.',
                )}
              />
            )}
            <div style={{ marginBottom: '10px' }}>
              {config.includeAdActivity && (
                <ActivityCard
                  dailyStats={flightDailyStats.models}
                  label={reportModuleLabels.includeAdActivity}
                  lines={[
                    { name: 'Views', flavors: [{ key: 'ad_view' }] },
                    { name: 'Clicks', flavors: [{ key: 'ad_click' }], yAxisId: 'right' },
                  ]}
                />
              )}
              {config.includeEmailActivity && (
                <ActivityCard
                  dailyStats={flightDailyStats.models}
                  label={reportModuleLabels.includeEmailActivity}
                  lines={[
                    {
                      name: 'Views',
                      flavors: [{ key: 'email_view' }, { key: 'pinpoint_tracked_email_open' }],
                    },
                    {
                      name: 'Clicks',
                      flavors: [
                        { key: 'email_link_click' },
                        { key: 'pinpoint_tracked_email_click' },
                      ],
                      yAxisId: 'right',
                    },
                  ]}
                />
              )}
              {config.includePageActivity && (
                <ActivityCard
                  dailyStats={flightDailyStats.models}
                  label={reportModuleLabels.includePageActivity}
                  lines={[
                    { name: 'Views', flavors: [{ key: 'page_view' }] },
                    { name: 'Clicks', flavors: [{ key: 'page_link_click' }], yAxisId: 'right' },
                  ]}
                />
              )}
              {config.includeFlightStats && (
                <FlightCard end={end} flight={flight} localUrl={localUrl} start={start} />
              )}
              {config.includeConversionsTable && hasGoals && (
                <ConversionTableCard
                  attributionModel={config.attributionModel}
                  campaigns={campaigns}
                  end={end}
                  goals={goals.models.map((goal) => goal.toJS())}
                  localUrl={localUrl}
                  model={flight}
                  start={start}
                  targetings={targetings.models}
                />
              )}
            </div>
          </>
        );
      }}
    </Observer>
  );
}

export default FlightReport;
