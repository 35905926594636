import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { Campaign } from '@feathr/blackbox';
import { DripCampaign, PinpointEmailBaseCampaign } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeaderComponent } from '@feathr/components';
import { useStore } from '@feathr/extender/state';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

function getHeader(data: Array<{ _original: Campaign }>): string {
  const emailCampaign = data.some((row) => row._original instanceof PinpointEmailBaseCampaign);
  return emailCampaign ? 'Views/Opens' : 'Views';
}

function getLabel(items: Campaign[]): string {
  // TODO: Possibly optimize this by showing a placeholder if the campaigns are pending.
  const emailCampaign = items.some((row) => row instanceof PinpointEmailBaseCampaign);
  return emailCampaign ? 'Views/Opens' : 'Views';
}

const ViewsColumn: IColumn<Campaign> = {
  id: 'views',
  checkboxLabel(items): JSX.Element {
    return <>{getLabel(items)}</>;
  },
  Header({ data }): JSX.Element {
    return TableColumnHeaderComponent({ title: getHeader(data) });
  },
  sortable: false,
  className: tableStyles.cellRight,
  width: 126,
  Cell({ original }): JSX.Element {
    return (
      <Observer>
        {function useAnonymousFunction(): JSX.Element {
          const { AggregatedStats } = useStore();
          if (original instanceof DripCampaign) {
            const flavors = original.getAggregateFlavors(AggregatedStats);
            const totalViews = flavors.pinpoint_tracked_email_open || 0;

            return <>{totalViews?.toLocaleString()}</>;
          }
          return <>{original.totalViews.toLocaleString()}</>;
        }}
      </Observer>
    );
  },
  Footer({ data }): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          const total = data.reduce((subtotal, currentValue) => {
            return subtotal + currentValue._original.totalViews;
          }, 0);
          return <>{total.toLocaleString()}</>;
        }}
      </Observer>
    );
  },
};

export default ViewsColumn;
