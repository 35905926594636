import type { IObservableArray } from 'mobx';
import numeral from 'numeral';

import type {
  Campaign,
  Segments as SegmentsCollection,
  Targetable,
  Targetables as TargetablesCollection,
  Targeting,
} from '@feathr/blackbox';
import { Segment } from '@feathr/blackbox';
import type { TValidateGrouped } from '@feathr/rachis';

import type { IUniqueEmailsResult } from '../AddWizard.useReconcileUniqueEmails';

interface ITargetingsErrors extends TValidateGrouped {
  all?: string[];
  kind?: string[];
  target_data?: string[];
}
interface ITargetablesErrors extends TValidateGrouped {
  all?: string[];
  radius?: string[];
  units?: string[];
}

interface IErrors extends TValidateGrouped {
  targetings: ITargetingsErrors[];
  targetables: ITargetablesErrors[];
}

interface IValdateStepTargetsProps {
  campaign: Campaign;
  targetings: IObservableArray<Targeting>;
  segments: Segment[];
  targetables: Targetable[];
  emailValidation: IUniqueEmailsResult;
}

export function validateStepTargets({
  campaign,
  targetings,
  segments,
  targetables: targetablesProp,
  emailValidation,
}: IValdateStepTargetsProps): IErrors {
  const targetables = targetablesProp;
  const errorMessages = {
    targetings: [],
    targetables: [{ units: [], radius: [] }],
  } as IErrors;
  const validatedTargetings = targetings.filter(
    (t) => !t.get('is_archived') && t.get('kind') !== 'geo',
  );
  const includedTargetings = validatedTargetings.filter((t) => t.get('included'));
  const { isEmailMappingCampaign, isSegmentCampaign, isFacebook } = campaign;
  if (validatedTargetings.length === 0) {
    errorMessages.targetings.push({ all: ['Please add at least one Target.'] });
  }
  if (includedTargetings.length === 0) {
    errorMessages.targetings.push({
      all: ['At least one Target must be "included" rather than "excluded".'],
    });
  }

  validatedTargetings.forEach((t) => {
    errorMessages.targetings.push(
      t.validate<ITargetingsErrors>(['target_data', 'kind'], false, 'grouped').errors,
    );
  });
  if (isFacebook && isSegmentCampaign) {
    if (
      // TODO: make sure this didn't break anything
      segments
        ?.flatMap((trgt) => (trgt instanceof Segment ? trgt.get('predicates') : []))
        .some((pred) => !!pred && pred.attr_against !== 'loc_url')
    ) {
      errorMessages.targetables.push({
        all: ['Meta campaign targets can only use the "URL" filter.'],
      });
    }
  }

  // Check for duplicate email addresses across csvs and groups for email mapping campaigns
  if (
    campaign.isEmailMappingCampaign &&
    (emailValidation?.isLoading || !emailValidation?.hasEnoughUniqueEmails)
  ) {
    errorMessages.targetings.push({
      all: [
        emailValidation?.isLoading
          ? 'Checking for duplicate email addresses...'
          : `Need ${numeral(emailValidation?.remainingEmails).format('0,0')} more unique email addresses`,
      ],
    });
  }

  if (
    !isSegmentCampaign &&
    !isEmailMappingCampaign &&
    targetables &&
    validatedTargetings.length !== targetables.length
  ) {
    errorMessages.targetables.push({
      // TODO: fix the error message about this with adding segments
      all: ['One or more targets are incomplete.'],
    });
  }
  if (targetables) {
    targetables.forEach((t: Segment | Targetable) => {
      if (t.isErrored) {
        errorMessages.targetables.push({
          all: ['Target is invalid.'],
        });
      } else {
        errorMessages.targetables.push(t.validate<ITargetablesErrors>([], false, 'grouped').errors);
      }
    });
  }
  return errorMessages;
}

export function getTargetSegments(
  targetings: IObservableArray<Targeting>,
  Segments: SegmentsCollection,
): Segment[] {
  return targetings
    .filter((t) => t.get('kind') === 'segment' && !!t.get('target_data') && !t.get('is_archived'))
    .map((t) => Segments.get(t.get('target_data')!));
}

export function getTargetables(
  targetings: IObservableArray<Targeting>,
  Targetables: TargetablesCollection,
): Targetable[] {
  return targetings
    .filter(
      (t) =>
        ['email_list', 'geo_audience', 'geofence', 'lookalike', 'search'].includes(t.get('kind')) &&
        !!t.get('target_data') &&
        !t.get('is_archived'),
    )
    .map((t) => Targetables.get(t.get('target_data')!))
    .filter((t) => t.isDirty || t.isEphemeral || !t.isPending || t.isPending);
}
