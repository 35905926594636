import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import type { AggregatedStat } from '@feathr/blackbox';
import { reportModuleLabels } from '@feathr/blackbox';
import { StatBox, StatsCardV1, VerifiedActivityToggle } from '@feathr/components';

import { ReportContext } from '../Report.context';

// Store scroll position outside component to persist across remounts
let savedScrollPosition = 0;

interface ICardProps {
  aggregatedStats: AggregatedStat;
  reportType: 'Flight' | 'Project';
  showVerifiedActivityToggle?: boolean;
}

function EmailPerformanceCard({
  aggregatedStats,
  reportType,
  showVerifiedActivityToggle = false,
}: ICardProps): JSX.Element {
  // Track whether we've attempted to restore scroll position for current toggle
  const hasAttemptedScroll = useRef(false);
  const { t } = useTranslation();
  const { prioritizeVerifiedActivity, setPrioritizeVerifiedActivity, isLoading } =
    useContext(ReportContext) ?? {};

  const flavors = aggregatedStats.get('flavors', {});

  const humanClicks = flavors.pinpoint_tracked_email_click || 0;
  const botClicks = flavors.bot_pinpoint_tracked_email_click || 0;
  const humanOpens = flavors.pinpoint_tracked_email_open || 0;
  const botOpens = flavors.bot_pinpoint_tracked_email_open || 0;
  const deliveries = flavors.pinpoint_tracked_email_delivered || 0;
  const deliveryRate = aggregatedStats.get('successful_delivery_rate') || 0;
  const opensPerPerson = aggregatedStats.get('opens_per_person') || 0;
  const sends = flavors.pinpoint_tracked_email_send || 0;
  const suppressions = flavors.pinpoint_tracked_email_suppression || 0;

  // Include bot traffic in stats if not prioritizing verified activity
  const clicks = prioritizeVerifiedActivity ? humanClicks : humanClicks + botClicks;
  const opens = prioritizeVerifiedActivity ? humanOpens : humanOpens + botOpens;

  function formatNumber(value: number): string {
    return numeral(value).format('0,0');
  }

  function formatPercent(value: number, suffix?: string): string {
    return numeral(value).format('0.00%') + (suffix ? ' ' + suffix : '');
  }

  function handleVerifiedActivityChange(value?: boolean): void {
    const scrollElement = document.getElementById('legacyScrollElement');
    if (scrollElement) {
      savedScrollPosition = scrollElement.scrollTop;
      hasAttemptedScroll.current = false;
    }
    setPrioritizeVerifiedActivity?.(value);
  }

  // TODO: Turn this into a reusable hook to trigger scroll position restoration
  useEffect(() => {
    // Restore scroll position after loading completes and if we haven't already attempted
    if (!isLoading && savedScrollPosition > 0 && !hasAttemptedScroll.current) {
      const scrollElement = document.getElementById('legacyScrollElement');
      if (scrollElement) {
        scrollElement.scrollTop = savedScrollPosition;
      }
      // Reset flags after restoration attempt
      hasAttemptedScroll.current = true;
      savedScrollPosition = 0;
    }
  }, [isLoading]);

  return (
    <StatsCardV1
      actions={
        showVerifiedActivityToggle
          ? [
              <VerifiedActivityToggle
                key={'human-traffic-toggle'}
                onChange={handleVerifiedActivityChange}
                value={prioritizeVerifiedActivity}
              />,
            ]
          : []
      }
      title={reportModuleLabels.includeEmailPerformance}
    >
      <StatBox
        isLoading={isLoading}
        label={t('Sends')}
        primary={formatNumber(sends - suppressions)}
        tooltip={t('Total sends across all email campaigns in this {{type}}.', {
          type: reportType,
        })}
      />
      <StatBox
        isLoading={isLoading}
        label={t('Deliveries')}
        primary={formatNumber(deliveries)}
        secondary={formatPercent(deliveryRate)}
        secondaryLabel={t('Delivery rate')}
        tooltip={t('Total successful deliveries across all email campaigns in this {{type}}.', {
          type: reportType,
        })}
      />
      <StatBox
        isLoading={isLoading}
        label={t('Opens')}
        primary={formatNumber(opens)}
        secondary={formatNumber(opensPerPerson)}
        secondaryLabel={t('Opens per person')}
        tooltip={t('Total opens across all email campaigns in this {{type}}.', {
          type: reportType,
        })}
      />
      <StatBox
        isLoading={isLoading}
        label={t('Total clicks')}
        primary={formatNumber(clicks)}
        tooltip={t('Total clicks across all email campaigns in this  {{type}}.', {
          type: reportType,
        })}
      />
    </StatsCardV1>
  );
}

export default observer(EmailPerformanceCard);
