import type { JSX } from 'react';
import React from 'react';

export default function textInputIcon(): JSX.Element {
  return (
    <svg
      fill={'none'}
      height={'16'}
      viewBox={'0 0 18 16'}
      width={'18'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <g id={'TextInputIcon'}>
        <g id={'Vector'}>
          <path
            d={
              'M7.00313 0.693734C6.97188 1.10623 7.28125 1.46873 7.69375 1.49998L8.175 1.53748C9.34375 1.62498 10.25 2.60311 10.25 3.77811V7.24998H9C8.58438 7.24998 8.25 7.58436 8.25 7.99998C8.25 8.41561 8.58438 8.74998 9 8.74998H10.25V12.2219C10.25 13.3969 9.34375 14.375 8.17188 14.4656L7.69063 14.5031C7.27813 14.5344 6.97188 14.8937 7.00313 15.3062C7.03438 15.7187 7.39687 16.0281 7.80937 15.9969L8.29062 15.9594C9.40625 15.875 10.375 15.3062 11.0031 14.4719C11.6313 15.3062 12.6 15.875 13.7156 15.9594L14.1969 15.9969C14.6094 16.0281 14.9719 15.7187 15.0031 15.3062C15.0344 14.8937 14.725 14.5312 14.3125 14.5L13.8313 14.4625C12.6562 14.375 11.75 13.3969 11.75 12.2219V8.74998H13C13.4156 8.74998 13.75 8.41561 13.75 7.99998C13.75 7.58436 13.4156 7.24998 13 7.24998H11.75V3.77811C11.75 2.60311 12.6562 1.62498 13.8281 1.53436L14.3094 1.49686C14.7219 1.46561 15.0312 1.10311 15 0.690609C14.9688 0.278109 14.6063 -0.0281408 14.1938 0.00310918L13.7125 0.0406092C12.5969 0.124984 11.6281 0.693734 11 1.52811C10.3719 0.693734 9.40313 0.124984 8.2875 0.0406092L7.80625 0.00310918C7.39375 -0.0281408 7.03438 0.278109 7.00313 0.693734Z'
            }
            fill={'#475569'}
          />
          <path
            d={
              'M2 4.99998C1.725 4.99998 1.5 5.22498 1.5 5.49998V10.5C1.5 10.775 1.725 11 2 11H16C16.275 11 16.5 10.775 16.5 10.5V5.49998C16.5 5.22498 16.275 4.99998 16 4.99998H2ZM0 5.49998C0 4.39686 0.896875 3.49998 2 3.49998H16C17.1031 3.49998 18 4.39686 18 5.49998V10.5C18 11.6031 17.1031 12.5 16 12.5H2C0.896875 12.5 0 11.6031 0 10.5V5.49998Z'
            }
            fill={'#475569'}
          />
        </g>
      </g>
    </svg>
  );
}
