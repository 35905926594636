import type { IObservableArray } from 'mobx';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { IStats } from '@feathr/blackbox';
import type { Campaign, Goal as GoalModel } from '@feathr/blackbox';
import { CampaignState, MAX_GOALS } from '@feathr/blackbox';
import { AlertV2, ButtonValid, EAlertV2Type, EmptyState, Radios } from '@feathr/components';
import Goal from '@feathr/extender/components/Goal';
import { useLocalUrl, useStore } from '@feathr/extender/state';
import { flattenErrors, getIconForAction } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import AdvancedConversionTrackingSettings from './AdvancedConversionTrackingSettings';
import { getGoalSegments, validateStepGoals } from './StepGoals';

import * as styles from './AddGoals.css';

interface IButtonProps {
  goals: IObservableArray<GoalModel>;
  onNext?: () => void;
}

interface IProps extends IButtonProps {
  onPrev?: () => void;
  campaign: Campaign;
  disabled?: boolean;
}

export interface IStepGoalsErrors extends TValidateGrouped {
  goals?: string[];
}

function AddGoals({ campaign, disabled = false, goals }: IProps): JSX.Element {
  const { t } = useTranslation();
  const { Goals, Segments } = useStore();
  const localUrl = useLocalUrl();

  const segments = getGoalSegments(goals, Segments);
  const validationErrors = validateStepGoals(goals, segments);

  function addGoal(): void {
    const model = Goals.create({
      parent: campaign.get('id'),
      conv_value: undefined,
      segment: undefined,
      kind: 'campaign',
    });
    runInAction(() => {
      goals.push(model);
    });
  }

  function removeGoal(goal: GoalModel): void {
    if (!goal.isEphemeral) {
      goal.set({ is_archived: true });
    } else {
      goals.remove(goal);
      Goals.remove(goal.id);
    }
  }

  function changeTrackingMode(mode: 'advanced' | 'auto'): void {
    goals.forEach(removeGoal);
    if (mode === 'advanced') {
      const goal = Goals.create({
        parent: campaign.get('id'),
        conv_value: 0,
        segment: undefined,
        conv_type: 'custom',
      });
      runInAction(() => {
        goals.push(goal);
      });
    }
    campaign.set({ conversion_tracking_mode: mode });
  }

  const conversionTrackingMode = campaign.get('conversion_tracking_mode');

  const stats: IStats = campaign.get('total_stats');
  const advancedGoal =
    conversionTrackingMode === 'advanced'
      ? goals.find((goal) => !goal.get('is_archived'))
      : undefined;

  const hasMaxGoals = goals.length >= MAX_GOALS;

  function onModeChange(newValue?: string): void {
    if (newValue === 'advanced' || newValue === 'auto') {
      changeTrackingMode(newValue);
    }
  }

  const addGoalsButton = (
    <ButtonValid
      disabled={hasMaxGoals ? true : undefined}
      errors={flattenErrors(validationErrors)}
      id={'addGoal'}
      key={'add'}
      name={'add_goal'}
      onClick={addGoal}
      prefix={getIconForAction('add')}
      tooltip={hasMaxGoals ? [t('Campaigns are limited to 5 goals.')] : undefined}
      type={'secondary'}
    >
      {goals.length >= 1 ? t('Add additional goal') : t('Add goal')}
    </ButtonValid>
  );

  return (
    <>
      {/* Display a warning that conversions may need to be rerun if a goal is added to published campaign */}
      {/* TODO: ask about this alert when it comes to the recommendation modal */}
      {campaign.get('state') === CampaignState.Published && (
        <AlertV2
          className={styles.alert}
          description={t(
            'To recalculate conversions according to new goal settings, select "Recalculate conversions" from the conversions table on this campaign\'s report.',
          )}
          title={t('Editing the goals of this campaign will make its conversions inaccurate.')}
          type={EAlertV2Type.warning}
        >
          <a href={localUrl(campaign.getItemUrl())} target={'_blank'}>
            {t('View campaign report')}
          </a>
        </AlertV2>
      )}
      <Radios
        dataName={'conversion_tracking_mode'}
        disabled={disabled}
        label={t('Conversion tracking mode')}
        layout={'block'}
        onChange={onModeChange}
        options={[
          { id: 'auto', name: t('Goal') },
          { id: 'advanced', name: t('Advanced') },
        ]}
        value={conversionTrackingMode}
      />
      {conversionTrackingMode === 'advanced' && advancedGoal && (
        <AdvancedConversionTrackingSettings disabled={disabled} goal={advancedGoal} />
      )}
      {conversionTrackingMode === 'auto' && (
        <>
          {goals.filter((g) => !g.get('is_archived')).length > 0 ? (
            <>
              {goals
                .filter((g) => !g.get('is_archived'))
                .map((goal, index) => {
                  return (
                    <Goal
                      disabled={disabled}
                      goal={goal}
                      goals={goals}
                      key={goal.id || index}
                      onRemove={removeGoal}
                      showWarning={!!stats.conversions && !!stats.conversions.full?.num}
                    />
                  );
                })}
              {conversionTrackingMode === 'auto' && <div>{addGoalsButton}</div>}
            </>
          ) : (
            <EmptyState
              description={t('This campaign will not track conversions.')}
              label={t('No goals added')}
              theme={'slate'}
            >
              {addGoalsButton}
            </EmptyState>
          )}
        </>
      )}
    </>
  );
}

export default observer(AddGoals);
