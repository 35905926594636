import { useSortable } from '@dnd-kit/sortable';
import { faStar as faStarLight } from '@fortawesome/pro-light-svg-icons';
import { faGripDotsVertical } from '@fortawesome/pro-regular-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX, MouseEvent } from 'react';
import React, { useEffect, useRef, useState } from 'react';

import type { IRowItem } from '@feathr/blackbox';
import { Button, Icon } from '@feathr/components';
import { useTextwidth } from '@feathr/hooks';

import { useFormEditor } from '../../FormEditorContext';
import { typeToIcon } from './Field.utils';

import * as styles from './Field.css';

interface IProps {
  isDragOverlay?: boolean;
  field: IRowItem;
}

// Component for rendering fields in the configuration panel.
function Field({ field, isDragOverlay }: Readonly<IProps>): JSX.Element {
  const { id, type, label, pinned } = field;
  const measureTextwidth = useTextwidth();
  const { updatePinnedFields } = useFormEditor();
  const [showTooltip, setShowTooltip] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [hovering, setHovering] = useState(false);

  const {
    attributes,
    listeners: defaultListeners,
    setNodeRef,
    isDragging,
  } = useSortable({
    id,
    data: { containerId: 'configuration-panel' },
  });

  const listeners = {
    ...defaultListeners,
    onMouseDown: (e: MouseEvent): void => {
      // Prevent right click from firing the same event as left click.
      if (e.button === 0) {
        // Not ideal, but <Icon> does not consistently recognize <svg> as the target of MouseEvent.
        // Sometimes it returns the <svg>'s child <path> element.
        if (
          (e.target as HTMLElement)?.id === 'pin-field' ||
          ((e.target as HTMLElement)?.parentNode as HTMLElement)?.id === 'pin-field'
        ) {
          handleClickPinField();
          return;
        }

        defaultListeners?.onMouseDown?.(e);
      }
    },
    // Required to control favorite icon state.
    onMouseOver: (): void => {
      setHovering(true);
    },
    onMouseLeave: (): void => {
      setHovering(false);
    },
  };

  function handleClickPinField(): void {
    updatePinnedFields(id, !pinned);
  }

  useEffect(() => {
    if (contentRef.current) {
      const maxContentWidth = contentRef.current.offsetWidth;
      const fontSize = 14;
      const textWidth = measureTextwidth(label, 'Inter', fontSize);
      setShowTooltip(isDragOverlay ? false : textWidth > maxContentWidth);
    }
  }, [contentRef, label]);

  const favButton = (
    <Icon
      className={classNames(styles.favIcon, { [styles.pinned]: pinned })}
      icon={pinned ? faStarSolid : faStarLight}
      id={`pin-field`}
    />
  );

  const element = (
    <Button
      className={styles.container}
      prefix={<Icon className={styles.grip} icon={faGripDotsVertical} />}
      tooltip={showTooltip ? label : undefined}
    >
      <div className={styles.content} ref={contentRef}>
        <Icon className={styles.icon} icon={typeToIcon(type)} size={6} />
        <span className={styles.label}>{label}</span>
      </div>
      {hovering ? favButton : null}
    </Button>
  );

  return (
    <div
      className={classNames(styles.root, { [styles.placeholder]: isDragging })}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
    >
      {element}
    </div>
  );
}

export default observer(Field);
