import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign } from '@feathr/blackbox';
import { EReportFlavorsRaw } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import { Select } from '@feathr/components';

import { flavorToInteractionMap } from './CampaignEngagementCard';

import * as styles from './CampaignInteractionSelect.css';

interface IProps {
  flavor?: string;
  campaign: Campaign;
  onSelectSingle: ((value: { id: string; name: string }) => void) | undefined;
}

function CampaignInteractionSelect({ campaign, flavor, onSelectSingle }: IProps): JSX.Element {
  const { t } = useTranslation();

  const adFlavors = [EReportFlavorsRaw.ad_view, EReportFlavorsRaw.ad_click];
  const emailFlavors = [
    EReportFlavorsRaw.pinpoint_tracked_email_send,
    EReportFlavorsRaw.pinpoint_tracked_email_hardbounce,
    EReportFlavorsRaw.pinpoint_tracked_email_softbounce,
    EReportFlavorsRaw.pinpoint_tracked_email_delivered,
    EReportFlavorsRaw.pinpoint_tracked_email_open,
    EReportFlavorsRaw.pinpoint_tracked_email_click,
    EReportFlavorsRaw.pinpoint_tracked_email_complaint,
    EReportFlavorsRaw.email_not_sent,
    EReportFlavorsRaw.email_preferences_update,
  ];

  function mapFlavorToOption(flavor: EReportFlavorsRaw): ISelectOption {
    return { id: flavor, name: flavorToInteractionMap(t)[flavor] };
  }

  const options = campaign.isAdCampaign
    ? adFlavors.map(mapFlavorToOption)
    : emailFlavors.map(mapFlavorToOption);

  return (
    <Select
      label={t('Interaction')}
      name={'interaction'}
      onSelectSingle={onSelectSingle}
      options={options}
      /**
       * The first flavor in "options" is the default flavor
       * we should load in the dropdown.
       */
      value={flavor ? options.find(({ id }) => id === flavor) : options[0]}
      wrapperClassName={styles.select}
    />
  );
}

export default observer(CampaignInteractionSelect);
