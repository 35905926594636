import type { TFunction } from 'i18next';
import capitalize from 'lodash.capitalize';

import { EBrowsers, EPlatforms } from '@feathr/blackbox';

function getLabel(t: TFunction, map: Record<string, string>, key?: string): string {
  return !key ? t('Unknown') : map[key] || capitalize(key);
}

export const browserToLabelMap = (
  t: TFunction,
  browser?: keyof typeof EBrowsers | string,
): string => {
  const map = {
    [EBrowsers.chrome]: t('Chrome'),
    [EBrowsers.firefox]: t('Firefox'),
    [EBrowsers.safari]: t('Safari'),
    [EBrowsers.edge]: t('Edge'),
    [EBrowsers.msie]: t('Internet Explorer'),
    [EBrowsers.opera]: t('Opera'),
    [EBrowsers.yahoo]: t('Yahoo'),
  };

  return getLabel(t, map, browser);
};

export const platformToLabelMap = (
  t: TFunction,
  platform?: keyof typeof EPlatforms | string,
): string => {
  const map = {
    [EPlatforms.windows]: t('Windows'),
    [EPlatforms.macos]: t('macOS'),
    [EPlatforms.iphone]: t('iPhone'),
    [EPlatforms.ipad]: t('iPad'),
    [EPlatforms.android]: t('Android'),
    [EPlatforms.chromeos]: t('Chrome OS'),
  };

  return getLabel(t, map, platform);
};
