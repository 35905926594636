import { faBullhorn } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { CampaignClass } from '@feathr/blackbox';
import { CampaignLabelMap } from '@feathr/blackbox';
import { Chip } from '@feathr/components';
import { getIconForAction } from '@feathr/hooks';

import { campaignColorMap, campaignIconMap } from '../../styles/campaign';

interface IProps {
  className?: string;
  cls: CampaignClass;
  name?: string;
  isMonetization?: boolean;
}

function CampaignTypeChip({
  className,
  cls,
  name,
  isMonetization = false,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  return (
    <Chip
      className={className}
      name={name ?? 'campaign-type-chip'}
      prefix={
        <FontAwesomeIcon
          data-testid={'campaign-type-icon'}
          icon={campaignIconMap.get(cls) ?? faBullhorn}
        />
      }
      suffix={isMonetization && getIconForAction('monetization')}
      theme={campaignColorMap.get(cls)}
    >
      {CampaignLabelMap.get(cls) ?? t('Campaign')}
    </Chip>
  );
}

export default CampaignTypeChip;
