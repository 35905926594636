import { createContext, useContext } from 'react';

import type { CustomField } from '@feathr/blackbox';

interface IFormEditorContext {
  onCreateCustomField: (field: CustomField) => void;
  updatePinnedFields: (fieldId: string, pin: boolean) => void;
}

export const FormEditorContext = createContext<IFormEditorContext | undefined>(undefined);

export function useFormEditor(): IFormEditorContext {
  const context = useContext(FormEditorContext);
  if (!context) {
    throw new Error('useFormEditor must be used within a FormEditorProvider');
  }
  return context;
}
