import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { Form } from '@feathr/blackbox';

import ActivitySummary from './ActivitySummary';
import Engagement from './Engagement';
import Information from './Information';

interface IProps {
  form: Form;
}

function FormReport({ form }: Readonly<IProps>): JSX.Element {
  return (
    <div>
      <Information form={form} />
      <ActivitySummary form={form} />
      <Engagement form={form} />
    </div>
  );
}

export default observer(FormReport);
