import { reaction } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ICampaignPartnerStats, TAttributionModel } from '@feathr/blackbox';
import { reportModuleLabels } from '@feathr/blackbox';
import type { ISorted } from '@feathr/components';
import { Alert, AlertType, Button, Spinner, Table, TableStatsCard } from '@feathr/components';
import { useToggle } from '@feathr/hooks';
import { useStore } from '@feathr/report_components/state';

import ExportModal from '../ExportModal';
import PartnersStatsColumns from './PartnersStatsColumns';

interface IProps {
  start: string;
  end: string;
  campaignId: string;
  attributionModel: TAttributionModel;
  localUrl: ((url: string) => string) | undefined;
}

function PartnersStatsTableCard({
  start,
  end,
  campaignId,
  attributionModel,
  localUrl,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { Campaigns } = useStore();
  const [showExportModal, toggleExportModal] = useToggle(false);
  const [isPartial, setIsPartial] = useState(false);

  const state = useLocalObservable(() => ({
    items: [] as ICampaignPartnerStats[],
    setItems: (items): void => {
      state.items = items;
    },
    sort: [{ id: 'conversions', desc: true }] as ISorted[],
    setSort: (sort): void => {
      state.sort = sort;
    },
    pagination: { page: 0, page_size: 20 },
    setPage: (page): void => {
      state.pagination.page = page;
    },
    setPageSize: (pageSize): void => {
      state.pagination.page_size = pageSize;
    },
  }));

  async function getItems(): Promise<void> {
    const response = await Campaigns.getPartnerStats(
      campaignId,
      start,
      end,
      attributionModel,
      state.sort,
      state.pagination,
    );
    state.setItems(response.data);
    if (response.meta?.is_partial) {
      setIsPartial(true);
    }
  }

  useEffect(() => {
    getItems().catch((error) => {
      throw error;
    });
  }, []);

  useEffect(() =>
    reaction(
      () => [state.pagination.page, state.pagination.page_size, state.sort],
      () => {
        getItems();
      },
    ),
  );

  if (!state.items) {
    return <Spinner />;
  }

  async function handleExportPartnerStats(email): Promise<void> {
    Campaigns.exportPartnerStats(
      campaignId,
      start,
      end,
      attributionModel,
      state.sort,
      state.pagination,
      email,
    );
  }

  const pagination = state.pagination;

  const actions = [
    <Button key={'export'} onClick={toggleExportModal}>
      {t('Export to CSV')}
    </Button>,
  ];

  return (
    <>
      <TableStatsCard actions={actions} title={reportModuleLabels.includePartnersStatsTable}>
        {isPartial && (
          <Alert type={AlertType.warning}>
            {t('Your campaign has more partners than can be shown in this table.')}
          </Alert>
        )}
        <Table<ICampaignPartnerStats>
          columns={PartnersStatsColumns({ localUrl, pagination })}
          idKey={'p_id'}
          initialSort={[{ id: 'conversions', desc: true }]}
          items={state.items}
          onPageChange={state.setPage}
          onPageSizeChange={state.setPageSize}
          onSortChange={state.setSort}
          page={state.pagination.page}
          pageSize={state.pagination.page_size}
          sort={state.sort}
        />
      </TableStatsCard>
      {showExportModal && (
        <ExportModal
          onClose={toggleExportModal}
          onConfirm={handleExportPartnerStats}
          title={t('Export Partner Stats')}
        />
      )}
    </>
  );
}

export default observer(PartnersStatsTableCard);
