import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import { useRole, useStore } from '@feathr/extender/state';

import { skeleton } from '@feathr/components/dist/style/skeleton.css';

interface IUserRoleLabelProps {
  readonly className?: string;
  readonly id: string;
}

function UserRoleLabel({ className, id }: Readonly<IUserRoleLabelProps>): JSX.Element {
  const { UserRoles } = useStore();
  const userRole = UserRoles.get(id);
  const { hasPermissionsLicenseOrFlag } = useRole();
  const roleName = userRole.name;

  let content = roleName;

  // Used to determine the title of the Users/Users & Roles breadcrumb
  if (id === 'users') {
    content = hasPermissionsLicenseOrFlag ? 'Users & Roles' : 'Users';
  }

  return <div className={classNames({ [skeleton]: userRole.isPending }, className)}>{content}</div>;
}

export default observer(UserRoleLabel);
