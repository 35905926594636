import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { Form, IPredicate } from '@feathr/blackbox';
import { Skeleton, Time, toast, Value } from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import { errorMessage, TimeFormat } from '@feathr/hooks';

import * as styles from './Metadata.css';

interface IProps {
  form: Form;
}

function Metadata({ form }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { Breadcrumbs } = useStore();
  const [lastSubmission, setLastSubmission] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchLastSubmission(): Promise<void> {
      try {
        const predicates: IPredicate[] = [
          {
            kind: 'activity',
            group: [
              {
                attr_against: 'form_id',
                comparison: 'eq',
                value: form.id,
              },
              {
                attr_against: 'flvr',
                comparison: 'eq',
                value: 'form_submission',
              },
            ],
          },
        ];
        const crumbs = Breadcrumbs.list({
          predicates,
          mode: 'match_all',
          lookback_mode: 'unbounded',
          lookback_value: 1,
          pagination: {
            page_size: 1,
          },
          ordering: ['-d_c'],
        });

        await when(() => !crumbs.isPending);

        setLastSubmission(crumbs.models.length ? crumbs.models[0].get('d_c') : undefined);
      } catch (error) {
        toast(
          t('Failed to fetch last submission:\n{{- error}}', { error: errorMessage(error, t) }),
        );
      } finally {
        setIsLoading(false);
      }
    }

    fetchLastSubmission();
  }, []);

  return (
    <div className={styles.root}>
      {form.published && (
        <Value
          label={t('Last published')}
          value={
            <Time
              format={TimeFormat.longWeekdayDateTime}
              timestamp={form.get('date_last_published')}
            />
          }
        />
      )}

      <Value
        isLoading={isLoading}
        label={t('Last submission received')}
        value={
          isLoading ? (
            <Skeleton width={120} />
          ) : lastSubmission ? (
            <Time format={TimeFormat.longWeekdayDateTime} timestamp={lastSubmission ?? ''} />
          ) : (
            t('No submissions received')
          )
        }
      />
      <Value
        label={t('Last edited')}
        value={
          <Time
            format={TimeFormat.longWeekdayDateTime}
            timestamp={form.get('date_last_modified')}
          />
        }
      />
    </div>
  );
}

export default observer(Metadata);
