import { observer } from 'mobx-react-lite';
import type { Dispatch, JSX, SetStateAction } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { IGracePeriod, IOpportunityLineItem } from '@feathr/blackbox';
import { NumberInput, Select } from '@feathr/components';
import PackageDetailsCard from '@feathr/extender/App/Settings/GracePeriodSelect/PackageDetailsCard';
import { useAccount, useFlags } from '@feathr/extender/state';

import UpdatedLicenseDate from './UpdatedGracePeriodCard';

import * as styles from './GracePeriodSelect.css';

export interface IGracePeriodSelectProps {
  onChange: Dispatch<SetStateAction<IOpportunityLineItem | undefined>>;
  licensePackage?: IOpportunityLineItem;
}

function GracePeriodSelect({
  onChange,
  licensePackage,
}: Readonly<IGracePeriodSelectProps>): JSX.Element {
  const account = useAccount();
  const { csmManager: hasAccountManagerFlag } = useFlags();
  const { t } = useTranslation();
  const { add_ons: addOns, discounts, packages, services } = account.get('license');

  const handleInputChange = (newValue: number, key: keyof IGracePeriod): void => {
    if (licensePackage) {
      onChange({
        ...licensePackage,
        grace_period: {
          ...licensePackage.grace_period,
          [key]: newValue,
        },
      });
    }
  };

  function handlePackageChange(newValue: IOpportunityLineItem): void {
    onChange(newValue);
  }

  function handleChangeStartDate(newValue?: number): void {
    handleInputChange(newValue ?? 0, 'start');
  }

  function handleChangeEndDate(newValue?: number): void {
    handleInputChange(newValue ?? 0, 'end');
  }

  function handleSelectOptionValue(pkg: IOpportunityLineItem): string {
    return pkg.name;
  }

  function handleSelectClear(): void {
    onChange(undefined);
  }

  function handleNoOptionsMessage(): string {
    return t('No license packages found on the account.');
  }

  const selectOptions = [...addOns, ...discounts, ...packages, ...services].filter(
    (item: IOpportunityLineItem) => item.opportunity_line_item_id,
  );

  return (
    <>
      <Select<IOpportunityLineItem>
        // Managing package grace period is reserved for Account Managers (previously known as CSM).
        disabled={!hasAccountManagerFlag}
        getOptionValue={handleSelectOptionValue}
        helpText={t(
          'Select a package on the account to add a grace period. Please contact support if the desired package is not listed.',
        )}
        isClearable={true}
        label={t('License package')}
        name={'grace-period-select'}
        noOptionsMessage={handleNoOptionsMessage}
        onChange={handlePackageChange}
        onClear={handleSelectClear}
        options={selectOptions}
        placeholder={t('Select package on the account...')}
        value={licensePackage}
        wrapperClassName={styles.select}
      />
      {licensePackage && (
        <PackageDetailsCard
          name={licensePackage.name}
          opp_id={licensePackage.opp_id}
          period={licensePackage.period}
        />
      )}

      <NumberInput
        clearableClassName={styles.numberInputWrapper}
        disabled={!hasAccountManagerFlag || !licensePackage}
        helpText={t('Add a grace period to the start of the license.')}
        label={t('Start earlier')}
        min={0}
        onChange={handleChangeStartDate}
        suffix={t('day', { count: licensePackage?.grace_period?.start ?? 0 })}
        value={licensePackage?.grace_period?.start}
      />
      {licensePackage && (
        <UpdatedLicenseDate
          gracePeriod={licensePackage.grace_period}
          gracePeriodKey={'start'}
          period={licensePackage.period}
        />
      )}

      <NumberInput
        clearableClassName={styles.numberInputWrapper}
        disabled={!hasAccountManagerFlag || !licensePackage}
        helpText={t('Add a grace period to the end of the license.')}
        label={t('End later')}
        min={0}
        onChange={handleChangeEndDate}
        suffix={t('day', { count: licensePackage?.grace_period?.end ?? 0 })}
        value={licensePackage?.grace_period?.end}
      />
      {licensePackage && (
        <UpdatedLicenseDate
          gracePeriod={licensePackage.grace_period}
          gracePeriodKey={'end'}
          period={licensePackage.period}
        />
      )}
    </>
  );
}

export default observer(GracePeriodSelect);
