import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ISelectOption } from '@feathr/components';
import { Select } from '@feathr/components';

import * as styles from './InteractionSelect.css';

interface IProps {
  onChange: (id?: 'form_submission' | 'form_view') => void;
}

interface IOption extends ISelectOption {
  id: 'form_submission' | 'form_view';
}

function InteractionSelect({ onChange }: IProps): JSX.Element {
  const [selected, setSelected] = useState<string | undefined>();
  const { t } = useTranslation();
  const options: IOption[] = [
    { id: 'form_submission', name: t('Submission') },
    { id: 'form_view', name: t('View') },
  ];

  function handleSelectSingle({ id }: IOption): void {
    onChange(id);
    setSelected(id);
  }

  function handleClear(): void {
    onChange(undefined);
    setSelected(undefined);
  }

  function getOptionLabel({ name }: IOption): string {
    return name;
  }

  function getOptionValue({ id }: IOption): string {
    return id;
  }

  return (
    <Select<IOption>
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      isClearable={true}
      name={'interaction'}
      onClear={handleClear}
      onSelectSingle={handleSelectSingle}
      options={options}
      placeholder={t('Filter by')}
      value={options.find((o) => o.id === selected)}
      wrapperClassName={styles.root}
    />
  );
}

export default InteractionSelect;
