import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormSummaryItem } from '@feathr/components';
import { useLocalUrl, useStore } from '@feathr/extender/state';

import type { ISummaryProps } from './CampaignSummary.types';

const PartnerSummary = observer(({ campaign }: ISummaryProps): JSX.Element | null => {
  const { t } = useTranslation();
  const { Partners } = useStore();
  const localUrl = useLocalUrl();

  const { isMonetization } = campaign;

  if (!isMonetization) {
    return null;
  }

  const partner = Partners.get(campaign.get('parent'));
  const errors = partner.validate([], false).errors;

  const link = (
    <a
      href={localUrl(`/projects/${campaign.get('event')}/partners/${partner.id}`)}
      target={'_blank'}
    >
      {partner.name}
    </a>
  );

  return <FormSummaryItem errors={errors} label={t('Partner')} value={link} />;
});

PartnerSummary.displayName = 'PartnerSummary';

export default PartnerSummary;
