import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import { useStore } from '@feathr/extender/state';

import { skeleton } from '@feathr/components/dist/style/skeleton.css';

interface IProps {
  className?: string;
  id: string;
}

function FontLabel({ className, id }: Readonly<IProps>): JSX.Element {
  const { Fonts } = useStore();
  const font = Fonts.get(id);
  return <div className={classNames({ [skeleton]: font.isPending }, className)}>{font.name}</div>;
}

export default observer(FontLabel);
