import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';

import { EAlertV2Type as AlertType } from '@feathr/components';
import { AlertV2 as Alert, Fieldset, Input } from '@feathr/components';

interface IProps {
  alertDescription: string;
  alertTitle: string;
  className?: string;
  disabled?: boolean;
  label: string;
  onChange?: (newValue?: boolean) => void;
  value?: boolean;
}

function ConsentCapture({
  alertDescription,
  alertTitle,
  className,
  disabled = false,
  label,
  onChange,
  value = false,
}: IProps): JSX.Element {
  const CONSENT_STRING = 'AGREE';
  const [liveValue, setLiveValue] = useState<string | undefined>(
    value ? CONSENT_STRING : undefined,
  );

  useEffect(() => {
    if (value && liveValue !== CONSENT_STRING) {
      // If value changed, but liveValue doesn't match, update liveValue to match.
      setLiveValue(CONSENT_STRING);
    } else if (!value && liveValue === CONSENT_STRING) {
      setLiveValue(undefined);
    }
  }, [setLiveValue, value]);

  function handleChangeConsent(newValue?: string): void {
    setLiveValue(newValue);
    // TODO: Translate consent.
    if (newValue === 'AGREE') {
      if (onChange) {
        onChange(true);
      }
    } else {
      if (onChange) {
        onChange(false);
      }
    }
  }

  return (
    <Fieldset className={className}>
      <Alert description={alertDescription} title={alertTitle} type={AlertType.info} />
      <Input
        disabled={disabled}
        label={label}
        name={'consent_capture'}
        onChange={handleChangeConsent}
        type={'text'}
        value={liveValue}
      />
    </Fieldset>
  );
}

export default observer(ConsentCapture);
