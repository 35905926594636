import type { TFunction } from 'i18next';

import { CampaignClass, type TTDCampaign } from '@feathr/blackbox';
import { getIconForAction } from '@feathr/hooks';

import type { IObjectiveOption } from './ObjectiveOption';

export function createOptions({
  campaign,
  t,
}: {
  campaign: TTDCampaign;
  t: TFunction;
}): IObjectiveOption[] {
  const disableReach = campaign.isSegmentCampaign;

  const expansionCampaigns = [
    CampaignClass.MobileGeoFenceRetargeting,
    CampaignClass.MobileGeoFencing,
    CampaignClass.Search,
    CampaignClass.Affinity,
    CampaignClass.SeedSegment,
  ];

  const disableExpansion = expansionCampaigns.includes(campaign.get('_cls'));

  const options: IObjectiveOption[] = [
    {
      id: 'reach',
      name: t('Build Reach and Awareness'),
      description: t('Introduce your organization to new audiences through broad targeting'),
      icon: getIconForAction('reach'),
      disabled: disableReach,
    },
    {
      id: 'high_demand',
      name: t('Deepen Engagement'),
      description: t('More deeply engage audiences who already know about you'),
      icon: getIconForAction('deepen'),
      disabled: disableExpansion,
    },

    {
      id: 'conversions',
      name: t('Drive Conversions'),
      description: t('Convert your best audiences into donors, members, and supporters'),
      icon: getIconForAction('drive'),
      disabled: disableExpansion,
    },
    {
      id: 'custom',
      name: t('Custom Objective'),
      description: t('Choose your own campaign setup and optimization preferences'),
      icon: getIconForAction('customize'),
      disabled: false,
    },
  ];
  return options;
}
