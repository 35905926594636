import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { RedirectDomain } from '@feathr/blackbox';
import { Button, Icon, Value } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';

import type { IRedirectDomainOption } from '../RedirectDomainSelect';

import * as styles from './SingleDomain.css';

interface ISingleDomainProps {
  value: RedirectDomain;
  label?: string;
  onChange: (option: IRedirectDomainOption) => void;
  showLinks?: boolean;
}

function SingleDomain({
  label,
  onChange,
  showLinks = false,
  value,
}: Readonly<ISingleDomainProps>): JSX.Element {
  const { t } = useTranslation();
  const localUrl = useLocalUrl();

  /**
   * Set domain on first render.
   * This will not re-render because the account has only one authorized domain.
   */
  onChange({
    name: value.get('domain'),
    id: value.id,
  });

  return (
    <div className={styles.root}>
      <Value label={label} value={value.get('domain')} />
      {showLinks && (
        <Button
          className={styles.link}
          href={localUrl('/settings/account/domains')}
          suffix={<Icon icon={faExternalLink} />}
          target={'_blank'}
          type={'link'}
        >
          {t('View authorized domains')}
        </Button>
      )}
    </div>
  );
}

export default observer(SingleDomain);
