import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import { useStore } from '@feathr/extender/state';

import { skeleton } from '@feathr/components/dist/style/skeleton.css';

interface IProps {
  className?: string;
  id: string;
}

function FlightLabel({ className, id }: Readonly<IProps>): JSX.Element {
  const { Flights } = useStore();

  const flight = Flights.get(id);
  const name = flight.isPending ? 'placeholder' : flight.name;

  return <div className={classNames({ [skeleton]: flight.isPending }, className)}>{name}</div>;
}

export default observer(FlightLabel);
