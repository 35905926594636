import { observer } from 'mobx-react-lite';
import type { Dispatch, JSX, SetStateAction } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { AutoPinpointEmailBaseCampaign, TUnitOfTime } from '@feathr/blackbox';
import {
  CardContent,
  CardHeader,
  CardV2 as Card,
  DatePicker,
  Fieldset,
  NumberInput,
  Radios,
  Select,
  Well,
} from '@feathr/components';
import { useUser } from '@feathr/extender/state';
import { flattenError, moment, TimeFormat } from '@feathr/hooks';
import type { IValidateGrouped } from '@feathr/rachis';

import * as styles from './AutomationSchedule.css';

interface IMenuOption {
  id: TUnitOfTime;
  value: string;
}

interface IAutomationScheduleProps {
  campaign: AutoPinpointEmailBaseCampaign;
  context?: 'drip' | 'pinpoint';
  disabled: boolean;
  repeat: 'first' | 'every';
  setRepeat: Dispatch<SetStateAction<'first' | 'every'>>;
  validationErrors: IValidateGrouped;
}

const minimumTimeValues = { minutes: 5, hours: 1, days: 1, weeks: 1 };

function AutomationSchedule({
  campaign,
  context = 'pinpoint',
  disabled,
  repeat,
  setRepeat,
  validationErrors,
}: Readonly<IAutomationScheduleProps>): JSX.Element {
  const user = useUser();
  const { t } = useTranslation();

  const initialDelay =
    campaign.get('delay_value') === 5 && campaign.get('delay_unit') === 'seconds'
      ? 'immediately'
      : 'delay';
  const [delay, setDelay] = useState<'immediately' | 'delay'>(initialDelay);

  const userTimezone = user.get('timezone');
  const defaults = campaign.getDefaults();
  const cooldownUnit = campaign.get('cooldown_unit', 'minutes');
  const timeOfDay = campaign.get('time_to_send');
  const isTimeSubtype = campaign.get('subtype') === 'time';

  function onDelayChange(newValue?: string): void {
    // When sending immediately, the delay is actually 5 seconds.
    if (newValue === 'immediately') {
      campaign.set({
        delay_value: 5,
        delay_unit: 'seconds',
        send_schedule: 'now',
      });
      setDelay(newValue);
    }

    // If delay is selected, set send schedule fields back to default values
    if (newValue === 'delay') {
      campaign.set({
        send_schedule: defaults.send_schedule,
        delay_value: defaults.delay_value,
        delay_unit: defaults.delay_unit,
      });
      setDelay(newValue);
    }
  }

  function getOptionValue(option: IMenuOption): string {
    return option.value;
  }

  function handleChangeTimeOfDay(newTimestamp?: string): void {
    const newMoment = moment.utc(newTimestamp);
    campaign.set({ time_to_send: newMoment.format(TimeFormat.isoDateTime) });
  }

  function getTimeOptions(): IMenuOption[] {
    return [
      { id: 'minutes', value: 'minutes' },
      { id: 'hours', value: 'hours' },
      { id: 'days', value: 'days' },
      { id: 'weeks', value: 'weeks' },
    ];
  }

  function handleChangeRepeat(newValue?: string): void {
    if (newValue) {
      campaign.set({
        repeat: newValue === 'every',
        // Reset the cooldown value to the selected cooldown unit's minimum value
        cooldown_value: newValue === 'every' ? minimumTimeValues[cooldownUnit] : 0,
        cooldown_unit: 'minutes',
      });

      setRepeat(newValue as 'first' | 'every');
    }
  }

  function getTimeValue(attribute: 'delay_unit' | 'cooldown_unit'): IMenuOption | undefined {
    return getTimeOptions().find(
      (option) =>
        option.id === campaign.get(attribute, attribute.startsWith('delay') ? 'minutes' : 'hours'),
    );
  }

  function getMinimumTimeValue(timeUnitAttribute: 'delay_unit' | 'cooldown_unit'): number {
    const timeUnit = campaign.get(
      timeUnitAttribute,
      timeUnitAttribute.startsWith('delay') ? 'minutes' : 'hours',
    );
    return minimumTimeValues[timeUnit];
  }

  function getOptionLabelDelay(option: IMenuOption): string | undefined {
    const delayValue = campaign.get('delay_value');
    return {
      minutes: t('minute', { count: delayValue }),
      hours: t('hour', { count: delayValue }),
      days: t('day', { count: delayValue }),
      weeks: t('week', { count: delayValue }),
    }[option.id];
  }

  function getOptionLabelCooldown(option: IMenuOption): string | undefined {
    const cooldownValue = campaign.get('cooldown_value');
    return {
      minutes: t('minute', { count: cooldownValue }),
      hours: t('hour', { count: cooldownValue }),
      days: t('day', { count: cooldownValue }),
      weeks: t('week', { count: cooldownValue }),
    }[option.id];
  }

  function onSelectSingle(option: IMenuOption): void {
    campaign.set({ delay_unit: option.id, delay_value: minimumTimeValues[option.id] });
  }

  function handleChangeCooldownUnit(option: IMenuOption): void {
    campaign.set({ cooldown_unit: option.id, cooldown_value: minimumTimeValues[option.id] });
  }

  const scheduleInputs = (
    <Fieldset>
      {!isTimeSubtype && (
        <Radios
          dataName={'send_schedule'}
          disabled={disabled}
          helpText={
            context === 'drip'
              ? t('Start your drip campaign immediately following the trigger, or after a delay.')
              : t('Send your email immediately following the trigger, or after a delay.')
          }
          label={t('Send schedule')}
          layout={'block'}
          onChange={onDelayChange}
          options={[
            { id: 'immediately', name: t('Immediately') },
            { id: 'delay', name: t('Delay') },
          ]}
          required={context === 'pinpoint'}
          value={delay}
        />
      )}
      {delay === 'delay' && !isTimeSubtype && (
        <NumberInput
          attribute={'delay_value'}
          className={styles.delay}
          clearableClassName={styles.delayElement}
          disabled={disabled}
          helpText={
            context === 'drip'
              ? t(
                  'Set the amount of time that the person should wait after enrolling before they are sent this email.',
                )
              : t(
                  'The amount of time between the person performing the action and sending the email.',
                )
          }
          label={t('Delay time')}
          min={1}
          model={campaign}
          name={'delay_value'}
          optional={context === 'drip'}
          suffix={
            <Select
              disabled={disabled}
              getOptionLabel={getOptionLabelDelay}
              getOptionValue={getOptionValue}
              name={'delay_unit'}
              onSelectSingle={onSelectSingle}
              options={getTimeOptions()}
              value={getTimeValue('delay_unit')}
            />
          }
          validationError={flattenError(validationErrors.delay_value)}
        />
      )}

      {isTimeSubtype && (
        <DatePicker
          autoComplete={'off'}
          dateFormat={'h:mm aa'}
          disabled={disabled}
          helpText={t(
            'On the day that the date-trigger matches, choose when the email should be sent.',
          )}
          label={t('Time of day')}
          name={'time_to_send'}
          onDateStrChange={handleChangeTimeOfDay}
          showTimeSelect={true}
          showTimeSelectOnly={true}
          timeIntervals={5}
          timezone={userTimezone}
          validationError={flattenError(validationErrors.time_to_send)}
          value={timeOfDay}
          wrapperClassName={styles.timeOfDaySelect}
        />
      )}
      <Radios
        disabled={disabled}
        helpText={
          context === 'drip'
            ? t(
                'Select whether to enroll people every time they meet the enrollment rules or only the first time.',
              )
            : t(
                'Send your email only the first time the person performs the trigger, or every time.',
              )
        }
        label={t('Send cadence')}
        layout={'block'}
        name={'send_cadence'}
        onChange={handleChangeRepeat}
        options={[
          { id: 'first', name: t('Send only the first time') },
          { id: 'every', name: t('Send every time'), disabled: isTimeSubtype },
        ]}
        required={context === 'pinpoint'}
        value={repeat}
      />
      {repeat === 'every' && (
        <NumberInput
          attribute={'cooldown_value'}
          className={styles.delay}
          clearableClassName={styles.delayElement}
          disabled={disabled}
          helpText={t(
            'The minimum amount of time that should pass before someone is able to trigger the campaign again.',
          )}
          label={t('Cooldown period')}
          min={getMinimumTimeValue('cooldown_unit')}
          model={campaign}
          name={'cooldown_value'}
          suffix={
            <Select
              disabled={disabled}
              getOptionLabel={getOptionLabelCooldown}
              getOptionValue={getOptionValue}
              name={'cooldown_unit'}
              onSelectSingle={handleChangeCooldownUnit}
              options={getTimeOptions()}
              value={getTimeValue('cooldown_unit')}
            />
          }
          validationError={flattenError(validationErrors.cooldown_value)}
        />
      )}
    </Fieldset>
  );

  return context === 'pinpoint' ? (
    <Card width={'full'}>
      <CardHeader title={t('Schedule')} />
      <CardContent>{scheduleInputs}</CardContent>
    </Card>
  ) : (
    <Well layout={'vertical'} theme={'white'}>
      {scheduleInputs}
    </Well>
  );
}

export default observer(AutomationSchedule);
