import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import { useStore } from '@feathr/extender/state';

import type { IMatchProps } from '../routes';

import { skeleton } from '@feathr/components/dist/style/skeleton.css';

interface IProps {
  className?: string;
  matches: IMatchProps;
  id: string;
}

function AccountLabel({ className, id, matches }: Readonly<IProps>): JSX.Element {
  const { Accounts } = useStore();
  const { accountId } = matches;
  const account = Accounts.get(accountId);

  // Use the account name by default
  let name = account.name;

  if (matches?.tertiary === 'advertisers') {
    const subadvertiser = account.get('ttd')?.subadvertisers?.find((sub) => sub.subadv_id === id);
    name = subadvertiser?.name ?? 'Unnamed Advertiser';
  }

  return <div className={classNames({ [skeleton]: account.isPending }, className)}>{name}</div>;
}

export default observer(AccountLabel);
