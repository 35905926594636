import type { EditorEvents } from '@tiptap/react';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { Form } from '@feathr/blackbox';
import { RichTextEditorV2 as RichTextEditor } from '@feathr/components';

interface IProps {
  form: Form;
}

function SubmissionThankYouMessage({ form }: Readonly<IProps>): JSX.Element {
  const settings = form.get('settings');
  const postSubmitHTML = settings.post_submit_html;

  function handleChange(properties: EditorEvents['update']): void {
    const content = properties.editor.getHTML();
    form.set({ settings: { ...settings, post_submit_html: content } });
  }

  return (
    <RichTextEditor
      content={postSubmitHTML}
      excludeControls={{ heading: ['h5', 'h6'] }}
      onChange={handleChange}
    />
  );
}

export default observer(SubmissionThankYouMessage);
