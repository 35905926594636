import { faPlus, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { useDisclosure } from '@mantine/hooks';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { IRowItem } from '@feathr/blackbox';
import { Button, Icon, Input, Label } from '@feathr/components';
import { useAccount, useUser } from '@feathr/extender/state';

import { allowedFormFieldIds } from '../FormEditor';
import CreateCustomFieldModal from './CreateCustomFieldModal';
import Field from './Field';

import * as styles from './FormFields.css';

interface IProps {
  fields: IRowItem[];
}

function FormFields({ fields }: IProps): JSX.Element {
  const { t } = useTranslation();
  const user = useUser();
  const account = useAccount();
  const pinnedFields = user.getSetting('forms_pinned_fields', {})?.[account.id] ?? [];
  const [search, setSearch] = useState<string>('');
  const [createModalOpen, { close: closeCreateModal, open: openCreateModal }] =
    useDisclosure(false);

  function handleSearch(value?: string): void {
    setSearch(value || '');
  }

  const filteredFields = fields.filter((field) =>
    field.label.toLowerCase().includes(search.toLowerCase()),
  );

  const groupedFields = filteredFields.reduce(
    (acc, field) => {
      if (pinnedFields.includes(field.id)) {
        acc.pinned.push(field);
      } else if (allowedFormFieldIds.includes(field.id)) {
        acc.defaults.push(field);
      } else {
        acc.custom.push(field);
      }
      return acc;
    },
    { pinned: [], defaults: [], custom: [] } as Record<
      'pinned' | 'defaults' | 'custom',
      IRowItem[]
    >,
  );

  const { pinned, defaults, custom } = groupedFields;

  return (
    <>
      <div className={styles.actions}>
        <Input
          isClearable={true}
          onChange={handleSearch}
          placeholder={t('Search by name...')}
          prefix={<Icon icon={faSearch} />}
          type={'text'}
          value={search}
          wrapperClassName={styles.input}
        />
        <Button
          onClick={openCreateModal}
          tooltip={t('Add custom field')}
          type={'icon-small-outlined'}
        >
          <Icon icon={faPlus} />
        </Button>
      </div>

      <div className={styles.container}>
        <div className={styles.section}>
          <Label className={styles.optionGroup}>{t('PINNED')}</Label>
          {pinned.length > 0 ? pinned.map((field) => <Field field={field} key={field.id} />) : null}
        </div>
        <div className={styles.section}>
          <Label className={styles.optionGroup}>{t('DEFAULT')}</Label>
          {defaults.map((field) => (
            <Field field={field} key={field.id} />
          ))}
        </div>
        <div className={styles.section}>
          <Label className={styles.optionGroup}>{t('CUSTOM')}</Label>
          {custom.map((field) => (
            <Field field={field} key={field.id} />
          ))}
        </div>
      </div>

      <CreateCustomFieldModal onClose={closeCreateModal} opened={createModalOpen} />
    </>
  );
}

export default observer(FormFields);
