import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { IMergeField, PinpointEmailBaseCampaign, Template } from '@feathr/blackbox';
import { CampaignClass, TemplateClass } from '@feathr/blackbox';
import { DebouncedInput, Fieldset, Input } from '@feathr/components';
import MergetagSelect from '@feathr/extender/components/MergetagSelect';
import { useStore } from '@feathr/extender/state';
import { flattenError, useDebouncedCallback } from '@feathr/hooks';
import type { IValidateGrouped } from '@feathr/rachis';

interface IEmailInformationInputsProps {
  campaign: PinpointEmailBaseCampaign;
  disabled: boolean;
  template?: Template;
  validationErrors?: IValidateGrouped;
}

function EmailInformationInputs({
  campaign,
  disabled,
  template,
  validationErrors,
}: Readonly<IEmailInformationInputsProps>): JSX.Element {
  const { t } = useTranslation();
  const { Templates } = useStore();
  const [mergetagTemplate] = useState(
    Templates.create({ _cls: TemplateClass.PinpointEmail, account: campaign.get('account') }),
  );
  const [subjectHadFocus, setSubjectHadFocus] = useState(false);

  const isPartnerMessageCampaign = campaign.get('_cls') === CampaignClass.PinpointPartnerMessage;

  const debouncedTemplateUpdateSubject = useDebouncedCallback((subject: string) => {
    template?.set({ subject });
    template?.patchDirty();
  });

  useEffect(() => {
    return autorun(() => {
      if (!template) {
        return;
      }
      // Campaign subject is optional, but template subject is not
      const campaignSubject = campaign.get('subject');
      const templateSubject = template.get('subject');
      if (campaignSubject !== templateSubject) {
        debouncedTemplateUpdateSubject(campaignSubject ?? '');
      }
    });
  }, [campaign, template, debouncedTemplateUpdateSubject]);

  const handleSelectOption = useCallback(
    (option: IMergeField) => {
      const subject = campaign.get('subject', '');
      campaign.set({ subject: subject.concat(option.value) });

      // Update template's used_mergefields when a new merge tag is added
      if (template && !template.get('used_mergefields').includes(option.value)) {
        template.addMergefield(option.value);
      }
    },
    [campaign, template],
  );

  function handleDebouncedSubjectChange(newValue: string = ''): void {
    campaign.set({ subject: newValue });
  }

  function handleDebouncedPreviewTextChange(newValue: string = ''): void {
    template?.set({ preview_text: newValue });
  }

  function handleBlurSubject(): void {
    setSubjectHadFocus(true);
  }

  return (
    <Fieldset>
      <DebouncedInput<string>
        defaultValue={campaign.get('subject')}
        onChange={handleDebouncedSubjectChange}
      >
        {(liveValue, onChangeLiveValue): JSX.Element => (
          <Input
            disabled={disabled}
            helpText={t('The subject line for the email your audience will receive.')}
            label={t('Subject')}
            name={'email_subject'}
            onBlur={handleBlurSubject}
            onChange={onChangeLiveValue}
            type={'text'}
            validationError={subjectHadFocus ? flattenError(validationErrors?.subject) : undefined}
            value={liveValue}
          />
        )}
      </DebouncedInput>
      <MergetagSelect
        disabled={disabled}
        helpText={t(
          'You can use merge tags to personalize the subject line of your message. Choose the data you want to merge in from this dropdown to insert it at the end of your current subject line.',
        )}
        isPartnerMessageCampaign={isPartnerMessageCampaign}
        name={'merge_tags'}
        onChange={handleSelectOption}
        template={mergetagTemplate}
      />
      {template && (
        <DebouncedInput<string>
          defaultValue={template.get('preview_text')}
          onChange={handleDebouncedPreviewTextChange}
        >
          {(liveValue, onChangeLiveValue): JSX.Element => (
            <Input
              disabled={disabled}
              helpText={t(
                'The preview text that displays along with the subject line in some email clients.',
              )}
              label={t('Preview text')}
              onChange={onChangeLiveValue}
              optional={true}
              type={'text'}
              value={liveValue}
            />
          )}
        </DebouncedInput>
      )}
    </Fieldset>
  );
}

export default observer(EmailInformationInputs);
