import type { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { faChartMixedUpCircleCurrency, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { useDisclosure } from '@mantine/hooks';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import type { TTDCampaign } from '@feathr/blackbox';
import {
  Button,
  CardV2 as Card,
  CollapseButton,
  Confetti,
  Recommendation,
  Toggle,
} from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';

import { useCampaignValidation } from '../../../CampaignEditPage/AdWizard';
import { ERecommendationType } from './CampaignRecommendations.utils';
import RecommendationModal from './RecommendationModal';
import AddGoal from './RecommendationModal/AddGoal';
import EnableOmnitargeting from './RecommendationModal/EnableOmnitargeting';
import ExtendEndDate from './RecommendationModal/ExtendEndDate';
import IncreaseBaseBid from './RecommendationModal/IncreaseBaseBid';
import IncreaseBudget from './RecommendationModal/IncreaseBudget';
import IncreaseBudgetExtendEndDate from './RecommendationModal/IncreaseBudgetExtendEndDate';
import IncreaseFrequencyCap from './RecommendationModal/IncreaseFrequencyCap';

import * as styles from './CampaignRecommendations.css';

interface ICampaignRecommendation {
  title: string;
  description: string;
  action: string;
  icon: IconDefinition;
  type: ERecommendationType;
}

interface ICampaignRecommendationsProps {
  campaign: TTDCampaign;
  recommendations: ICampaignRecommendation[];
}

function CampaignRecommendations({
  campaign,
  recommendations,
}: ICampaignRecommendationsProps): JSX.Element {
  const localUrl = useLocalUrl();
  const history = useHistory();
  const { t } = useTranslation();

  const cardRef = useRef<HTMLDivElement>(null);
  const [isExploding, setIsExploding] = useState(false);
  const [opened, { close, open }] = useDisclosure(false);
  const [collapsed, setCollapsed] = useState(true);
  const [localRecommendations, setLocalRecommendations] =
    useState<ICampaignRecommendation[]>(recommendations);
  const [confettiPosition, setConfettiPosition] = useState({ x: 0, y: 0 });
  const [selectedRecommendation, setSelectedRecommendation] = useState<ERecommendationType | null>(
    null,
  );
  const { getValidationErrors } = useCampaignValidation({
    campaign,
  });

  const errors = getValidationErrors();

  function handleCollapse(): void {
    setCollapsed(!collapsed);
  }

  function handleApplyRecommendation(type: ERecommendationType): () => void {
    return function (): void {
      setSelectedRecommendation(type);

      if (type === ERecommendationType.UpdateCreatives) {
        const creativesUrl = localUrl(campaign.getItemUrl('edit')) + '#step4';

        history.push(creativesUrl);
      } else {
        open();
      }
    };
  }

  function updateConfettiPosition(): void {
    if (cardRef.current) {
      const rect = cardRef.current.getBoundingClientRect();
      setConfettiPosition({
        x: rect.left + rect.width / 2,
        y: rect.top + rect.height / 2,
      });
    } else {
      // Fallback to center of screen if card ref is not available
      setConfettiPosition({
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
      });
    }
  }

  function handleTriggerConfetti(): void {
    updateConfettiPosition();
    setIsExploding(true);
  }

  function onComplete(): void {
    setIsExploding(false);
  }

  function handleToggleEmptyState(): void {
    if (localRecommendations.length > 0) {
      setLocalRecommendations([]);
      // Use requestAnimationFrame to run after next paint
      requestAnimationFrame(() => {
        updateConfettiPosition();
        setIsExploding(true);
      });
    } else {
      setLocalRecommendations(recommendations);
    }
  }

  const collapseText = collapsed ? t('View more') : t('View less');

  // Determine the recommendations to display based on the collapsed state
  const displayedRecommendations = collapsed
    ? localRecommendations.slice(0, 1)
    : localRecommendations;

  return (
    <>
      <RecommendationModal
        campaign={campaign}
        onClose={close}
        opened={opened}
        size={selectedRecommendation === ERecommendationType.AddGoal ? 'md' : 'default'}
      >
        {selectedRecommendation === ERecommendationType.ExtendEndDate && (
          <ExtendEndDate campaign={campaign} errors={errors?.duration ?? []} />
        )}
        {selectedRecommendation === ERecommendationType.IncreaseBudget && (
          <IncreaseBudget campaign={campaign} errors={errors?.budget ?? []} />
        )}

        {selectedRecommendation === ERecommendationType.IncreaseBudgetExtendEndDate && (
          <IncreaseBudgetExtendEndDate campaign={campaign} errors={errors} />
        )}

        {selectedRecommendation === ERecommendationType.IncreaseBaseBid && (
          <IncreaseBaseBid campaign={campaign} />
        )}

        {selectedRecommendation === ERecommendationType.IncreaseFrequencyCap && (
          <IncreaseFrequencyCap campaign={campaign} />
        )}
        {selectedRecommendation === ERecommendationType.AddGoal && <AddGoal campaign={campaign} />}

        {selectedRecommendation === ERecommendationType.EnableOmnitargeting && (
          <EnableOmnitargeting campaign={campaign} />
        )}
      </RecommendationModal>

      <Confetti.Container>
        {isExploding && (
          <Confetti
            confettiSource={{
              x: confettiPosition.x,
              y: confettiPosition.y,
              w: 0,
              h: 0,
            }}
            fadeFallingConfetti={true}
            onConfettiComplete={onComplete}
          />
        )}
      </Confetti.Container>

      <div ref={cardRef}>
        <Card width={'full'}>
          <Card.Header title={'Recommended for you'}>
            <Button onClick={handleTriggerConfetti}>Trigger Confetti</Button>
            <Toggle
              label={'Empty State'}
              onChange={handleToggleEmptyState}
              value={localRecommendations.length === 0}
            />
          </Card.Header>
          <Card.Content addVerticalGap={true}>
            {displayedRecommendations.length > 0 ? (
              <>
                {displayedRecommendations.map((recommendation) => (
                  <Recommendation
                    description={recommendation.description}
                    key={recommendation.title}
                    suffix={<Recommendation.Icon icon={recommendation.icon} />}
                    title={recommendation.title}
                  >
                    <Recommendation.Button onClick={handleApplyRecommendation(recommendation.type)}>
                      {recommendation.action}
                    </Recommendation.Button>
                  </Recommendation>
                ))}
                <CollapseButton
                  className={styles.collapseButton}
                  collapsed={collapsed}
                  icons={{
                    collapsedLeft: faChevronDown,
                    collapsedRight: faChevronDown,
                    expanded: faChevronUp,
                  }}
                  onClick={handleCollapse}
                >
                  {collapseText}
                </CollapseButton>
              </>
            ) : (
              <Recommendation
                description={t('You completed all recommendations.')}
                layout={'vertical'}
                suffix={<Recommendation.Icon bordered={true} icon={faChartMixedUpCircleCurrency} />}
                title={t('You did it!')}
              />
            )}
          </Card.Content>
        </Card>
      </div>
    </>
  );
}

export default observer(CampaignRecommendations);
