import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { DripCampaign } from '@feathr/blackbox';
import { AlertV2 as Alert, Checkbox, Tooltip } from '@feathr/components';
import { EAlertType } from '@feathr/components/src/AlertV2/AlertV2';
const MAX_ENROLL_COUNT = 200000;

import * as styles from './DripBulkEnroll.css';

interface IProps {
  campaign: DripCampaign;
  peopleCount: number;
  hasErrors: boolean;
}

function DripBulkEnroll({ campaign, peopleCount, hasErrors }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const [shouldShowAlert, setShouldShowAlert] = useState(false);
  const [overLimit, setOverLimit] = useState(false);
  const sendAll = campaign.get('send_all');

  function handleSetBulkEnroll(newValue?: boolean): void {
    campaign.set({ send_all: newValue });
  }

  useEffect(() => {
    if (hasErrors) {
      campaign.set({ send_all: false });
      return;
    }

    if (peopleCount > MAX_ENROLL_COUNT) {
      // If the state was checked, we show the alert.
      if (sendAll === true) {
        setShouldShowAlert(true);
      }

      // Set campaign.send_all to false if over limit.
      handleSetBulkEnroll(false);
      setOverLimit(true);
    } else {
      setOverLimit(false);
      setShouldShowAlert(false);
    }
  }, [peopleCount, hasErrors]);

  const checkbox = (
    <Checkbox
      data-name={'checkbox-drip-bulk-single-send'}
      disabled={peopleCount === 0 || overLimit || hasErrors}
      label={t('Enroll the {{ count, number }} person who already meets these criteria.', {
        count: peopleCount > 0 && !hasErrors ? peopleCount : 0,
      })}
      layout={'well'}
      name={'drip_bulk_single_send'}
      onChange={handleSetBulkEnroll}
      value={sendAll}
    />
  );

  const element =
    shouldShowAlert || peopleCount < MAX_ENROLL_COUNT ? (
      checkbox
    ) : (
      <Tooltip
        className={styles.tooltip}
        position={'bottom'}
        title={t('Adjust filters to include fewer than {{number}} people.', {
          number: MAX_ENROLL_COUNT,
        })}
      >
        {checkbox}
      </Tooltip>
    );

  return (
    <div className={styles.root}>
      {overLimit ? (
        <>
          {element}
          {shouldShowAlert && (
            <Alert
              className={styles.alert}
              description={t(
                'Adjust your filters to include fewer than {{number}} people. Publishing with current filters will only enroll new people who meet these criteria going forward. No bulk enrollment will occur.',
                { number: MAX_ENROLL_COUNT },
              )}
              title={t('Enrollment limit exceeded')}
              type={EAlertType.warning}
            />
          )}
        </>
      ) : (
        <>{element}</>
      )}
    </div>
  );
}

export default observer(DripBulkEnroll);
