import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { TTDCampaign } from '@feathr/blackbox';
import BaseBidSelect from '@feathr/extender/App/EventsPage/CampaignEditPage/AdWizard/AdWizardOptimizationStep/BaseBidSelect/BaseBidSelect';

import RecommendationModal from './RecommendationModal';

interface IProps {
  campaign: TTDCampaign;
  errors?: string[];
}

function IncreaseBaseBid({ campaign }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();

  const initialBaseBid = campaign.initialAttributes?.bidding_strategy?.base_bid;
  const recommendedValue = 10;
  const formattedRecommendedValue = numeral(Number(recommendedValue)).format('$0,0.00');
  const formattedInitialBaseBid = numeral(Number(initialBaseBid)).format('$0,0.00');

  const alert = t('Increase base bid from {{before}} to {{after}}', {
    before: formattedInitialBaseBid,
    after: formattedRecommendedValue,
  });

  return (
    <>
      <RecommendationModal.Alert description={alert} />
      {/* TODO: pull the recommended value from the BE */}
      <BaseBidSelect campaign={campaign} recommendedValue={recommendedValue} />
    </>
  );
}

export default observer(IncreaseBaseBid);
