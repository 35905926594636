import { faCircleXmark, faInfoCircle, faSync } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { PinpointEmailBaseCampaign } from '@feathr/blackbox';
import {
  Button,
  ButtonValid,
  CardActions,
  CardContent,
  CardHeader,
  CardV2,
  EmailInput,
  Fieldset,
  Form,
  Spinner,
  toast,
  Tooltip,
} from '@feathr/components';
import PersonSelect from '@feathr/extender/components/PersonSelect';
import { useStore } from '@feathr/extender/state';
import { flattenError } from '@feathr/hooks';
import { validate } from '@feathr/rachis';

import * as styles from './PinpointEmailCampaignStepTest.css';

interface IProps {
  campaign: PinpointEmailBaseCampaign;
  disabled?: boolean;
  onNext: () => void;
  onPrev: () => void;
  setTestEmail: (value?: string) => void;
  setTestPersonId: (value?: string) => void;
  testEmail?: string;
  testPersonId?: string;
}

async function poll(
  campaign: PinpointEmailBaseCampaign,
  setTimeout: (timeout: number) => void,
): Promise<void> {
  await campaign.reload(['test_campaign_status']);
  if (!campaign.isErrored) {
    setTimeout(window.setTimeout(poll, 10000, campaign, setTimeout));
  }
}

// Maximum number of recipients for a test email
const MAX_EMAILS = 10;

function PinpointEmailCampaignStepTest({
  campaign,
  disabled = false,
  onNext,
  onPrev,
  setTestEmail,
  setTestPersonId,
  testEmail,
  testPersonId,
}: IProps): JSX.Element {
  const { Templates } = useStore();
  const [emailHadFocus, setEmailHadFocus] = useState(false);
  const [personHadFocus, setPersonHadFocus] = useState(false);
  const [timeout, setTimeout] = useState<number | undefined>();
  const { t } = useTranslation();

  const templateId = campaign.get('template_id');
  const template = Templates.get(templateId);
  const testStatus = campaign.get('test_campaign_status');
  const testEmailList = testEmail?.split(/\s*,\s*/) ?? [];

  useEffect(() => {
    if (timeout === undefined) {
      poll(campaign, setTimeout);
    }
    return () => {
      if (timeout) {
        window.clearTimeout(timeout);
      }
    };
  }, [campaign, timeout, setTimeout]);

  function validateEmail(): string[] {
    const error = validate.single(testEmailList, {
      presence: { allowEmpty: false, message: '^Email addresses cannot be empty.' },
      length: {
        maximum: MAX_EMAILS,
        tooLong: `^You can only add up to ${MAX_EMAILS} email addresses.`,
      },
      list: {
        email: {
          message: '^An email address is invalid.',
        },
      },
    });
    return flattenError(error) ?? [];
  }

  function validatePerson(): string[] {
    const error = validate.single(testPersonId, {
      presence: { allowEmpty: false, message: '^Example contact cannot be empty.' },
    });
    return error ?? [];
  }

  const validationErrors = (): string[] => {
    const errors: string[] = [];
    errors.push(...validatePerson(), ...validateEmail());
    return errors;
  };

  function handleBlurEmail(): void {
    setEmailHadFocus(true);
  }

  function handleBlurPerson(): void {
    setPersonHadFocus(true);
  }

  async function handleSendTestEmail(): Promise<void> {
    await template.patchDirty();
    await campaign.patchDirty();
    await campaign.sendTestEmail({ email: testEmailList, per_id: testPersonId! });
    toast(
      t(
        'Test email send scheduled. It should arrive at the provided email addresses in the next 5 - 10 minutes.',
      ),
    );
  }

  if (template.isPending) {
    return <Spinner />;
  }

  const emailAddressHelpText = (
    <div className={styles.emailHelpText}>
      <span>
        {t('You can add up to {{count}} comma-separated email address.', { count: MAX_EMAILS })}
      </span>
      <span>{`${testEmailList.length}/${MAX_EMAILS}`}</span>
    </div>
  );

  return (
    <Form
      actions={[
        <Button key={'prev'} name={'previous_step'} onClick={onPrev}>
          {t('Previous')}
        </Button>,
        <Button key={'next'} name={'next_step'} onClick={onNext} type={'primary'}>
          {t('Next')}
        </Button>,
      ]}
      description={t(
        'Feathr recommends that you use this step to confirm that your email campaign works and looks as expected. Provide a list of email addresses below and select Submit to send a test email to the provided recipients using your chosen email template.',
      )}
      label={t('Edit Campaign: Test')}
    >
      <CardV2>
        <CardHeader title={t('Send a preview email')} />
        <CardContent>
          <Fieldset>
            <PersonSelect
              disabled={disabled || testStatus === 'integrating'}
              label={
                <>
                  {t('Example contact')}{' '}
                  <Tooltip
                    title={t(
                      'This example contact will be used to fill in merge fields that are included in your email and subject line.',
                    )}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </Tooltip>
                </>
              }
              name={'example_contact'}
              onBlur={handleBlurPerson}
              onChange={setTestPersonId}
              placeholder={'Example contact'}
              validationError={personHadFocus ? validatePerson() : []}
              value={testPersonId}
            />
            <EmailInput
              allowMultiple={true}
              disabled={disabled || testStatus === 'integrating'}
              helpPlacement={'bottom'}
              helpText={emailAddressHelpText}
              label={t('Email addresses')}
              name={'test_email_address'}
              onBlur={handleBlurEmail}
              onChange={setTestEmail}
              placeholder={t('Add one or more emails separated by commas')}
              t={t}
              type={'email'}
              validationError={emailHadFocus ? validateEmail() : []}
              value={testEmail}
            />
            {testStatus === 'integrating' && (
              <Trans t={t}>
                <p className={styles.inProgress}>
                  <FontAwesomeIcon icon={faSync} size={'lg'} spin={true} /> We're sending your test
                  email now. It should arrive in your inbox soon.
                </p>
              </Trans>
            )}
            {testStatus === 'failed' && (
              <Trans t={t}>
                <p className={styles.failed}>
                  <FontAwesomeIcon icon={faCircleXmark} size={'lg'} />
                  We were unable to send your test email. Please try again soon. If this error
                  persists, try using a different Example Contact.
                </p>
              </Trans>
            )}
          </Fieldset>
        </CardContent>
        <CardActions>
          <ButtonValid
            disabled={testStatus === 'integrating' ? true : undefined}
            errors={validationErrors()}
            name={'send_test_email'}
            onClick={handleSendTestEmail}
          >
            {t('Send test email')}
          </ButtonValid>
        </CardActions>
      </CardV2>
    </Form>
  );
}

export default observer(PinpointEmailCampaignStepTest);
