import type { IBaseAttributes, IPresenceConstraint, TConstraints } from '@feathr/rachis';
import { Collection, Model } from '@feathr/rachis';

import { EIntegrationTypes } from './integrations';

export interface ITargeting extends IBaseAttributes {
  included: boolean;
  is_enabled: boolean;
  kind: string;
  name: string;
  parent: string;
  target_data?: string;
  integrations?: EIntegrationTypes[];
  /** Used to determine if a targeting should use AND (different group numbers) or OR (same group number) */
  group?: number;
}

export class Targeting extends Model<ITargeting> {
  public static readonly defaults: Partial<ITargeting> = {
    integrations: [EIntegrationTypes.Tradedesk],
  };
  public readonly className = 'Targeting';
  public get constraints(): TConstraints<ITargeting> {
    return {
      kind: {
        inclusion: [
          'email_list',
          'geo_audience',
          'geo',
          'geofence',
          'lookalike',
          'search',
          'segment',
        ],
      },
      target_data: {
        presence: (_, attributes) => {
          const { kind } = attributes;
          const config: IPresenceConstraint = { allowEmpty: false };
          switch (kind) {
            case 'segment':
              config.message =
                '^One of your targets does not have a group. Please choose or create a group.';
              break;

            case 'geo':
              config.message = '^One of your filters does not have a geographic area to filter on.';
              break;

            default:
              config.message = '^One of your targets is not fully configured.';
          }
          return config;
        },
      },
    };
  }

  public constructor(initialAttributes: Partial<ITargeting> = {}) {
    super(initialAttributes);
  }

  public override getDefaults(): Partial<ITargeting> {
    return Targeting.defaults;
  }
}

export class Targetings extends Collection<Targeting> {
  public getClassName(): string {
    return 'targetings';
  }

  public getModel(attributes: Partial<ITargeting>): Targeting {
    return new Targeting(attributes);
  }
}
