import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router';

import { FieldCollection, type Flavors, type Person } from '@feathr/blackbox';
import { Fieldset } from '@feathr/components';
import BreadcrumbsTimeline from '@feathr/extender/components/BreadcrumbsTimeline';
import { hiddenFlavors, useStore } from '@feathr/extender/state';
import { useInfiniteList } from '@feathr/rachis';

import Filters from './Filters';

import * as styles from './ActivityFeed.css';

export interface IActivityFeedProps {
  person: Person;
}

function ActivityFeed({ person }: Readonly<IActivityFeedProps>): JSX.Element {
  const { Breadcrumbs, Segments } = useStore();
  const [infiniteBreadcrumbsList, clearInfiniteList] = useInfiniteList(Breadcrumbs);
  const { segmentId } = useParams<{ segmentId: string }>();
  const [flavors, setFlavors] = useState<Array<keyof Flavors>>([]);
  const [filterMode, setFilterMode] = useState<'all' | 'group'>('all');

  const group = segmentId ? Segments.get(segmentId) : undefined;

  // Filter out hidden flavors
  let visibleFlavors: Array<keyof Flavors> = [];
  if (flavors.length) {
    visibleFlavors = flavors.filter((flavor) => !hiddenFlavors.includes(flavor));
  }

  const filtersAll = visibleFlavors.length
    ? { flvr__in: visibleFlavors }
    : { flvr__nin: hiddenFlavors };
  const filtersGroup = group
    ? {
        // Filter by visible flavors
        flvr__in: visibleFlavors.length ? visibleFlavors : undefined,
        // If there are no visible flavors, filter by excluded hidden flavors
        flvr__nin: !visibleFlavors.length ? hiddenFlavors : undefined,
        lookback_mode: group?.get('lookback_mode'),
        lookback_value: group?.get('lookback_value'),
        mode: group?.get('mode'),
        predicates: group?.get('predicates'),
      }
    : undefined;

  const breadcrumbs = infiniteBreadcrumbsList(
    {
      filters: filterMode === 'all' ? filtersAll : filtersGroup,
      pagination: {
        page: 0,
        page_size: 10,
      },
    },
    { url: Breadcrumbs.url('person', person.id) },
  );

  function handleFilterModeChange(mode?: 'all' | 'group'): void {
    setFilterMode(mode ?? 'all');
    clearInfiniteList();
  }

  function handleFlavorsChange(newFlavors?: Array<keyof Flavors>): void {
    if (newFlavors) {
      setFlavors(newFlavors);
    }
    clearInfiniteList();
  }

  function handleFlavorsClear(): void {
    setFlavors([]);
    clearInfiniteList();
  }

  function next(): void {
    breadcrumbs.fetchMore();
  }

  if (!breadcrumbs.isPending) {
    runInAction(() => {
      breadcrumbs.models.forEach((model) => model.set({ per_id: person.id }));
    });
  }

  return (
    <Fieldset className={styles.root} name={'activity'}>
      <Filters
        flavors={flavors}
        mode={filterMode}
        onFlavorsChange={handleFlavorsChange}
        onFlavorsClear={handleFlavorsClear}
        onModeChange={handleFilterModeChange}
        personId={person.id}
        showMode={!!segmentId}
      />
      <InfiniteScroll
        className={styles.infinite}
        dataLength={breadcrumbs.models.length}
        hasMore={breadcrumbs.pagination.page < breadcrumbs.pagination.pages - 1}
        // Don't use the built in loader.
        loader={<></>}
        next={next}
        scrollableTarget={'legacyScrollElement'}
      >
        <BreadcrumbsTimeline
          breadcrumbs={breadcrumbs.models}
          context={FieldCollection.Person}
          hasViewRawData={true}
          isLoading={breadcrumbs.isPending}
        />
      </InfiniteScroll>
    </Fieldset>
  );
}

export default observer(ActivityFeed);
