import { type IObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import type { DisplayCreative, Goal, Partner, Targeting, TTDCampaign } from '@feathr/blackbox';
import { CardV2 as Card, Form } from '@feathr/components';

import type { ICampaignValidationErrors } from '../../../CampaignSummary';
import CampaignSummary from '../../../CampaignSummary';
import TTDRecommendedOptimizationModal from '../TTDRecommendedOptimizationModal';

import * as styles from '../AdWizard.css';

interface IProps {
  campaign: TTDCampaign;
  targets: IObservableArray<Targeting>;
  goals: IObservableArray<Goal>;
  creatives: IObservableArray<DisplayCreative>;
  getValidationErrors: () => ICampaignValidationErrors;
}

export function validatePartner(partner: Partner): string[] {
  return partner.validate([], false).errors;
}

function AdWizardReviewStep({ campaign, getValidationErrors }: Readonly<IProps>): JSX.Element {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const additionalBudgetParam = Number(queryParams.get('additionalBudget'));

  const { isPastEndDate, isMonetization, isPublished } = campaign;
  const { t } = useTranslation();

  // Never prompt the user to increase budget if the campaign has reached its end date
  const showRecommendationsModal =
    !!additionalBudgetParam && !isPastEndDate && !isMonetization && isPublished;

  const errors = getValidationErrors();

  return (
    <Form className={styles.formRoot} label={'Edit Campaign: Review'}>
      {showRecommendationsModal && (
        <TTDRecommendedOptimizationModal campaign={campaign} validate={getValidationErrors} />
      )}
      <Card width={'narrow'}>
        <Card.Header
          description={t('Review the summary below to make sure everything is set up correctly.')}
          title={t('Review')}
        />
        <Card.Content>
          <CampaignSummary campaign={campaign} layout={'vertical'} validationErrors={errors} />
        </Card.Content>
      </Card>
    </Form>
  );
}

export default observer(AdWizardReviewStep);
