import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, CodeMirrorElement, CopyToClipboardButton } from '@feathr/components';
import { getFormEmbedCode } from '@feathr/extender/state';

import * as styles from './FormCode.css';

interface IProps {
  handleGenerateCode: () => Promise<void>;
  contentDomain?: string;
  shortCode?: string;
  formName?: string;
}

function FormCode({
  handleGenerateCode,
  contentDomain,
  shortCode,
  formName,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();

  if (!shortCode || !contentDomain || !formName) {
    return (
      <Button name={'generate-code'} onClick={handleGenerateCode} type={'primary'}>
        {t('Generate code')}
      </Button>
    );
  }

  const value = getFormEmbedCode(shortCode, contentDomain, formName);

  return (
    <>
      <CodeMirrorElement
        className={styles.code}
        name={'embed-code'}
        options={{ lineWrapping: true, readOnly: true }}
        value={value}
      />
      <CopyToClipboardButton
        className={styles.copy}
        name={'copy-embed-code'}
        t={t}
        text={value}
        type={'primary'}
      />
    </>
  );
}

export default FormCode;
