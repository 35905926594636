import { faArrowsRotate, faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ITemplate, PinpointEmailBaseCampaign, Template } from '@feathr/blackbox';
import { CampaignState, TemplateClass } from '@feathr/blackbox';
import {
  Button,
  EmailTemplateSelect as EmailTemplateSelectComponent,
  Icon,
} from '@feathr/components';
import { useStore } from '@feathr/extender/state';

import type { TTemplateSelectAndEditState } from '../TemplateSelectAndEditModal';
import TemplateSelectAndEditModal from '../TemplateSelectAndEditModal';

import * as styles from './EmailTemplateSelect.css';

interface IProps {
  campaign: PinpointEmailBaseCampaign;
  context: 'drip' | 'pinpoint';
  disabled?: boolean;
  /** Update template before saving */
  onPreChangeTemplate?: (template: Template, patch: Partial<ITemplate>) => Partial<ITemplate>;
  template?: Template;
}

function EmailTemplateSelect({
  campaign,
  context,
  disabled,
  onPreChangeTemplate,
  template: originalTemplate,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { Templates } = useStore();
  const [template, setTemplate] = useState(originalTemplate);
  const [thumbnailUrl, setThumbnailUrl] = useState<string | undefined>();

  const disclosure = useDisclosure();
  const [, { open: openTemplateModal }] = disclosure;

  const [initialState, setInitialState] = useState<TTemplateSelectAndEditState | undefined>();
  const isDraft = campaign.get('state') === CampaignState.Draft;

  useEffect(() => {
    setThumbnailUrl(
      isDraft && template ? template.get('thumbnail_url') + `?cb=${Date.now()}` : undefined,
    );
  }, [template, template?.isAttributeDirty('content')]);

  async function handleTemplateChange(templateId?: string): Promise<void> {
    if (!templateId) {
      // Remove template
      campaign.set({ template_id: undefined });
      return;
    }

    // Save changes to campaign before cloning template
    if (context === 'pinpoint') {
      await campaign.patchDirty();
    }

    // Duplicate template, attach to campaign, update name to match campaign
    const duplicatedTemplate = await Templates.clone(templateId, {
      campaign_id: campaign.get('parent_id') || campaign.id,
      event_id: campaign.get('event'),
      _cls: TemplateClass.PinpointEmail,
    });
    setTemplate(duplicatedTemplate);
    let templatePatch: Partial<ITemplate> = { name: campaign.get('name') };
    // Allow implementing components to modify template before it is saved
    if (onPreChangeTemplate) {
      templatePatch = onPreChangeTemplate(duplicatedTemplate, templatePatch);
    }
    duplicatedTemplate.set(templatePatch);
    await duplicatedTemplate.patchDirty();

    // Attach template to campaign
    await campaign.addTemplate(duplicatedTemplate);
  }

  // Open template modal for editing
  function handleEditTemplate(): void {
    setInitialState('editing');
    openTemplateModal();
  }

  // Open template modal for selecting a new template
  function handleChooseTemplate(): void {
    setInitialState('selecting');
    openTemplateModal();
  }

  return (
    <EmailTemplateSelectComponent
      description={
        template ? template?.get('preview_text') : t('Get started by selecting a template')
      }
      imageUrl={thumbnailUrl}
      isLoading={template?.isPending}
      previewUrl={template && !isDraft ? campaign.getTemplatePreviewUrl() : undefined}
      theme={'slate'}
      title={template === undefined ? t('No template selected') : template.name}
    >
      {template === undefined ? (
        <Button disabled={disabled} onClick={openTemplateModal}>
          {t('Select template')}
        </Button>
      ) : (
        <Group className={styles.actions} grow={true} justify={'space-between'}>
          <Button
            disabled={disabled}
            onClick={handleEditTemplate}
            prefix={<Icon icon={faPenToSquare} />}
          >
            {t('Edit content')}
          </Button>
          <Button
            disabled={disabled}
            onClick={handleChooseTemplate}
            prefix={<Icon icon={faArrowsRotate} />}
          >
            {t('Change template')}
          </Button>
        </Group>
      )}
      <TemplateSelectAndEditModal
        disclosure={disclosure}
        initialState={initialState}
        onChange={handleTemplateChange}
        templateClass={TemplateClass.PinpointEmail}
        templateId={template?.id}
      />
    </EmailTemplateSelectComponent>
  );
}

export default observer(EmailTemplateSelect);
