import { useState } from 'react';

export interface IWithRecommendedValue<T = number> {
  recommendedValue?: T;
}

interface IUseRecommendedValue {
  /** Only use recommendedValue if it exists AND the user hasn't selected anything yet */
  shouldUseRecommendedValue: boolean;
  /** Set to true when the user has selected an alternate value */
  setHasUserSelected: (hasUserSelected: boolean) => void;
  /** Whether the recommended value exists */
  hasRecommendation: boolean;
}

/** The useRecommendedValue hook allows the passing of a recommended value to populate the input with upon
 * its initial load (rather than displaying the one currently saved to the model). It also tracks of whether
 * the user has selected an alternate value to stop displaying the recommended value with `shouldUseRecommendedValue`.
 */
function useRecommendedValue<T = number>(recommendedValue?: T): IUseRecommendedValue {
  const [hasUserSelected, setHasUserSelected] = useState(false);
  const hasRecommendation = recommendedValue !== undefined;
  const shouldUseRecommendedValue = !hasUserSelected && hasRecommendation;

  return { shouldUseRecommendedValue, setHasUserSelected, hasRecommendation };
}

export default useRecommendedValue;
