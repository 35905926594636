import { faGlobe, faHistory, faStar } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Tooltip } from '@feathr/components';
import { useAccount, useLocalUrl, useUser } from '@feathr/extender/state';

import Switcher from '../Switcher';
import useAccountSwitcher from './useAccountSwitcher';

import * as styles from './AccountSwitcher.css';

interface IAccountSwitcherProps {
  className?: string;
}

type TToggle = 'favorites' | 'recent' | undefined;

export function determineActiveToggle(
  hasFavoritesFeature: boolean,
  favoriteAccounts: string[],
  recentAccounts: string[],
): TToggle {
  if (hasFavoritesFeature) {
    if (favoriteAccounts.length) {
      return 'favorites';
    }
    if (recentAccounts.length) {
      return 'recent';
    }
  }
  return undefined;
}

function AccountSwitcher({ className }: IAccountSwitcherProps): JSX.Element {
  const { t } = useTranslation();

  const account = useAccount();
  const user = useUser();
  const localUrl = useLocalUrl();

  const favoriteAccounts = user.getSetting('favorite_account_ids') || observable([] as string[]);
  const filteredFavoriteAccounts = favoriteAccounts.filter((value) => value !== account.id);

  const recentAccounts = user.get('recent_account_ids');
  const filteredRecentAccounts = recentAccounts.filter((value) => value !== account.id);

  const hasFavoritesFeature = user.isAdmin || user.isSudoer;

  const {
    accounts,
    allAccounts,
    isLoading,
    canSwitchAccounts,
    toggle,
    setToggle,
    search,
    setSearch,
    setSearchInstantly,
    inputValue,
    setInputValue,
  } = useAccountSwitcher({
    accountId: account.id,
    favoriteAccountIds: favoriteAccounts,
    recentAccountIds: recentAccounts,
  });

  useEffect(() => {
    setToggle(
      determineActiveToggle(hasFavoritesFeature, filteredFavoriteAccounts, filteredRecentAccounts),
    );
  }, [favoriteAccounts]);

  const url = new URL(window.location.href);

  const buttonFavorites = (
    <Tooltip
      key={'favorites'}
      title={filteredFavoriteAccounts.length ? 'Favorites' : 'Add some favorite accounts'}
    >
      <Button
        className={styles.iconButton}
        disabled={!filteredFavoriteAccounts.length}
        onClick={() => {
          setToggle('favorites');
          setInputValue(undefined);
          setSearchInstantly(undefined);
        }}
        type={toggle === 'favorites' && !search ? 'clicked' : 'icon-outlined'}
      >
        <FontAwesomeIcon icon={faStar} />
      </Button>
    </Tooltip>
  );
  const buttonRecent = (
    <Tooltip key={'recent'} title={'Recent'}>
      <Button
        className={styles.iconButton}
        onClick={() => {
          setToggle('recent');
          setInputValue(undefined);
          setSearchInstantly(undefined);
        }}
        type={toggle === 'recent' && !search ? 'clicked' : 'icon-outlined'}
      >
        <FontAwesomeIcon icon={faHistory} />
      </Button>
    </Tooltip>
  );
  const buttonAll = (
    <Tooltip key={'all'} title={'All'}>
      <Button
        className={styles.iconButton}
        onClick={() => {
          setToggle(undefined);
          setInputValue(undefined);
          setSearchInstantly(undefined);
        }}
        type={toggle === undefined && !search ? 'clicked' : 'icon-outlined'}
      >
        <FontAwesomeIcon icon={faGlobe} />
      </Button>
    </Tooltip>
  );

  const buttons =
    hasFavoritesFeature && canSwitchAccounts
      ? [buttonFavorites, buttonRecent, buttonAll]
      : [buttonRecent, buttonAll];

  return (
    <Switcher
      buttons={buttons}
      className={className}
      count={isLoading ? undefined : allAccounts.models.length}
      currentItem={{ id: account.id, logo: account.get('logo'), name: account.name }}
      hasMore={toggle === undefined && !accounts.isPending && accounts.models.length > 0}
      isLoading={isLoading || accounts.isPending}
      items={accounts.models.map((a) => {
        const pathParts = url.pathname.split('/');
        pathParts.splice(1, 1, a.id);
        return {
          id: a.id,
          logo: a.get('logo'),
          name: a.name,
          url: `${pathParts.slice(0, 3).join('/')}${
            pathParts.length <= 3 ? `${url.search}${url.hash}` : ''
          }`,
        };
      })}
      // Use href instead of link to bypass react router to enforce a complete refresh.
      linkType={'href'}
      placeholder={t('Find an account...')}
      search={inputValue}
      setSearch={(newValue) => {
        setInputValue(newValue);
        setSearch(newValue);
      }}
      suffix={
        hasFavoritesFeature ? (
          <Button link={localUrl('/accounts')} type={'link'}>
            {t('View all accounts')}
          </Button>
        ) : undefined
      }
    />
  );
}

export default observer(AccountSwitcher);
