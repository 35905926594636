import type { Active, DragOverEvent, Over, UniqueIdentifier } from '@dnd-kit/core';

import type { IFormConfig, IListRowItem, IRowItem } from '@feathr/blackbox';

interface IBaseProps {
  availableFields: IRowItem[];
  setAvailableFields: (fields: IRowItem[]) => void;
  usedFields: IRowItem[];
  setUsedFields: (fields: IRowItem[]) => void;
}

export interface IDragOverEvent extends DragOverEvent {
  active: Active & {
    data: {
      current: {
        sortable: {
          containerId: 'form-builder' | 'configuration-panel';
        };
      };
    };
  };
  over: Over & {
    data: {
      current: {
        containerId: 'form-builder' | 'configuration-panel';
      };
    };
  };
}

// Props for the overall onDragOver handler
interface IHandlerProps extends IBaseProps {
  over: Pick<IDragOverEvent['over'], 'id' | 'data'>;
  active: Pick<IDragOverEvent['active'], 'id' | 'data'>;
}

// Props for container-specific drop handlers
interface IDropHandlerProps extends IBaseProps {
  activeId: UniqueIdentifier;
  overId: UniqueIdentifier;
  initialContainer: string;
}

function handleDropInBuilder({
  initialContainer,
  overId,
  activeId,
  availableFields,
  setAvailableFields,
  usedFields,
  setUsedFields,
}: IDropHandlerProps): void {
  const overIndex = usedFields.findIndex((field) => field?.id === overId);

  if (usedFields.length >= 1 && !overId) {
    return;
  }

  // If the builder is empty, add the first field to the builder
  if (usedFields.length <= 0) {
    setUsedFields([availableFields.find((field) => field.id === activeId)!]);
    setAvailableFields(availableFields.filter((field) => field.id !== activeId));
    return;
  }

  // From configuration panel to builder
  if (initialContainer === 'configuration-panel') {
    const newBuilderFields = [
      ...usedFields,
      availableFields.find((field) => field.id === activeId),
    ].filter((field) => field !== undefined) as IRowItem[];
    setUsedFields(newBuilderFields);
    setAvailableFields(availableFields.filter((field) => field?.id !== activeId));
  }

  // From builder to builder
  if (initialContainer === 'form-builder') {
    const currentIndex = usedFields.findIndex((field) => field.id === activeId);
    const newBuilderFields = [...usedFields];

    // Remove the active field from its original position
    newBuilderFields.splice(currentIndex, 1);

    // Insert the active field at the new position
    newBuilderFields.splice(overIndex, 0, usedFields[currentIndex]);
    setUsedFields(newBuilderFields);
  }
}

function handleDropInConfigurationPanel({
  availableFields,
  setAvailableFields,
  usedFields,
  setUsedFields,
  initialContainer,
  overId,
  activeId,
}: IDropHandlerProps): void {
  const overIndex = availableFields.findIndex((field) => field.id === overId);

  // From builder to configuration panel
  if (initialContainer === 'form-builder') {
    // Prevents email being removed from form.
    if (activeId === 'email') {
      return;
    }

    const newAvailableFields = [
      ...availableFields.slice(0, overIndex),
      usedFields.find((field) => field.id === activeId)!,
      ...availableFields.slice(overIndex),
    ].filter((field) => field !== undefined) as IRowItem[];
    setAvailableFields(newAvailableFields);
    setUsedFields(usedFields.filter((field) => field.id !== activeId));
  }

  // From configuration panel to configuration panel
  if (initialContainer === 'configuration-panel') {
    const currentIndex = availableFields.findIndex((field) => field.id === activeId);

    const newAvailableFields = [...availableFields];

    // Remove the active field from its original position
    newAvailableFields.splice(currentIndex, 1);

    // Insert the active field at the new position
    newAvailableFields.splice(overIndex, 0, availableFields[currentIndex]);

    setAvailableFields(newAvailableFields.filter((field) => field !== undefined) as IRowItem[]);
  }
}

export function onDragOver({
  availableFields,
  setAvailableFields,
  usedFields,
  setUsedFields,
  over,
  active,
}: IHandlerProps): void {
  if (!active || !active?.id || !over || !over?.id) {
    return;
  }

  const {
    id: activeId,
    data: {
      current: {
        sortable: { containerId: initialContainer },
      },
    },
  } = active;

  const {
    id: overId,
    data: {
      current: {
        containerId: currentContainer = ['form-builder', 'configuration-panel'].includes(
          overId as string,
        )
          ? overId
          : undefined,
      } = { current: { containerId: overId } },
    },
  } = over;

  // Sortable items use an abstraction of droppable and draggable, so ignore on initial click
  if (overId === activeId) {
    return;
  }

  if (currentContainer === 'form-builder') {
    handleDropInBuilder({
      initialContainer,
      overId,
      activeId,
      availableFields,
      setAvailableFields,
      usedFields,
      setUsedFields,
    });
    return;
  }

  // Configuration panel
  if (currentContainer === 'configuration-panel') {
    handleDropInConfigurationPanel({
      initialContainer,
      overId,
      activeId,
      availableFields,
      setAvailableFields,
      usedFields,
      setUsedFields,
    });
    return;
  }
}

export function getListFieldOptions({
  config,
  fieldId,
}: {
  config: IFormConfig;
  fieldId: string;
}): string[] | undefined {
  const listFields = config.rows.flatMap((row) =>
    row.fields.filter((field) => field.type === 'list'),
  ) as IListRowItem[];
  const existingOptions = listFields.find(({ id }) => id === fieldId)?.options;
  return existingOptions ?? [];
}
