import { runInAction, set } from 'mobx';
import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { TTDCampaign } from '@feathr/blackbox';
import { NumberInput } from '@feathr/components';
import { useRecommendedValue } from '@feathr/extender/App/EventsPage/CampaignEditPage/AdWizard';

import RecommendationModal from './RecommendationModal';

interface IProps {
  campaign: TTDCampaign;
  errors: string[];
  suppressAlert?: boolean;
}

function IncreaseBudget({
  campaign,
  errors = [],
  suppressAlert = false,
}: Readonly<IProps>): JSX.Element {
  // TODO: pull the recommended value from the BE
  const recommendedValue = 25;

  const { t } = useTranslation();
  const { shouldUseRecommendedValue, setHasUserSelected } = useRecommendedValue(recommendedValue);
  const initialBudget = campaign.initialAttributes.exposure_settings?.target_value ?? 0;
  const initialBudgetFormatted = numeral(initialBudget).format('$0');

  const currentBudget = shouldUseRecommendedValue
    ? recommendedValue
    : campaign.get('exposure_settings').target_value ?? 0;
  const recommendedValueFormatted = numeral(recommendedValue).format('$0');

  function handleTargetValueChange(newValue?: number): void {
    if (!newValue) {
      return;
    }

    const exposureSettings = campaign.get('exposure_settings');
    if (exposureSettings) {
      runInAction((): void => {
        set(exposureSettings, {
          target_value: newValue,
        });
      });
      campaign.setAttributeDirty('exposure_settings');
    }
    setHasUserSelected(true);
  }

  const alert = t('Increase budget from {{before}} to {{after}}', {
    before: initialBudgetFormatted,
    after: recommendedValueFormatted,
  });
  const helpText = t('Original budget: {{before}}', {
    before: initialBudgetFormatted,
  });

  return (
    <>
      {!suppressAlert && <RecommendationModal.Alert description={alert} />}
      {/* TODO: consider a reusable component for this in the wizard that is extended here */}
      <NumberInput
        // TODO: left align the input value when this becomes reusable
        helpPlacement={'bottom'}
        helpText={helpText}
        label={'Budget'}
        min={recommendedValue}
        name={'budget'}
        onChange={handleTargetValueChange}
        prefix={'$'}
        validationError={errors}
        value={currentBudget}
      />
    </>
  );
}

export default observer(IncreaseBudget);
