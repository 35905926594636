import classNames from 'classnames';
import type { IObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type {
  Campaign,
  Goal as GoalModel,
  Segment,
  Segments as SegmentsCollection,
} from '@feathr/blackbox';
import { CampaignClass } from '@feathr/blackbox';
import {
  Button,
  ButtonValid,
  CardContent,
  CardHeader,
  CardV2 as Card,
  Form,
} from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import { flattenErrors, hasDuplicates } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import AddGoals from './AddGoals';

import * as styles from '../AdWizard/AdWizard.css';

interface IButtonProps {
  goals: IObservableArray<GoalModel>;
  onNext?: () => void;
}

interface IProps extends IButtonProps {
  onPrev?: () => void;
  campaign: Campaign;
  disabled?: boolean;
}

export interface IStepGoalsErrors extends TValidateGrouped {
  goals?: string[];
}

const maxGoalError = `You may not assign more than five goals to a single campaign.
 If you need to capture many different activities as conversions, consider
 using the Advanced conversion tracking mode`;

// TODO: move into a separate utils file (StepGoals.utils.ts)
export function validateStepGoals(
  goals: IObservableArray<GoalModel>,
  goalSegments?: Segment[],
): IStepGoalsErrors {
  if (goals.filter((g) => !g.get('is_archived')).length === 0) {
    return { goals: [] };
  }

  if (goals.filter((g) => !g.get('is_archived')).length >= 6) {
    return { goals: [maxGoalError] };
  }

  const errorMessages: IStepGoalsErrors = { goals: [] };
  errorMessages.goals = goals
    .filter((g) => !g.get('is_archived'))
    .map((g) => {
      const errors = g.validate(['segment', 'conv_value'], false).errors;
      if (errors.length > 0) {
        return errors[0];
      }
      return undefined;
    })
    .filter((msg) => !!msg) as string[];
  if (goalSegments) {
    const segmentIds: string[] = [];
    errorMessages.goals = errorMessages.goals.concat(
      goalSegments
        .map((g) => {
          segmentIds.push(g.id);
          const errors = g.validate([], false).errors;
          if (!(g.get('predicates', []) || []).every((p) => p.kind !== 'attribute')) {
            errors.push('Groups with filters on person attributes cannot be used as goal groups.');
          }
          if (errors.length > 0) {
            return errors[0];
          }

          return undefined;
        })
        .filter((msg) => !!msg) as string[],
    );
    if (hasDuplicates(segmentIds)) {
      errorMessages.goals = errorMessages.goals.concat(
        'Must not use the same group for additional goals.',
      );
    }
  }

  return errorMessages;
}

// TODO: move into a separate utils file (StepGoals.utils.ts)
export function getGoalSegments(
  goals: IObservableArray<GoalModel>,
  Segments: SegmentsCollection,
): Segment[] {
  return goals
    .filter((g) => !!g.get('segment') && !g.get('is_archived'))
    .map((g) => Segments.get(g.get('segment')!));
}

const NextStepButton = observer(({ goals, onNext }: IButtonProps): JSX.Element => {
  const { t } = useTranslation();
  const { Segments } = useStore();
  const segments = getGoalSegments(goals, Segments);
  const validationErrors = validateStepGoals(goals, segments);
  return (
    <ButtonValid errors={flattenErrors(validationErrors)} name={'next_step'} onClick={onNext}>
      {t('Next')}
    </ButtonValid>
  );
});

function StepGoals({ campaign, disabled = false, goals, onNext, onPrev }: IProps): JSX.Element {
  const { t } = useTranslation();
  const { isTTDCampaign } = campaign;

  const isDrip = campaign.get('_cls') === CampaignClass.Drip;

  const actions = !isDrip
    ? [
        <Button key={'prev'} name={'previous_step'} onClick={onPrev}>
          {t('Previous')}
        </Button>,
        <NextStepButton goals={goals} key={'next'} onNext={onNext} />,
      ]
    : [];

  const goalsDescription = (
    <Trans t={t}>
      <p>
        Select a goal for this campaign. In Goal mode, track conversions when individuals who engage
        with this campaign join a group. In Advanced mode, track conversions using a Conversion
        Pixel to monitor people interacting with the campaign.
      </p>
      <p>
        <a
          href={'https://help.feathr.co/hc/en-us/articles/360047216094-What-is-a-Goal'}
          target={'_blank'}
        >
          Learn more about goal tracking
        </a>
      </p>
    </Trans>
  );

  return (
    <Form
      // TODO: Remove this when we horizontal wizard the campaign types that also utilize this step
      actions={!isTTDCampaign && actions}
      className={classNames({ [styles.formRoot]: isTTDCampaign })}
      label={t('Edit Campaign: Goals')}
      width={'wide'}
    >
      <Card name={'goals-card'} width={'wide'}>
        <CardHeader description={goalsDescription} title={t('Set Goals')} />
        <CardContent addVerticalGap={true}>
          <AddGoals campaign={campaign} disabled={disabled} goals={goals} />
        </CardContent>
      </Card>
    </Form>
  );
}

export default observer(StepGoals);
