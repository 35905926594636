import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { Form, IForm } from '@feathr/blackbox';
import { CardV2 as Card, Checkbox, EmailInput } from '@feathr/components';
import { flattenError } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

interface IProps {
  form: Form;
}

function validateFormSubmissionEmails(form: Form): TValidateGrouped {
  return form.validate(['notification_settings.email_addresses'], false, 'grouped').errors ?? [];
}

function SubmissionEmailsCard({ form }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const [emails, setEmails] = useState<string>();
  const [emailInputHadFocus, setEmailInputHadFocus] = useState<boolean>(false);

  const notificationSettings = form.get('notification_settings');
  const shouldEmail = notificationSettings.should_email;
  const emailAddresses = notificationSettings.email_addresses ?? [];

  const validationErrors = validateFormSubmissionEmails(form);

  // On render, load the email addresses into the input field
  useEffect(() => {
    setEmails(emailAddresses.join(', '));
  }, []);

  // If shouldEmail is false, clear the email input field
  useEffect(() => {
    if (!shouldEmail) {
      setEmailInputHadFocus(false);
    }
  }, [shouldEmail]);

  function handleOnChange(newValue?: string): void {
    // Do not show error messages while the input field is focused
    setEmailInputHadFocus(false);
    const emailList = newValue?.split(',').map((email) => email.trim()) ?? [];
    if (emailList.length <= 5) {
      form.set({
        notification_settings: {
          ...notificationSettings,
          email_addresses: emailList,
        },
      });
      setEmails(newValue);
    }
  }

  function handleBlur(): void {
    setEmailInputHadFocus(true);
  }

  const validationError = emailInputHadFocus
    ? flattenError(validationErrors['notification_settings.email_addresses'])
    : undefined;

  return (
    <Card>
      <Card.Header
        description={t('Choose how you want to stay up to date with new activity')}
        title={t('Submission Emails')}
      />
      <Card.Content addVerticalGap={true}>
        <Checkbox<IForm>
          attribute={['notification_settings', 'should_email']}
          label={t('Send email notifications upon submission of a form')}
          model={form}
        />
        <EmailInput
          allowMultiple={true}
          disabled={!shouldEmail}
          helpText={t('Enter up to 5 email addresses separated by commas')}
          label={t('Email addresses')}
          onBlur={handleBlur}
          onChange={handleOnChange}
          required={shouldEmail}
          t={t}
          type={'email'}
          validationError={validationError}
          value={emails}
        />
      </Card.Content>
    </Card>
  );
}

export default observer(SubmissionEmailsCard);
