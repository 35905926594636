import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { Breadcrumb, FlavorKeys, IBreadcrumb } from '@feathr/blackbox';
import { FieldCollection } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { Button, TableColumnHeader } from '@feathr/components';
import { DateCell } from '@feathr/extender/App/DataPage/DataTableCells';
import DeviceChip from '@feathr/extender/components/DeviceChip';
import FlavorChip from '@feathr/extender/components/FlavorChip';
import MarkerMap from '@feathr/extender/components/MarkerMap';
import { useLocalUrl, useStore } from '@feathr/extender/state';
import { TimeFormat } from '@feathr/hooks';

import BreadcrumbDetailsButton from './BreadcrumbDetailsButton';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';
import * as styles from '@feathr/extender/App/DataPage/BreadcrumbColumns/BreadcrumbColumns.css';

const columns: Array<IColumn<Breadcrumb>> = [
  {
    id: 'd_c',
    accessor: (original: Breadcrumb) => original.get('d_c'),
    Header: TableColumnHeader({
      sortType: 'numeric',
      title: 'Date/Time',
    }),
    headerClassName: tableStyles.sort,
    sortable: true,
    className: tableStyles.cell,
    Cell: DateCell('d_c', undefined, TimeFormat.shortDateTime),
  },
  {
    id: 'p_id',
    Header: TableColumnHeader({
      title: 'Partner',
    }),
    sortable: false,
    className: tableStyles.cell,
    Cell({ original }): JSX.Element {
      return (
        <Observer>
          {function useAnonymousFunction(): JSX.Element {
            const localUrl = useLocalUrl();
            const { Partners } = useStore();

            const partnerId = original.get('p_id');
            const partner = partnerId ? Partners.get(partnerId) : undefined;

            if (!partner || partner.isPending) {
              return <span>-</span>;
            }

            return (
              <Button link={localUrl(partner.getItemUrl())} type={'link'}>
                {partner.get('name')}
              </Button>
            );
          }}
        </Observer>
      );
    },
  },
  {
    id: 'flvr',
    Header: TableColumnHeader({
      title: 'Flavor',
    }),
    sortable: false,
    Cell({ original }): JSX.Element {
      return (
        <Observer>
          {function useAnonymousFunction(): JSX.Element {
            return <FlavorChip flavor={original.get('flvr') as FlavorKeys} />;
          }}
        </Observer>
      );
    },
  },
  {
    id: 'location',
    Header: TableColumnHeader({
      title: 'Location',
    }),
    sortable: false,
    width: 122,
    className: tableStyles.cellCenter,
    Cell({ original }): JSX.Element {
      return (
        <Observer>
          {function useAnonymousFunction(): JSX.Element {
            const loc = original.get('loc') || ({} as IBreadcrumb['loc']);
            return <MarkerMap className={styles.map} lat={loc.latitude} lng={loc.longitude} />;
          }}
        </Observer>
      );
    },
  },
  {
    id: 'device',
    Header: TableColumnHeader({
      title: 'Device',
    }),
    sortable: false,
    width: 100,
    Cell({ original }): JSX.Element {
      return <DeviceChip breadcrumb={original} />;
    },
  },
  {
    id: 'details',
    Header: TableColumnHeader({
      title: 'Details',
    }),
    sortable: false,
    width: 80,
    className: tableStyles.cellCenter,
    Cell({ original }): JSX.Element {
      return (
        <BreadcrumbDetailsButton
          breadcrumb={original}
          context={FieldCollection.Partner}
          hasViewRawData={false}
        />
      );
    },
  },
];

export default columns;
