import { when } from 'mobx';
import { useEffect, useState } from 'react';

import type { Account } from '@feathr/blackbox';
import { useRole, useStore } from '@feathr/extender/state';
import { useDebounce } from '@feathr/hooks';
import type { Attributes, IListParams, ListResponse } from '@feathr/rachis';

type TToggle = 'favorites' | 'recent' | undefined;

interface IUseAccountSwitcher {
  /** The ID of the account the user is presently using, e.g. `Feathr Playground` is `5589c9c30a6540a7a33d3838`. */
  accountId: string;
  favoriteAccountIds?: string[];
  recentAccountIds?: string[];
}

interface IUseAccountSwitcherReturn {
  /** ListResponse of Accounts the requesting user can access based on role or presence in the account. */
  accounts: ListResponse<Account>;
  allAccounts: ListResponse<Account>;
  isLoading: boolean;
  canSwitchAccounts: boolean;
  toggle: TToggle;
  setToggle: (toggle: TToggle) => void;
  search: string | undefined;
  setSearch: (value: string | undefined) => void;
  setSearchInstantly: (value: string | undefined) => void;
  inputValue: string | undefined;
  setInputValue: (value: string | undefined) => void;
}

function getBaseListParams(accountId: string): IListParams<Attributes<Account>> {
  return {
    filters: {
      id__ne: accountId,
      is_archived__ne: true,
    },
    only: ['id', 'name', 'logo'],
    pagination: {
      page_size: 10,
    },
    ordering: ['name'],
  };
}

function useAccountSwitcher({
  accountId,
  favoriteAccountIds,
  recentAccountIds,
}: Readonly<IUseAccountSwitcher>): IUseAccountSwitcherReturn {
  const { Accounts } = useStore();
  const { isSudoer } = useRole();
  const [allAccounts, setAllAccounts] = useState<ListResponse<Account>>(Accounts.newListResponse());
  const [accounts, setAccounts] = useState<ListResponse<Account>>(Accounts.newListResponse());
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [canSwitchAccounts, setCanSwitchAccounts] = useState<boolean>(false);

  const [toggle, setToggle] = useState<TToggle>(undefined);
  const [inputValue, setInputValue] = useState<string | undefined>();
  const [search, setSearch, setSearchInstantly] = useDebounce<string | undefined>();

  useEffect(() => {
    async function fetchAllAccounts(): Promise<void> {
      const listParams = getBaseListParams(accountId);
      const listOfAccounts = Accounts.list(listParams);
      await when(() => listOfAccounts.isLoaded);

      setAllAccounts(listOfAccounts);
      setIsLoading(listOfAccounts.isPending);

      // Determine if the user can switch accounts
      setCanSwitchAccounts(
        // We check `isSudoer` here because as an added layer of security. The backend also checks if the user is a sudoer and filters the response accordingly.
        listOfAccounts.isLoaded && (listOfAccounts.models.length > 0 || isSudoer),
      );
    }

    fetchAllAccounts();
  }, [accountId, Accounts, isSudoer]);

  useEffect(() => {
    const listParams = getBaseListParams(accountId);
    let filteredAccounts: ListResponse<Account>;

    if (toggle === 'recent' && !recentAccountIds?.length) {
      // Displaying recent, but there are none.
      filteredAccounts = Accounts.newListResponse();
    } else if (
      search ||
      (toggle === 'favorites' && favoriteAccountIds?.length) ||
      toggle === 'recent'
    ) {
      // Displaying filtered results.
      if (search) {
        listParams.filters!.name__icontains = search;
      } else if (toggle === 'favorites') {
        listParams.filters!.id__in = favoriteAccountIds;
      } else if (toggle === 'recent') {
        listParams.filters!.id__in = recentAccountIds;
        delete listParams.ordering;
      }

      filteredAccounts = Accounts.list(listParams);
    } else {
      // Displaying all results.
      filteredAccounts = allAccounts;
    }

    setAccounts(filteredAccounts);
  }, [toggle, search, accountId]);

  // Update search state when inputValue changes
  useEffect(() => {
    setSearch(inputValue);
  }, [inputValue, setSearch]);

  return {
    allAccounts,
    accounts,
    isLoading,
    canSwitchAccounts,
    toggle,
    setToggle,
    search,
    setSearch,
    setSearchInstantly,
    inputValue,
    setInputValue,
  };
}

export default useAccountSwitcher;
