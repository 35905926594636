import type { JSX } from 'react';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import type { IBreadcrumbItem } from '@feathr/components';
import { Breadcrumbs as BreadcrumbsComponent, Time } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';
import { stringifyBreadcrumbs, TimeFormat } from '@feathr/hooks';

import { label as getLabel, path as getPath } from './routes';
import type { IMatchProps } from './routes/routes';
import { getRoute } from './routes/routes';

interface IProps {
  className?: string;
  includeCurrentPage?: boolean;
}

function Breadcrumbs({ className, includeCurrentPage = true }: IProps): JSX.Element | null {
  const localUrl = useLocalUrl();

  const match = useRouteMatch<{
    accountId: string;
    primary: string;
    secondary?: string;
    tertiary?: string;
    quaternary?: string;
    quinary?: string;
    senary?: string;
    septenary?: string;
    octonary?: string;
  }>({
    path: '/:accountId/:primary/:secondary?/:tertiary?/:quaternary?/:quinary?/:senary?/:septenary?/:octonary?',
  });

  if (!match) {
    return null;
  }

  function prepareBreadcrumbData(
    matches: IMatchProps,
    key: keyof IMatchProps,
    depth: number,
  ): IBreadcrumbItem | null {
    const matchParam = matches[key];
    if (!matchParam) {
      return null;
    }

    const item = getRoute(matches, depth);
    const label = item ? getLabel(item, matchParam, matches) : null;

    if (!item || !label) {
      return null;
    }

    return {
      key,
      label,
      path: localUrl(getPath(item, depth, matches)),
      depth,
    };
  }

  // Build all breadcrumb data items
  const breadcrumbItems = [
    prepareBreadcrumbData(match.params, 'accountId', 0),
    prepareBreadcrumbData(match.params, 'primary', 1),
    prepareBreadcrumbData(match.params, 'secondary', 2),
    prepareBreadcrumbData(match.params, 'tertiary', 3),
    prepareBreadcrumbData(match.params, 'quaternary', 4),
    prepareBreadcrumbData(match.params, 'quinary', 5),
    prepareBreadcrumbData(match.params, 'senary', 6),
    prepareBreadcrumbData(match.params, 'septenary', 7),
    prepareBreadcrumbData(match.params, 'octonary', 8),
  ].filter((item): item is NonNullable<typeof item> => Boolean(item));

  // Grab url segments and filter out undefined ones
  const urlSegments = Object.entries(match.params).filter((item) => !!item[1]);
  const appCuesStringifiedCrumbs = stringifyBreadcrumbs(urlSegments);

  // Show date if the page is the account dashboard.
  const date =
    match.params.primary === 'dashboard' ? (
      <Time format={TimeFormat.longWeekdayDate} timestamp={new Date().toISOString()} />
    ) : null;

  return (
    <BreadcrumbsComponent
      appCuesTrigger={appCuesStringifiedCrumbs}
      breadcrumbItems={breadcrumbItems}
      className={className}
      date={date}
      includeCurrentPage={includeCurrentPage}
    />
  );
}

export default Breadcrumbs;
