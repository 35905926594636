import type { TFunction } from 'i18next';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Page from '@feathr/extender/App/Page';
import { useUser } from '@feathr/extender/state';

import AdPerformance from './AdPerformance';
import AudienceEngagement from './AudienceEngagement';
import EmailPerformance from './EmailPerformance';

export function getGreeting(currentHour: number, name: string, t: TFunction): string {
  let timeOfDay: string;
  // 12am - 11:59pm
  if (currentHour >= 1 && currentHour < 12) {
    timeOfDay = t('morning');
    // 12pm - 4:59pm
  } else if (currentHour >= 12 && currentHour < 17) {
    timeOfDay = t('afternoon');
  } else {
    timeOfDay = t('evening');
  }

  if (name === 'Unnamed User') {
    return t('👋 Good {{timeOfDay}}!', { timeOfDay });
  }

  return t('👋 Good {{timeOfDay}}, {{name}}!', { timeOfDay, name });
}

function DashboardPage(): JSX.Element {
  const { t } = useTranslation();
  const { name } = useUser();

  const currentHour = new Date().getHours();
  const greeting = getGreeting(currentHour, name, t);

  return (
    <Page collapseHeader={false} pageTitle={t('Dashboard')} title={greeting}>
      <AudienceEngagement />
      <EmailPerformance />
      <AdPerformance />
    </Page>
  );
}

export default observer(DashboardPage);
