import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { TTDCampaign } from '@feathr/blackbox';
import CrossDeviceTargetingToggle from '@feathr/extender/App/EventsPage/CampaignEditPage/AdWizard/AdWizardOptimizationStep/CrossDeviceTargetingToggle';

import RecommendationModal from './RecommendationModal';

interface IProps {
  campaign: TTDCampaign;
  errors?: string[];
}

function EnableOmnitargeting({ campaign }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const alert = t('Enable Omnitargeting to improve campaign performance');

  return (
    <>
      <RecommendationModal.Alert description={alert} />
      <CrossDeviceTargetingToggle campaign={campaign} recommendedValue={10} />
    </>
  );
}

export default observer(EnableOmnitargeting);
