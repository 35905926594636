import { Stack } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { TTDCampaign } from '@feathr/blackbox';
import { AlertV2 as Alert, Button, EAlertV2Type as EAlertType, Modal } from '@feathr/components';
import { useCampaignValidation } from '@feathr/extender/App/EventsPage/CampaignEditPage/AdWizard';
import SaveCampaignButton from '@feathr/extender/App/EventsPage/CampaignEditPage/SaveCampaignButton';

import * as styles from './RecommendationModal.css';

interface IProps {
  campaign: TTDCampaign;
  children: ReactNode;
  onClose: () => void;
  opened: boolean;
  // pick from the modal props
  size?: 'default' | 'lg' | 'md';
}

function RecommendationAlert({
  description,
}: Readonly<{ description: string | string[] }>): JSX.Element {
  const { t } = useTranslation();

  return (
    <Alert
      className={styles.alert}
      description={description}
      title={t('Based on Feathr Intelligence')}
      type={EAlertType.recommendation}
    />
  );
}

function RecommendationModal({
  campaign,
  onClose,
  opened,
  children,
  size = 'default',
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { childModels, grandchildModels, getValidationErrors } = useCampaignValidation({
    campaign,
  });

  async function handleClose(): Promise<void> {
    // Await the reloading of the campaign details so there aren't weird content shifts
    await campaign.reload();
    onClose();
  }

  return (
    <Modal
      closeOnClickOutside={false}
      description={t(
        'These intelligent recommendations may increase the performance of this campaign.',
      )}
      onClose={handleClose}
      opened={opened}
      rightActions={
        <>
          <Button onClick={handleClose} type={'secondary'}>
            {t('Cancel')}
          </Button>
          <SaveCampaignButton
            campaign={campaign}
            childModels={childModels}
            grandchildModels={grandchildModels}
            postSave={handleClose}
            showIcon={true}
            type={'primary'}
            validate={getValidationErrors}
          />
        </>
      }
      size={size}
      title={t('Recommended campaign optimization')}
    >
      <Stack>{children}</Stack>
    </Modal>
  );
}

RecommendationModal.Alert = RecommendationAlert;

export default observer(RecommendationModal);
