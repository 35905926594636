import throttle from 'lodash/throttle';
import type React from 'react';
import { useEffect, useRef, useState } from 'react';

export interface IUseSCrollAndCollapseProps {
  collapseHeaderOnScroll?: boolean;
}

export interface IUseSCrollAndCollapseReturn {
  shouldCollapseHeader?: boolean;
  scrolled?: boolean;
  scrollContainerRef: React.RefObject<HTMLDivElement> | null;
}

const COLLAPSE_THRESHOLD = 10;
const EXPAND_THRESHOLD = 1;

function useScrollAndCollapse({
  collapseHeaderOnScroll,
}: Readonly<IUseSCrollAndCollapseProps>): IUseSCrollAndCollapseReturn {
  const [isHeaderCollapsed, setIsHeaderCollapsed] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const scrollContainerRef = useRef<null | HTMLDivElement>(null);
  const scrollTimeout = useRef<number | null>(null);
  const isAdjustingScroll = useRef(false);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleCheckIfScrolled);

      // Only add the handler if header should not collapse on scroll
      if (collapseHeaderOnScroll) {
        scrollContainer.addEventListener('scroll', handleCollapseOnScroll);
      }
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleCheckIfScrolled);
        scrollContainer.removeEventListener('scroll', handleCollapseOnScroll);
      }
      // Clean up timeout on unmount
      if (scrollTimeout.current) {
        window.clearTimeout(scrollTimeout.current);
      }
    };
  }, []);

  const handleCheckIfScrolled = throttle(() => {
    if (scrollContainerRef?.current) {
      const currentScroll = scrollContainerRef.current.scrollTop;
      setScrolled(currentScroll > 0);
    }
  }, 100);

  /**
   * Handles the scroll event with throttling to optimize performance.
   * @param event - The scroll event object.
   */
  const handleCollapseOnScroll = throttle(() => {
    if (!scrollContainerRef.current || isAdjustingScroll.current) {
      return;
    }

    const currentScroll = scrollContainerRef.current.scrollTop;

    if (scrollTimeout.current) {
      window.clearTimeout(scrollTimeout.current);
    }

    scrollTimeout.current = window.setTimeout(() => {
      setIsHeaderCollapsed((prev) => {
        // If already collapsed and not at the very top, maintain state
        if (prev && currentScroll <= EXPAND_THRESHOLD && currentScroll !== 0) {
          return prev;
        }

        // Expand header when scrolled to the very top
        if (prev && currentScroll === 0) {
          return false;
        }

        // Collapse header when scrolling past threshold
        if (!prev && currentScroll >= COLLAPSE_THRESHOLD) {
          isAdjustingScroll.current = true;
          setTimeout(() => {
            isAdjustingScroll.current = false;
          }, 50);
          return true;
        }

        return prev;
      });
    }, 150);
  }, 50);

  return {
    shouldCollapseHeader: collapseHeaderOnScroll && isHeaderCollapsed,
    scrollContainerRef,
    scrolled,
  };
}

export default useScrollAndCollapse;
