import type { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import {
  faBadgeCheck,
  faBullseyePointer,
  faEnvelope,
  faEnvelopes,
  faUsers,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { TTheme } from '@feathr/components';
import { Chip } from '@feathr/components';

import type { TStat } from '../SegmentOption';

interface IProps {
  /** A descriptor to display alongside the statistic. Overrides the default descriptor of the component. */
  descriptor?: string;
  /** The icon to display alongside the statistic. Overrides the icons provided by the component. */
  icon?: IconDefinition;
  name?: string;
  statType?: TStat;
  statValue: number;
  theme?: string;
}

function SegmentDataChip({
  descriptor,
  icon,
  name,
  statType = 'num_cookies_total',
  statValue,
  theme,
}: IProps): JSX.Element {
  const { t } = useTranslation();

  const formattedValue = numeral(statValue).format('0,0');

  const statisticToLabel: Record<TStat, string> = {
    num_users_total: t('{{num}} people', {
      count: statValue,
      num: formattedValue,
    }),
    num_cookies_total: t('{{num}} reachable people', {
      count: statValue,
      num: formattedValue,
    }),
    num_crumbs_total: t('{{num}} breadcrumbs', {
      count: statValue,
      num: formattedValue,
    }),
    // Does not include additional emails added for people
    num_emails_total: t('{{num}} unique email addresses', {
      count: statValue,
      num: formattedValue,
    }),
    num_all_emails_total: t(
      '{{num}} unique email addresses (including additional email addresses)',
      {
        count: statValue,
        num: formattedValue,
      },
    ),
  };

  const statisticToIcon: Record<TStat, IconDefinition> = {
    num_users_total: faUsers,
    num_cookies_total: faBadgeCheck,
    num_crumbs_total: faBullseyePointer,
    num_emails_total: faEnvelope,
    num_all_emails_total: faEnvelopes,
  };

  const statisticToTheme: Record<TStat, TTheme | 'default'> = {
    num_users_total: 'default',
    num_cookies_total: 'sky',
    num_crumbs_total: 'sky',
    num_emails_total: 'sky',
    num_all_emails_total: 'sky',
  };

  const tooltip = descriptor ? `${formattedValue} ${descriptor}` : statisticToLabel[statType];

  return (
    <Observer>
      {(): JSX.Element => (
        <Chip
          name={name}
          prefix={<FontAwesomeIcon icon={icon ?? statisticToIcon[statType]} />}
          theme={theme ?? statisticToTheme[statType]}
          tooltip={tooltip}
        >
          {formattedValue}
        </Chip>
      )}
    </Observer>
  );
}

export default SegmentDataChip;
