export default [
  // Address is not an attribute in the Feathr person collection. This mapping is handled in the backend.
  {
    id: 'address',
    u_key: 'Address',
    data_type: 'str',
    collection: 'Person',
  },
  {
    id: 'companies',
    u_key: 'Companies',
    data_type: 'list',
    collection: 'Person',
  },
  {
    id: 'email',
    u_key: 'Primary email',
    data_type: 'str',
    collection: 'Person',
  },
  {
    id: 'external_id',
    u_key: 'External ID',
    data_type: 'str',
    collection: 'Person',
  },
  {
    id: 'name',
    u_key: 'Name',
    data_type: 'str',
    collection: 'Person',
  },
  {
    id: 'first_name',
    u_key: 'First name',
    data_type: 'str',
    collection: 'Person',
  },
  {
    id: 'last_name',
    u_key: 'Last name',
    data_type: 'str',
    collection: 'Person',
  },
  {
    id: 'date_of_birth',
    u_key: 'Date of birth',
    data_type: 'date',
    collection: 'Person',
  },
  {
    id: 'occupation',
    u_key: 'Title',
    data_type: 'str',
    collection: 'Person',
  },
  {
    id: 'phone',
    u_key: 'Phone number',
    data_type: 'str',
    collection: 'Person',
  },
];
