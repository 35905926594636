import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import Papa from 'papaparse';
import type { JSX } from 'react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign, Targeting } from '@feathr/blackbox';
import { CampaignState, TargetableClass } from '@feathr/blackbox';
import { FileUpload, toast } from '@feathr/components';
import { useAccount, useStore } from '@feathr/extender/state';
import { validate } from '@feathr/rachis';

import { useTargetable } from '../../AdWizardTargetsStep.useTargetable';

interface IProps {
  campaign: Campaign;
  targetings: Targeting[];
  onRemove: (targeting: Targeting) => void;
}

interface IEmailRow {
  email: string;
  [key: string]: string;
}

function validateEmail(email: string): boolean {
  const errors: Array<string | undefined> =
    validate.single(email, {
      presence: { allowEmpty: false },
      email: true,
    }) ?? [];
  return errors.length === 0;
}

export async function parseFile(
  file: File,
  onComplete: (numEmails: number) => void,
): Promise<IEmailRow[]> {
  return new Promise((resolve) => {
    Papa.parse<IEmailRow>(file, {
      header: true,
      complete: (results) => {
        const uniqueEmails = new Set(
          results.data
            .filter((row): row is IEmailRow => !!row?.email && validateEmail(row.email))
            .map((row) => row.email.trim()),
        );
        onComplete(uniqueEmails.size);
        resolve(results.data);
      },
    });
  });
}

function EmailListUploadButton({ campaign, targetings, onRemove }: Readonly<IProps>): JSX.Element {
  const account = useAccount();
  const { Targetings } = useStore();
  const { t } = useTranslation();

  const targeting = Targetings.create({
    parent: campaign.get('id'),
    kind: 'email_list',
    included: true,
  });

  const { targetable } = useTargetable({
    campaign,
    targeting,
    onRemove,
    overrides: {
      _cls: TargetableClass.email_list,
    },
  });

  const handleUpload = useCallback(
    async (key: string, container: string, file: File, filename: string) => {
      const updateEmailCount = (numEmails: number): void => {
        runInAction(() => {
          targetable.set({ num_unique_emails: numEmails });
        });
      };

      const parsedData = await parseFile(file, updateEmailCount);

      if (Object.keys(parsedData[0])[0] !== 'email') {
        toast(t('The uploaded CSV does not have a column with an "email" header'), {
          type: 'error',
        });
        return;
      }

      if (Object.keys(parsedData[0]).length > 1) {
        toast(t('The uploaded CSV cannot contain more than one column'), {
          type: 'error',
        });
        return;
      }

      if (targetable.get('num_unique_emails') === 0) {
        toast(t('The uploaded CSV has no valid email addresses.'), {
          type: 'error',
        });
        return;
      }

      // Only add the targeting if the file has valid email addresses
      runInAction(() => {
        targetings.push(targeting);
      });

      // Update targetable with file information
      const url = `https://s3.amazonaws.com/${container}/${encodeURIComponent(key)}`;
      const advertiserId = account.get('ttd')?.id ?? 'noAdvId';
      const fileExt = filename.split('.').pop() ?? 'csv';
      const targetId = targetable.get('id');
      const cleanFileName = `${advertiserId}-${targetId}.${fileExt}`;

      runInAction(() => {
        targetable.set({
          name: cleanFileName,
          list_file: url,
          list_file_name: filename,
        });
      });
    },
    [targetable, account, targetings, targeting, t],
  );

  return (
    <FileUpload
      attribute={'list_file'}
      component={'ContextMenu.Item'}
      disabled={campaign.get('state') === CampaignState.Published}
      label={'Upload CSV'}
      model={targetable}
      name={'upload_csv'}
      onUpload={handleUpload}
      pickerOptions={{
        accept: ['text/comma-separated-values', 'text/csv', 'application/csv', '.csv'],
        maxFiles: 1,
        exposeOriginalFile: true,
        storeTo: {
          location: 's3',
          container: 'feathr-import-data',
          access: 'private',
          region: 'us-east-1',
        },
      }}
    />
  );
}

export default observer(EmailListUploadButton);
