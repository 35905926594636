import type { JSX } from 'react';
import React from 'react';
import type { SingleValueProps } from 'react-select';
import { components } from 'react-select';

import type { IObjectiveOption } from '../ObjectiveOption';

import * as styles from './ObjectiveValue.css';

type TSingleValueProps = SingleValueProps<IObjectiveOption>;

function ObjectiveValue(props: TSingleValueProps): JSX.Element {
  const { data } = props;

  return (
    <components.SingleValue {...props}>
      <div className={styles.root}>
        <div>{data.icon}</div>
        <span className={styles.label}>{data.name}</span>
      </div>
    </components.SingleValue>
  );
}

export default ObjectiveValue;
