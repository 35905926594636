import { remove, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { ISelectOption } from '@feathr/components';
import { Card, Checkbox, Fieldset, Input, Select } from '@feathr/components';
import type { IBeePluginFormField } from '@feathr/extender/components/BeeEditor';

import * as styles from '../TemplateEditor.css';

export interface IFormFieldConfigProps {
  field: IBeePluginFormField;
}

export interface IFieldTypeOption extends ISelectOption {
  id: IBeePluginFormField['type'];
  name: string;
}

const typeOptions: IFieldTypeOption[] = [
  { id: 'text', name: 'Text' },
  { id: 'email', name: 'Primary email' },
  { id: 'tel', name: 'Phone' },
  { id: 'url', name: 'URL' },
  { id: 'textarea', name: 'Multiline text' },
  { id: 'number', name: 'Number' },
  { id: 'checkbox', name: 'Checkbox' },
  { id: 'hidden', name: 'Hidden' },
];

function FormFieldConfig({ field }: Readonly<IFormFieldConfigProps>): JSX.Element {
  const { attributes = {}, label, type } = field;
  const { 'data-callback': dataCallback, id, placeholder, required, value } = attributes;

  function handleRequiredChange(newValue?: boolean): void {
    runInAction(() => {
      field.attributes = {
        ...attributes,
        required: !!newValue,
      };
    });
  }

  function handleLabelChange(newValue?: string): void {
    runInAction(() => {
      field.label = newValue ?? '';
    });
  }

  function handlePlaceholderChange(newValue?: string): void {
    runInAction(() => {
      field.attributes = {
        ...attributes,
        placeholder: newValue,
      };
    });
  }

  function handleValueChange(newValue?: string): void {
    runInAction(() => {
      field.attributes = {
        ...attributes,
        value: newValue,
      };
    });
  }

  function handleResponseChange(newValue?: string): void {
    runInAction(() => {
      field.attributes = {
        ...attributes,
        'data-callback': newValue,
      };
    });
  }

  function handleFieldTypeChange(option: IFieldTypeOption): void {
    runInAction(() => {
      field.type = option.id;
      if (['checkbox', 'number', 'hidden'].includes(option.id)) {
        remove(attributes, 'placeholder');
      }
      if (['hidden'].includes(option.id)) {
        remove(attributes, 'label');
        remove(attributes, 'required');
      }
    });
  }

  return (
    <Card className={styles.formFieldCard} title={id?.split('-')[1] || label}>
      <Fieldset>
        {type !== 'submit' && (
          <Select
            label={'Field type'}
            name={'field-type-select'}
            onSelectSingle={handleFieldTypeChange}
            options={typeOptions}
            value={typeOptions.find((opt) => opt.id === type)}
          />
        )}
        {!['hidden', 'submit'].includes(type) && (
          <>
            <Input label={'Label'} onChange={handleLabelChange} type={'text'} value={label} />
            <Checkbox
              label={'Required'}
              onChange={handleRequiredChange}
              value={required || false}
            />
          </>
        )}
        {['text', 'textarea', 'url', 'email', 'tel'].includes(type) && (
          <Input
            label={'Placeholder'}
            onChange={handlePlaceholderChange}
            type={'text'}
            value={placeholder}
          />
        )}
        {['hidden', 'submit'].includes(type) && (
          <Input label={'Value'} onChange={handleValueChange} type={'text'} value={value} />
        )}
        {['submit'].includes(type) && (
          <Input
            label={'Response'}
            onChange={handleResponseChange}
            type={'text'}
            value={dataCallback}
          />
        )}
      </Fieldset>
    </Card>
  );
}

export default observer(FormFieldConfig);
