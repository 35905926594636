import type { IObservableArray } from 'mobx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { type Campaign, CampaignClass, type Targeting } from '@feathr/blackbox';
import { toast } from '@feathr/components';
import { useStore } from '@feathr/extender/state';

interface IUseReconcileUniqueEmailsProps {
  targetings: IObservableArray<Targeting>;
  minRequiredEmails?: number;
  campaign: Campaign;
}

export interface IUniqueEmailsResult {
  numUniqueEmails: number | null;
  hasEnoughUniqueEmails: boolean;
  remainingEmails: number;
  isLoading: boolean;
}

const MIN_NUM_EMAILS = {
  [CampaignClass.EmailListFacebook]: 2500,
  [CampaignClass.EmailList]: 2500,
};

export function useReconcileUniqueEmails({
  targetings,
  minRequiredEmails,
  campaign,
}: IUseReconcileUniqueEmailsProps): IUniqueEmailsResult {
  const { Targetables, Segments } = useStore();
  const [numUniqueEmails, setNumUniqueEmails] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { isEmailMappingCampaign } = campaign;

  // Get included email list targetings
  const includedEmail = targetings.filter(
    (t) => !t.get('is_archived') && t.get('kind') === 'email_list' && t.get('included'),
  );

  // Get included email list IDs
  const includedEmailIds = includedEmail
    .map((t) => t.get('target_data'))
    .filter(Boolean) as string[];

  // Get included email list URLs
  const includedEmailLists = includedEmailIds
    .map((id) => {
      const targetable = Targetables.get(id);
      return targetable?.get('list_file');
    })
    .filter(Boolean) as string[];

  // Get included segment IDs
  const includedSegmentIds = targetings
    .filter((t) => !t.get('is_archived') && t.get('kind') === 'segment' && t.get('included'))
    .map((t) => t.get('target_data'))
    .filter(Boolean) as string[];

  // Stringify the arrays so they can be used as reliable deps
  const deps = JSON.stringify(includedSegmentIds) + JSON.stringify(includedEmailLists);

  // Only process if targetings exist
  useEffect(() => {
    if (!targetings && !isEmailMappingCampaign) {
      return;
    }

    async function getNumUniqueEmails(): Promise<void> {
      setIsLoading(true);

      try {
        const response = await Segments.getUniqueEmailsFromTargetables({
          included_segment_ids: includedSegmentIds,
          email_list_urls: includedEmailLists,
        });

        setNumUniqueEmails(response.unique_emails);
      } catch (error) {
        toast(t('Something went wrong reconciling duplicate emails: {{-error}}', { error }), {
          type: 'error',
        });
        setNumUniqueEmails(null);
      } finally {
        setIsLoading(false);
      }
    }

    if (includedEmailIds.length || includedSegmentIds.length) {
      getNumUniqueEmails();
    } else {
      // Reset state if no targetings to process
      setNumUniqueEmails(null);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deps]);

  const numRequiredEmails = minRequiredEmails ?? MIN_NUM_EMAILS[campaign.get('_cls')];
  const hasEnoughUniqueEmails = Boolean(numUniqueEmails && numUniqueEmails >= numRequiredEmails);
  const remainingEmails = numUniqueEmails ? numRequiredEmails - numUniqueEmails : numRequiredEmails;

  return {
    numUniqueEmails,
    hasEnoughUniqueEmails,
    remainingEmails,
    isLoading,
  };
}
