import { Flex } from '@mantine/core';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign, Targeting } from '@feathr/blackbox';
import { TargetableClass } from '@feathr/blackbox';
import { Button, CardV2 as Card, Chip, ContextMenu, Label } from '@feathr/components';
import { cssVar, getIconForAction } from '@feathr/hooks';

import { useTargetable } from '../AdWizardTargetsStep.useTargetable';

import * as styles from './EmailListTargeting.css';

interface IProps {
  campaign: Campaign;
  targeting: Targeting;
  onRemove: (targeting: Targeting) => void;
  isReconcilingEmails?: boolean;
}

function EmailListTargeting({
  campaign,
  targeting,
  onRemove,
  isReconcilingEmails,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();

  const { targetable, onRemoveTargeting } = useTargetable({
    campaign,
    targeting,
    onRemove,
    overrides: {
      _cls: TargetableClass.email_list,
    },
  });

  const emailCountProvided = numeral(targetable.get('num_unique_emails', 0)).format('0,0');
  const errors = toJS(targetable.validate([], false).errors);
  const isValid = targetable.isValid();
  const buttonProps = {
    name: 'remove_targeting',
    onClick: onRemoveTargeting,
    disabled: isReconcilingEmails,
    'aria-label': t('Delete'),
  };

  return (
    <Card name={'email-list-targeting'} theme={isValid ? 'default' : 'danger'} width={'full'}>
      <Card.Content>
        <Flex align={'center'} gap={cssVar('--spacing-3')} justify={'end'}>
          {isValid ? (
            <>
              <Label className={styles.label}>{targetable.get('list_file_name')}</Label>
              <Chip
                prefix={getIconForAction('email')}
                theme={'sky'}
                tooltip={t('{{num}} unique email addresses', {
                  count: targetable.get('num_unique_emails', 0),
                  num: emailCountProvided,
                })}
              >
                {emailCountProvided}
              </Chip>
              <ContextMenu
                buttonType={isValid ? 'icon' : 'iconDanger'}
                iconName={'ellipsis'}
                name={'targeting-context-menu'}
              >
                <ContextMenu.Item
                  {...buttonProps}
                  prefix={getIconForAction('delete')}
                  theme={'danger'}
                >
                  {t('Delete')}
                </ContextMenu.Item>
              </ContextMenu>
            </>
          ) : (
            <>
              <p className={styles.label}>
                {errors[0]} {t('Remove it to proceed.')}
              </p>
              <Button {...buttonProps} type={'iconDanger'}>
                {getIconForAction('delete')}
              </Button>
            </>
          )}
        </Flex>
      </Card.Content>
    </Card>
  );
}

export default observer(EmailListTargeting);
