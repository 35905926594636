import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { OptionProps, SingleValueProps } from 'react-select';
import { components } from 'react-select';

import type { ISelectOption } from '@feathr/components';
import { Select } from '@feathr/components';

interface IProps {
  onChange: (typeface: string) => void;
  value: string;
}

function SingleValue(props: SingleValueProps<ISelectOption>): JSX.Element {
  const {
    data: { id, name },
  } = props;
  return (
    <components.SingleValue {...props}>
      <span style={{ fontFamily: id }}>{name}</span>
    </components.SingleValue>
  );
}

function Option(props: OptionProps<ISelectOption>): JSX.Element {
  const {
    data: { id, name },
  } = props;
  return (
    <components.Option {...props}>
      <span style={{ fontFamily: id }}>{name}</span>
    </components.Option>
  );
}

function TypefaceSelect({ onChange, value }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();

  // Options are based on browser safe fonts.
  const options = [
    {
      id: 'Inter',
      name: t('System default (Inter)'),
    },
    {
      id: 'Arial',
      name: t('Arial'),
    },
    {
      id: 'Verdana',
      name: t('Verdana'),
    },
    {
      id: 'Georgia',
      name: t('Georgia'),
    },
    {
      id: 'Times New Roman',
      name: t('Times New Roman'),
    },
    {
      id: 'Tahoma',
      name: t('Tahoma'),
    },
    {
      id: 'Trebuchet MS',
      name: t('Trebuchet MS'),
    },
    {
      id: 'Roboto',
      name: t('Roboto'),
    },
    {
      id: 'Lato',
      name: t('Lato'),
    },
    {
      id: 'Montserrat',
      name: t('Montserrat'),
    },
  ];

  function handleSelect({ id }: ISelectOption): void {
    onChange(id);
  }

  return (
    <Select
      components={{
        SingleValue,
        Option,
      }}
      label={t('Typeface family')}
      name={'font-select'}
      onSelectSingle={handleSelect}
      options={options}
      placeholder={t('Select a font...')}
      value={options.find((option) => option.id === value)}
    />
  );
}

export default TypefaceSelect;
