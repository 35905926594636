import type { PickerOptions } from 'filestack-js';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import {
  CountrySelect,
  Form,
  ImagePicker,
  Input,
  SaveButtonValid,
  Textarea,
  Toolbar,
} from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import TagsField from '@feathr/extender/components/TagsField';
import { useStore } from '@feathr/extender/state';

import PartnerCustomData from './PartnerCustomData';

import styles from './EventPartnerPage.css';

export interface ICustomDataKeyValuePair {
  key: string;
  value: number | string | boolean | Date | string[] | undefined;
}

function EventPartnerPage(): JSX.Element {
  const { t } = useTranslation();
  const { Partners } = useStore();
  const { partnerId } = useParams<{ partnerId: string }>();

  const partner = Partners.get(partnerId);
  const errors = partner.validate([], false).errors;

  const actions = (
    <Toolbar>
      <SaveButtonValid errors={errors} model={partner} />
    </Toolbar>
  );

  function handleChangeTags(values: string[]): void {
    partner.set({ tag_ids: values });
  }

  return (
    <Page actions={actions} loading={partner.isPending} title={'Edit Partner'} width={'wide'}>
      <Form className={styles.page} label={'Edit Partner'}>
        <ImagePicker
          attribute={'logo'}
          model={partner}
          pickerOptions={
            {
              fromSources: ['local_file_system', 'url', 'imagesearch'],
              customText: {
                'Select Files to Upload': t('Select File to Upload'),
              },
            } as PickerOptions
          }
        />
        <Input
          attribute={'name'}
          label={t('Name')}
          model={partner}
          placeholder={t('Jean Deaux')}
          type={'text'}
        />
        <Input
          attribute={'website'}
          label={'Website'}
          model={partner}
          placeholder={'https://www.feathr.co'}
          type={'text'}
        />
        <CountrySelect
          attribute={['ttd', 'country_code']}
          label={t('Country')}
          model={partner}
          required={true}
        />
        <Input
          attribute={'email'}
          label={t('Primary email')}
          model={partner}
          placeholder={'jean@feathr.co'}
          type={'text'}
        />
        <Textarea
          attribute={'description'}
          label={t('Description')}
          model={partner}
          placeholder={t('Director of Customer Research')}
        />
        <Input
          attribute={'external_id'}
          helpText={
            'An External ID is a unique identifier for a person record. This ID will be used to find the person for updates and deduplication.'
          }
          label={t('External ID')}
          model={partner}
          placeholder={t('The identifier for this partner in another platform')}
          type={'text'}
        />
        <TagsField
          context={'partner'}
          label={'Tags'}
          onChange={handleChangeTags}
          value={partner.get('tag_ids', [])}
        />
      </Form>
      <PartnerCustomData partner={partner} />
    </Page>
  );
}

export default observer(EventPartnerPage);
