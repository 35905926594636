import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ValueType } from 'react-select';

import type { TTDCampaign } from '@feathr/blackbox';
import { CreatableSelect, Tooltip } from '@feathr/components';
import { getIconForAction } from '@feathr/hooks';

import type { IWithRecommendedValue } from '../../AdWizard.useRecommendedValue';
import useRecommendedValue from '../../AdWizard.useRecommendedValue';

const MIN_FREQUENCY_CAP = 1;
const MAX_FREQUENCY_CAP = 1000;
const DEFAULT_VALUE = 9;
const LIFETIME_PERIOD = '2147483647';

export interface IFreqCapSelectProps extends IWithRecommendedValue {
  campaign: TTDCampaign;
}

interface IOption {
  value: string;
  label: string;
}

function compareOption(inputValue: string, option: IOption): boolean {
  return inputValue === option.value;
}

function isValidNewOption(
  inputValue: string,
  _: ValueType<IOption>,
  options: readonly IOption[],
): boolean {
  const asNumber = Number(inputValue);
  return !(
    !inputValue ||
    !Number.isFinite(asNumber) ||
    !(asNumber > 0 && asNumber <= MAX_FREQUENCY_CAP) ||
    options.some((option) => compareOption(inputValue, option))
  );
}

function FreqCapSelect({ campaign, recommendedValue }: Readonly<IFreqCapSelectProps>): JSX.Element {
  const { t } = useTranslation();
  const { shouldUseRecommendedValue, setHasUserSelected, hasRecommendation } =
    useRecommendedValue(recommendedValue);

  const cap = shouldUseRecommendedValue
    ? recommendedValue
    : campaign.get('bidding_strategy').freq_cap;
  const period = String(campaign.get('bidding_strategy').freq_period);

  function createOption(inputValue: string): IOption {
    const asNumeral = numeral(Number(inputValue));
    return {
      value: asNumeral.format('0'),
      label: t('{{count}} impression', { count: Number(inputValue) }),
    };
  }

  function formatCreateLabel(inputValue: string): string {
    return t('Use custom frequency cap: {{count}} impression', {
      count: Number(inputValue),
    });
  }

  function clamp(value: number, min: number, max: number): number {
    return Math.min(Math.max(value, min), max);
  }

  function onChangeFreqCap(value: string | number): void {
    const newCap = Number(value);
    // Clamp value between min cap and max cap
    const adjustedCap = clamp(newCap, MIN_FREQUENCY_CAP, MAX_FREQUENCY_CAP);

    campaign.set({
      bidding_strategy: {
        ...campaign.get('bidding_strategy'),
        // if value is NaN, use the default value (9) otherwise use the clamped value
        freq_cap: isNaN(newCap) ? DEFAULT_VALUE : adjustedCap,
      },
    });

    setHasUserSelected(true);
  }

  function handleSelectSingle(option: { value: string; label: string }): void {
    onChangeFreqCap(option.value);
  }

  function getHelpText(period: string): string {
    if (period === LIFETIME_PERIOD) {
      return t(
        'The maximum number of times Feathr may show ads from this campaign to the same user for the duration of the campaign.',
      );
    }
    return t(
      'The maximum number of times Feathr may show ads from this campaign to the same user in {{article}} {{period}}-hour period.',
      {
        article: period.includes('8') ? t('an') : t('a'),
        period: Math.ceil(campaign.get('bidding_strategy').freq_period / 60),
      },
    );
  }

  const maximumOption: IOption = {
    value: String(MAX_FREQUENCY_CAP),
    label: t('{{count}} impression', { count: MAX_FREQUENCY_CAP }),
  };

  // If there's a recommended value, only offer that as an option
  const defaultOptions: IOption[] = hasRecommendation
    ? [
        {
          value: String(recommendedValue),
          label: t('{{count}} impression', { count: recommendedValue }),
        },
      ]
    : [
        { value: '4', label: t('{{count}} impression', { count: 4 }) },
        { value: '9', label: t('{{count}} impression', { count: 9 }) },
        { value: '16', label: t('{{count}} impression', { count: 16 }) },
        { value: '18', label: t('{{count}} impression', { count: 18 }) },
      ];

  function getValue(): IOption {
    // Only show recommended label if user hasn't selected anything yet
    if (shouldUseRecommendedValue) {
      return {
        value: String(recommendedValue),
        label: t('{{count}} impression (recommended)', { count: recommendedValue }),
      };
    }

    return (
      // return matching option or
      defaultOptions.find((option) => option.value === String(cap)) ||
      // if option exceeds max cap, return maximum option (1000 impressions) otherwise return custom option
      (cap && cap > MAX_FREQUENCY_CAP
        ? maximumOption
        : { value: String(cap), label: t('{{count}} impression', { count: cap }) })
    );
  }

  const label = (
    <>
      {t('Frequency cap')} <Tooltip title={getHelpText(period)}>{getIconForAction('info')}</Tooltip>
    </>
  );

  const helpText = hasRecommendation
    ? t('Original frequency cap: {{cap}}', {
        cap: t('{{count}} impression', {
          count: campaign.initialAttributes.bidding_strategy?.freq_cap,
        }),
      })
    : undefined;

  return (
    <CreatableSelect<IOption>
      createOption={createOption}
      defaultOptions={defaultOptions}
      formatCreateLabel={formatCreateLabel}
      helpPlacement={'bottom'}
      helpText={helpText}
      isFullWidth={true}
      isValidNewOption={isValidNewOption}
      label={label}
      name={'frequency-cap'}
      onSelectSingle={handleSelectSingle}
      value={getValue()}
    />
  );
}

export default observer(FreqCapSelect);
