import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import type { SingleValueProps } from 'react-select';
import { components } from 'react-select';

import { Segment } from '@feathr/blackbox';
import { Label } from '@feathr/components';

import SegmentDataChip from '../SegmentDataChip';
import type { TStat } from '../SegmentOption';

import * as styles from './SegmentSingleValue.css';

type TSingleValueProps = SingleValueProps<Segment>;

interface IDataProps extends TSingleValueProps {
  selectProps: {
    stat?: TStat;
    isLoading: boolean;
    isDisabled: boolean;
  };
  data: Segment;
}

function SegmentSingleValue(props: TSingleValueProps): JSX.Element {
  const {
    data,
    selectProps: { stat, isLoading, isDisabled },
  } = props as IDataProps;

  const testId = `${data.id}-${stat}`;

  return (
    <components.SingleValue {...props}>
      <Observer>
        {(): JSX.Element => (
          <Label className={styles.label} disabled={isDisabled} weight={'normal'}>
            {data.name}
          </Label>
        )}
      </Observer>

      {/* If the options are loading, don't show the data chips so the loading ellipsis can be displayed. Otherwise, the chips shift abruptly on load. */}
      {data instanceof Segment && !isLoading && (
        <div className={styles.data}>
          <SegmentDataChip
            name={`${data.id}-num_users_total`}
            statType={'num_users_total'}
            statValue={data.get('stats').num_users_total || 0}
          />
          {!!stat && (
            <SegmentDataChip
              name={testId}
              statType={stat}
              statValue={data.get('stats')[stat] || 0}
            />
          )}
        </div>
      )}
    </components.SingleValue>
  );
}

export default SegmentSingleValue;
