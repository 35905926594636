import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';

import type { ILineItem } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';
import { currencyFormatter } from '@feathr/extender/utils';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

function BillColumns(data: ILineItem[], eventId: string): Array<IColumn<ILineItem>> {
  return [
    {
      id: 'description',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: 'Campaign',
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              const localUrl = useLocalUrl();

              return original.campaign_id ? (
                <Link to={localUrl(`/projects/${eventId}/campaigns/${original.campaign_id}`)}>
                  {original.description}
                </Link>
              ) : (
                <>{original.description}</>
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'quantity',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: 'Qty',
      }),
      width: 80,
      headerClassName: tableStyles.sort,
      className: tableStyles.cellRight,
      Cell: ({ original }): JSX.Element => {
        return <>{original.quantity}</>;
      },
    },
    {
      id: 'unitPrice',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: 'Unit Price',
      }),
      width: 120,
      headerClassName: tableStyles.sort,
      className: tableStyles.cellRight,
      Cell: ({ original }): JSX.Element => {
        const unitPrice = original.price;
        return <>{currencyFormatter.format(unitPrice)}</>;
      },
    },
    {
      id: 'price',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: 'Total',
      }),
      width: 200,
      headerClassName: tableStyles.sort,
      className: tableStyles.cellRight,
      Cell: ({ original }): JSX.Element => {
        const total = original.price * original.quantity;
        return <>{currencyFormatter.format(total)}</>;
      },
      Footer(): JSX.Element {
        return (
          <>
            <strong>Amount Due:</strong>{' '}
            {currencyFormatter.format(
              data.reduce((total, item) => total + item.price * item.quantity, 0),
            )}
          </>
        );
      },
    },
  ];
}

export default BillColumns;
