import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Form } from '@feathr/blackbox';
import type { IRadioOption } from '@feathr/components';
import { CardV2 as Card, Radios } from '@feathr/components';
import { flattenError } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import RedirectURLEditor from './RedirectURLEditor';
import ThankYouMessageEditor from './ThankYouMessageEditor';

import * as styles from './AfterFormSubmissionCard.css';

interface IProps {
  form: Form;
}

type TAfterSubmissionBehavior = 'message' | 'redirect';

function convertSubmissionBehaviorToBoolean(value: TAfterSubmissionBehavior): boolean {
  if (value === 'message') {
    return false;
  }
  // if value is 'redirect'
  return true;
}

function validateFormPostSubmitHTML(form: Form): TValidateGrouped {
  return (
    form.validate(
      ['settings.post_submit_html', 'settings.post_submit_redirect_url'],
      false,
      'grouped',
    ).errors ?? []
  );
}

function AfterFormSubmissionCard({ form }: IProps): JSX.Element {
  const { t } = useTranslation();
  const settings = form.get('settings');
  const afterSubmissionAction: TAfterSubmissionBehavior = settings.should_redirect_on_submit
    ? 'redirect'
    : 'message';

  const afterSubmissionOptions: Array<IRadioOption<TAfterSubmissionBehavior>> = [
    { id: 'message', name: t('Display a thank you message') },
    { id: 'redirect', name: t('Redirect to another page') },
  ];

  // The RichTextEditor component doesn't support validation yet, so we are validating the field at the Radio level.
  const validationErrors = validateFormPostSubmitHTML(form);

  const validPostSubmitRedirectURL =
    flattenError(validationErrors['settings.post_submit_redirect_url'])?.length === 0
      ? settings.post_submit_redirect_url
      : undefined;

  function handleOnChange(newValue?: string): void {
    if (!newValue) {
      return;
    }
    form.set({
      settings: {
        ...settings,
        should_redirect_on_submit: convertSubmissionBehaviorToBoolean(
          newValue as TAfterSubmissionBehavior,
        ),
        // When switching from 'redirect' to 'message', clear the redirect URL if it is invalid.
        post_submit_redirect_url: validPostSubmitRedirectURL,
      },
    });
  }

  return (
    <Card width={'narrow'}>
      <Card.Header title={t('After Form Submission')} />
      <Card.Content>
        <Radios
          label={t('What should happen after a visitor submits this form?')}
          layout={'block'}
          onChange={handleOnChange}
          options={afterSubmissionOptions}
          // TODO: move the validation of this to ThankYouMessageEditor.
          validationError={flattenError(validationErrors['settings.post_submit_html'])}
          value={afterSubmissionAction}
          wrapperClassName={styles.radios}
        />
        {afterSubmissionAction === 'message' && <ThankYouMessageEditor form={form} />}
        {afterSubmissionAction === 'redirect' && <RedirectURLEditor form={form} />}
      </Card.Content>
    </Card>
  );
}

export default observer(AfterFormSubmissionCard);
