import type { Dispatch, JSX, ReactNode, SetStateAction } from 'react';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

type TActions = ReactNode;
type ActionBarProviderProps = { readonly children: ReactNode };

interface IActionBarContext {
  leftActions: TActions;
  rightActions: TActions;
  setLeftActions: Dispatch<SetStateAction<TActions>>;
  setRightActions: Dispatch<SetStateAction<TActions>>;
}

export const actionBarClassName = 'hasActionBar';

const ActionBarContext = createContext<IActionBarContext | undefined>(undefined);

/*
 * See Kent C. Dodds' article on effective context usage:
 * https://kentcdodds.com/blog/how-to-use-react-context-effectively
 */
function ActionBarProvider({ children }: ActionBarProviderProps): JSX.Element {
  const [leftActions, setLeftActions] = useState<TActions>(undefined);
  const [rightActions, setRightActions] = useState<TActions>(undefined);

  const memoizedValue = useMemo(() => {
    // Set body class 'hasActions' when either leftActions or rightActions is set
    if (leftActions || rightActions) {
      document.body.classList.add(actionBarClassName);
    } else {
      document.body.classList.remove(actionBarClassName);
    }

    return {
      leftActions,
      rightActions,
      setLeftActions,
      setRightActions,
    };
  }, [leftActions, rightActions, setLeftActions, setRightActions]);

  return <ActionBarContext.Provider value={memoizedValue}>{children}</ActionBarContext.Provider>;
}

/*
 * To set the actions in the action bar, call the useActionBar hook in the component that renders the action bar.
 * This should be the root component for the campaign that displays the steps. Add a useEffect and set the contents
 * of the action bar using `setRightActions` and `setLeftActions` from the useActionBar hook.
 * See GoogleAdsCampaignEdit.tsx for an example.
 */
function useActionBar(): IActionBarContext {
  const context = useContext(ActionBarContext);

  // Clear action bar buttons when navigating to a new page.
  useEffect(() => {
    return () => {
      context?.setRightActions(undefined);
      context?.setLeftActions(undefined);
    };
  }, []);

  if (context === undefined) {
    throw new Error('useActionBar hook must be wrapped in an ActionBarProvider context provider');
  }
  return context;
}

export { ActionBarProvider, useActionBar };
