import { Flex, Stack } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign, Targeting } from '@feathr/blackbox';
import { Categories, TargetableClass } from '@feathr/blackbox';
import { Button, CardV2 as Card, Select } from '@feathr/components';
import { cssVar, getIconForAction } from '@feathr/hooks';

import { useTargetable } from '../AdWizardTargetsStep.useTargetable';
import AffinityTargetingDataSelect from './AffinityTargetingDataSelect/AffinityTargetingDataSelect';

interface IProps {
  campaign: Campaign;
  targeting: Targeting;
  onRemove: (targeting: Targeting) => void;
}

interface ICategoryOption {
  id: number;
  name: string;
}

interface ICategoryGroups {
  label: string;
  options: ICategoryOption[];
}

function AffinityTargeting({ campaign, targeting, onRemove }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { targetable, onRemoveTargeting } = useTargetable({
    campaign,
    targeting,
    onRemove,
    overrides: { _cls: TargetableClass.affinity },
  });

  const handleCategorySelect = useCallback(
    (option: ICategoryOption | ICategoryGroups) => {
      // Clear the audience when the category changes
      targetable.set({
        category_id: (option as ICategoryOption).id,
        name: undefined,
        thirdparty_data_id: undefined,
        thirdparty_data_name: undefined,
        thirdparty_brand_name: undefined,
        description: undefined,
        unique_user_count: undefined,
        full_path: undefined,
      });
    },
    [targetable],
  );

  const selectedGroup = Categories.find(
    (cat: ICategoryGroups) =>
      !!cat.options.find((opt: ICategoryOption) => opt.id === targetable.get('category_id')),
  );
  const selectedCategory =
    selectedGroup &&
    selectedGroup.options.find((cat: ICategoryOption) => cat.id === targetable.get('category_id'));

  return (
    <Card name={'affinity-targeting'}>
      <Card.Content addVerticalGap={true}>
        <Flex align={'center'} gap={cssVar('--spacing-3')}>
          <Stack w={'100%'}>
            <Select<ICategoryOption | ICategoryGroups>
              helpText={t('Choose an affinity category to narrow your search.')}
              label={t('Affinity category')}
              name={'category_select'}
              onSelectSingle={handleCategorySelect}
              options={Categories}
              required={true}
              value={selectedCategory}
            />
            <AffinityTargetingDataSelect targetable={targetable} />
          </Stack>
          <Button name={'remove_segment'} onClick={onRemoveTargeting} type={'iconDanger'}>
            {getIconForAction('delete')}
          </Button>
        </Flex>
      </Card.Content>
    </Card>
  );
}

export default observer(AffinityTargeting);
