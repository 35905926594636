import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { BaseCampaign } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader } from '@feathr/components';
import CampaignTypeChip from '@feathr/extender/components/CampaignTypeChip';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

const TypeColumn: IColumn<BaseCampaign> = {
  id: '_cls',
  checkboxLabel: 'Type',
  Header: TableColumnHeader({
    title: 'Type',
  }),
  sortable: false,
  minWidth: 150,
  className: tableStyles.cellLeft,
  Cell({ original }): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          const type = original.get('_cls');

          return <CampaignTypeChip cls={type} isMonetization={original.isMonetization} />;
        }}
      </Observer>
    );
  },
};

export default TypeColumn;
