import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { IEphemeralTargetableOrSegment, TTDCampaign } from '@feathr/blackbox';
import { Button, Chip, Skeleton, toast, Tooltip, Well } from '@feathr/components';
import { getIconForAction } from '@feathr/hooks';

import * as styles from './RecommendedBudget.css';

interface IProps {
  campaign: TTDCampaign;
  budget: string;
  onClick: () => void;
  recBudget: number | null;
  setRecBudget: (budget: number) => void;
  targetingsWithData: IEphemeralTargetableOrSegment[];
}

function RecommendedBudget({
  budget,
  onClick,
  campaign,
  recBudget,
  setRecBudget,
  targetingsWithData,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  // Re-query the recommended budget when the campaign's start or end date changes
  useEffect(() => {
    async function getRecommendedBudget(): Promise<void> {
      if (!campaign.isPending) {
        try {
          setIsLoading(true);
          const budget = await campaign.getRecommendedBudget({
            campaign: campaign.attributes,
            targetings: targetingsWithData,
          });
          setRecBudget(budget.recommended_budget);
        } catch (error) {
          toast(
            t('Something went wrong while trying to recommend a budget: {{-error}}', { error }),
            { type: 'error' },
          );
        } finally {
          setIsLoading(false);
        }
      }
    }

    getRecommendedBudget();
  }, [campaign.get('date_start'), campaign.get('date_end')]);

  if (isLoading || recBudget === null) {
    return (
      <Well className={styles.root} layout={'vertical'}>
        <Skeleton height={24} width={'80%'} />
        <Skeleton height={32} width={'50%'} />
        <Skeleton height={32} />
      </Well>
    );
  }

  return (
    <Well className={styles.root} layout={'vertical'}>
      <Chip
        className={styles.chipFix}
        suffix={
          <Tooltip
            title={t(
              "The recommended budget is produced by analyzing your campaign's target audience, filters, optimization settings and duration.",
            )}
          >
            {getIconForAction('info')}
          </Tooltip>
        }
        theme={'sky'}
      >
        {t('Recommended for you')}
      </Chip>
      <span className={styles.bigNumber} data-name={'recommended_budget'}>
        {budget}
      </span>
      <Button name={'use_recommended_budget'} onClick={onClick} type={'primary'}>
        {t('Use recommended budget')}
      </Button>
    </Well>
  );
}

export default RecommendedBudget;
