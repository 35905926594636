import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';
import React from 'react';
import type { RowRenderProps } from 'react-table';

import type { FlavorKeys } from '@feathr/blackbox';
import { Flavors } from '@feathr/blackbox';
import { Chip } from '@feathr/components';
import { cssVar } from '@feathr/hooks';

import { flavorColor, flavorIcon } from '../../styles/breadcrumbs';

import * as styles from './FlavorChip.css';

interface IProps extends RowRenderProps {
  flavor: FlavorKeys;
}

function FlavorChip({ flavor }: IProps): JSX.Element {
  const flvr = flavor || 'custom';
  const icon = flavorIcon(flvr);
  const color = flavorColor(flvr) || cssVar('--color-text-body');
  const description = Flavors[flvr] || flvr;
  return (
    <Chip className={styles.chipFix} prefix={<FontAwesomeIcon icon={icon} />} theme={color}>
      {description}
    </Chip>
  );
}

export default FlavorChip;
