import { runInAction, when } from 'mobx';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';

import type { Form } from '@feathr/blackbox';
import type { IFormElementProps, ISelectOption, ISelectProps } from '@feathr/components';
import { isMulti, Select } from '@feathr/components';
import { useStore } from '@feathr/extender/state';

export interface IFormSelectBaseProps extends IFormElementProps {
  filters?: Record<string, unknown>;
  isLoading?: boolean;
  placeholder?: ISelectProps['placeholder'];
  wrapperClassName?: ISelectProps['wrapperClassName'];
}

export interface IFormSelectSingleProps extends IFormSelectBaseProps {
  isMulti?: false;
  onChange: (value?: string) => void;
  value?: string;
}

export interface IFormSelectMultiProps extends IFormSelectBaseProps {
  isMulti: true;
  onChange: (value?: string[]) => void;
  value?: string[];
}

function FormSelect(props: Readonly<IFormSelectSingleProps>): JSX.Element;
function FormSelect(props: Readonly<IFormSelectMultiProps>): JSX.Element;
function FormSelect(props: Readonly<IFormSelectSingleProps | IFormSelectMultiProps>): JSX.Element {
  const { Forms } = useStore();
  const [forms, setForms] = useState<Form[]>();
  const [isPending, setIsPending] = useState<boolean>(false);

  const { filters, isLoading } = props;
  useEffect(() => {
    (async function fetchForms(): Promise<void> {
      setIsPending(true);
      const fetchedForms = Forms.list({ filters });
      await when(() => !fetchedForms.isPending);

      // Use runInAction to batch state updates
      runInAction(() => {
        setForms(fetchedForms.models);
        setIsPending(false);
      });
    })();
  }, [Forms, filters]);

  const options: ISelectOption[] = forms?.map((form) => ({ id: form.id, name: form.name })) ?? [];

  const baseProps = {
    name: 'form-select',
    options,
  };

  if (isMulti(props)) {
    const { onChange, value, ...rest } = props;

    function handleChange(option: readonly ISelectOption[]): void {
      onChange(option?.map((o) => o.id));
    }

    const selectedOptions = options.filter((option) => {
      return value?.includes(option.id);
    });

    return (
      <Select<ISelectOption>
        {...baseProps}
        {...rest}
        isLoading={isPending || isLoading}
        onChange={handleChange}
        value={selectedOptions}
      />
    );
  } else {
    const { onChange, value, ...rest } = props;

    function handleChange(option: ISelectOption): void {
      onChange(option?.id);
    }

    const selectedOption = options.find((option) => {
      return value === option.id;
    });

    return (
      <Select<ISelectOption>
        {...baseProps}
        {...rest}
        isLoading={isPending || isLoading}
        onChange={handleChange}
        value={selectedOption}
      />
    );
  }
}

export default FormSelect;
