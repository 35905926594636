import type { UniqueIdentifier } from '@dnd-kit/core';
import { observer } from 'mobx-react-lite';
import type { Dispatch, JSX, SetStateAction } from 'react';
import React from 'react';

import type { Form, IRowItem } from '@feathr/blackbox';

import type { IFieldError } from '../FormEditor';
import FieldConfiguration from './FieldConfiguration';
import FieldProperties from './FieldProperties';
import SubmitButtonProperties from './SubmitButtonProperties';

import * as styles from './ConfigurationPanel.css';

interface IProps {
  focusedField: IRowItem | undefined;
  form: Form;
  fields: IRowItem[];
  isSubmitButtonFocused: boolean;
  onFocusField: Dispatch<SetStateAction<IRowItem | undefined>>;
  updateFieldProperties: (key: UniqueIdentifier, value: unknown) => void;
  onDeleteField: () => void;
  onFieldBlur: (fieldId: string) => void;
  validationErrors: IFieldError[];
  onFocusSubmitButton: Dispatch<SetStateAction<boolean>>;
}

function ConfigurationPanel({
  fields,
  form,
  focusedField,
  isSubmitButtonFocused,
  onFocusField,
  updateFieldProperties,
  onDeleteField,
  onFieldBlur,
  validationErrors,
  onFocusSubmitButton,
}: IProps): JSX.Element {
  function handleBlur(event: React.FocusEvent<HTMLDivElement>): void {
    // Unfocus if user clicks outside of the panel
    if (event.relatedTarget === null) {
      onFocusField(undefined);
    }
  }

  return (
    // Make div focusable so it does not change the focused state of the active input
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <div className={styles.root} onBlur={handleBlur} tabIndex={0}>
      {isSubmitButtonFocused ? (
        <SubmitButtonProperties form={form} toggleFocus={onFocusSubmitButton} />
      ) : focusedField ? (
        <FieldProperties
          field={focusedField}
          isRemovable={focusedField.id !== 'email'}
          onDeleteField={onDeleteField}
          onFieldBlur={onFieldBlur}
          onFocusField={onFocusField}
          updateFieldProperties={updateFieldProperties}
          validationErrors={validationErrors}
        />
      ) : (
        <FieldConfiguration fields={fields} form={form} />
      )}
    </div>
  );
}

export default observer(ConfigurationPanel);
