import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Segment } from '@feathr/blackbox';

import EditFilters from '../EditFilters';
import Glue from '../Glue';

interface IProps {
  parentSegment?: Segment;
}

function ParentSegment({ parentSegment }: IProps): JSX.Element | null {
  const { t } = useTranslation();
  if (!parentSegment || parentSegment.isPending) {
    return null;
  }

  return (
    <>
      <EditFilters disabled={true} hideAlert={true} hideGauge={true} segment={parentSegment} />
      <Glue isLastItem={false} t={t} />
    </>
  );
}

export default observer(ParentSegment);
