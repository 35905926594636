import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import type { Dispatch, JSX, SetStateAction } from 'react';
import React from 'react';

import type { Form } from '@feathr/blackbox';
import { Button, Input } from '@feathr/components';
import { getIconForAction } from '@feathr/hooks';

import * as styles from './SubmitButtonProperties.css';

interface IProps {
  form: Form;
  toggleFocus: Dispatch<SetStateAction<boolean>>;
}

function SubmitButtonProperties({ form, toggleFocus }: Readonly<IProps>): JSX.Element {
  const formConfig = form.formConfig;
  const label = formConfig.settings.submitLabel;
  const isLabelValid = label !== undefined && label.trim() !== '';

  function handleChangeLabel(newValue?: string): void {
    form.setConfig({
      ...formConfig,
      settings: {
        ...formConfig.settings,
        submitLabel: newValue ?? '',
      },
    });
  }

  function handleClickBack(): void {
    toggleFocus(false);
  }

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <Button
          disabled={!isLabelValid}
          onClick={handleClickBack}
          prefix={getIconForAction('back')}
          type={'icon-outlined'}
        />
        <h4 className={styles.title}>{t('Customize Submit Button')}</h4>
      </div>
      <div className={styles.inputs}>
        <Input
          initializeFocused={true}
          label={t('Button text')}
          onChange={handleChangeLabel}
          required={true}
          validationError={!isLabelValid ? t('This field is required') : undefined}
          value={label}
        />
      </div>
    </div>
  );
}

export default observer(SubmitButtonProperties);
