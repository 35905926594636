import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { Segment } from '@feathr/blackbox';
import { toast } from '@feathr/components';

import SegmentExportModal from './DataSegmentOptions/SegmentExportModal';

interface IProps {
  toggleIsModalOpen: () => void;
  segment: Segment;
}

const defaults = {
  persons: {
    fields: ['name', 'email', 'external_id'],
    headers: ['Name', 'Primary email', 'External ID'],
  },
  breadcrumbs: {
    fields: ['d_c', 'loc_url'],
    headers: ['Date/Time', 'URL'],
  },
};

function SegmentExportModalWrapper({ segment, toggleIsModalOpen }: IProps): JSX.Element {
  const { t } = useTranslation();
  const [mode, setMode] = useState<'breadcrumbs' | 'persons'>('persons');
  const [fields, setFields] = useState(defaults[mode].fields);
  const [headers, setHeaders] = useState(defaults[mode].headers);

  function handleChangeFields(newFields: string[], newHeaders: string[]): void {
    setFields(newFields);
    setHeaders(newHeaders);
  }

  function handleChangeMode(newMode: 'breadcrumbs' | 'persons'): void {
    setMode(newMode);
    setFields(defaults[newMode].fields);
    setHeaders(defaults[newMode].headers);
  }

  async function handleOnConfirm(email: string): Promise<void> {
    try {
      await segment.export(fields, headers, email, mode);
      // SegmentExportModal has a success toast.
    } catch (e) {
      toast(t('There was an error exporting your group: {{message}}', { message: e }), {
        type: ToastType.ERROR,
      });
    }
  }

  return (
    <SegmentExportModal
      fields={fields}
      mode={mode}
      onChangeFields={handleChangeFields}
      onChangeMode={handleChangeMode}
      onClose={toggleIsModalOpen}
      onConfirm={handleOnConfirm}
      title={t('Export Group')}
    />
  );
}

export default observer(SegmentExportModalWrapper);
