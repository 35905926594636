import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CardV2 as Card, TimeFrameSelect, VerifiedActivityToggle } from '@feathr/components';
import { useTimeFrameSelect } from '@feathr/extender/hooks';
import { ECardCollapseKeys, ENamespaces, useToggle } from '@feathr/hooks';

import EmailPerformanceCharts from './EmailPerformanceCharts';

import * as styles from './EmailPerformance.css';

function EmailPerformance(): JSX.Element {
  const { t } = useTranslation();
  const { options, timeFrame, timeFrameValue, handleChangeTimeFrame } = useTimeFrameSelect();
  const [prioritizeVerifiedActivity, toggleVerifiedActivity] = useToggle(false);

  return (
    <Card width={'full'}>
      <Card.Header
        isCollapsible={true}
        persist={{
          key: ECardCollapseKeys.EmailPerformance,
          namespace: ENamespaces.AccountDashboard,
        }}
        title={t('Email Performance')}
        width={'full'}
      >
        <VerifiedActivityToggle
          onChange={toggleVerifiedActivity}
          value={prioritizeVerifiedActivity}
        />
        <TimeFrameSelect onChange={handleChangeTimeFrame} options={options} value={timeFrame} />
      </Card.Header>
      <Card.Content contentClassName={styles.content} name={'email-performance-card-content'}>
        <EmailPerformanceCharts
          prioritizeVerifiedActivity={prioritizeVerifiedActivity}
          timeFrameValue={timeFrameValue}
        />
      </Card.Content>
    </Card>
  );
}

export default EmailPerformance;
