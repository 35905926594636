import type { IReactionDisposer, IReactionPublic } from 'mobx';
import { reaction } from 'mobx';
import type { DependencyList } from 'react';
import { useEffect, useRef, useState } from 'react';

interface IUseReactionEffectOptions {
  once?: boolean;
}

export function useReactionEffect<T>(
  expression: (r: IReactionPublic) => T,
  effect: (arg: T, r: IReactionPublic) => void,
  options: IUseReactionEffectOptions = {},
  deps?: DependencyList,
): void {
  const [isRan, setRan] = useState(false);
  const { once = false } = options;
  // Don't store these in state to prevent refreshing the hook on change.
  const isRanImmediately = useRef<boolean>(false);
  const disposer = useRef<IReactionDisposer | null>(null);

  useEffect(
    () => {
      const d = reaction(
        expression,
        (arg, _, r) => {
          if (once && isRan) {
            return;
          }

          /*
           * The reaction function ONLY runs when the return value from
           * expression changes. So we need to use fireImmediately, which ignores
           * whether or not expression is truthy.
           */
          if (!isRanImmediately.current) {
            isRanImmediately.current = true;
            if (!expression(r)) {
              return;
            }
          }

          effect(arg, r);
          setRan(true);
        },
        { fireImmediately: true },
      );
      disposer.current = d;
      return d;
    },
    // Only run this effect once, unless there are dependencies.
    deps ?? [],
  );

  /*
   * Workaround to ensure reaction effect only runs once.
   */
  useEffect(() => {
    if (once && isRan && disposer.current) {
      // Dispose of our reaction after it has run.
      disposer.current();
      disposer.current = null;
    }
  }, [once, isRan, disposer]);
}
