import { Stack } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign } from '@feathr/blackbox';
import { CampaignState, isPinpointCampaign } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import {
  AlertV2 as Alert,
  Button,
  ConfirmModal,
  EAlertV2Type as EAlertType,
  TLDR,
  toast,
} from '@feathr/components';
import CampaignSummary from '@feathr/extender/App/EventsPage/CampaignSummary';
import { useLocalUrl, useStore } from '@feathr/extender/state';

import EventSelect from '../EventSelect';

import * as styles from './CampaignDuplicateModal.css';

interface ICampaignDuplicateModalProps {
  campaign: Campaign;
  opened: boolean;
  close: () => void;
}

function CampaignDuplicateModal({
  campaign,
  opened,
  close,
}: Readonly<ICampaignDuplicateModalProps>): JSX.Element {
  const rootStore = useStore();
  const { Events } = rootStore;
  const { t } = useTranslation();
  const localUrl = useLocalUrl();

  const [selectedProject, setSelectedProject] = useState<ISelectOption>();
  const state = campaign.get('state');
  const isNotDraft = state !== CampaignState.Draft;
  const eventId = campaign.get('event');
  const event = eventId ? Events.get(eventId) : undefined;
  const eventName = event?.name ?? '';

  const flightOrProjectURL = campaign.get('flight')
    ? localUrl(campaign.getItemUrl())
    : localUrl(`/projects/${campaign.get('parent')}/report`);

  // Select the current event anytime the duplicate modal is triggered
  useEffect(() => {
    if (!selectedProject && event && !event.isPending) {
      setSelectedProject({ id: eventId, name: event.name });
    }
  }, [opened, eventId, event]);

  async function duplicate(): Promise<void> {
    try {
      await campaign.cloneCampaign(selectedProject ? { event: selectedProject?.id } : {});

      toast(
        t(
          'Campaign "{{name}}" is being duplicated to {{- eventName}}. We will email you when it is complete.',
          {
            name: campaign.name,
            eventName: selectedProject?.name ?? eventName,
          },
        ),
      );
      close();
    } catch (error: any) {
      toast(
        t('Encountered an error while duplicating:\n{{- errorMessage}}', {
          errorMessage: error.message,
        }),
        {
          type: 'error',
        },
      );
    }
  }

  function handleChangeEvent(event: ISelectOption): void {
    setSelectedProject(event);
  }

  function handleClearEvent(): void {
    setSelectedProject(undefined);
  }

  return (
    <ConfirmModal
      cancelButtonText={t('Cancel')}
      confirmButtonText={t('Duplicate')}
      onClose={close}
      onConfirm={duplicate}
      opened={opened}
      t={t}
      title={t('Duplicate Campaign')}
    >
      <Stack>
        {isNotDraft && (
          <TLDR
            action={<Button link={flightOrProjectURL}>{t('View report')}</Button>}
            description={t(
              "You can easily recalculate conversions from the conversions table on this flight's report to include the newly cloned campaign.",
            )}
            name={'tldr-recalculate-conversions'}
            title={t('Recalculate conversions')}
          />
        )}
        {isPinpointCampaign(campaign) && (
          <EventSelect
            helpText={t('This campaign will be duplicated to the selected project.')}
            id={eventId}
            label={t('Project')}
            onChange={handleChangeEvent}
            onClear={handleClearEvent}
            placeholder={t('Select a project...')}
            value={selectedProject}
          />
        )}
        <CampaignSummary
          campaign={campaign}
          className={styles.campaignSummary}
          layout={'vertical'}
        />
        <Alert
          className={styles.alert}
          description={t("You will receive a notification once it's complete.")}
          title={t('Cloning this campaign will only take a few minutes. ')}
          type={EAlertType.warning}
        />
      </Stack>
    </ConfirmModal>
  );
}

export default observer(CampaignDuplicateModal);
