import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';
import { Collapse } from 'react-collapse';

import type { Flight } from '@feathr/blackbox';
import { Button, Fieldset, FileUpload, Label, Toolbar } from '@feathr/components';
import { useToggle } from '@feathr/hooks';

import * as styles from './FlightPathImagePicker.css';

import defaultFlightImage01 from '@feathr/extender/images/flight_default_01.jpg';
import defaultFlightImage02 from '@feathr/extender/images/flight_default_02.jpg';
import defaultFlightImage03 from '@feathr/extender/images/flight_default_03.jpg';
import defaultFlightImage04 from '@feathr/extender/images/flight_default_04.jpg';
import defaultFlightImage05 from '@feathr/extender/images/flight_default_05.jpg';
import defaultFlightImage06 from '@feathr/extender/images/flight_default_06.jpg';
import defaultFlightImage07 from '@feathr/extender/images/flight_default_07.jpg';

interface IProps {
  flight: Flight;
}

const defaultImages = [
  defaultFlightImage01,
  defaultFlightImage02,
  defaultFlightImage03,
  defaultFlightImage04,
  defaultFlightImage05,
  defaultFlightImage06,
  defaultFlightImage07,
];

function getFlightImageURL(flight: Flight): string {
  const sharedImage = flight.get('shared_image');
  if (!sharedImage) {
    return defaultFlightImage07;
  }
  const tokens = sharedImage.split(':');
  if (tokens.length && tokens[0] === 'defaultFlightImage') {
    return defaultImages[+tokens[1]];
  }
  return sharedImage;
}

function FlightPathImagePicker({ flight }: IProps): JSX.Element {
  const [showDefaultImages, toggleShowDefaultImages] = useToggle(false);
  return (
    <Fieldset className={styles.root}>
      <Label>Image</Label>
      <img alt={''} className={styles.flightImage} src={getFlightImageURL(flight)} />
      <Toolbar align={'left'}>
        <Button
          onClick={toggleShowDefaultImages}
          type={showDefaultImages ? 'secondary' : 'primary'}
        >
          {showDefaultImages ? 'Cancel' : 'Change'}
        </Button>
        <FileUpload
          attribute={'shared_image'}
          label={'Upload'}
          model={flight}
          pickerOptions={{
            transformations: {
              circle: false,
              crop: {
                aspectRatio: 1150 / 215,
                force: true,
              },
              rotate: false,
            },
          }}
        />
      </Toolbar>
      <Collapse isOpened={showDefaultImages}>
        {defaultImages.map((image, index) => (
          <img
            alt={''}
            className={styles.defaultImage}
            key={index}
            onClick={() => {
              flight.set({ shared_image: `defaultFlightImage:${numeral(index).format('00')}` });
              toggleShowDefaultImages();
            }}
            src={image}
          />
        ))}
      </Collapse>
    </Fieldset>
  );
}

export default observer(FlightPathImagePicker);
