import { computed, when } from 'mobx';
import { validate } from 'validate.js';

import { concatPath } from '@feathr/hooks';
import type { IBaseAttributes, IRachisMessage, ListResponse, TConstraints } from '@feathr/rachis';
import { Collection, DisplayModel, isWretchError, wretch } from '@feathr/rachis';

import type { IRedirectDomain } from './redirect_domains';

export type TStatusRecord = Record<EPinpointRequestStatus | 'N/A', string>;

export const domainStatusToColorMap: TStatusRecord = {
  Pending: 'yellow',
  Success: 'green',
  Failed: 'red',
  TemporaryFailure: 'orange',
  NotStarted: 'default',
  /*
   * Adding N/A as default for Domains that have
   * not initated the Email Domain verification process since
   * they won't have DKIM or MX Records with a status.
   */
  'N/A': 'default',
};

export const domainStatusToLabelMap: TStatusRecord = {
  Pending: 'Pending',
  Success: 'Ready',
  Failed: 'Failed',
  TemporaryFailure: 'Erroring',
  NotStarted: 'N/A',
  'N/A': 'N/A',
};

export enum ERegistrars {
  enom = 'enom',
  godaddy = 'godaddy',
  namecheap = 'namecheap',
  networksolutions = 'networksolutions',
  tucows = 'tucows',
  default = 'default',
}

export enum EPinpointRequestStatus {
  Pending = 'Pending',
  Success = 'Success',
  Failed = 'Failed',
  TemporaryFailure = 'TemporaryFailure',
  NotStarted = 'NotStarted',
}

export interface IPinpointRecord {
  name: string;
  value: string;
}

export interface IMXRecord extends IPinpointRecord {
  type: 'MX' | 'TXT';
}

export interface ICNAMERecord extends IPinpointRecord {
  type: 'CNAME';
}

interface IPinpointVerifyIdentityRequest<T extends IPinpointRecord> {
  account: string;
  status: EPinpointRequestStatus;
  records: T[];
}

export interface IDomainIdentityRequest extends IPinpointVerifyIdentityRequest<ICNAMERecord> {
  domain: string;
  tokens: string[];
}

export interface IVerifyMailFromRequest extends IPinpointVerifyIdentityRequest<IMXRecord> {
  email: string;
}

export interface IDomainRecordsForwardRequest {
  /** The DNS host format we should apply to the records. */
  format: ERegistrars;
  /** The message included in the email to the recipient. */
  message: string;
  /** The email address we are sending the records to. */
  recipient: string;
}

export function validateRecordsRequest(
  recordsRequest: IDomainRecordsForwardRequest,
): Record<'recipient', string[]> {
  const errors =
    validate(
      recordsRequest,
      {
        recipient: {
          presence: {
            allowEmpty: false,
            message: '^Please provide a valid email address.',
          },
          email: {
            message: '^Please provide a valid email address.',
          },
        },
      },
      { format: 'grouped' },
    ) || {};

  return errors;
}

export interface IDomainRecordsFormatted extends Record<string, unknown> {
  content_domain: ICNAMERecord;
  dkim: ICNAMERecord[];
  mx: IMXRecord[];
}

interface IDomainRecordsFormattedRequest {
  /** The DNS host format we should apply to the records. */
  format?: ERegistrars;
  /** The format the records should be returned in. */
  type?: 'csv' | 'json';
}

export interface IDomain extends IBaseAttributes {
  account: string;
  /**
   * The domain a user wants to serve content from. Required to create a Domain document.
   * Content Domain records are displayed in the Domains table and must be added to a user's
   * DNS records to verify the Content Domain. Formerly known as "Redirect".
   */
  content_domain: string;
  date_created: string;
  /**
   * DomainKeys Identified Mail records provided by AWS/Pinpoint.
   * Required to be added to user's DNS records for verifying the Email Domain.
   */
  dkim: IDomainIdentityRequest;
  /**
   * The domain a user wants to send emails from. Optional and may be added later.
   * Email addresses belonging to this domain will be automatically verified and
   * not have to be sent a verification email.
   * Requires both DKIM and MX records to be added to the user's Email Domain.
   */
  email_domain: string;
  /**
   * The DNS record that directs email to the user's Email Domain.
   * We provide a default and suggest that the user does not change it.
   */
  mail_from: string;
  /**
   * Mail Exchange records provided by AWS/Pinpoint.
   * Required to be added to user's DNS records for verifying the Email Domain.
   */
  mx: IVerifyMailFromRequest;
  /** The preferred format for the user's DNS records. */
  preferred_format?: ERegistrars;
  /** The RedirectDomain document for the Content Domain. */
  redirect: IRedirectDomain;
}

export class Domain extends DisplayModel<IDomain> {
  public readonly className = 'Domain';
  public get constraints(): TConstraints<IDomain> {
    return {
      content_domain: {
        domain: {
          subdomain: true,
        },
      },
      email_domain: {
        domain: {
          subdomain: false,
        },
      },
      mail_from: {
        domain: {
          subdomain: true,
        },
      },
    };
  }

  public get name(): string {
    return this.get('content_domain') || 'Unnamed domain';
  }

  public async getMatchingDomains(): Promise<ListResponse<Domain>> {
    this.assertCollection(this.collection);

    const response: ListResponse<Domain> = this.collection.list({
      filters: {
        email_domain: this.get('email_domain'),
      },
    });
    await when(() => !response.isPending);

    return response;
  }

  /**
   * Getter that returns the current email sending status.
   * After adding the records to their site, it can take up to 24 hours for the DKIM and MX statuses to update.
   */
  public emailSendingStatus(bypassMX?: boolean): keyof typeof EPinpointRequestStatus {
    // Email sending status is dependent on DKIM and optionally MX statuses.

    const dkimStatus = this.get('dkim')?.status;
    const mxStatus = this.get('mx')?.status;

    if (bypassMX) {
      // If MX is bypassed, only consider DKIM status
      return dkimStatus || EPinpointRequestStatus.NotStarted;
    } else {
      // Consider both DKIM and MX statuses
      const statuses = [dkimStatus, mxStatus];

      /*
       * Both DKIM and MX records must be successfully verified
       * for the email sending status to be "Ready".
       */
      if (statuses.every((status) => status === EPinpointRequestStatus.Success)) {
        return EPinpointRequestStatus.Success;
      }

      if (statuses.includes(EPinpointRequestStatus.Failed)) {
        return EPinpointRequestStatus.Failed;
      }

      if (statuses.includes(EPinpointRequestStatus.Pending)) {
        return EPinpointRequestStatus.Pending;
      }

      return EPinpointRequestStatus.NotStarted;
    }
  }

  /**
   * Getter that returns the current content domain serving status.
   * Does not "fail" after a certain threshold of time.
   */
  @computed
  public get contentDomainStatus(): keyof typeof EPinpointRequestStatus {
    return this.get('redirect').is_verified
      ? EPinpointRequestStatus.Success
      : EPinpointRequestStatus.Pending;
  }

  /**
   * Getter than returns a boolean of whether or not the content domain
   * is verified.
   */
  @computed
  public get isContentServingReady(): boolean {
    return this.contentDomainStatus === EPinpointRequestStatus.Success;
  }

  @computed
  public get isV1(): boolean {
    return !this.isEphemeral && !this.get('email_domain');
  }

  public getItemUrl(pathSuffix?: string): string {
    return concatPath(`settings/account/domains/${this.id}`, pathSuffix);
  }

  /**
   * Allows a user to restart the Email Domain verification process if it failed.
   */
  public async refresh(): Promise<IDomain> {
    this.assertCollection(this.collection);
    const url = `${this.collection.url()}${this.id}/refresh`;
    const response = await wretch<IDomain>(url, {
      headers: this.collection.getHeaders(),
      method: 'GET',
    });

    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data;
  }

  /**
   * Method that sends an email containing formatted domain records to the provided email address.
   */
  public async sendRecords({
    format,
    message,
    recipient,
  }: IDomainRecordsForwardRequest): Promise<IRachisMessage> {
    this.assertCollection(this.collection);

    if (validateRecordsRequest({ format, message, recipient }).recipient) {
      throw new Error('Please provide a valid email');
    }
    const params = new URLSearchParams({
      format,
      message,
      recipient,
    });
    const url = `${this.collection.url()}${this.id}/send_records?${params.toString()}`;
    const response = await wretch<IRachisMessage>(url, {
      headers: this.collection.getHeaders(),
      method: 'POST',
    });
    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data;
  }

  /**
   * Method that returns a set of formatted records based on the selected DNS host.
   * Can return as JSON for display in UI or CSV for downloads.
   */
  public async formatRecords({
    format = ERegistrars.default,
    type = 'json',
  }: IDomainRecordsFormattedRequest): Promise<IDomainRecordsFormatted> {
    this.assertCollection(this.collection);

    const params = new URLSearchParams({ format, type });
    const url = `${this.collection.url()}${this.id}/records?${params.toString()}`;
    // TODO: If `type` is `csv` then wretch will not work as expected.
    const response = await wretch<IDomainRecordsFormatted>(url, {
      headers: this.collection.getHeaders(),
      method: 'GET',
    });
    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data;
  }

  public getDownloadUrl(format: ERegistrars): string {
    this.assertCollection(this.collection);

    const params = new URLSearchParams({ format, type: 'csv' });
    return `${this.collection.url()}${this.id}/records?${params.toString()}`;
  }
}

export class Domains extends Collection<Domain> {
  public getModel(attributes: IDomain): Domain {
    return new Domain(attributes);
  }

  public getClassName(): string {
    return 'domains';
  }
}
