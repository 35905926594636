import type { Dispatch, FocusEvent, JSX, SetStateAction } from 'react';
import React from 'react';

import type { Form } from '@feathr/blackbox';
import { defaultFormDesignSettings } from '@feathr/blackbox';
import { Button } from '@feathr/components';

import * as styles from './SubmitButton.css';

const {
  submitButtonTextSize: defaultSubmitButtonTextSize,
  submitLabel: defaultSubmitLabel,
  buttonShape: defaultButtonShape,
  submitButtonColor: defaultSubmitButtonColor,
  submitButtonTextColor: defaultSubmitButtonTextColor,
  typeface: defaultTypeface,
} = defaultFormDesignSettings;

interface IProps {
  form: Form;
  setIsFocused: Dispatch<SetStateAction<boolean>>;
}

export default function SubmitButton({ form, setIsFocused }: Readonly<IProps>): JSX.Element {
  const {
    submitLabel: label = defaultSubmitLabel,
    buttonShape: shape = defaultButtonShape,
    submitButtonColor: backgroundColor = defaultSubmitButtonColor,
    submitButtonTextColor: color = defaultSubmitButtonTextColor,
    submitButtonTextSize: fontSize = defaultSubmitButtonTextSize,
    typeface: fontFamily = defaultTypeface,
  } = form.formConfig.settings;

  function handleClick(): void {
    setIsFocused(true);
  }

  function handleBlur(event: FocusEvent<HTMLButtonElement | HTMLAnchorElement>): void {
    if (event.relatedTarget === null) {
      setIsFocused(false);
    }
  }

  return (
    <Button
      buttonShape={shape}
      className={styles.root}
      onBlur={handleBlur}
      onClick={handleClick}
      style={{
        backgroundColor,
        color,
        fontFamily: `${fontFamily}, sans-serif`,
        fontSize: `${fontSize}px`,
      }}
    >
      {label}
    </Button>
  );
}
