import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Nav, NavItem } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';

function EventContentNav(): JSX.Element {
  const localUrl = useLocalUrl();
  const { eventId } = useParams<{ eventId: string }>();
  const { t } = useTranslation();

  return (
    <Nav indented={true} label={'Project content navigation'} type={'vertical'}>
      <NavItem to={localUrl(`/projects/${eventId}/content/creatives`)}>{t('Creatives')}</NavItem>
      <NavItem to={localUrl(`/projects/${eventId}/content/templates`)}>{t('Templates')}</NavItem>
      <NavItem to={localUrl(`/projects/${eventId}/content/forms`)}>{t('Forms')}</NavItem>
    </Nav>
  );
}

export default observer(EventContentNav);
