import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { IUsageMetric, TUsageMetrics } from '@feathr/blackbox';
import { EUsageMetrics } from '@feathr/blackbox';

import { useFlags, useStore } from '.';

interface IUsageAndQuotaReturn {
  quota: Partial<IUsageMetric>;
  usage: Partial<IUsageMetric>;
  isUsageQuotaLoading: boolean;
  showUsage: boolean;
  getUsageText: (metric?: TUsageMetrics) => string | null;
}

const activeMetrics = [
  EUsageMetrics.GeofencingCampaigns,
  EUsageMetrics.HistoricalGeofencingCampaigns,
  EUsageMetrics.EmailMappingCampaigns,
];

function useUsageAndQuota(
  accountId: string,
  metrics: TUsageMetrics[],
  usageOverride: number | string | null = null,
): IUsageAndQuotaReturn {
  const { UsageMetrics, UsageQuotas } = useStore();
  const flags = useFlags();
  const { t } = useTranslation();
  const [usage, setUsage] = useState<Partial<IUsageMetric>>({});
  const [quota, setQuota] = useState<Partial<IUsageMetric>>({});
  const [isUsageQuotaLoading, setIsUsageQuotaLoading] = useState<boolean>(false);

  const showUsage = (flags.showUsageMetrics && !isUsageQuotaLoading && !!quota && !!usage) || false;

  /*
   * Return the given metric as display text with usage and quota.
   * If no metric is provided, use the first one from the metrics passed to the hook.
   */
  const getUsageText = (metric = metrics[0]): string | null => {
    return showUsage
      ? t('{{usage}} of {{quota}} {{label}}', {
          usage: usageOverride ?? usage[metric],
          quota: quota[metric] ?? 'unlimited',
          label: activeMetrics.includes(metric) ? 'active' : 'used',
        })
      : null;
  };

  // Get usage and quota on first render
  useEffect(() => {
    getUsageAndQuota();
  }, []);

  const getQuotaPromise = useCallback(async () => {
    await UsageQuotas.getSelectedQuotas(accountId, metrics).then((result) => {
      setQuota((prev) => {
        const newState = {};
        metrics.forEach((key: TUsageMetrics) => {
          newState[key] = result[key]?.quota;
        });
        return { ...prev, ...newState };
      });
    });
  }, [UsageQuotas, accountId, metrics]);

  const getUsagePromise = useCallback(async () => {
    // If usage override is passed skip retrieving usage from endpoint
    if (usageOverride !== null) {
      return;
    }
    const usagePromise = UsageMetrics.getSelectedMetrics(accountId, metrics);
    await usagePromise.then((result) => {
      setUsage((prev) => {
        const newState = {};
        metrics.forEach((key: TUsageMetrics) => {
          newState[key] = result[key];
        });
        return { ...prev, ...newState };
      });
    });
  }, [UsageMetrics, accountId, metrics, usageOverride]);

  const getUsageAndQuota = useCallback(async () => {
    setIsUsageQuotaLoading(true);
    getUsagePromise()
      .then(getQuotaPromise)
      .finally(() => {
        setIsUsageQuotaLoading(false);
      });
  }, [getUsagePromise, getQuotaPromise]);

  return { quota, usage, isUsageQuotaLoading, showUsage, getUsageText };
}

export default useUsageAndQuota;
