import type { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  faAddressBook,
  faAddressCard,
  faArrowPointer,
  faBrowser,
  faBullseyePointer,
  faChartPie,
  faCheck,
  faCode,
  faCommentDots,
  faEnvelope,
  faEnvelopeOpenText,
  faFileCheck,
  faFileImport,
  faGear,
  faMousePointer,
  faPaperPlane,
  faRectangleAd,
  faRobot,
  faStar,
} from '@fortawesome/pro-light-svg-icons';
import { faStarExclamation } from '@fortawesome/pro-solid-svg-icons';
import type { TFunction } from 'i18next';
import capitalize from 'lodash.capitalize';

import type { FlavorKeys } from '@feathr/blackbox';

function getLabel(t: TFunction, map: Record<string, string>, key?: string): string {
  return !key ? t('Unknown') : map[key] || capitalize(key);
}

export function flavorLabel(t: TFunction, flavor: FlavorKeys): string {
  const map: Record<FlavorKeys, string> = {
    ad_click: t('Clicked ad'),
    ad_view: t('Viewed ad'),
    blocked: t('Blocked'),
    bot_partner_message_link_click: t('Bot clicked partner message link'),
    bot_partner_message_unsubscribe: t('Bot unsubscribed from partner message'),
    bot_partner_message_view: t('Bot viewed partner message'),
    bot_pinpoint_tracked_email_click: t('Bot clicked email'),
    bot_pinpoint_tracked_email_open: t('Bot opened email'),
    bot_pinpoint_tracked_email_unsubscribe: t('Bot unsubscribed from email'),
    contact_list_import: t('Imported contact list'),
    conversation_response: t('Conversation response'),
    conversion: t('Conversion'),
    convert: t('Conversion pixel'),
    created_by_import: t('Created by import'),
    custom: t('Custom'),
    dashboard_create: t('Created dashboard'),
    dashboard_import: t('Imported contacts on partner dashboard'),
    dashboard_link_click: t('Clicked dashboard link'),
    dashboard_send_complete: t('Sent email from partner dashboard'),
    dashboard_send_request: t('Scheduled email invite'),
    dashboard_submit: t('Updated dashboard profile'),
    dashboard_view: t('Viewed partner dashboard'),
    email_bot_detection_click: t('Bot detection clicked email'),
    email_link_click: t('Clicked link in email'),
    email_not_sent: t('Was not sent email'),
    email_preferences_resubscribe: t('Resubscribed to email'),
    email_preferences_update: t('Updated email preferences'),
    email_send: t('Sent email'),
    email_view: t('Viewed email'),
    form_submission: t('Submitted form'),
    form_view: t('Viewed form'),
    imis_create: t('Created by iMIS'),
    imis_update: t('Updated by iMIS'),
    page_link_click: t('Clicked link on page'),
    page_view: t('Viewed page'),
    partner_message_complaint: t('Reported partner message as spam'),
    partner_message_delivered: t('Received partner message'),
    partner_message_hardbounce: t('Partner message hard bounce'),
    partner_message_link_click: t('Clicked link in partner message'),
    partner_message_rendering_failure: t('Partner message rendering failed'),
    partner_message_send: t('Was sent partner message'),
    partner_message_softbounce: t('Partner message soft bounce'),
    partner_message_suppression: t('Partner message was suppressed'),
    partner_message_unsubscribe: t('Unsubscribed from partner message'),
    partner_message_view: t('Viewed partner message'),
    pinpoint_tracked_email_click: t('Clicked email'),
    pinpoint_tracked_email_complaint: t('Reported email as spam'),
    pinpoint_tracked_email_delivered: t('Received email'),
    pinpoint_tracked_email_hardbounce: t('Hard bounce'),
    pinpoint_tracked_email_open: t('Opened email'),
    pinpoint_tracked_email_rendering_failure: t('Email template rendering failed'),
    pinpoint_tracked_email_send: t('Was sent email'),
    pinpoint_tracked_email_softbounce: t('Soft bounce'),
    pinpoint_tracked_email_suppression: t('Email was suppressed'),
    pinpoint_tracked_email_unsubscribe: t('Unsubscribed from email'),
    raisers_edge_constituent_created: t("Created by Raiser's Edge NXT"),
    raisers_edge_constituent_updated: t("Updated by Raiser's Edge NXT"),
    raisers_edge_constituent_summary: t("Raiser's Edge NXT Giving Summary updated"),
    raisers_edge_gift_added: t("Raiser's Edge NXT gift added"),
    report_link_click: t('Clicked report link'),
    report_view: t('Viewed campaign report'),
    shared_email_preferences_update: t('System updated email preferences'),
    update: t('Profile update'),
    user_create: t('Manually created'),
    user_update: t('Manually updated'),
    updated_by_import: t('Updated by import'),
    zapier_import: t('Zapier import'),
    zapier_update: t('Zapier update'),
  };

  return getLabel(t, map, flavor);
}

export function flavorColor(flavor: FlavorKeys): string {
  const map: Record<FlavorKeys, string> = {
    ad_click: 'sky',
    ad_view: 'sky',
    blocked: 'gray',
    bot_partner_message_link_click: 'gray',
    bot_partner_message_unsubscribe: 'gray',
    bot_partner_message_view: 'gray',
    bot_pinpoint_tracked_email_click: 'gray',
    bot_pinpoint_tracked_email_open: 'gray',
    bot_pinpoint_tracked_email_unsubscribe: 'gray',
    contact_list_import: 'indigo',
    conversation_response: 'gray',
    conversion: 'orange',
    convert: 'orange',
    created_by_import: 'indigo',
    custom: 'default',
    dashboard_create: 'orange',
    dashboard_import: 'green',
    dashboard_link_click: 'gray',
    dashboard_send_complete: 'orange',
    dashboard_send_request: 'green',
    dashboard_submit: 'green',
    dashboard_view: 'teal',
    email_bot_detection_click: 'gray',
    email_link_click: 'yellow',
    email_not_sent: 'yellow',
    email_preferences_resubscribe: 'yellow',
    email_preferences_update: 'yellow',
    email_send: 'yellow',
    email_view: 'yellow',
    form_submission: 'blue',
    form_view: 'blue',
    imis_create: 'orange',
    imis_update: 'orange',
    page_link_click: 'green',
    page_view: 'teal',
    partner_message_complaint: 'gray',
    partner_message_delivered: 'gray',
    partner_message_hardbounce: 'gray',
    partner_message_link_click: 'yellow',
    partner_message_rendering_failure: 'gray',
    partner_message_send: 'yellow',
    partner_message_softbounce: 'gray',
    partner_message_suppression: 'gray',
    partner_message_unsubscribe: 'gray',
    partner_message_view: 'yellow',
    pinpoint_tracked_email_click: 'yellow',
    pinpoint_tracked_email_complaint: 'red',
    pinpoint_tracked_email_delivered: 'yellow',
    pinpoint_tracked_email_hardbounce: 'red',
    pinpoint_tracked_email_open: 'yellow',
    pinpoint_tracked_email_rendering_failure: 'red',
    pinpoint_tracked_email_send: 'yellow',
    pinpoint_tracked_email_softbounce: 'red',
    pinpoint_tracked_email_suppression: 'yellow',
    pinpoint_tracked_email_unsubscribe: 'yellow',
    raisers_edge_constituent_created: 'purple',
    raisers_edge_constituent_summary: 'purple',
    raisers_edge_constituent_updated: 'purple',
    raisers_edge_gift_added: 'purple',
    report_link_click: 'teal',
    report_view: 'teal',
    shared_email_preferences_update: 'yellow',
    update: 'indigo',
    updated_by_import: 'indigo',
    user_create: 'default',
    user_update: 'default',
    zapier_import: 'orange',
    zapier_update: 'orange',
  };
  return map[flavor] ?? 'default';
}

export function flavorIcon(flavor: FlavorKeys): IconDefinition {
  const map: Record<FlavorKeys, IconDefinition> = {
    ad_click: faRectangleAd,
    ad_view: faRectangleAd,
    blocked: faMousePointer,
    bot_partner_message_link_click: faRobot,
    bot_partner_message_unsubscribe: faRobot,
    bot_partner_message_view: faRobot,
    bot_pinpoint_tracked_email_click: faRobot,
    bot_pinpoint_tracked_email_open: faRobot,
    bot_pinpoint_tracked_email_unsubscribe: faRobot,
    contact_list_import: faAddressCard,
    conversation_response: faCommentDots,
    conversion: faBullseyePointer,
    convert: faCode,
    created_by_import: faFileImport,
    custom: faStar,
    dashboard_create: faStarExclamation,
    dashboard_import: faAddressBook,
    dashboard_link_click: faBrowser,
    dashboard_send_complete: faCheck,
    dashboard_send_request: faPaperPlane,
    dashboard_submit: faAddressCard,
    dashboard_view: faBrowser,
    email_bot_detection_click: faRobot,
    email_link_click: faEnvelope,
    email_not_sent: faEnvelope,
    email_preferences_resubscribe: faEnvelope,
    email_preferences_update: faGear,
    email_send: faEnvelope,
    email_view: faEnvelope,
    form_submission: faFileCheck,
    form_view: faFileCheck,
    imis_create: faFileImport,
    imis_update: faAddressCard,
    page_link_click: faBrowser,
    page_view: faBrowser,
    partner_message_complaint: faEnvelope,
    partner_message_delivered: faEnvelope,
    partner_message_hardbounce: faEnvelope,
    partner_message_link_click: faEnvelope,
    partner_message_rendering_failure: faEnvelope,
    partner_message_send: faEnvelope,
    partner_message_softbounce: faEnvelope,
    partner_message_suppression: faEnvelope,
    partner_message_unsubscribe: faEnvelope,
    partner_message_view: faEnvelopeOpenText,
    pinpoint_tracked_email_click: faEnvelope,
    pinpoint_tracked_email_complaint: faEnvelope,
    pinpoint_tracked_email_delivered: faEnvelope,
    pinpoint_tracked_email_hardbounce: faEnvelope,
    pinpoint_tracked_email_open: faEnvelopeOpenText,
    pinpoint_tracked_email_rendering_failure: faEnvelope,
    pinpoint_tracked_email_send: faEnvelope,
    pinpoint_tracked_email_softbounce: faEnvelope,
    pinpoint_tracked_email_suppression: faEnvelope,
    pinpoint_tracked_email_unsubscribe: faEnvelope,
    raisers_edge_constituent_created: faFileImport,
    raisers_edge_constituent_summary: faFileImport,
    raisers_edge_constituent_updated: faFileImport,
    raisers_edge_gift_added: faFileImport,
    report_link_click: faChartPie,
    report_view: faChartPie,
    shared_email_preferences_update: faGear,
    update: faAddressCard,
    updated_by_import: faAddressCard,
    user_create: faArrowPointer,
    user_update: faArrowPointer,
    zapier_import: faFileImport,
    zapier_update: faFileImport,
  };
  return map[flavor] ?? faMousePointer;
}
